import React from "react";
import { LocalizedLink } from "src/components/commonComponents";
export const Banner = ({ logo, title, description, linkText, linkUrl, isHtmlDescription = false }) => {
  const imageAttrs = logo?.gatsbyImage?.images?.fallback
  return (
    <LocalizedLink to={linkUrl} className="banner-box tw-banner-box tw-mt-4 md:!tw-mt-0 tw-rounded-[32px] tw-bg-[#485FC7] tw-w-full  tw-p-4 tw-pb-[20px] lg:tw-pb-4 tw-justify-between tw-text-center lg:tw-text-left tw-flex tw-items-center tw-flex-col lg:tw-flex-row">
      {logo && <div className="logo tw-w-[180px] tw-h-auto">
      {imageAttrs && <img
            {...imageAttrs}
            alt={logo?.alt}
            loading="eager"
            fetchpriority="high"
            width={180}
            height={132}
            decoding = "async"
            className="tw-h-auto tw-w-full"
          />}
      </div>
      }
      <div className="banner-content tw-mx-4 tw-my-[25px] ">
        {title && <h5 className="banner-title title-5 !tw-text-accruent_white">{title}</h5>}
        {description && (
          <div className="banner-description dangerously-html-handl">
            {isHtmlDescription ?
              <div dangerouslySetInnerHTML={{
                __html: description,
              }} ></div> :
              <p>{description}</p>}
          </div>
        )}
      </div>
      {linkText && (
        <div className="banner-cta-link tw-mx-[40px]">
          <div className="learn-more tw-font-bold tw-text-[16px] tw-leading-4 tw-tracking-[0px] tw-font-Poppins tw-text-accruent_white hover:tw-underline tw-whitespace-nowrap hover:tw-text-accruent_white">
            {linkText}
          </div>
        </div>
      )}
    </LocalizedLink>
  );
}

// export default Banner;
