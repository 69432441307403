import React, { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { navigate } from "gatsby";
import { globalHistory } from "@reach/router";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import ResourcesContext from "src/components/helpers/ResourcesMainPageContext";
import SocialMediaIcons from "../helpers/SocialMediaIconsData";
import findSiteType from "src/components/helpers/findSiteType";
import useMountingForIcon from "src/hooks/useMountingForIcon";
import { useLocation }  from '@reach/router';
import getPathWithQueryParam from "src/../helpers/getPathWithQueryParam";
import { LocalizedLink,GlobalDropDown } from "../commonComponents";
import chevronAngleLeft from "../../../static/chevron-left-white.svg";
import angleRightBlack from "../../../static/angle-right-black.svg";
import "src/scss/_navbarMobile.scss"
import "src/styles/navbarMobile.css"
const NavbarMobile = ({
  currentNavItem,
  setCurrentNavItem,
  navigationLeft,
  setNavigationLeft,
  navCtaButtons,
  handleWatchDemoToggle,
  locale,
  innerCleanFilterWhenClickingResources,
  navbarId
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { navData, eyebrowNavData } = useContext(ResourcesContext);
  const navbarStart = navData?.slice(0, (navData?.length - 2))
  const {fetchedCookies} = useContext(ResourcesContext);

  let parent = null;
  let parentKey = "very-top";
  let navItems = [];
  const isMC = findSiteType('mc')
  const isEMS = findSiteType('ems')
  const mounted = useMountingForIcon();
  useEffect(() => {
    return globalHistory.listen(() => {
      if (typeof document !== "undefined") {
        //This sets the navigation when clicking on a new page from outside the navigation menu
        setCurrentNavItem(
          (
            navData.filter(
              ({ name }) =>
                name !== "Search" && name !== "Request Info" && name !== "Request Demo" && name !== "Get Started" &&
                name !== "Watch Demo" &&
                name !== "Free Trial" &&
                name !== "Contact Us"
            )
          )
        );
        document.documentElement.classList.remove("has-mobile-nav-open");
        document
          .getElementById(navbarId)
          .classList.remove("is-active");
        var $navbarBurgers = Array.prototype.slice.call(
          document.querySelectorAll(".navbar-burger"),
          0
        );
        if ($navbarBurgers.length > 0) {
          $navbarBurgers.forEach(function($el) {
            $el.classList.remove("is-active");
          });
        }
      }
    });
  });

  //This conditional block determines which nav items to show
  //when click around in the mobile nav menu.
  if (!Array.isArray(currentNavItem) && !!currentNavItem) {
    if (currentNavItem.parent) {
      //Third level and final level in navigation.
      parent = currentNavItem.parent;
      parentKey = parent.name;
    } else {
      //Second level parent.
      parent = navData.filter(
        ({ name }) =>
          name !== "Search" && name !== "Request Info" && name !== "Request Demo" && name !== "Get Started" &&
          name !== "Watch Demo" &&
          name !== "Free Trial" &&
          name !== "Contact Us"
      );
      parentKey = "categories";
    }
    navItems = currentNavItem.children;
  } else {
    //Top level parent.
    navItems = currentNavItem;
  }
  const onClickNavItem = (navItem) => (event) => {
    if (navItem.children?.length) {
      if (navItem.children?.length === 1) {
        setNavigationLeft(true);
        setCurrentNavItem(navItem.children[0]);
      } else {
        setNavigationLeft(true);
        setCurrentNavItem(navItem);
      }
    } else {
      innerCleanFilterWhenClickingResources(navItem.link)
      navigate(getPathWithQueryParam(location, navItem.link, fetchedCookies));
    }
  };

  const navigateLeft = (parent) => {
    if (parent?.children?.length === 1) {
      parent = parent?.parent;
      parentKey = parent ? parent.name : "very-top";
    }
    setNavigationLeft(false);
    setCurrentNavItem(parent);
  };

  const transitionClassName = navigationLeft ? "slide-out" : "slide-in";
  return (
    <div className="navbar-mobile">
      <TransitionGroup>
        <CSSTransition
          key={parentKey}
          classNames={transitionClassName}
          timeout={{ enter: 500, exit: 500 }}
        >
          <div className="columns is-multiline">
            {!parent && (
              <div className="column is-12 has-text-centered pb-0 mt-2">
                {(navCtaButtons?.[1]?.name?.includes("Demo") || navCtaButtons?.[0]?.name?.includes("Demo")) && 
                  <div>
                    {(!isEMS && !isMC) ?
                    <button
                      className="button is-carmine"
                    >
                      <LocalizedLink className="has-text-white px-7" to={locale === 'en' ? '/request-demo' : 'https://www.accruent.com/request-demo'}>
                        Request Demo
                      </LocalizedLink>
                    </button>
                    :
                    <button
                    className="button is-carmine"
                    >
                      <span
                      >
                       {isMC ? 
                       <LocalizedLink className="has-text-white px-7" to={navCtaButtons?.[0]?.link}>
                          {navCtaButtons?.[0]?.name}
                       </LocalizedLink>
                        : 
                      <LocalizedLink className="has-text-white px-7" to={navCtaButtons?.[1]?.link}>
                          {navCtaButtons?.[1]?.name}
                       </LocalizedLink>
                      }
                      </span>
                    </button>
                    }
                  </div>
                  }
              </div>
            )}
            {parent && (
              <div className="header" onClick={() => navigateLeft(parent)}>
                <button className="button is-text">
                <img src={chevronAngleLeft} decoding="async" className="svg-inline--fa fa-chevron-left icon tw-max-w-max"/>
                {/* <svg class="svg-inline--fa fa-chevron-left icon" data-prefix="fas" data-icon="chevron-left" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z"></path></svg> */}
                </button>
                <span className="text has-text-centered">
                  {currentNavItem.name}
                </span>
              </div>
            )}
            <div className="column is-12 pt-0">
              <div className="nav-items">
                {!!parent && currentNavItem.link && (currentNavItem.link!='#') && (
                  <button
                    onClick={onClickNavItem({ link: currentNavItem.link })}
                    className="button is-text nav-item"
                  >
                    All {currentNavItem.name}
                  </button>
                )}
                {navItems?.map((navItem) => (
                  <button
                    key={navItem.name}
                    onClick={onClickNavItem(navItem)}
                    className="button is-text nav-item"
                  >
                    {navItem.name}
                    {!!navItem.children.length && mounted && (
                      <span className="icon">
                        <img src={angleRightBlack} decoding="async" className="svg-inline--fa fa-chevron-right arrow-svg"/>
                       {/* <svg class="svg-inline--fa fa-chevron-right arrow-svg" aria-hidden="true" data-prefix="fas" data-icon="chevron-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"></path></svg> */}
                      </span>
                    )}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </CSSTransition>
      </TransitionGroup>
      <div className="columns is-multiline is-lilac mb-0 pt-1">
          <div className="column is-12 has-text-centered">
            <button
              onClick={onClickNavItem({ link: currentNavItem?.link })}
              className="button is-info is-outlined has-background-white"
            >
              {navCtaButtons?.[0] && (navCtaButtons[0]?.name?.includes("Request Info") || navCtaButtons[0]?.name.includes("Get Started") || navCtaButtons[0]?.name.includes("Contact Us"))  && (
                <LocalizedLink className="px-8" to={navCtaButtons[0].link}>
                  {navCtaButtons[0].name}
                </LocalizedLink>
              )}
              {navCtaButtons?.[1] && navCtaButtons[1]?.name.includes("Free Trial") && (
                <LocalizedLink className="px-8" to={navCtaButtons[1].link}>
                  {navCtaButtons[1].name}
                </LocalizedLink>
              )}
            </button>
          </div>
          <div className="column is-12">
            <div className="columns is-mobile has-text-centered is-justify-content-center">
              {eyebrowNavData?.map(({ name, link }, index) => (
                <div className="column is-3" key={`${name}_${index}`}>
                  <span key={name} className="nav-item">
                    <LocalizedLink to={link}>{t(name)}</LocalizedLink>
                  </span>
                </div>
              ))}
            </div>
            {!(isEMS || isMC) && 
               <span className="is-mobile has-text-centered">
                  <GlobalDropDown locale={locale} />
                </span> 
           }
          </div>
          <div className="column is-12 py-4 has-background-white">
            <div className="columns is-mobile has-text-centered is-justify-content-center">
              {SocialMediaIcons?.map(({ icon, account, href }, index) => (
                <div className="column is-2" key={`${account}_${index}`}>
                  <a
                    href={href}
                    rel="nofollow"
                    aria-label={`Accruent on ${account}`}
                    target="_blank"
                  >
                    {mounted && <span className="icon has-text-black">
                      <i className={icon}></i>
                    </span>}
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
    </div>
  );
};

export default NavbarMobile;
