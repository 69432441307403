export const getCountryNameFromTimezone = (timezone = "America/Chicago") => {
    const usTimeZoneCountries = ["Adak", "Anchorage", "Boise", "Center", "Chicago", "Denver",
    "Detroit", "Honolulu", "Indianapolis", "Juneau", "Knox", "Los_Angeles", "Louisville", 
    "Marengo", "Menominee", "Metlakatla", "Monticello", "New_Salem", "New_York", "Nome", 
    "Petersburg", "Phoenix", "Sitka", "Tell_City", "Vevay", "Vincennes", "Winamac", "Yakutat"
    ]
   
    let userRegion = ''
    let userCity = ''
    let isUserUsa = false
    let userTimeZone = ''

    if (Intl) {
        userTimeZone = timezone
        var tzArr = userTimeZone.split("/")
        userRegion = tzArr[0]
        userCity = tzArr[tzArr.length - 1]
        if (userCity == "Calcutta") {
            userCity = "Kolkata"
        }
        if(usTimeZoneCountries.includes(userCity)){
            isUserUsa = true
        }
    }
    return isUserUsa
}