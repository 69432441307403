/**
 * Convert HTML to the string
 * @param  {String} str string
 */
const stringFromHTML = function(str) {
  if (str) {
    const tagStr = str.replace("&nbsp;", " ").replace("|&nbsp;", " ") || "";
    const pTags = tagStr.match(/<p[^>]*>(.*?)<\/p>/gm);

    let body = "";
    (pTags || []).forEach((pTag) => {
      body += pTag.replace("<p>", "").replace("</p>", "");
    });
    return body.replace(/<\/?[^>]+(>|$)/g, "");
  } else return null;
};

module.exports = stringFromHTML;
