import React from "react";
import getBenefitsData from "../helpers/getBenefitsData";
import { PricingCards } from "./pricingCards";
import { TabsBox } from "./tabsBox";
import { BenefitsWrapper } from "./benefitsCard";
export const PricingSectionBenefits = ({ pricingItem, entity, locale }) => {
  const benefitsData = getBenefitsData(pricingItem?.relationships?.fieldBenefitsSection, "pricing_section", false, locale);
  const bg = entity?.relationships?.fieldBackgroundImage

  return (
    <div className='' >
      {!!benefitsData && <div className="md:tw-w-[66%] tw-w-full tw-mx-auto tw-mb-auto md:tw-mt-2 tw-mt-3">
        <BenefitsWrapper benefits={[benefitsData]} locale={locale} />
      </div>
      }
      <TabsBox
        tabs={["MONTHLY", "YEARLY"]}
        tabClass={"round-button-tab tw-w-fit tw-rounded-[21px] tw-bg-accruent_mist tw-p-[2px] tw-flex tw-items-center tw-justify-start"}
        isToogle = {true}
      >
        {(currentTab) => <PricingCards version='V2' isMonthly={currentTab === "MONTHLY"} fieldPricingItemCard={pricingItem.relationships?.fieldPricingItemCard} bg={bg} />}
      </TabsBox>

    </div>
  )

}
