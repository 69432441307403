import React, { useEffect, useRef } from "react";
import bulmaCollapsible from "@creativebulma/bulma-collapsible/src/js/index.js";
import cn from "classnames";
import { LocalizedLink } from "./localizedLink";
/**
 *
 * @param {Array of Objects} data - [{ title: String, content: String, id: String/Number }]
 * @param {string} id - asked by Bulma Collapsible API to be arount wrapper container
 * @param {boolean} isColoredQa - to change fontAwsome icon for colored QA list
 * @param {Number} activeItemId - used to set Active Accordion on the basis of item id.
 * @param {Function} handleClick - used to handle click event of Accordion Header.
 * @param {boolean} allowMultiple - asked by Bulma Collapsible API to allow multiple active Accordion.
 * Used in in /solutions/cmms
 */
export const Accordion = ({ data, id = "my_id", isCapitalized = false, isQa = false, isLegal = false, isColoredQa = false, allowMultiple = true, activeItemId = null, handleClick = null }) => {
  const callapsibleRef = useRef();
  useEffect(() => {
    if (callapsibleRef.current && !handleClick) {
      callapsibleRef.current = bulmaCollapsible.attach(".tw-is-collapsible", {
        container: callapsibleRef.current,
        allowMultiple: allowMultiple,
      });
    }
  }, []);

  const onClick = (e, item) => {
    if (handleClick) {
      e?.preventDefault();
      handleClick(item)
    }
  }

  return (
    <div id={id} ref={callapsibleRef} className="collapisible-accordion">
      {data.map((item, index) => {
        const isContentReactElement = React.isValidElement(item.content);
        return (
          <article className={`${isColoredQa ? 'tw-qa-list-is-colored-qa-section-message tw-bg-transparent tw-border-2 tw-border-solid tw-border-accruent_white tw-rounded-3xl tw-mb-[15px] tw-p-0' : isLegal ? "tw-mb-2" : 'tw-mb-0'} ${(activeItemId === item.id) ? 'is-open' : ''}`} key={item.id}>
            <a
              data-action="collapse"
              href={`#${item.id}`}
              className="!tw-no-underline tw-text-accruent-heading-color"
              onClick={(e) => { onClick(e, item) }}
            >
              <div className= {`${isColoredQa ? 'tw-qa-list-is-colored-qa-section-message-header tw-p-4 md:tw-pt-[38px] md:tw-pr-10 md:tw-pb-[34px] md:tw-pl-6 !tw-bg-transparent tw-border-none !tw-text-accruent_white tw-rounded-t tw-rounded-b-none' : isLegal ? "tw-border-none !tw-bg-accruent_white tw-mx-4 tw-px-4 md:tw-mx-0  md:!tw-bg-accruent_slightgray" : 'tw-text-heading_color tw-p-4 !tw-bg-accruent_white tw-border-b-[1px] tw-border-solid tw-border-accruent_lightgrey tw-rounded-t tw-rounded-b-none'} tw-items-center tw-border-black tw-border-solid tw-border-b tw-bg-white tw-flex tw-justify-between tw-p-4 tw-relative`} data-action="collapse">
                {isQa ? (
                  <h3
                    className={`${isColoredQa ? 'tw-text-accruent_white tw-mr-11 !tw-mb-0' : isLegal ? "tw-text-accruent_blue !tw-mb-0" : '!tw-mb-0'} !tw-text-newH6Mobile md:!tw-text-newH6Desktop tw-break-normal tw-text-left tw-mb-4 ${isCapitalized}`}
                  >
                    {item.title}
                  </h3>
                ) : (
                  <h6
                    className={`${isColoredQa ? 'tw-text-accruent_white tw-mr-11 !tw-mb-0' : isLegal ? "tw-text-accruent_blue !tw-mb-0" : '!tw-mb-0'} tw-text-mobileLgFont md:tw-text-lgFont tw-break-normal tw-text-left tw-mb-4 ${isCapitalized}`}
                  >
                    {item.title}
                  </h6>
                )}
                {/* Open/Close */}
                {isQa ? (
                  <span className="tw-justify-end tw-text-base md:tw-text-xl">
                    <i className="fas fa-angle-down"></i>
                    <i className="fas fa-angle-up tw-hidden"></i>
                  </span>
                ) : (isColoredQa ? (
                  <span className="icon is-size-3 icon-accordion !tw-justify-end">
                    <i class="fa-solid fa-circle-chevron-down"></i>
                    <i class="fa-solid fa-circle-chevron-up !tw-hidden"></i>
                  </span>
                ) : (
                  <span className={`${isLegal ? 'tw-text-accruent_blue' : ''} icon is-size-4 is-size-5-tablet is-size-6-mobile`}>
                    <i className="fas fa-plus"></i>
                    <i className="fas fa-minus tw-hidden"></i>
                  </span>
                ))}
              </div>
            </a>
            <div
              id={item.id}
              className={`${isColoredQa ? 'tw-qa-list-is-colored-qa-section-message-body tw-hidden tw-bg-transparent tw-text-accruent_white tw-border-none' : 'tw-bg-white tw-border-none tw-p-0'} tw-is-collapsible tw-overflow-y-hidden tw-p-0`}
              data-parent={id}
            >
              {isContentReactElement ? (
                item.content
              ) : (
                isLegal ?
                  <div className="tw-px-6 tw-pb-[30px] tw-pt-2 tw-bg-accruent_slightgray md:tw-bg-accruent_white">
                    {item?.content.map((itemDesc, index) => {
                      return (
                        <LocalizedLink
                          to={itemDesc?.fieldArticleSectionLink[0]?.url}
                          className="tw-text-accruent_blue tw-pb-2.5 tw-block hover:tw-text-accruent_blue"
                        >
                          {itemDesc?.title}
                        </LocalizedLink>
                      )
                    })}

                  </div>
                  :
                  <div
                    className= {`${isColoredQa ? 'tw-qa-list-is-colored-qa-section-message-body-content tw-pt-0 tw-pr-[29px] tw-pb-6 tw-pl-6 tw-text-mdFont' : 'tw-py-5 tw-px-6 tw-text-mdFont tw-text-heading_color'} dangerously-html-handle`}
                    dangerouslySetInnerHTML={{ __html: item.content }}
                  />
              )}
            </div>
          </article>
        );
      })}
    </div>
  );
};

// export default Accordion;
