import React from "react";
import { LocalizedLink, AccruentImg, PopupVideo } from "src/components/commonComponents";
import { get } from "src/utils";
import { StaticImage } from "gatsby-plugin-image";
const getImageObject = require("src/../helpers/getImageObject")
const defaultImage = "../../../static/placeholders/accruent_default-hero-placeholder.jpg";

const ExpandableBlockItem = (props) => {
    const { entity, expandedBlock, handleExpandedBlock } = props

    const videoUrl = get(entity, "relationships.fieldVideoRef.fieldMediaVideoEmbedField");
    const videoThumbnail = get(entity, "relationships.fieldVideoRef.relationships.thumbnail");
    const mediaImage = getImageObject(entity?.relationships?.fieldMediaImage,{},"fieldMediaImage")
    let contentImage =mediaImage?.gatsbyImage ? (
        <AccruentImg
            file={mediaImage}
            className="content-block-closeup-image has-height-100-percent"
        />
    ) : (
        <StaticImage
            src={defaultImage}
            alt={'Default Image'}
            className="has-height-100-percent"
            aspectRatio={2.0}
            placeholder="blurred"
            layout="constrained"
        />

    );

    const contentMedia = !videoUrl?contentImage:(
        <PopupVideo videoUrl={videoUrl} thumbnail={videoThumbnail} thumbnailClass={"content-block-closeup-image tw-h-full tw-relative"} isForegroundThumbnail={true} />
    )

    return (
        <div className={`content-block-item tw-pl-0 tw-mb-[32px] tw-cursor-pointer md:tw-mb-[18px] tw-mt-0 md:tw-p-[8px_0_8px_16px] tw-border-l-[4px] tw-border-solid tw-border-accruent_lilac ${expandedBlock.id === entity.nid ? 'active-item md:tw-border-l-[4px] tw-border-l-0 !tw-border-accruent_sapphire' : ''}`} key={entity.uuid}>
            <div onClick={() => handleExpandedBlock({ id: entity.nid, imageSrc: getImageObject(entity?.relationships?.fieldMediaImage,{},"fieldMediaImage"), fieldVideoRef: entity?.relationships?.fieldVideoRef})}>
                <div className="main-content tw-border-l-[4px] tw-border-solid tw-border-accruent_sapphire md:tw-border-none tw-pl-[15px] md:tw-pl-0">
                    {!entity.fieldHideTitle && (
                        <h6 className=" tw-text-left tw-mb-[10px] md:tw-mb-[16px] tw-mt-0 tw-text-mobileLgFont md:tw-text-lgFont pointer !tw-text-accruent_sapphire tw-opacity-100 md:tw-opacity-50">
                            {entity.title}
                        </h6>
                    )}
                    <div className="expand-content-box md:tw-hidden">
                        <div className="expand-description-box dangerously-html-handle"
                            dangerouslySetInnerHTML={{
                                __html: entity?.body?.value ?? '',
                            }}
                        />
                        <div className="expand-button-box">
                            {entity.fieldCtaUrl && (
                                <LocalizedLink
                                    to={entity.fieldCtaUrl.url}
                                    className=" tw-font-Poppins tw-tracking-[0.03px] hover:tw-text-expand_link tw-text-expand_link tw-text-[16px] tw-leading-[16px] tw-font-bold tw-mr-[16px] hover:tw-underline"
                                >
                                    {`${entity.fieldCtaUrl.title} >`}
                                </LocalizedLink>
                            )}
                            {entity.fieldSecondaryCta && (
                                <LocalizedLink
                                    to={entity.fieldSecondaryCta.url}
                                    className=" tw-font-Poppins tw-tracking-[0.03px] hover:tw-text-expand_link tw-text-expand_link tw-text-[16px] tw-leading-[16px] tw-font-bold tw-mr-[16px] hover:tw-underline"
                                >
                                    {`${entity.fieldSecondaryCta.title} >`}
                                </LocalizedLink>
                            )}
                        </div>
                    </div>
                </div>
                <div className="content-block-item__image tw-block md:tw-hidden">
                    {contentMedia}
                </div>
            </div>
        </div>
    );
};

export default ExpandableBlockItem;
