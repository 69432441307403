import React, { useEffect, useMemo, useState } from "react";
import { get } from "src/utils";
import getAuthorName from "../../../../helpers/getAuthorName";
import getResourceTagsList from "../../../../helpers/getResourceTagsList";
// import Hero from "src/components/hero/ResourcesHero";
import { ResourcesHero as Hero} from "src/components/templateComponents";
import {getResourceDate} from "../../../../helpers/dateUtilities";
import BackendResourcesInternalLink from "../../helpers/BackendResourcesInternalLink";
import splitBodyContent from "../../helpers/splitBodyContent";
import { useLocation } from '@reach/router';
import getTitleId from "../../helpers/getTitleId";
import getQaData from "../../helpers/getQaData";
//Content Connectors Imports
import getContentConnectorFields, { getContentConnectorAnchors } from "src/components/helpers/getContentConnectorFields";
import { Eyebrow } from "src/components/commonComponents";
import loadable from '@loadable/component';
import useAddParamInHtmlLink from "src/components/helpers/useAddParamInHtmlLink";
import { removeScriptTagsWithFastWistiaSrc } from "src/utils"
const ContentConnector = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.ContentConnector
 });
 // const Video = loadable(() => import('src/components/Video'))
const Video = loadable(() => import("src/components/commonComponents"), {
  resolveComponent: (components) => components.Video
});
// const Accordion = loadable(() => import('src/components/Accordion'))
const Accordion = loadable(() => import("src/components/commonComponents"), {
  resolveComponent: (components) => components.Accordion
});
// const SideBar = loadable(() => import('src/components/resources/ResourceSideBar'));
const SideBar = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.SideBar
});
const Helmet = loadable(() => import('react-helmet'));
const StickyBox = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.StickyBox
});
// const Picture = loadable(() => import('src/components/Picture'))
const Picture = loadable(() => import("src/components/commonComponents"), {
  resolveComponent: (components) => components.Picture
});
// const PopupVideo = loadable(() => import('src/components/popupVideo/PopupVideo'))
const PopupVideo = loadable(() => import("src/components/commonComponents"), {
  resolveComponent: (components) => components.PopupVideo
});
const AddWistiaScript = loadable(() => import('src/components/helpers/AddWisitaScript'))

export const SingleBlogPost = ({ data }) => {
  const { locale } = data;
  const videoId = data?.fieldVideoRef?.fieldMediaVideoEmbedField;
  const videoStandardEmbedCode =  data?.fieldVideoRef?.fieldVideoTranscript?.value
  const authorName = getAuthorName(data?.relationships);
  const resourceTags = getResourceTagsList([data.industryIds, data.solutionIds, data.productIds])
  let heroImage = get(data, "heroImage");
  const heroVideoUrl = get(data, "relationships.fieldHeroVideo.fieldMediaVideoEmbedField");
  const heroVideoThumb = get(data, "relationships.fieldHeroVideo.relationships.thumbnail.url");

  const fieldFaqSection = data?.relationships?.fieldFaqSection;
  const location = useLocation();
  let contentConnectors = [];
  if (Array.isArray(data?.relationships?.fieldContentSections)) {
    contentConnectors = getContentConnectorFields(
      data.relationships?.fieldContentSections,
      "basicPage"
    );
  }
 
  useEffect(() => {
     BackendResourcesInternalLink("new-single-resource-content-body",location);
  },[])
  const questionAnswersData = fieldFaqSection ? getQaData(fieldFaqSection): null;
  const genericBodyWithWista = data?.body?.value
  const genericBody  = removeScriptTagsWithFastWistiaSrc(genericBodyWithWista)
  let splitFunc = splitBodyContent(genericBody);
  let firstContentToString = splitFunc?.firstContentToString;
  let secondContentToString = splitFunc?.secondContentToString;
  useAddParamInHtmlLink()
  return (
    <section className="section !tw-pt-0 !tw-pb-0 content-visibility-auto-mobile resources-page">
      <div className="container blog-page">
        <section className="medium-wrapper tw-text-[16px] md:tw-text-[18px] tw-py-0 tw-px-[15px] md:tw-px-0 md:tw-pl-[12px] md:tw-py-0 tw-mt-[32px] md:tw-mt-[72px]">
          <div className="columns md:tw-flex">
            <div className="tw-w-full lg:tw-w-[calc(100%-376px)] lg:tw-max-w-[calc(100%-376px)] [&]:tw-px-0 [&]:md:tw-pr-3">
              {!!authorName && (
                <div className="article-by">
                  By <span> {authorName}</span>
                </div>
              )}
              {data.body && <div
                className="new-single-resource-content-body dangerously-html-handle"
                dangerouslySetInnerHTML={{ __html: firstContentToString?.replace(/href/g, "target='_blank' href") }}
              ></div>
              }
              <div className="middle-of-content mb-2">
               {heroVideoUrl ?
                  <PopupVideo
                    videoUrl={heroVideoUrl}
                    thumbnail={heroVideoThumb}
                    thumbnailClass={`thumbnail-box  tw-min-h-[376px] [&]:tw-p-0 is-12-mobile  thumbnail-hero`}
                    isHero
                  />
                  :
                  (heroImage) ?
                    <div className={`tw-min-h-[376px] [&]:tw-p-0`}>
                      <Picture
                        image={heroImage}
                        className={'tw-h-full tw-w-full tw-min-h-[376px] md:tw-min-h-0 tw-object-cover'}
                      />
                    </div> : null
                }
              </div>
              {secondContentToString && <div
                className="new-single-resource-content-body dangerously-html-handle"
                dangerouslySetInnerHTML={{ __html: secondContentToString?.replace(/href/g, "target='_blank' href") }}
              ></div>
              }
              {videoId && (
                <div className="box">
                  <Video videoUrl={videoId} videoStandardEmbedCode={videoStandardEmbedCode}/>
                </div>
              )}
              {contentConnectors.map((data, index) => (
                <div key={index} className="section-container">
                  <ContentConnector
                    key={index}
                    data={data}
                    locale={locale}
                    formFullWidth={true}
                  />
                </div>
              ))}
    
              {fieldFaqSection && 
              <section className="section">
                {fieldFaqSection?.fieldQaSchema ? (
                  <Helmet>
                    <script type="application/ld+json">{`
                      ${fieldFaqSection?.fieldQaSchema}
                    `}</script>
                  </Helmet>
                ) : (
                  ""
                )}
                <div
                  className="has-word-break qa-list"
                  component="QaList"
                  nid={fieldFaqSection?.nid}
                >
                  <div className="columns">
                    <div className="column is-12" id={getTitleId(fieldFaqSection?.title)}>
                      <h2 className="title title-3">
                        {fieldFaqSection?.title}
                      </h2>
                      <Accordion
                        data={questionAnswersData}
                        id={`id${fieldFaqSection?.nid}`}
                        isQa
                      />
                    </div>
                  </div>
                </div>
              </section>
              }

            </div>
            <div className="tw-w-full lg:tw-max-w-[376px] lg:tw-p-[12px]">
              <div className="tw-bg-accruent_white tw-w-full md:tw-w-[376px] tw-relative">
              <StickyBox
                  scrollContainerClass={"sidebar-scroll"}
                  >
                  <div className="right-box">
                    <SideBar recommendedResources={data.rectifiedRecommended} />
                  </div>
              </StickyBox>
              </div>
            </div>
          </div>
        </section>
      </div>
      {genericBodyWithWista && <AddWistiaScript videoStandardEmbedCode={genericBodyWithWista}/>}
    </section>
  )
}

// export default SingleBlogPost;
