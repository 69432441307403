import React from "react";
import cn from "classnames";
import { LocalizedLink } from "../commonComponents";

/**
 * Each BicolorCards is a set of two side by side cards, so to render several pairs
 * you have to render several BicolorCards
 * @param {Array} cards this needs to be an array with TWO objects inside
 */
const BicolorCards = ({ cards }) => {
  return (
    <div className="columns is-gapless is-centered bicolor-cards">
      {cards.map((card, index) => {
        const partClass = index === 0 ? "first-part" : "second-part";
        return (
          <div className="column is-6 is-12-mobile is-align-items-stretch" key={card.title}>
            <div className={`part tw-h-full tw-flex tw-flex-col tw-justify-between !tw-py-[50px] !tw-px-10 box ${partClass}`}>
              <div>
                <div className={`${ partClass === "second-part" ? "tw-text-white " : "" }
                  title mb-3 has-text-transform-none is-size-2-desktop`}>{card.title}
                </div>
                <div className="description content dangerously-html-handle" dangerouslySetInnerHTML={{ __html: card.description }} />
              </div>
              <div>
                <div>
                  {card.cta && (
                    <LocalizedLink
                      to={card.cta?.url?.path || null}
                      className={cn("has-width-100-percent", {
                        "button is-primary is-medium": index === 0,
                        "button is-white is-medium": index !== 0,
                      })}
                    >
                      {card.cta?.title || null}
                    </LocalizedLink>
                  )}
                </div>
                <div>
                  {card.secondaryCta && (
                    <LocalizedLink
                      to={card.secondaryCta?.url?.path || null}
                      className={`has-width-100-percent ${
                        index === 0
                          ? "button is-primary is-medium mb-3"
                          : "button is-white is-medium mb-3"
                      }`}
                    >
                      {card.secondaryCta?.title || null}
                    </LocalizedLink>
                  )}
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default BicolorCards;
