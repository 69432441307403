import React from "react";
import ZigZagBlockItem from "./ZigZagBlockItem";

const ZigZagBlock = (props) => {
  const { data: blockData } = props;

  return (
    <div>
      {blockData &&
        blockData.map((entity, index) => {
          return (
            <ZigZagBlockItem entity={entity} index={index} key={entity.nid} isArrowLink={props.isArrowLink} fieldShowBackgroundPattern={props?.fieldShowBackgroundPattern}/>
          );
        })}
    </div>
  );
};

export default ZigZagBlock;
