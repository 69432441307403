import React, { useContext } from "react";
import {  useLocation } from "@reach/router";
import { LocalizedLink, Picture, PopupVideo,ShareFollowBox } from "src/components/commonComponents";
import { formatDate } from "src/utils";
import ResourcesContext from "src/components/helpers/ResourcesMainPageContext";
const prefixLangcode = require('src/../helpers/prefixLangcode');
import { ResourceTagsList } from "src/components/templateComponents";

export const ResourcesHero = (
  {
    type,
    contentType,
    heading,
    subHeading,
    subTitle,
    body,
    hashtags,
    cta,
    heroImage,
    date,
    time,
    readTime,
    videoUrl,
    thumbnail,
    season,
    overviewLink,
    overViewText,
    hideShare,
    startDateFormated,
    endDateFormated,
    startDateTime,
    endDateTime,
    timeZone,
    checkForOnDemand,
    fromEvent,
    fieldHighlightsPoints,
    publishDate = '',
    isPressRelease = false,
    locale = 'en'
  }
) => {
  const metaList = [season, date, readTime, subTitle].filter(m => !!m)
  const heroImageAttrs = heroImage?.gatsbyImage
  let noTransformClass = contentType && contentType === 'White Paper' ? 'tw-normal-case' : ''

  // Amsterdam Kaizen Case Studies Test
  const { isSSR } = useContext(ResourcesContext);
  const kaizenCaseStudies = [
    '/resources/case-studies/genesco',
    '/resources/case-studies/abbvie',
    '/resources/case-studies/basf',
    '/resources/case-studies/tufts-university',
    '/resources/case-studies/loreal'
  ]
  const location = useLocation();
  const currentPath = location.pathname
  var ctaButtonText = kaizenCaseStudies.includes(currentPath) ? "Learn More" : "Download"
  let gaEventTracking = () => {
    if (contentType == "CASE STUDY" && ctaButtonText == "Learn More") {
      if (
        !isSSR
      ){
        if (window &&
          window.dataLayer) {
            window.dataLayer.push({
              event: "gaTriggerEvent",
              gaCategory: "CTA Engagement",
              gaAction: "Download Button Click",
              gaLabel: "Case Study",
            });
          }
      }
    }
  }

  if (type === 'banner') {
    return (
      <div className="tw-relative tw-text-accruent_white">
        <Picture
          image={heroImage}
          className={'tw-h-full tw-w-full tw-bg-accruent_gray tw-object-cover'}
          parentClassName={'tw-h-full tw-absolute tw-w-full'}
          width={150}
          height={150}
        />
        <div
          className="tw-bg-no-repeat tw-bg-cover tw-h-auto tw-left-0 tw-min-h-[400px] tw-top-0 tw-w-full tw-z-0 tw-relative"
        >  <div className="tw-banner_gradient tw-min-h-[400px]">
          <div className="tw-py-0 tw-px-[15px] md:tw-px-2 tw-container_custom">
            <div className="tw-text-accruent_white tw-text-smFont tw-mb-0 tw-pl-0 tw-pb-[33px] tw-font-Poppins tw-leading-[21px] tw-tracking-[1.8px] lg:-tw-ml-3 tw-pt-[47px]">
              <LocalizedLink
                to={prefixLangcode(overviewLink, locale)}
                className={`md:tw-px-0 lg:tw-px-0 xl:tw-px-0 tw-text-accruent_white tw-font-medium tw-base_transition hover:tw-text-accruent_white ${noTransformClass}`}
              >
                ‹  {overViewText}
              </LocalizedLink>
            </div>
            <div className="md:tw-px-0 lg:tw-px-0 xl:tw-px-0  tw-columns !tw-mt-0 tw-p-0">
              <div className="tw-column p-0 is-12-desktop is-12-mobile">
                <div className={`tw-font-Roboto tw-text-xs tw-tracking-[1.8px] tw-pb-2 tw-pl-0 tw-font-medium tw-text-accruent_white ${noTransformClass} ${contentType === 'White Paper'  ? 'tw-transform-none' : 'tw-uppercase'}`}>
                  {contentType}
                </div>
                <div className="tw-max-w-full lg:tw-max-w-[calc(100%-475px)] tw-mb-[76px]">
                  {heading && <h1 className={`tw-mb-4 tw-w-full tw-text-accruent_white tw-text-xxxlFont md:tw-text-headingFont tw-font-Helvetica md:tw-font-Poppins lg:tw-font-Poppins tw-p-0 tw-mx-0 tw-mt-0`}>{heading}</h1>}
                  {(checkForOnDemand !== undefined && !checkForOnDemand)  && <div>
                    {startDateFormated && <p className="tw-text-[24px] tw-font-Poppins tw-mb-[6px] tw-font-bold tw-text-accruent_white tw-mt-0 tw-mx-0 tw-p-0">{startDateFormated}{endDateFormated && <span> - {endDateFormated}</span>}</p>}
                    {startDateTime && <p className="tw-text-[20px] tw-font-Poppins tw-mb-4 tw-font-bold tw-text-accruent_white tw-mt-0 tw-mx-0 tw-p-0">{startDateTime} {endDateTime && <span> - {endDateTime}</span>} <span className="tw-text-[12px] tw-pl-2">{timeZone}</span></p>}
                  </div>}
                  {subTitle &&  <small className="white-content">
                    {subTitle}
                  </small>
                  }
                  {body && <p className="tw-font-Roboto tw-text-smFont md:tw-text-[22px] tw-leading-4 md:tw-leading-6 tw-mb-4 tw-mt-0 tw-mx-0 tw-text-accruent_white tw-font-normal">
                    {body}
                  </p>
                  }
                  {(!fromEvent || fromEvent === undefined) && date && <p className="tw-text-[24px] tw-font-Poppins tw-mb-[6px] tw-font-bold tw-text-accruent_white tw-mt-0 tw-mx-0 tw-p-0">
                    Date: {date}
                  </p>
                  }
                  {(!fromEvent || fromEvent === undefined) && time && <p className="tw-text-[20px] tw-font-Poppins tw-mb-4 tw-font-bold tw-text-accruent_white tw-mt-0 tw-mx-0 tw-p-0">
                    Time: {time}
                  </p>
                  }

                  {hashtags && <ResourceTagsList ResourceTags={hashtags} />}


                </div>
              </div>
            </div>

          </div>
        </div>
        </div>
      </div>
    )
  }
  let checkForContentType = false
  let heroImagePostion = ['Blog', 'Article', 'BROCHURE', 'CASE STUDY', 'CompanyNews', 'INFOGRAPHICS', 'PODCAST', 'Knowledge Hub' ]
  if(heroImagePostion.includes(contentType)){
    checkForContentType = true;
  }
  if (contentType === "CompanyNews") {
    return (
      <div className="company-news-hero">
        <h1 className="heading-truncate">{heading}</h1>
        {hashtags && <ResourceTagsList ResourceTags={hashtags} />}

      </div>
    )
  }

  return (
    <>
      <div className={(type != 'body') ? "columns tw-px-[15px] md:tw-p-[10px] tw-mt-3 md:tw-mt-0 tw-flex tw-flex-col-reverse md:tw-flex-row" : "body-resources-hero"}>
        {videoUrl ?
          <PopupVideo
            videoUrl={videoUrl}
            thumbnail={thumbnail}
            className={`${checkForContentType ? "remove-mb tw-hidden md:tw-block": ""} `}
            thumbnailClass={`thumbnail-box tw-min-h-[199px] md:tw-min-h-[376px] tw-bg-gray-300 tw-bg-center tw-bg-no-repeat tw-bg-cover tw-h-auto tw-min-w-[100%] md:tw-max-w-[376px] md:tw-min-w-[376px] tw-p-0 tw-block tw-flex-grow tw-flex-shrink is-12-mobile ${checkForContentType ? "remove-mb": ""} thumbnail-hero`}
            isHero
            checkForContentType
          />
          :
          ((type != 'body') && heroImageAttrs) ?
            <div className={`tw-min-h-[376px] tw-bg-gray-300 tw-bg-center tw-bg-no-repeat tw-bg-cover tw-h-auto tw-max-w-[376px] tw-p-0 tw-block tw-flex-grow tw-flex-shrink banner-graphic is-12-mobile ${checkForContentType ? "remove-mb  tw-hidden md:tw-block": ""} `}>
              <Picture
                image={heroImage}
                className={'tw-h-full tw-object-cover tw-w-full'}
              />
            </div> : <div className="tw-min-h-[376px] tw-bg-gray-300 tw-bg-center tw-bg-no-repeat tw-bg-cover tw-h-auto tw-max-w-[376px] tw-p-0 tw-block tw-flex-grow tw-flex-shrink banner-graphic is-12-mobile">
              <div
                className={'tw-h-full tw-object-cover tw-w-full'}
              />
            </div>
        }
        <div className={(type != 'body') ? "tw-relative md:tw-w-[calc(100%-376px)] md:tw-ml-4 tw-flex tw-flex-col tw-justify-between tw-p-0 is-12-mobile" : "body-banner-content"}>
         <div>
          {(!isPressRelease && contentType) && <div className="tw-text-accruent-base-color tw-font-Roboto tw-text-xsFont tw-tracking-widest tw-mb-2 tw-pb-0 tw-pl-0 tw-uppercase tw-h-[17px] tw-font-medium">{contentType}</div>}
            {(isPressRelease && contentType) && (
              <>
                <div className="eyebrow-tags-wrapper tw-mt-8 tw-flex tw-flex-row tw-justify-between tw-border-b-[2px] border-[#EFEFEF]">
                  <div className="tag-contentType tw-p-[12px_24px] tw-bg-[#EFEFEF] tw-rounded-t-[16px] tw-text-[12px] tw-font-bold tw-leading-4 tw-tracking-[1.8px] tw-uppercase tw-text-[#212121] ">{contentType}</div>
                  <div className="tag-contentTypeDate tw-uppercase tw-p-[12px_0] tw-text-[#212121] tw-text-[18px] tw-leading-[23px] tw-tracking-[2.1px] tw-font-normal">{publishDate ?
                   formatDate(publishDate, "MMMM D, YYYY")
                    :
                    ''}</div>
              </div>
            </>
          )}
          {heading && <h1 className="tw-mb-3 tw-w-full tw-min-h-110 tw-text-accruent-heading-color tw-font-Helvetica md:tw-font-Poppins lg:tw-font-Poppins">{heading}</h1> }
          {hashtags && <ResourceTagsList ResourceTags={hashtags} />}
          {subHeading && <p className="tw-font-Helvetica md:tw-font-Roboto tw-text-xlFont [&]:tw-font-medium [&]:md:tw-font-medium tw-mt-1 md:tw-mt-0 tw-mb-0 tw-text-accruent-base-color">{subHeading}</p>}
          {body && <p className="tw-font-Helvetica md:tw-font-Roboto tw-text-xlFont [&]:tw-font-medium [&]:md:tw-font-medium tw-mt-1 md:tw-mt-0 tw-mb-0 tw-text-accruent-base-color">{body}</p>}

          {!!metaList.length && <div className="time-tag tw-mt-[22px] tw-text-[1rem] tw-leading-[22px] tw-tracking-[0.29px] lg:tw-absolute lg:tw-bottom-0">
            {metaList.map((m, i, arr) => m && <><span>{m}</span> {(arr.length > (i + 1)) ? <span> | </span> : ""} </>)}
          </div>
          }
          </div>
          {cta && (
            <LocalizedLink
              to={cta}
              handleClick = {gaEventTracking}
              className="tw-base_transition tw-mt-8 tw-ml-0 tw-font-Poppins tw-text-[1rem] tw-leading-4 tw-bg-accruent_sapphire hover:tw-bg-accruent_white tw-text-accruent_white hover:tw-text-accruent_sapphire tw-inline-block tw-border-2 tw-border-accruent_sapphire tw-rounded-[12px] tw-font-bold tw-py-[14px] tw-px-6 tw-h-auto tw-whitespace-pre-wrap tw-mb-0 tw-mr-auto
              "
            >
              <i className="fal fa-file-pdf tw-mr-[5px]"></i>  {ctaButtonText}
            </LocalizedLink>
          )}
        </div>

      </div>
      {!hideShare && <div className="tw-block tw-ml-[7px] tw-mt-[46px] md:tw-hidden">
        <ShareFollowBox />
      </div>
      }
    </>
  );
};

// export default ResourcesHero;
