// src/constants/locales.js

/*
 * This controls the languages available for the site
 */
module.exports = {
    en: {
        path: `en`,
        locale: `en-US`,
        siteLanguage: `en`,
        ogLanguage: `en_US`,
        default: true,
    },
    'en-gb': {
        path: `uk`,
        locale: `en-GB`,
        siteLanguage: `en-gb`,
        ogLanguage: `en_GB`,
        default: false,
    },
    //es: {
    //    path: `es`,
    //    locale: `es-ES`,
    //    siteLanguage: `es`,
    //    ogLanguage: `es_ES`
    //},
    //de: {
    //path: `de`,
    //locale: `de-DE`,
    //siteLanguage: `de`,
    //ogLanguage: `de_DE`
    //}
}
