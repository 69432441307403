import React, {useRef, useState, useEffect} from 'react'
import loadable from '@loadable/component';
import getTitleId from "../helpers/getTitleId";
import splitBodyContent from '../helpers/splitBodyContent';
import {Picture} from '../commonComponents';
import useAddParamInHtmlLink from "src/components/helpers/useAddParamInHtmlLink";
import OutreachCheck from '../helpers/OutreachCheck';
import { removeScriptTagsWithFastWistiaSrc } from "src/utils"
import { isElementInViewport } from 'src/utils';
import { useLazyLoadOnScroll } from "src/hooks/useLazyLoadOnScroll";
const StoryLane = loadable(() => import("src/components/commonComponents"), { resolveComponent: (components) => components.StoryLane});
const Calendly = loadable(() => import("src/components/commonComponents"), { resolveComponent: (components) => components.Calendly});
const Pricing = loadable(() => import("src/components/commonComponents"), { resolveComponent: (components) => components.PricingCalc});
const AddWistiaScript = loadable(() => import('src/components/helpers/AddWisitaScript'))

export const TextAreaBlock = ({ contentEntity, index, pageType = null, heroImage = null }) => {
  // Regular expression pattern to match URLs from multiple domains
  const [calconicVisible, setCalconicVisible] = useState(false);
  const [storyLaneVisible, setStoryLaneVisible] = useState(false);
  const shouldLoad = useLazyLoadOnScroll()
  var integratedIframeDomains = /(https?:\/\/(?:app\.)?(?:storylane\.io|calendly\.com|calconic\.com)[^\s]+)/g;
  let firstContentToString = null, secondContentToString = null, genericBody = contentEntity?.body?.value, checkForKnowledhub = false
  let genericBodyWithWistaScript = genericBody
  genericBody = removeScriptTagsWithFastWistiaSrc(contentEntity?.body?.value)
  let genericBodyWithoutIntegrations = genericBody?.replace(integratedIframeDomains, '')
  if (pageType === 'knowledge_hub_' && index === 0) {
    checkForKnowledhub = true
  }
  let calconicRef = useRef(null);
  let storyLaneRef = useRef(null);
  if (checkForKnowledhub) {
    let splitFunc = splitBodyContent(genericBodyWithoutIntegrations);
    firstContentToString = splitFunc?.firstContentToString;
    secondContentToString = splitFunc?.secondContentToString;
  }
  if (firstContentToString) {
    genericBodyWithoutIntegrations = firstContentToString
  }
  const calendlyProductDemo = OutreachCheck(genericBody)
  const checkForIframe = calendlyProductDemo?.checkForOutreach || calendlyProductDemo?.checkForProductDemo || calendlyProductDemo?.checkForCalendly || calendlyProductDemo?.checkForPricing
  useAddParamInHtmlLink()
  useEffect(() => {
    setCalconicVisible(isElementInViewport(calconicRef.current))
  },[calendlyProductDemo?.checkForPricing])
  useEffect(() => {
    setStoryLaneVisible(isElementInViewport(storyLaneRef.current))
  },[calendlyProductDemo?.checkForProductDemo])
  return (
    <div className='text-area-block tw-text-area-block' id={getTitleId(contentEntity.title)}>
      {!contentEntity.fieldHideTitle &&
        <>{!contentEntity?.fieldShowTitleFullWidth ? <h2 className='tw-mb-4 tw-mt-0'>{contentEntity.title}</h2> : <h3 className='tw-mb-4 tw-mt-0 !tw-w-full'>{contentEntity.title}</h3>}</>
        
      }
      {contentEntity?.body?.summary &&
        <div
          dangerouslySetInnerHTML={{ __html: contentEntity?.body?.summary }}
          className="text-area-inner-block tw-mt-4"
        ></div>
      }
      {calendlyProductDemo?.checkForOutreach &&
        <div class="sl-embed" >
          <iframe class="sl-demo" src={calendlyProductDemo?.outreachUrl} name="sl-embed" allow="fullscreen;"></iframe>
        </div>

      }
      {contentEntity.body &&
        <div
          dangerouslySetInnerHTML={{ __html: genericBodyWithoutIntegrations }}
          className="text-area-inner-block dangerously-html-handle"
        ></div>
      }
      {checkForKnowledhub && <div className="middle-of-content tw-mb-4">
        {(heroImage) ?
          <div className={`column banner-graphic p-0 is-12-mobile`}>
            <Picture
              image={heroImage}
              className={'resources-hero-image'}
            />
          </div> : null}
      </div>}
      {
        secondContentToString &&
        <div
          dangerouslySetInnerHTML={{ __html: secondContentToString }}
          className="text-area-inner-block dangerously-html-handle"
        ></div>
      }
      {calendlyProductDemo?.checkForCalendly && <Calendly calendly={calendlyProductDemo}/>}
      {calendlyProductDemo?.checkForProductDemo && <div ref={storyLaneRef}> {(storyLaneVisible || shouldLoad) ?
        <StoryLane storyLane={calendlyProductDemo} />
        : <div className="set-min-height"></div>} </div>}
      {calendlyProductDemo?.checkForPricing && <div ref={calconicRef}>{(calconicVisible || shouldLoad) ?
        <Pricing pricing={calendlyProductDemo} />
        : <div className="set-min-height"></div>
      }
      </div>}
     {genericBodyWithWistaScript && <AddWistiaScript videoStandardEmbedCode={genericBodyWithWistaScript}/>}
    </div>
  );
};
