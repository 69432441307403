import { useStaticQuery, graphql } from "gatsby";

export default function useQuestionsCTAForm() {
  const data = useStaticQuery(graphql`
    {
      allNodeComparisonQuestionItem(limit: 3) {
        nodes {
          nid: drupal_internal__nid
          uuid: drupal_id
          fieldIcon: field_icon {
            iconName: icon_name
            style
          }
          fieldCtaUrl: field_cta_url {
            title
            url
          }
          title
          fieldCardContent: field_card_content
        }
      }
    }
  `);
  const questionsCTA = data.allNodeComparisonQuestionItem?.nodes?.map(
    (node) => {
      const {
        fieldIcon,
        fieldCtaUrl,
        title,
        fieldCardContent,
        uuid,
      } = node;
      return {
        fieldIcon,
        fieldCtaUrl,
        title,
        fieldCardContent,
        uuid,
      };
    }
  );

  return { questionsCTA: questionsCTA };
}
