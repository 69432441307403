/**
 * @param {String} wholeForm - MUST be the at least of the form:
 * <script src="//app-abc.marketo.com/js/forms2/js/forms2.min.js"></script>
 * <form id="mktoForm_1517"></form>
 * <script>MktoForms2.loadForm("//app-abc.marketo.com", "167-BOY-362", 1517);</script>
 *
 * But can also be whole content like such: https://gist.githubusercontent.com/jordotech/64817cb2bd0374d215f828cac7c007d2/raw/d549db80609d4550e6a47a4fd214e4f1c0908103/marketo.txt
 *
 * @returns {Object} {serial, id} -
 *    @serial String XXX-XXX-XXX string from the form above
 *    @id Number XXXX number from above form
 */
const extractROICalculatorAttributes = (string) => {
  if (string) {
    const separated = string.split("></script>")[0]?.split(" ");
    const src = separated[1]?.trim().replace(/"/g, "").substring('src='.length);
    const width = separated[2]?.trim().replace(/"/g, "").substring('width='.length);
    const height = separated[3]?.trim().replace(/"/g, "").substring('height='.length);
    const calc = separated[4]?.trim().replace(/"/g, "").substring('calc='.length);
    const currency_code = separated[5]?.trim().replace(/"/g, "").substring('currency_code='.length);
    const lang = separated[6]?.trim().replace(/"/g, "").substring('lang='.length);
    const company = separated[7]?.trim().replace(/"/g, "").substring('company='.length);
    return {
      src,
      width,
      height,
      calc,
      currency_code,
      lang,
      company
    };
  } else
    return {
      src: null,
      width: null,
      height: null,
      calc: null,
      currency_code: null,
      lang: null,
      company: null,
    };
};

export default extractROICalculatorAttributes
