import { useStaticQuery, graphql } from "gatsby";

export default function useCompanyResourceData() {
  const data = useStaticQuery(graphql`
  {
    allSitePage(filter: {context: {content_type: {eq: "company_news"}}}) {
      group(field: context___content_type) {
        nodes {
          ...resourceCards
        }
      }
    }
  }
  `);

  return { data };
}
