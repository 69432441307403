import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export const AccruentImg = ({ file, ...props }) => {
  if (!file) {
    return <React.Fragment />
  }
  const image = getImage(file);
  if (!image) {
    return <React.Fragment />
  }

  if (file?.caption) {
    let leftCaption = file?.caption.substring(0, file?.caption.indexOf(':') + 1)
    let rightCaption = file?.caption.substring(file?.caption.indexOf(':') + 1)
    return (
      <figure>
        <GatsbyImage
          image={image}
          alt={file.alt ? file.alt : ''}
          decoding = "async"
          {...props}
        />
        <figcaption className="caption"><b>{leftCaption}</b> {rightCaption}</figcaption>
      </figure>
    );
  }

  return (
    <GatsbyImage
      image={image}
      alt={file.alt?file.alt:''}
      {...props}
    />
  );
}

// export default AccruentImg;
