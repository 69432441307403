import React from 'react'
import { graphql, useStaticQuery } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { BgImage as BackgroundImage } from 'gbimage-bridge';
import findSiteType from "src/components/helpers/findSiteType";
const relativePath = findSiteType('mc') ? "mc/services/services_background.png" : findSiteType("ems") ? "about-us-ems.png" : "services/services_background.png"
export const BgPatternImage = ({children, ...rest }) => {

    const { bgImages } = useStaticQuery(
        graphql`
        query {
            bgImages: allFile(
                filter: {relativePath: {in: ["services/services_background.png", "mc/services/services_background.png", "about-us-ems.png"]}}
            ) {
                nodes {
                    relativePath
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED, quality: 100, formats: [AUTO, WEBP, AVIF])
                    }
                }
            }
        }
    `
    );
    const bgImage = bgImages?.nodes?.find((node) => node.relativePath == (rest?.relativePath?rest.relativePath:relativePath))
  
    if (bgImage) {
        const pluginImage = getImage(bgImage);
        return (
            <BackgroundImage image={pluginImage} {...rest} >
                {children}
            </BackgroundImage>
        );
    }


    return (
        <> {children}</>
    )
}


