const extractNavData = (entities = [], language = 'en') => {
  const result = [];
  entities.forEach((entity) => {
    const isLocaleEntity = entity?.locale === language;
    if (isLocaleEntity) {
      result.push({
        name: entity.name,
        link: entity.link,
      });
    }
  });
  return result;
}

export default extractNavData;
