import React from 'react'
import { useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import accruentLogoSvg from "../../../static/Accruent-Logo-color.svg";
import { LocalizedLink } from './localizedLink';

const accruentLogo = <StaticImage src={"../../images/logos/accruent-products/Accruent-Logo-color.png"} alt="Accruent-Logo" loading="lazy" width={226} height={33} quality={70} decoding = "async" />;
const lucernexLogo = <StaticImage src={"../../images/logos/accruent-products/lucernex-logo.png"} alt="lucernex-logo" loading="lazy" width={226} height={33} quality={70} decoding = "async" />;
const maintenanceconnectionLogo = <StaticImage src={"../../../static/home/Products/mc.png"} alt="maintenanceconnection-logo" loading="lazy" height={33} quality={70} decoding = "async" />;
const meridianLogo = <StaticImage src={"../../images/logos/accruent-products/meridian-logo.png"} alt="meridian-logo" loading="lazy" width={226} height={33} quality={70} decoding = "async" />;
const vxfieldLogo = <StaticImage src={"../../images/logos/accruent-products/vxfield-logo.png"} alt="vxfield-logo" loading="lazy" width={226} height={33}  quality={70} decoding = "async" />;
const vxobserveLogo = <StaticImage src={"../../images/logos/accruent-products/vxobserve-logo.png"} alt="vxobserve-logo" loading="lazy" width={226} height={33} quality={70} decoding = "async" />;
const emsFlexLogo = <StaticImage src={"../../images/logos/accruent-products/EMS-flex-logo-home.png"} alt="EMS-flex" loading="lazy"  width={226} height={33}  quality={70} decoding = "async" />;
const famisLogo = <StaticImage src={"../../images/logos/accruent-products/FAMIS-360.png"} alt="FAMIS-360" loading="lazy" height={33} width={226} quality={70} decoding = "async" />;
const LXProjectsLogo = <StaticImage src={"../../images/logos/accruent-products/LX-Projects.png"} alt="LX-Projects" loading="lazy" width={226} height={33}  quality={70} decoding = "async" />;
const LXTransactionLogo = <StaticImage src={"../../images/logos/accruent-products/LX-Transaction.png"} alt="LX-Transaction" loading="lazy" width={226} height={33} quality={70}  decoding = "async" />;
const TmsLogo = <StaticImage src={"../../images/logos/accruent-products/TMS_1.png"} alt="LX-Projects" loading="lazy"  width={226} height={33} quality={70} decoding = "async" />;
const LXContractsLogo = <StaticImage src={"../../images/logos/accruent-products/LX-Contracts.png"} alt="LX-Contracts" loading="lazy" width={226} height={33}  quality={70} decoding = "async" />;
const aoemLogo = <StaticImage src={"../../images/logos/accruent-products/accruent_products_aoem_product-logo.png"} alt="aoem" loading="lazy"  width={226} height={33} quality={70} decoding = "async" />;
const LXMarketLogo = <StaticImage src={"../../images/logos/accruent-products/LX-Markets.png"} alt="LX-Markets" loading="lazy"  width={226} height={33}  quality={70} decoding = "async" />;
const emsLogo = <StaticImage src={"../../images/logos/accruent-products/EMS.png"} alt="EMS" loading="lazy" width={226} height={33} quality={70} decoding = "async" />;

const data = {
  title: "Take a Deeper Dive",
  desc: "Explore key Accruent software functionalities that can help you manage your facilities, assets, and people",
  tabs: [{ key: "spaces", title: "Spaces" }, { key: "assets", title: "Assets" }, { key: "people", title: "People" }],
  content: {
    "spaces": {
      title: "Spaces Management Solutions",
      logo: "",
      subtitle: "Manage sites or buildings",
      children: [
        {
          title: "Preventive and Predictive",
          children: [
            {
              title: "Maintenance Connection ",
              desc: "Optimize preventive and predictive scheduling, planning, and execution using our CMMS/EAM ",
              link: "https://website.maintenanceconnection.com/program-solutions/preventive-maintenance-software",
              Webinarlink: "https://www.accruent.com/resources/webinars/get-ahead-with-preventive-maintenance",
              logo: maintenanceconnectionLogo,
              isFullLink: true
            },
            {
              title: "FAMIS 360 ",
              desc: "This facilities management software is purpose-built to streamline preventive operations for companies in CRE, education, and the public sector. ",
              link: "/products/famis-360",
              Webinarlink: "https://www.accruent.com/resources/webinars/simplify-single-solution-retail-lifecycle",
              logo: famisLogo
            },
            {
              title: "TMS",
              desc: "Use this healthcare CMMS to operate more predictively and reduce costs in healthcare, pharmaceuticals, and biomed. ",
              link: "/products/tms",
              Webinarlink: "https://www.accruent.com/resources/webinars/new-webinar-series-step-3-asset-work-order-management",
              logo: TmsLogo,
              isUSOnly: true
            }
          ]

        },
        {
          title: "Self-Service Space Booking",
          children: [
            {
              title: "EMS ",
              desc: "Reserve parking spots, equipment, desks, rooms, and more with EMS space and resource scheduling  ",
              link: "https://www.emssoftware.com/solutions/shared-space-resource-scheduling-software",
              Webinarlink: "https://www.emssoftware.com/resources/webinars/practical-global-return-work-navigating-modern-office-using-ems",
              logo: emsLogo,
              isFullLink: true
            },
            {
              title: "EMS Flex ",
              desc: "Use this one-click desk booking solution to find and book the right spaces in a flash",
              link: "/products/ems-flex",
              Webinarlink: "https://www.accruent.com/resources/webinars/unleash-power-one-click-desk-booking-introducing-ems-flex-2",
              logo: emsFlexLogo
            },
          ]
        },
        {
          title: "Resource & Event Management",
          children: [
            {
              title: "EMS ",
              desc: "Control the entire event planning process in a single source of record",
              link: "https://www.emssoftware.com/solutions/conference-event-management-software",
              Webinarlink: "https://www.accruent.com/resources/webinars/are-your-events-facilities-teams-connected-they-should-be",
              logo: emsLogo,
              isFullLink: true
            },
          ]
        },
        {
          title: "BMS Temp & Lighting Control",
          children: [
            {
              title: "vx Observe",
              desc: "Use IoT remote monitoring and energy management to automate temperature and lighting controls",
              link: "/products/vx-suite/vx-observe",
              Webinarlink: "https://www.accruent.com/resources/webinars/gain-competitive-edge-cre-intelligent-buildings",
              logo: vxobserveLogo
            },
          ]
        },
        {
          title: "Service Scheduling",
          children: [
            {
              title: "vx Field ",
              desc: "Use this field service management system to streamline service execution and performance analysis",
              link: "/solutions/field-service-management-software",
              logo: vxfieldLogo
            },
          ]
        },
        {
          title: "Utilization & Optimization Analytics",
          children: [
            {
              title: "EMS",
              desc: "Make informed utilization decisions with EMS space utilization and optimization software",
              link: "https://www.emssoftware.com/solutions/analytics-space-utilization-optimization-software",
              Webinarlink: "https://www.accruent.com/resources/webinars/top-2023-tech-trends-changing-global-workplace",
              logo: emsLogo,
              isFullLink: true
            },
          ]
        },
        {
          title: "Safety & Compliance",
          children: [
            {
              title: "Meridian",
              desc: "Utilize this powerful engineering document management system to simplify safety and compliance efforts ",
              link: "/products/meridian",
              Webinarlink:"https://www.accruent.com/resources/webinars/benefits-validated-engineering-document-management-cloud",
              logo: meridianLogo
            },
            {
              title: "Lx Contracts",
              desc: "Easily maintain compliance with standards like FASB ASC 842, IASB IFRS 16, and GASB 87",
              link: "/solutions/fasb-iasb-gasb-compliance-software",
              Webinarlink:"https://www.accruent.com/resources/webinars/trimming-fat-get-fasbiasb-compliant-now",
              logo: LXContractsLogo,
              isUSOnly: true
            },
          ]
        },
        {
          title: "Lease Admin & Accounting (FASB)",
          children: [
            {
              title: "Lx Contracts ",
              desc: "Easily manage lease dates, analyze rent costs, and integrate with your existing tools",
              link: "/products/lucernex/lx-contracts",
              Webinarlink:"https://www.accruent.com/resources/webinars/fasb-iasb-lease-accounting-compliance-three-legged-stool",
              logo: LXContractsLogo
            },
          ]
        },
        {
          title: "Transaction Management",
          children: [
            {
              title: "Lx Transaction Management",
              desc: "Gain full insight into your portfolio with integrated real estate transaction management.",
              link: "/products/lucernex/lx-transaction-management",
              logo: LXTransactionLogo
            },
          ]
        },
        {
          title: "Portfolio Strategy Planning",
          children: [
            {
              title: "Lucernex",
              desc: "Simplify portfolio strategy and decision-making with our comprehensive real estate management solution",
              link: "/products/lucernex",
              Webinarlink: "https://www.accruent.com/resources/webinars/best-practices-how-right-size-and-optimize-your-real-estate-portfolio",
              logo: lucernexLogo
            },
          ]
        },
        {
          title: "Capital Project Management",
          children: [
            {
              title: "Lx Projects",
              desc: "Simplify capital project management with our powerful construction project management software",
              link: "/products/lucernex/lx-projects",
              Webinarlink:"https://www.accruent.com/resources/webinars/reduce-costs-accelerate-timelines-accruent-project-management-best-practices",
              logo: LXProjectsLogo
            },
          ]
        },
      ]
    },
    "assets": {
      title: "Asset Management Solutions",
      logo: "",
      subtitle: "Maintain tools or machines",
      children: [
        {
          title: "Mobile",
          children: [
            {
              title: "All",
              desc: "Use Accruent software solutions on mobile for on-the-go operations and increased efficiency",
              link: "/products",
              logo: accruentLogo
            },
          ]
        },
        {
          title: "Work Order",
          children: [
            {
              title: "Maintenance Connection",
              desc: "Take the hassle out of work order management and unlock your maintenance team’s potential with this CMMS/EAM",
              link: "https://website.maintenanceconnection.com/features/work-order-management-software",
              Webinarlink: "https://website.maintenanceconnection.com/resources/webinars/insights-replay-best-practices-work-order-workflows?utm_source=direct&utm_medium=none&internal_site=true&_ga=2.125927053.1297415155.1690987366-1077945504.1686774526",
              logo: maintenanceconnectionLogo,
              isFullLink: true
            },
            {
              title: "FAMIS 360",
              desc: "Easily create and execute work orders for CRE, public sector, and education via one centralized, automated work order tracking tool.",
              Webinarlink: "https://www.accruent.com/resources/blog-posts/work-order-management-for-ems-with-famis360",
              link: "/products/famis-360",
              logo: famisLogo
            },
            {
              title: "TMS",
              desc: "Streamline medical equipment maintenance and improve availability and utilization rates in your healthcare environment ",
              link: "/products/tms",
              Webinarlink: "https://www.accruent.com/resources/webinars/10-step-webinar-series-step-3-asset-inventory-work-order-management-deep-dive",
              logo: TmsLogo,
              isUSOnly: true
            },
          ]
        },
        {
          title: "IOT",
          children: [
            {
              title: "vx Observe",
              desc: "Prolong asset and equipment life with vx Observe's IoT remote monitoring, alarm filtering and predictive maintenance.",
              link: "/products/vx-suite/vx-observe",
              Webinarlink: "https://www.accruent.com/resources/webinars/predictive-analytics-maintenance-remote-iot-monitoring",
              logo: vxobserveLogo
            },
            {
              title: "AOEM",
              desc: "Effectively manage risk, reach energy consumption targets, and meet carbon-neutral goals with our IoT-powered energy management solution",
              link: "/products/accruent-observe-energy-management-aoem",
              Webinarlink: "https://www.accruent.com/resources/webinars/smart-energy-iot-solutions-power-connectivity ",
              logo: aoemLogo
            },
          ]
        },
        {
          title: "Preventive & Predictive",
          children: [
            {
              title: "Maintenance Connection ",
              desc: "Optimize preventive and predictive scheduling, planning, and execution using our CMMS/EAM ",
              link: "https://website.maintenanceconnection.com/program-solutions/preventive-maintenance-software",
              Webinarlink: "https://www.accruent.com/resources/webinars/get-ahead-with-preventive-maintenance",
              logo: maintenanceconnectionLogo,
              isFullLink: true
            },
            {
              title: "FAMIS 360 ",
              desc: "This facilities management software is purpose-built to streamline preventive operations for companies in CRE, education, and the public sector. ",
              link: "/products/famis-360",
              Webinarlink: "https://www.accruent.com/resources/webinars/understanding-bulk-uploader",
              logo: famisLogo
            },
            {
              title: "TMS",
              desc: "Use this healthcare CMMS to operate more predictively and reduce costs in healthcare, pharmaceuticals, and biomed. ",
              link: "/products/tms",
              Webinarlink: "https://www.accruent.com/resources/webinars/new-webinar-series-step-3-asset-work-order-management",
              logo: TmsLogo,
              isUSOnly: true
            }
          ]

        },
        {
          title: "Service Request",
          children: [
            {
              title: "Maintenance Connection ",
              desc: "Take the hassle out of service request management and unlock your maintenance team’s potential with this CMMS/EAM",
              link: "https://website.maintenanceconnection.com/features/work-order-management-software",
              Webinarlink: "https://website.maintenanceconnection.com/resources/webinars/3-ways-manage-your-facility-labor-using-maintenance-program?utm_source=direct&utm_medium=none&internal_site=true&_ga=2.120804744.1297415155.1690987366-1077945504.1686774526",
              logo: maintenanceconnectionLogo,
              isFullLink: true
            },
            {
              title: "FAMIS 360 ",
              desc: "Easily create and execute service requests for CRE, public sector, and education via one centralized, automated work order tracking tool.",
              link: "/products/famis-360",
              Webinarlink: "https://www.accruent.com/resources/blog-posts/work-order-management-for-ems-with-famis360",
              logo: famisLogo
            },
            {
              title: "TMS",
              desc: "Streamline medical equipment maintenance and improve availability and utilization rates in your healthcare environment ",
              link: "/products/tms",
              Webinarlink: "https://www.accruent.com/resources/webinars/new-webinar-series-step-3-asset-work-order-management ",
              logo: TmsLogo,
              isUSOnly: true
            }
          ]

        },
        {
          title: "Shutdown Procedures/Safety",
          children: [
            {
              title: "Maintenance Connection ",
              desc: "Easily manage shutdown-related work orders to streamline plant turnaround activities  ",
              link: "/products/maintenance-connection",
              Webinarlink: "https://www.accruent.com/resources/webinars/optimizing-plant-turnaround-time",
              logo: maintenanceconnectionLogo
            },
            {
              title: "Meridian",
              desc: "Use this single source of documentation to make sure engineering data is available easily and transparently ",
              link: "/products/meridian",
              Webinarlink: "https://www.accruent.com/resources/webinars/optimizing-plant-turnaround-time",
              logo: meridianLogo
            },
            {
              title: "vx Field",
              desc: "Utilize field service management to help simplify work order execution in the field",
              link: "/products/vx-suite/vx-field",
              logo: vxfieldLogo
            }
          ]

        },
        {
          title: "Reporting/API",
          children: [
            {
              title: "All",
              desc: "Accruent's data-driven software solutions always offer API integrations and reporting capabilities",
              link: "/products",
              logo: accruentLogo
            },
          ]
        },
        {
          title: "Labor Management",
          children: [
            {
              title: "Maintenance Connection",
              desc: "Increase technician effectiveness and provide clarity into PM process with this CMMS/EAM ",
              link: "/products/maintenance-connection",
              Webinarlink: "https://website.maintenanceconnection.com/resources/webinars/3-ways-manage-your-facility-labor-using-maintenance-program?utm_source=direct&utm_medium=none&internal_site=true&_ga=2.120804744.1297415155.1690987366-1077945504.1686774526",
              logo: maintenanceconnectionLogo
            },
            {
              title: "FAMIS 360",
              desc: "Automate work orders, maintenance processes and space management to improve employee efficiency in CRE, education, and the public sector",
              link: "/products/famis-360",
              Webinarlink: "https://www.accruent.com/resources/blog-posts/work-order-management-for-ems-with-famis360",
              logo: famisLogo
            },
            {
              title: "TMS",
              desc: "Transform the patient experience, eliminate data silos, ensure compliance, and optimize workforce productivity with our healthcare CMMS ",
              link: "/products/tms",
              Webinarlink: "https://www.accruent.com/resources/webinars/new-webinar-series-step-6-partssupply-management-procurement",
              logo: TmsLogo,
              isUSOnly: true
            },
            {
              title: "Meridian",
              desc: "Use Meridian’s accessible, centralized, and transparent information to help every department in your business bust silos, streamline collaboration, close the loop, and get the job done.",
              link: "/products/meridian",
              Webinarlink: "https://www.accruent.com/resources/webinars/concurrent-engineering-coordinating-changes-dynamic-world",
              logo: meridianLogo
            },
          ]
        },
        {
          title: "ALIM",
          children: [
            {
              title: "Meridian",
              desc: "Overcome recurring pain points — such as shutdowns, rework, compliance issues and accidents — using Meridian's asset lifecycle information management tool.",
              link: "/resources/videos/meridian-asset-lifecycle-information-management",
              Webinarlink: "https://www.accruent.com/resources/webinars/benefits-anytime-anywhere-access-your-engineering-information",
              logo: meridianLogo
            },
          ]
        },
        {
          title: "Spare Parts",
          children: [
            {
              title: "Maintenance Connection",
              desc: "Make sure you always have the right parts in the right place at the right time using this CMMS/EAM ",
              link: "https://website.maintenanceconnection.com/features/equipment-inventory-management-software",
              Webinarlink: "https://website.maintenanceconnection.com/resources/webinars/improving-efficiency-reducing-cost-through-better-inventory-management?utm_source=direct&utm_medium=none&internal_site=true&_ga=2.199507630.1297415155.1690987366-1077945504.1686774526",
              logo: maintenanceconnectionLogo,
              isFullLink: true
            },
            {
              title: "FAMIS 360",
              desc: "Using the FAMIS 360 work order management tool, managers gain insight into labor, parts, purchases, schedules, budgets and procedures needed to execute planned PM and inspections.",
              link: "/products/famis-360",
              Webinarlink: "https://www.accruent.com/resources/webinars/famis-360-incidents-module-how-control-incidents-measure-impact",
              logo: famisLogo
            },
            {
              title: "TMS",
              desc: "Use triggers and automations to streamline your parts procurement processes, eliminate downtime and increase your standard of patient care in the healthcare space ",
              link: "/products/tms",
              Webinarlink: "https://www.accruent.com/resources/webinars/10-step-webinar-series-step-3-asset-inventory-work-order-management-deep-dive",
              logo: TmsLogo,
              isUSOnly: true
            },
          ]
        },
        {
          title: "Asset Management",
          children: [
            {
              title: "Maintenance Connection",
              desc: "Take the hassle out of asset management and unlock your maintenance team’s potential with this CMMS/EAM",
              link: "https://website.maintenanceconnection.com/features/work-order-management-software",
              Webinarlink: "https://website.maintenanceconnection.com/resources/webinars/best-practices-asset-lifecycle-management?utm_source=direct&utm_medium=none&internal_site=true&_ga=2.165949758.1297415155.1690987366-1077945504.1686774526",
              logo: maintenanceconnectionLogo,
              isFullLink: true
            },
            {
              title: "FAMIS 360",
              desc: "Easily create and execute work orders for CRE, public sector, and education via one centralized, automated work order tracking tool.",
              Webinarlink: "https://www.accruent.com/resources/blog-posts/work-order-management-for-ems-with-famis360",
              link: "/products/famis-360",
              logo: famisLogo
            },
            {
              title: "TMS",
              desc: "Streamline medical equipment maintenance and improve availability and utilization rates in your healthcare environment ",
              link: "/products/tms",
              Webinarlink: "https://www.accruent.com/resources/webinars/10-step-webinar-series-step-3-asset-inventory-work-order-management-deep-dive",
              logo: TmsLogo,
              isUSOnly: true
            },
          ]
        },
      ]
    },
    "people": {
      title: "People Management Solutions",
      logo: "",
      subtitle: "Help your workforce and visitors",
      children: [
        {
          title: "More Efficient Maintenance",
          children: [
            {
              title: "Maintenance Connection",
              desc: "Optimize preventive and predictive maintenance scheduling, planning, and execution using our CMMS/EAM",
              link: "https://website.maintenanceconnection.com/program-solutions/preventive-maintenance-software",
              Webinarlink: "https://website.maintenanceconnection.com/resources/webinars/3-ways-manage-your-facility-labor-using-maintenance-program?utm_source=direct&utm_medium=none&internal_site=true&_ga=2.120804744.1297415155.1690987366-1077945504.1686774526",
              logo: maintenanceconnectionLogo,
              isFullLink: true
            },
            {
              title: "FAMIS 360",
              desc: "This facilities management software is purpose-built to streamline preventive maintenance operations for companies in CRE, education, and the public sector. ",
              link: "/products/famis-360",
              Webinarlink: "https://www.accruent.com/resources/blog-posts/work-order-management-for-ems-with-famis360",
              logo: famisLogo
            },
            {
              title: "TMS",
              desc: "Use this healthcare CMMS to operate more predictively and reduce costs in healthcare, pharmaceuticals, and biomed.",
              link: "/products/tms",
              Webinarlink: "https://www.accruent.com/resources/webinars/new-webinar-series-step-3-asset-work-order-management ",
              logo: TmsLogo,
              isUSOnly: true
            },
          ]
        },
        {
          title: "Space and desk booking",
          children: [
            {
              title: "EMS",
              desc: "Help your teams and visitors reserve parking spots, equipment, desks, rooms, and more with EMS space and resource scheduling ",
              link: "https://www.emssoftware.com/solutions/shared-space-resource-scheduling-software",
              Webinarlink: "https://www.emssoftware.com/resources/webinars/practical-global-return-work-navigating-modern-office-using-ems ",
              logo: emsLogo,
              isFullLink: true
            },
            {
              title: "EMS Flex ",
              desc: "Use this one-click desk booking solution to enable teams and visitors to find and book the right spaces in a flash",
              link: "/products/ems-flex",
              Webinarlink: "https://www.accruent.com/resources/webinars/unleash-power-one-click-desk-booking-introducing-ems-flex-2",
              logo: emsFlexLogo
            },
          ]
        },
        {
          title: "Move Planning",
          children: [
            {
              title: "Lx Transaction Management",
              desc: "As part of your IWMS, Accruent Lx Transaction Management is an integrated solution that helps you manage the real estate transaction lifecycle.",
              link: "/products/lucernex/lx-transaction-management",
              Webinarlink: "https://www.accruent.com/resources/webinars/managing-real-estate-lifecycle-family-dollar",
              logo: LXTransactionLogo
            },
            {
              title: "Lx Markets & Sites",
              desc: "This market planning and site selection software helps organizations identify and select new locations, increase revenue weeks, and build faster ",
              link: "/products/lucernex/lx-markets-sites",
              Webinarlink: "https://www.accruent.com/resources/webinars/retails-ever-changing-needs-how-project-management-solution-can-help",
              logo: LXMarketLogo
            },
          ]
        },
        {
          title: "Resource & Event Management",
          children: [
            {
              title: "EMS",
              desc: "Help your teams easily control the entire event planning process in a single source of record",
              link: "https://www.emssoftware.com/solutions/conference-event-management-software",
              Webinarlink: "https://www.accruent.com/resources/webinars/are-your-events-facilities-teams-connected-they-should-be",
              logo: emsLogo,
              isFullLink: true
            },
          ]
        },
        {
          title: "Energy management dashboards",
          children: [
            {
              title: "AOEM",
              desc: "Help your energy management teams seamlessly monitor, target, and validate your estate’s energy mix to meet net zero goals and keep costs under control",
              link: "/products/accruent-observe-energy-management-aoem",
              Webinarlink: "https://www.accruent.com/resources/webinars/enabling-smart-energy-management-accruent-observe-energy-management",
              logo: aoemLogo
            },
          ]
        },
        {
          title: "Service Scheduling",
          children: [
            {
              title: "vx Field ",
              desc: "Use this field service management system to streamline service execution and performance analysis",
              link: "/solutions/field-service-management-software",
              logo: vxfieldLogo
            },
          ]
        },
        {
          title: "People & wayfinding",
          children: [
            {
              title: "EMS ",
              desc: "EMS' wayfinding capabilities seamlessly guide visitors to their destinations and free up staff time for quality interactions",
              link: "/products/ems",
              Webinarlink: "https://www.accruent.com/resources/webinars/reshaping-modern-workplace-future-work-now",
              logo: emsLogo
            },
          ]
        },
        {
          title: "Utilization & Optimization Analytics",
          children: [
            {
              title: "EMS ",
              desc: "Make informed utilization decisions with EMS space utilization and optimization software",
              link: "https://www.emssoftware.com/solutions/analytics-space-utilization-optimization-software",
              Webinarlink: "https://www.accruent.com/resources/webinars/reshaping-modern-workplace-future-work-now?_ga=2.158496442.1297415155.1690987366-1077945504.1686774526 ",
              logo: emsLogo,
              isFullLink: true
            },
          ]
        },
        {
          title: "Streamlined lease Accounting ",
          children: [
            {
              title: "Lx Contracts ",
              desc: "Easily manage lease dates, analyze rent costs, and integrate with your existing tools",
              link: "/products/lucernex/lx-contracts",
              Webinarlink: "https://www.accruent.com/resources/webinars/fasb-iasb-lease-accounting-compliance-three-legged-stool ",
              logo: LXContractsLogo
            },
          ]
        },
        {
          title: "Transaction Management",
          children: [
            {
              title: "Lx Transaction Management",
              desc: "Gain full insight into your portfolio with integrated real estate transaction management.",
              link: "/products/lucernex/lx-transaction-management",
              logo: LXTransactionLogo
            },
          ]
        },
        {
          title: "Portfolio Strategy Planning",
          children: [
            {
              title: "Lucernex",
              desc: "Simplify portfolio strategy and decision-making with our comprehensive real estate management solution",
              link: "/products/lucernex",
              Webinarlink: "https://www.accruent.com/resources/webinars/best-practices-how-right-size-and-optimize-your-real-estate-portfolio",
              logo: lucernexLogo
            },
          ]
        },
      ]
    }

  }
}

export const CircleContent = ({locale}) => {
  const [activeTab, setActiveTab] = useState(data?.tabs?.[0]?.key);
  const [activeCircle, setActiveCircle] = useState(0);
  const linkStyle = 'tw-tracking-[0.03px] tw-text-accruent_dark_imp_blue hover:tw-text-accruent_dark_imp_blue tw-font-bold tw-no-underline tw-text-[16px] tw-font-Poppins'
  const tabListClass = 'tw-relative tw-w-[181px] tw-h-[181px] tw-shadow-[0px_12px_26px_rgba(0, 0, 0, 0.1607843137)] tw-border-[3px] tw-border-solid tw-border-accruent_dark_imp_blue tw-rounded-[50%] tw-flex tw-justify-center tw-items-center tw-flex-col tw-gap-[8px] tw-p-[8px] tw-text-center'
  
  return (
    <section className="tab-slider modern-operations 
    software-modern-operation padding-top padding-bottom
     !tw-bg-accruent_white tw-mt-[60px] tw-mb-[60px] tw-p-[0_25px] md:tw-p-[0px_16px] content-visibility-auto-mobile">
      <div className="container">
        <h2 className="tw-text-center !tw-text-accruent-heading-color !tw-mb-0 tw-text-mobileXxlFont md:tw-text-xxlFont">{data?.title}</h2>
        <div className='tw-text-center tw-max-w-[713px] tw-m-auto tw-mb-[54px] tw-text-mobileLgFont md:tw-text-mobileXlFont !tw-font-medium'>
          {data?.desc}
        </div>
        <ul className="tab-menu tw-flex tw-items-center tw-m-[0_auto] tw-text-center tw-justify-center tw-cursor-pointer ">
          {data?.tabs?.map((tab, i) => {
            return (
              <li className={`${activeTab === tab?.key ? 'active !tw-text-accruent_dark_imp_blue !tw-border-b-[3px] !tw-border-solid !tw-border-accruent_dark_imp_blue' : ''} tw-text-black  tw-mr-[20px] tw-text-[16px] tw-pb-[23px] tw-w-[15%] tw-font-bold`} onClick={() => { setActiveTab(tab?.key); setActiveCircle(0); }} key={tab?.key}>{tab?.title}</li>
            )
          })}
        </ul>
        <div className="hr-line tw-bg-hr_bg_color tw-m-auto tw-w-[48%] tw-h-[3px] tw-relative tw-top-[-3px] tw-hidden lg:tw-block"></div>
        <div className="tab-content tw-hidden lg:tw-block tw-mt-[32px]">
          <div className="item-details active tw-flex-wrap !tw-flex ">
            <div className="info-box tw-w-[70%] tw-flex tw-justify-center tw-items-start tw-mt-[187px] tw-min-h-[650px]">
              <div className={`center-image-box tw-relative tw-w-[331px] tw-h-[331px] tw-border-[3px] tw-border-solid tw-border-accruent_dark_imp_blue tw-shadow-[0px_12px_26px_#00000029] tw-rounded-[50%] tw-flex tw-justify-center tw-items-center tw-flex-col tw-gap-[16px] tw-p-[48px] tw-text-center ${activeTab === 'assets' ? 'tw-border-accruent_upsdell_red': activeTab === 'people' ? 'tw-border-accruent_purple_form' :""} ` + activeTab }>
                <div className="logo-item tw-w-[80px]">
                  <StaticImage src="../../images/new-homepage/accruent-symbol.png" alt="accruent-logo" loading="eager" />
                </div>

                <h4 className='tw-text-[23px] tw-leading-[27px] tw-tracking-[0.21px] tw-text-accruent_dark_imp_blue tw-font-bold'>{data?.content?.[activeTab]?.title}</h4>
                <p className='sub-desc tw-text-[20px] tw-leading-[26px] tw-tracking-0 tw-text-accruent_grey_light tw-text-center'>{data?.content?.[activeTab]?.subtitle}</p>
                <ul className="info-list-link">
                  {data?.content?.[activeTab]?.children?.map((content, c) => {
                    return (
                      <li key={c} onClick={() => setActiveCircle(c)} className={`${activeTab} ${activeCircle === c ? 'active tw-bg-accruent_blue tw-w-[144px] tw-h-[144px] tw-text-[1rem]' : ''} tw-list-none tw-shadow-[0px_6px_12px_#00000029] tw-w-[138px] tw-h-[138px] tw-rounded-[50%] tw-flex tw-justify-center tw-items-center tw-p-[16px] tw-text-center tw-absolute tw-cursor-pointer hover:tw-bg-accruent_blue`}>
                        <span className= ' tw-text-accruent_dark_imp_blue tw-text-[14px] tw-leading-[18px] tw-tracking-[0.14px] tw-font-bold'>{content?.title}</span>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
            <div className="content-box tw-w-[30%]">
              {/* <div className="preview-box">
                <ul className="preview-bg-list">
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </div> */}
              <div className="related-products-box tw-bg-accruent_lightgray tw-rounded-[48px] tw-p-[48px_16px] tw-h-full">
                <div className="heading tw-text-center">
                  <h4 className="tw-font-Poppins tw-tracking-[0.22px] tw-text-[24px] tw-font-bold tw-leading-[28px] tw-mb-[24px] tw-text-accruent-heading-color">Related Products</h4>
                </div>
                {data?.content?.[activeTab]?.children[activeCircle]?.children?.map((item, i) => {
                  const linkPrefix = locale == "en-gb" ? item.isFullLink ? '' : (!item.isUSOnly ? '/uk' : '') : ''

                  return (
                    <div key={i} className={`${i !== 0 ? "mt-2" : ""} product-detail tw-inline-block tw-w-full tw-bg-accruent_white tw-shadow-[0px_12px_26px_#7d7d7d29] tw-rounded-[48px_48px_48px_0px] tw-p-[24px_16px]`}>
                      <div className="product-logo tw-mb-[12px]">
                        {/* <span>Accurent</span> */}
                        {item?.logo}

                      </div>

                      <p className='tw-text-[16px] tw-leading-[21px] tw-text-accruent_grey_light tw-mb-[24px]'>
                        {item?.desc}
                      </p>
                      <div className='is-flex is-justify-content-space-between'>
                      <LocalizedLink to={linkPrefix+item?.link} className={linkStyle}>
                        Learn More <span className='text-[18px]' >›</span>
                      </LocalizedLink>
                        {item?.Webinarlink && <LocalizedLink to={item?.Webinarlink} className={linkStyle}>
                          Related Webinar <span  className='text-[18px]'>›</span>
                        </LocalizedLink>
                        }
                      </div>
                    </div>
                  )
                })}


              </div>
            </div>
          </div>
          
        </div>
        <div className="mobile-tab-item-list  md:tw-hidden">
          <div className={`tab-list ${tabListClass}`}>
            <div className="logo-item tw-w-[60px]">
              <StaticImage src="../../images/new-homepage/accruent-symbol.png" alt="accruent-logo" />
            </div>
            <h4 className='tw-text-[17px] tw-leading-[27px] tw-tracking-[0.21px] tw-text-accruent_dark_imp_blue tw-font-bold tw-font-Poppins'>Facilities Management Solutions</h4>
          </div>
          <div className={`tab-list red-circle ${tabListClass} tw-text-circle_red tw-border-circle_red tw-ml-auto tw-mr-0`}>
            <div className="logo-item tw-w-[60px]">
              <StaticImage src="../../images/new-homepage/accruent-symbol.png" alt="accruent-logo" />
            </div>
            <h4 className='tw-text-[17px] tw-text-circle_red tw-leading-[27px] tw-tracking-[0.21px] tw-font-bold tw-font-Poppins'>Asset Management Solutions</h4>
          </div>
          <div className={`tab-list ${tabListClass}`}>
            <div className="logo-item tw-w-[60px]">
              <StaticImage src="../../images/new-homepage/accruent-symbol.png" alt="accruent-logo" />
              </div>
              <h4 className='tw-text-[17px] tw-leading-[27px] tw-tracking-[0.21px] tw-text-accruent_dark_imp_blue tw-font-bold tw-font-Poppins'>People Management Solutions</h4>
            </div>
        </div>
      </div>
    </section>
  )
}

