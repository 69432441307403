import React, { useEffect, useState } from "react";
/**
 * Custom React hook for implementing lazy loading on scroll.
 *
 * @param {number} scrollThreshold - The distance (in pixels) from the top of the page at which the content should start loading. Once the user scrolls beyond this threshold, content loading is triggered.
 *
 * @returns {boolean} - A boolean state variable, `shouldLoad`, which is initially `false` and becomes `true` when the user scrolls past the specified scroll threshold. This variable can be used to conditionally render or load content when it becomes visible in the viewport.
 */
export const useLazyLoadOnScroll = (scrollThreshold = 100) => {
  const [shouldLoad, setShouldLoad] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (!shouldLoad && window.scrollY > scrollThreshold) {
        setShouldLoad(true);
        window.removeEventListener('scroll', handleScroll);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [shouldLoad, scrollThreshold]);

  // Return the `shouldLoad` state variable, indicating whether content should be loaded.
  return shouldLoad;
};

