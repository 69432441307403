import { navigate } from "gatsby";
import React, { useEffect, useRef } from "react";
import { useLocation } from '@reach/router';
import findSiteType from "../helpers/findSiteType";

export const InpLink = ({ children, to = '/', className }) => {
  const location = useLocation()?.pathname;
  const loadingRef = useRef(null);
  let linkToNavigate = to;
  
  try {
    const isInternalLink = /^\/(?!\/)/.test(to);
    const url = !isInternalLink ? new URL(to) : null;
    if (url) {
      const isMc = findSiteType('mc'), isEms = findSiteType('ems'), isAcc = findSiteType('accruent');
      if ((url?.origin === 'https://www.accruent.com' && isAcc) || (url?.origin === 'https://emssoftware.com' && isEms) || (url?.origin === 'https://website.maintenanceconnection.com' && isMc)) {
        linkToNavigate = url?.pathname
      }
    }
  } catch (err) {
    console.log("Some unexpected error has occurred", err);
  }

  const handleLink = () => {
    loadingRef.current.classList.remove('tw-hidden')
    if (location !== linkToNavigate) {
      navigate(linkToNavigate);
    }
    else {
      loadingRef.current.classList.add('tw-hidden')
    }
  }
  useEffect(() => {
    loadingRef.current.classList.add('tw-hidden')
  }, [location])
  return (
    <>
      <span onClick={handleLink} className={`tw-cursor-pointer ${className}`}>{children}</span>
      <span ref={loadingRef} className="tw-text-black tw-text-[4px] tw-fixed tw-top-0 tw-right-0 tw-hidden">Loading...</span>
    </>
  )
}
