import React from "react";

/**
 * @Context - Used in Resources Main page to hold the filters and unique values that are present
 * in the array that comes from Drupal
 */
const ResourcesContext = React.createContext({
  filters: [],
  setFilters: () => {},
  uniqueTypes: [],
  uniqueIndustries: [],
  uniqueSolutions: [],
  uniqueProducts: [],
  allQueryParams: {},
  setAllQueryParams: () => {},
});

ResourcesContext.displayName = "ResourcesContext";

export default ResourcesContext;
