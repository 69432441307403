import React from "react";
import {AccruentImg,LocalizedLink, PopupVideo} from "src/components/commonComponents";
import { StaticImage } from "gatsby-plugin-image";
import { get } from "src/utils";
import findSiteType from "src/components/helpers/findSiteType";
const getImageObject = require("src/../helpers/getImageObject");
const imageBasePath = "../../../static/"

const ZigZagBlockItem = (props) => {
  const { entity, index, isArrowLink, fieldShowBackgroundPattern } = props;

  let className = "";
  let imgClassName = "";
  let aspectRatio = 1.77;
  if (entity.fieldFormat === "min_img_height") {
    aspectRatio = null;
  } else {
    imgClassName = `content-block-closeup-image tw-h-full tw-mr-0 md:tw-mr-[33px] ${entity.fieldFormat === "rounded_corner_image" ? "tw-rounded-[24px]" : ''}`;
  }
  const videoUrl = get(entity, "relationships.fieldVideoRef.fieldMediaVideoEmbedField");
  const videoThumbnail = get(entity, "relationships.fieldVideoRef.relationships.thumbnail");
  const mediaImage = getImageObject(entity?.relationships?.fieldMediaImage,{},"fieldMediaImage");
  let contentImage = entity?.relationships?.fieldMediaImage ? (
    <AccruentImg
      file={mediaImage}
      className={imgClassName}
      width={740}
    />
  ) : (
    findSiteType('mc') ?
      <StaticImage
        className="tw-h-full"
        src={imageBasePath + "mc-social-share.png"}
        alt={"default Image"}
        aspectRatio={2.0}
        placeholder="blurred"
        layout="constrained"
        width={740}
        decoding = "async"
      /> :
      findSiteType('ems') ?
        <StaticImage
          className="tw-h-full"
          src={imageBasePath + "ems-social-share.png"}
          alt={"default Image"}
          aspectRatio={2.0}
          placeholder="blurred"
          layout="constrained"
          width={740}
          decoding = "async"
        /> :
        <StaticImage
          className="tw-h-full"
          src={imageBasePath + "accruent-social-share.png"}
          alt={"default Image"}
          aspectRatio={2.0}
          placeholder="blurred"
          layout="constrained"
          width={740}
          decoding = "async"
        />

  );
  const buttonArrow = !isArrowLink ? '' : '>'
  const contentMedia = !videoUrl ? contentImage : (
    <div>
      {mediaImage?.gatsbyImage &&<AccruentImg
         file={mediaImage}
        className={"about-hero-image"}
         width={740}
      />}
      <div className={mediaImage?.gatsbyImage ? "": "show-video tw-pt-[56%] md:tw-pt-[43%]"}></div>
      <div>
        <PopupVideo videoUrl={videoUrl} thumbnail={videoThumbnail} thumbnailClass={"p-0 is-full-tablet hero-video"} />
      </div>
    </div>
  )
  const btnStyles = "tw-font-Poppins tw-tracking-[0.3px] tw-text-[16px] tw-leading-[16px] tw-font-bold tw-mr-[16px] tw-inline-block tw-mb-[10px]"

  return (
    <div
      className={`${className}${
        entity.fieldFormat === "min_img_height" ? "" : ""
      }${
        index % 2 ? "" : "reverse-rows "
      }product-page-zigzag-box tw-block tw-mt-[52px] md:!tw-mt-[2rem] tw-shadow-none tw-bg-transparent tw-border-0 tw-justify-items-center md:tw-flex tw-items-center tw-mb-0 tw-relative ${entity.fieldFormat === "rounded_corner_image" ? "align-normal": ""}`}
      key={entity.uuid}
    >
      <div className={`content-block-item__image tw-relative ${entity.fieldFormat === "content_7_5_ratio"?" tw-w-full md:tw-w-5/12":" tw-w-full md:tw-w-1/2"} prduct-page-container`}>
        {contentMedia}
      </div>
      <div className={`${entity.fieldFormat === "content_7_5_ratio"?" tw-w-full md:tw-w-7/12":"tw-w-full md:tw-w-1/2"} prduct-page-container is-12-mobile`}>
        <div
          className={`content-block-item__description tw-pr-[0px] tw-p-0 md:tw-pr-[33px] tw-h-full ${
            entity.fieldFormat === "min_img_height"
              ? ""
              : ""
          }`}
        >
             {!!entity.fieldPreHeading && (
            <div className="pre-heading">
              {entity.fieldPreHeading}
            </div>
          )}
          {!entity.fieldHideTitle && (
            <h3 className=" tw-transform-none tw-text-mobileXxlFont md:tw-text-xxlFont tw-mt-[15px] tw-mb-[16px] md:mt-[16px]">
              {entity.title}
            </h3>
          )}
          {entity.fieldSubtitle && (
            <div className="tw-text-[21px] tw-leading-[28px] tw-text-accruent_black tw-mb-[36px]">{entity.fieldSubtitle}</div>
          )}
          {entity.body && (
            <div
              className="tw-content-box dangerously-html-handle"
              dangerouslySetInnerHTML={{
                __html: entity.body.value,
              }}
            />
          )}
          <div className={`button-box ${fieldShowBackgroundPattern ? "show-bg": ""}`}>
            {entity.fieldCtaUrl && (
              <LocalizedLink
                to={entity.fieldCtaUrl.url}
                className={`hover:tw-text-accruent_dark_imp_blue hover:tw-underline tw-text-accruent_dark_imp_blue ${btnStyles} ${fieldShowBackgroundPattern ? "tw-p-[14px_24px] tw-rounded-[12px] tw-bg-accruent_dark_imp_blue tw-text-accruent_white hover:tw-text-accruent_white": ""}`}
              >
                {`${entity.fieldCtaUrl.title} ${buttonArrow}`}
              </LocalizedLink>
            )}
            {entity.fieldSecondaryCta && (
              <LocalizedLink
                to={entity.fieldSecondaryCta.url}
                className={`hover:tw-text-accruent_dark_imp_blue hover:tw-underline tw-text-accruent_dark_imp_blue ${btnStyles} ${fieldShowBackgroundPattern ? "tw-p-[14px_24px] tw-rounded-[12px] tw-bg-accruent_dark_imp_blue tw-text-accruent_white hover:tw-text-accruent_white": ""}`}
              >
                {`${entity.fieldSecondaryCta.title} ${buttonArrow}`}
              </LocalizedLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ZigZagBlockItem;
