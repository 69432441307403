import React from 'react';
import { LocalizedLink } from './localizedLink';

const prefixLangcode = require('src/../helpers/prefixLangcode');

/**
 *
 * @param {string} to - internal url path
 * @param {string} title - link title
 */
export const Eyebrow = ({ to, title,  locale = "en",  className = "tw-base_transition tw-text-accruent-heading-color"}) => {
  return (
    <div className="tw-h-[50px] md:tw-h-auto tw-text-accruent-heading-color tw-text-xsFont md:tw-text-smFont tw-mb-0 tw-pl-0 tw-pb-[33px] tw-font-Poppins tw-leading-[21px] tw-tracking-[1.8px]">
    <LocalizedLink
      to={prefixLangcode(to, locale)}
      className={className}
    >
      ‹  {title}
    </LocalizedLink>
  </div>
  );
};

// export default Eyebrow;