import React, { useEffect } from "react";
import { onLCP, onFID } from 'web-vitals';

const airtableId = process.env.GATSBY_AIRTABLE_ID;
const airtableApiKey = process.env.GATSBY_AIRTABLE_API;
const airtableIdForMoble = process.env.GATSBY_AIRTABLE_ID_MOBILE;
const sendToAnalytics= (metric, page, isEms, isMc) => {
  if(airtableId && airtableApiKey && airtableIdForMoble){
    const LCP = metric[0]?.name === "LCP" ? (metric[0]?.value)/1000 : metric[1]?.name === "LCP" ? (metric[1]?.value)/1000 : 0
    const FCP = metric[1]?.name === "FID" ? metric[1]?.value: metric[0]?.name === "FID" ?  metric[0]?.value : 0
    let recordsForMobile = null
    const checkForMobileResolution = typeof(window) !== undefined && window?.innerWidth
    let records = {
      "fields": {
        "LCP" : LCP,
        "FID" : FCP,
        "Page": page,
        "Id": Math.floor(Math.random() * 1000).toString(),
        "Website": isEms ? "EMS": isMc ? "MC": "ACCRUENT"
      }
    }
    if(checkForMobileResolution < 800){
      recordsForMobile = {"fields": {...records?.fields, "Resolution_Width": checkForMobileResolution}}
    }
    fetch(`https://api.airtable.com/v0/${(!recordsForMobile) ? airtableId : airtableIdForMoble}?api_key=${airtableApiKey}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify((!recordsForMobile) ? records: recordsForMobile)
    }).then((res) => res.json())
      .catch((error) => {
        console.log(error);
    })
  }
}

export const  AirtableComponent = ({ page, isEms, isMc }) => {
  let matrixArr = []

 
  const analyticsMethod = (metric) => {
    matrixArr.push(metric);
    if(matrixArr.length === 2){
      sendToAnalytics(matrixArr, page, isEms, isMc)
    }
  }
  useEffect(() => {
    try{
      onLCP(analyticsMethod);
      onFID(analyticsMethod);
    }catch(err){
      console.log("error while fetching vitals", err);
    }
  }, []);
 
  return (
    null
  )
}
// export default AirtableComponent;