import React, { useState } from 'react';
import { LocalizedLink } from './localizedLink';
const getImageObject = require("src/../helpers/getImageObject")
/**
 *
 * @param {enum} version - ['V1','V2'] used for Versioning 
 * @param {boolean} isMobile - change view  on mobile device
 * @param {Array of Objects} fieldPricingItemCard - [{entity: {fieldPrice: Number,fieldGraphic: Object,fieldKeyFeaturesBullets ...}]
 * @param {boolean} isMonthly - to show monthly data
 * @param {Object} bg - localFile for background image of the section.
 */

export const PricingCards = ({ version = 'V1', isMobile = false, fieldPricingItemCard, isMonthly, bg }) => {
  const [featuresOneOpen, setFeaturesOneOpen] = useState(false);
  const [featuresTwoOpen, setFeaturesTwoOpen] = useState(false);

  if (version == 'V2') {
    return (
      <div className='pricing-cards tw-justify-center tw-relative tw-mt-[23px] md:tw-flex tw-block'>
        <div className='overlay-bg tw-hidden md:tw-block tw-absolute tw-w-[87%] tw-left-[-525px] tw-top-[36px]'>
          {bg?.relationships?.fieldImage?.url && <img className='tw-inline' alt={bg?.fieldImage?.alt} src={bg?.relationships?.fieldImage?.url} decoding = "async" />}
        </div>
        {fieldPricingItemCard?.map((pricingCard, index) => {
          return (
            <PricingCard key={index} card={pricingCard} isMonthly={isMonthly} isWhite={index % 2 !== 0} />
          )
        })}

      </div>
    )
  }

  return (
    <div className={`deployment-cards ${isMobile ? "is-mobile" : ""}`}>
      <div className='columns'>
        {fieldPricingItemCard?.map((pricingCard, index) => {
          const pricingEntity = pricingCard?.entity
          const imgObj = getImageObject(pricingEntity?.relationships?.fieldImage, pricingEntity?.fieldImage)
          return (
            <div className='column is-4-desktop is-6-tablet is-12-mobile' key={index}>
              <div className='card'>
                <div className="icon-header">
                  <span class="fa-stack fa-2x">
                    <i
                      className={"fa-solid fa-circle fa-stack-2x"}
                      style={pricingEntity?.fieldBackgroundColor
                        ? { color: pricingEntity?.fieldBackgroundColor }
                        : { color: 'white', border: `1px dashed ${pricingEntity?.fieldIconColor}`, borderRadius: '45px', width: '80px' }}></i>
                    <i className={`fa-light fa-${pricingEntity?.fieldIcon?.iconName} fa-stack-1x fa-inverse`} style={{ color: pricingEntity?.fieldIconColor }}></i>
                  </span>
                </div>
                <div className="is-flex is-flex-direction-row is-justify-content-center is-align-items-center">
                  <h3 className='subtitle-2'>
                    {pricingEntity?.title}
                  </h3>
                  {pricingEntity.fieldAddOnText && (
                    <div className="grey-bar-wrapper">
                      <span className='grey-bar'>{pricingEntity?.fieldAddOnText}</span>
                    </div>
                  )}
                </div>
                <p className='content-para'>{pricingEntity?.fieldSubtitle}</p>
                {pricingEntity?.fieldKeyFeaturesBullets && (
                  <div className="features">
                    {index === 0 ? (
                      <>
                        {!featuresOneOpen && (
                          <div className="btn-feat" onClick={() => setFeaturesOneOpen(true)}>
                            SEE TOP FEATURES <i className="fa fa-chevron-down" aria-hidden="true"></i>
                          </div>
                        )}
                        {featuresOneOpen && (
                          <div>
                            <ul className="bullets">
                              {pricingEntity.fieldKeyFeaturesBullets.map((bullet, index) => (
                                <li key={bullet}>{bullet}</li>
                              ))}
                            </ul>
                            <div className="btn-feat" onClick={() => setFeaturesOneOpen(false)}>HIDE <i className="fa fa-chevron-up" aria-hidden="true"></i></div>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        {!featuresTwoOpen && (
                          <div className="btn-feat" onClick={() => setFeaturesTwoOpen(true)}>
                            SEE TOP FEATURES <i className="fa fa-chevron-down" aria-hidden="true"></i>
                          </div>
                        )}
                        {featuresTwoOpen && (
                          <div>
                            <ul className="bullets">
                              {pricingEntity.fieldKeyFeaturesBullets?.map((bullet, index) => (
                                <li key={bullet}>{bullet}</li>
                              ))}
                            </ul>
                            <div className="btn-feat" onClick={() => setFeaturesTwoOpen(false)}>HIDE <i className="fa fa-chevron-up" aria-hidden="true"></i></div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
                {pricingEntity?.fieldImage ?
                  <div>
                    {isMonthly && <p className='currency'>{pricingEntity?.fieldPrefix} {pricingEntity?.fieldPrice} <span>/ {pricingEntity?.fieldPriceFrequency}</span></p>}
                    {!isMonthly && <p className='currency'>{pricingEntity?.fieldPrefix} {pricingEntity?.fieldYearlyPrice} <span>/ {pricingEntity?.fieldYearlyPriceFrequency}</span></p>}
                    <p className='subscribe-text'>{pricingEntity?.fieldPriceText}&nbsp;</p>

                    <LocalizedLink to={pricingEntity?.fieldCtaUrl?.url} className='btn-pointer'>
                      <button className='link-btn'>{pricingEntity?.fieldCtaUrl?.title}</button>
                    </LocalizedLink>
                  </div>
                  :
                  <div>
                    {isMonthly && <p className='currency'>{pricingEntity?.fieldPrefix} {pricingEntity?.fieldPrice} <span>/ {pricingEntity?.fieldPriceFrequency}</span></p>}
                    {!isMonthly && <p className='currency'>{pricingEntity?.fieldPrefix} {pricingEntity?.fieldYearlyPrice} <span>/ {pricingEntity?.fieldYearlyPriceFrequency}</span></p>}
                    <p className='subscribe-text'>{pricingEntity?.fieldPriceText}&nbsp;</p>

                    <LocalizedLink to={pricingEntity?.fieldCtaUrl?.url} className='btn-pointer'>
                      <button className='link-btn'>{pricingEntity?.fieldCtaUrl?.title}</button>
                    </LocalizedLink>
                  </div>
                }
                <span>
                </span>

              </div>
            </div>
          )
        })

        }

      </div>

    </div>
  )
}

/**
 *
 * @param {Objects} card - {fieldPrice: Number,fieldGraphic: Object,fieldKeyFeaturesBullets ...}
 * @param {boolean} isMonthly - to show monthly data
 * @param {boolean} isWhite - to make background white color
 */

const PricingCard = ({ card, isMonthly, isWhite }) => {
  const [showFeature, seShowFeature] = useState(true)
  const price = isMonthly ? (card?.fieldPrice) : (card?.fieldYearlyPrice)
  const frequency = isMonthly ? (card?.fieldPriceFrequency) : (card?.fieldYearlyPriceFrequency)
  const isFeatureAvaillable = card?.fieldKeyFeaturesBullets?.length
  const image = getImageObject(card?.relationships?.fieldGraphic, card?.fieldGraphic)
  const imageAttr = image?.gatsbyImage?.images?.fallback

  return (
    <div className={`pricing-card-container tw-p-0 md:tw-m-[12px_12px_0] tw-m-[1rem_1rem_0] tw-rounded-3xl md:tw-w-[346px] tw-w-auto tw-mb-0 tw-min-h-[530px] tw-max-w-full ${isWhite ? 'pricing-card-container-white lg:tw-ml-1' : ''} `} >
      <div className={`pricing-card tw-bg-accruent_sapphire tw-rounded-3xl md:tw-pt-[25px] tw-pt-6 md:tw-pb-[25px] tw-pb-12 md:tw-pr-9 tw-pr-[35px] md:tw-pl-9 tw-pl-[37px] tw-relative md:tw-min-h-[518px] tw-flex tw-flex-col tw-justify-between tw-items-center tw-border-[2px] tw-border-solid tw-border-accruent_sapphire md:tw-w-[346px]  tw-w-auto  ${isWhite ? 'pricing-cards-white tw-border-[2px] tw-border-solid tw-border-accruent_sapphire tw-bg-accruent_white' : ''}`} >
        <div className='pricing-header tw-w-full'>
          <h3 className='title-4 tw-text-accruent_sapphire'> {card?.title} </h3>
          <div
            className='subtitle-2 tw-text-accruent_sapphire dangerously-html-handle'
            dangerouslySetInnerHTML={{ __html: card?.body?.value }}
          />
          {!!card?.fieldAddOnText && <div className='add-on tw-bg-accruent_background_color tw-rounded-[6px] tw-w-fit tw-text-center tw-pt-1.5 tw-px-4 tw-pb-1 tw-text-[12px] tw-text-accruent_sapphire tw-leading-4 tw-tracking-[0.4px] tw-font-bold tw-m-auto tw-mt-[16px]'> {card?.fieldAddOnText} </div>}
        </div>
        <div className='pricing-footer tw-w-full tw-mt-6'>
          {image &&
            <img
              {...imageAttr}
              alt={imageAttr?.alt}
              loading="eager"
              fetchpriority="high"
              className={'pricing-card-image tw-w-[165px] tw-m-auto'}
              decoding = "async"
            />
          }
          <div className='price-info tw-text-accruent_orange tw-flex tw-justify-center tw-items-center tw-mt-[32.1px] md:tw-text-[56px] tw-text-[48px] md:tw-leading-[62px] tw-leading-[55.68px] md:tw-tracking-[0.5px] tw-tracking-[0.43px] tw-font-Helvetica tw-font-bold'> <span className='tw-text-[20px] tw-leading-[30px]'>{card?.fieldPrefix}</span>{price}<span className='tw-text-[20px] tw-leading-[30px]'>&nbsp;{frequency}</span></div>
          <div className='info tw-text-[1rem] !tw-leading-[22px] tw-tracking-[0.29px] tw-mt-[2px] extra-info tw-text-accruent_white'>{card?.fieldPriceText}</div>
          <LocalizedLink to={card?.fieldCtaUrl?.url} className='btn-pointer tw-w-full'>
            <button className='button md:tw-mt-[27px] tw-mt-6 md:tw-mb-9 tw-mb-0 tw-w-full !tw-rounded-[14px] tw-p-3 tw-leading-[15px] tw-tracking-normal tw-font-Poppins tw-text-accruent_sapphire tw-font-semibold tw-text-[16px] tw-bg-accruent_white tw-border-[1px] tw-border-solid tw-border-transparent tw-h-[2.5em] tw-cursor-pointer tw-justify-center tw-text-center link-btn'>{card?.fieldCtaUrl?.title}</button>
          </LocalizedLink>
        </div>
        {isFeatureAvaillable && <div key={showFeature + ""} className='show-hide-toggle-container tw-absolute -tw-bottom-4 tw-left-0 tw-w-full'>
          <div onClick={() => seShowFeature(!showFeature)} className='show-hide-toggle'> {!showFeature ? "Show Main Features" : "Hide Main Features"} {showFeature ? <i class="fal fa-minus-circle" aria-hidden="true"></i> : <i class="fal fa-plus-circle" aria-hidden="true"></i>}</div>
        </div>
        }

      </div>

      {isFeatureAvaillable && <ul className={`feature-list ${!showFeature ? "hide-features" : ""}`}>
        {card?.fieldKeyFeaturesBullets?.map((feature, i) => {
          return (<li key={i} className='info tw-text-[1rem] !tw-leading-[22px] tw-tracking-[0.29px] tw-mt-[2px]'>{feature}</li>)
        })}
      </ul>
      }
    </div>
  )

}