import isEmpty from "src/components/helpers/isEmpty";
import parseStringFromHTML from "src/components/helpers/parseStringFromHTML";
import getUrlPathBySiteId from 'src/../helpers/getUrlPathBySiteId';
const getImageObject = require("src/../helpers/getImageObject")
const parseAndTruncate = require("../../../helpers/parseAndTruncate");
const prefixLangcode = require('src/../helpers/prefixLangcode');

const createRelatedResources = (data, passedOptions) => {
  const options = {
    isTaxonomyPage: false,
    hasDescription: false,
    descriptionSize: 200,
    ...passedOptions,
  };
  if (options.isFeaturedContent) return createFeaturedContents(data, options);
  if (options.isTaxonomyPage) return createForTaxonomy(data, options);
  const recommendedHolder = [];
  if (!isEmpty(data)) {
    data.forEach((edge) => {
      const initial = edge.node?.context || edge.context || edge.pageContext || edge;
      let teaser_img = false;
      const mediaImg = initial?.fieldMediaImage ? initial?.fieldMediaImage : initial.relationships?.fieldMediaImage
      const heroImg = initial?.fieldHeroMedia ? initial?.fieldHeroMedia : initial.relationships?.fieldHeroMedia
      if (mediaImg) {
        if (
          Array.isArray(mediaImg) &&
          mediaImg[0]
        ) {
          teaser_img = getImageObject(mediaImg[0], {}, "fieldMediaImage");
        } else {
          teaser_img = getImageObject(mediaImg, {}, "fieldMediaImage");
        }
      } else if (heroImg) {
        if (
          Array.isArray(heroImg) &&
          heroImg[0]
        ) {
          teaser_img = getImageObject(heroImg[0], {}, "fieldHeroMedia");;
        } else {
          teaser_img = getImageObject(heroImg, {}, "fieldHeroMedia");
        }
      } else if (initial?.heroImage?.gatsbyImage) {
        teaser_img = initial?.heroImage
      }

      else if (initial?.fieldVideoRef?.relationships?.thumbnail?.gatsbyImage) {
        teaser_img = getImageObject(initial?.fieldVideoRef?.relationships?.thumbnail, initial?.fieldVideoRef?.thumbnail);
      }
      const UrlPath = getUrlPathBySiteId(initial?.fieldTargetSite?.targetId, initial.entityUrl?.path, initial)
      const obj = {
        image: teaser_img,
        title: initial.title,
        path: UrlPath,
        content_type: initial.type?.id || initial.type || null,
        label: initial.contentTypeId?.[0]?.label || null,
        body: "",
        date: initial.entityCreated,
        industry: initial.industryIds,
        solution: initial.solutionIds,
        product: initial.productIds,
        uuid: initial.uuid,
        fieldExternalLink: initial.fieldExternalLink,
        targetSiteId: initial?.fieldTargetSite?.targetId,
        fieldTags: initial?.fieldTags?initial?.fieldTags?.entityLabel:initial?.relationships?.fieldTags?.entityLabel,
        fieldPublishDate: initial?.fieldPublishDate
      };
      
      if (options.hasDescription) {
        if (initial.truncatedBody) {
          obj.description = initial.truncatedBody;
        } else if (initial?.body?.value && initial?.body?.value !== "") {
          const description = parseStringFromHTML(initial.body.value)
            .replace(/&amp;/g, "&")
            .replace(/&nbsp;/g, " ");
          obj.description = description;
        } else if (
          initial?.fieldTheCompany?.value &&
          initial?.fieldTheCompany?.value !== ""
        ) {
          const description = parseStringFromHTML(initial.fieldTheCompany.value)
            .replace(/&amp;/g, "&")
            .replace(/&nbsp;/g, " ");
          obj.description = description;
        }
      }
      recommendedHolder.push(obj);
    });
  }

  return recommendedHolder;
};

// Used for creating featured contents section on homepage.
// Have image, title, description, industry/solution/product as hashtag
// industry/solution hashtag links to resources?{industry/solution = fieldFilteredLabel}
// Product hashtag links to the product url itself
const createFeaturedContents = (data, options) => {
  const vocabularyMap = {
    industry: 'Industries',
    solutions: 'Solutions',
    accruent_product: 'Products',
  };
  const recommendedHolder = [];
  if (!isEmpty(data)) {
    data.forEach((node) => {
      const initial = node?.context;
      if (!initial) {
        return null;
      }
      let description = parseStringFromHTML(initial.description?.value)
        ?.replace(/&amp;/g, "&")
        ?.replace(/&nbsp;/g, " ");

      const content_type = initial.vid?.targetId ? vocabularyMap[initial.vid.targetId] : undefined;
      let contentTypeLink;
      if (content_type === 'Products') {
        contentTypeLink = initial.entityUrl?.path;
      } else if (content_type === 'Industries') {
        contentTypeLink = initial.fieldFilterLabel ? `/resources?industry=${initial.fieldFilterLabel}` : undefined;
      } else if (content_type === 'Solutions') {
        contentTypeLink = initial.fieldFilterLabel ? `/resources?solution=${initial.fieldFilterLabel}` : undefined;
      }
      const UrlPath = getUrlPathBySiteId(initial?.fieldTargetSite?.targetId, initial.entityUrl.path)
      const obj = {
        image: initial.fieldHeroImage?.gatsbyImage ?? false,
        title: initial.fieldPageTitle ?? initial.entityLabel,
        path: UrlPath,
        content_type,
        contentTypeLink,
        body: "",
        description,
        date: initial.entityCreated,
      };
      recommendedHolder.push(obj);
    });
  }
  return recommendedHolder;
}

// Used for related resources in taxonomy pages.
// Only key values are different. Do the same with above function.
const createForTaxonomy = (data, options = { locale: 'en' }) => {
  const recommendedHolder = [];
  if (!isEmpty(data)) {
    if (!options?.newResourceReferenceField) {
      data.forEach((entity) => {
        const initial = entity
        if (!initial?.entityUrl?.path) {
          return;
        }
        let teaser_img = false;
        const videoRef = initial?.fieldVideoRef ? initial?.fieldVideoRef : initial?.relationships?.fieldVideoRef
        if (initial.relationships?.fieldMediaImage) {
          if (Array.isArray(initial.relationships?.fieldMediaImage)) {
            teaser_img = getImageObject(initial.relationships?.fieldMediaImage[0], {}, "fieldMediaImage");
          } else {
            teaser_img = getImageObject(initial.relationships?.fieldMediaImage, {}, "fieldMediaImage");
          }
        } else if (initial.relationships?.fieldHeroMedia) {
          if (Array.isArray(initial.relationships?.fieldHeroMedia)) {
            teaser_img = getImageObject(initial.relationships?.fieldHeroMedia[0], {}, "fieldHeroMedia");
          } else {
            teaser_img = getImageObject(initial.relationships?.fieldHeroMedia, {}, "fieldHeroMedia");
          }
        }
        else if (initial?.heroImage?.gatsbyImage && resource?.type !== 'video') {
          teaser_img = initial?.heroImage
        }
        else if (videoRef?.relationships?.thumbnail?.gatsbyImage) {
          teaser_img = getImageObject(videoRef?.relationships?.thumbnail, videoRef?.thumbnail);
        }
        const UrlPath = initial?.fieldTargetSite?.targetId === 451 ? getUrlPathBySiteId(initial?.fieldTargetSite?.targetId, prefixLangcode(initial.entityUrl?.path, initial?.locale)): getUrlPathBySiteId(initial?.fieldTargetSite?.targetId, initial.entityUrl?.path)

        const obj = {
          image: teaser_img,
          title: initial.title,
          path: UrlPath,
          content_type: initial.type?.id || initial.type || null,
          body: "",
          industry: initial.industry?.map((entity) => ({
            id: entity.entityId,
            label: entity.fieldPageTitle ?? entity.entityLabel,
          })),
          changed: initial?.changed,
          entityId: initial?.entityId
        };
        if (options.hasDescription) {
          if (initial?.fieldResourceDescription) {
            obj.description = parseAndTruncate(initial?.fieldResourceDescription);
          } else if (initial?.body?.value && initial?.body?.value !== "") {
            const description = parseStringFromHTML(initial.body?.value)
              .replace(/&amp;/g, "&")
              .replace(/&nbsp;/g, " ");
            obj.description = description;
          } else if (
            initial?.fieldTheCompany?.value &&
            initial?.fieldTheCompany?.value !== ""
          ) {
            const description = parseStringFromHTML(initial.fieldTheCompany.value)
              .replace(/&amp;/g, "&")
              .replace(/&nbsp;/g, " ");
            obj.description = description;
          }
        }
        recommendedHolder.push(obj);
      });
    } else {
      data.forEach((entity) => {
        const initial = entity
        if (initial && initial?.relationships?.fieldResourceReferenceItem && !initial?.fieldOverrideItem) {
          const resource = initial?.relationships?.fieldResourceReferenceItem
          if (!resource?.entityUrl?.path || !initial?.status) {
            return;
          }
          let teaser_img = false;
          const videoRef = resource?.fieldVideoRef ? resource?.fieldVideoRef : resource?.relationships?.fieldVideoRef
          if (resource.relationships?.fieldMediaImage) {
            if (Array.isArray(resource.relationships?.fieldMediaImage)) {
              teaser_img = getImageObject(resource.relationships?.fieldMediaImage[0], {}, "fieldMediaImage");
            } else {
              teaser_img = getImageObject(resource.relationships?.fieldMediaImage, {}, "fieldMediaImage");
            }
          } else if (resource.relationships?.fieldHeroMedia) {
            if (Array.isArray(resource.relationships?.fieldHeroMedia)) {
              teaser_img = getImageObject(resource.relationships?.fieldHeroMedia[0], {}, "fieldHeroMedia");
            } else {
              teaser_img = getImageObject(resource.relationships?.fieldHeroMedia, {}, "fieldHeroMedia");
            }
          }
          else if (resource?.heroImage?.gatsbyImage) {
            teaser_img = resource?.heroImage
          }
          else if (videoRef?.relationships?.thumbnail?.gatsbyImage) {
            teaser_img = getImageObject(videoRef?.relationships?.thumbnail, videoRef?.thumbnail);
          }
          const UrlPath = resource?.fieldTargetSite?.targetId === 451 ? getUrlPathBySiteId(resource?.fieldTargetSite?.targetId, prefixLangcode(resource.entityUrl?.path, resource?.locale)): getUrlPathBySiteId(resource?.fieldTargetSite?.targetId, resource.entityUrl?.path)
          const obj = {
            image: teaser_img,
            title: resource.title,
            path: UrlPath,
            content_type: resource.type?.id || resource.type || null,
            body: "",
            industry: resource?.relationships?.industry?.map((entity) => ({
              id: entity?.entityId,
              label: entity?.fieldPageTitle ?? entity?.entityLabel,
            })),
            changed: resource?.changed,
            entityId: resource?.entityId
          };
          if (options.hasDescription) {
            if (resource?.fieldResourceDescription) {
              obj.description = parseAndTruncate(resource?.fieldResourceDescription);
            } else if (resource?.body?.value && resource?.body?.value !== "") {
              const description = parseStringFromHTML(resource.body.value)
                .replace(/&amp;/g, "&")
                .replace(/&nbsp;/g, " ");
              obj.description = description;
            } else if (
              resource?.fieldTheCompany?.value &&
              resource?.fieldTheCompany?.value !== ""
            ) {
              const description = parseStringFromHTML(resource.fieldTheCompany.value)
                .replace(/&amp;/g, "&")
                .replace(/&nbsp;/g, " ");
              obj.description = description;
            }
          }

          recommendedHolder.push(obj);
        } else if (initial && initial?.fieldOverrideItem) {
          const resource = initial?.relationships?.fieldResourceReferenceItem
          if (!initial?.fieldCtaUrl?.url || !initial?.status) {
            return;
          }
          let teaser_img = false;
          teaser_img = getImageObject(initial?.relationships?.fieldGraphic, initial?.fieldGraphic);
          const obj = {
            image: teaser_img,
            title: initial?.fieldTitle,
            path: initial?.fieldCtaUrl?.url,
            content_type: resource && (resource?.drupalResourceType?.entity?.entityLabel || resource?.type?.id),
            body: "",
            industry: resource && resource?.relationships?.industry?.map((entity) => ({
              id: entity?.entityId,
              label: entity?.fieldPageTitle ?? entity?.entityLabel,
            })),
            overRideLinkText: initial?.fieldCtaUrl?.title,
            changed: initial?.changed,
            entityId: initial?.entityId
          };
          if (initial?.fieldResourceDescription) {
            obj.description = parseAndTruncate(initial?.fieldResourceDescription);
          }
          recommendedHolder.push(obj);
        }
      });
    }
  }

  return recommendedHolder;
};
export default createRelatedResources;
