const site_vars = require("../../../src/get_site_data");
const target_site_tid = site_vars.drupal_site_id;
const relatedWebsites = ["accruent.com","accruent.uk","maintenanceconnection.com","emssoftware.com"]
const getRelAtrribute = (link) =>{
     if(link && relatedWebsites.some(site=>link.includes(site))){
        let filteredSite = relatedWebsites.filter(site=>link.includes(site))?.[0]
        if(filteredSite && (filteredSite === 'emssoftware.com' && target_site_tid === 452) || (filteredSite === 'accruent.com' && target_site_tid === 451) || (filteredSite === 'maintenanceconnection.com' && target_site_tid === 453)){
            return {rel: undefined}
        }
         return {rel:"noreferrer"}
     }
    return {};
}

export default getRelAtrribute;