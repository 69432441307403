import React, {useState} from "react";
import { LocalizedLink } from "../commonComponents/localizedLink";
import { AccruentImg } from "../commonComponents/accruentImg";
import { Picture } from "../commonComponents/picture";
import getImageObject from "../../../helpers/getImageObject";

const NavItems = ({active, index, item, activeIndex, handleChange, activeSecondaryCta, setActiveClass, setActiveClassDropdown, name}) => {
  return( 
    <>
      <LocalizedLink to={item?.link ? item?.link : null} handleClick={() => {
           setActiveClass({ [name]: '' })
           setActiveClassDropdown({ [name]: 'menu-is-inactive' })
      }}> <div className={`image-div tw-pl-[37px] tw-pr-[50px] tw-pt-5 tw-pb-[15px] tw-cursor-pointer tw-bg-accruent_lightgray tw-min-h-[60px] ${active ? "active tw-bg-accruent_white": ""}`} onMouseEnter={() => handleChange(index)}>
        {(item?.navbarGreyImg && item?.navbarHeaderImg) && 
        <div>
          <Picture image={item?.navbarHeaderImg} className={`${activeIndex !== index ? '!tw-hidden': ''}`}/>
          <Picture image={item?.navbarGreyImg} className={`${activeIndex === index ? '!tw-hidden': ''}`}/>
        </div>
        }
      </div></LocalizedLink>
    </>
  )
}

const NavbarWithGraphics = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeSpolitlight, setSpotlight] = useState(props.items?.[0]?.spotlightItem);
  const [activeSecondaryCta, setSecondaryCta] = useState(props.items?.[0]?.fieldTermSecondCta)
  const [activeQuickLinks, setQuickLinks] = useState(props?.items?.[0]?.fieldQuickCta)
  const handleChange = (index) => {
    setActiveIndex(index)
    setSpotlight(props?.items?.[index]?.spotlightItem)
    setSecondaryCta(props?.items?.[index]?.fieldTermSecondCta)
    setQuickLinks(props?.items?.[index]?.fieldQuickCta)
  }
  const resetState = (index) => {
    setActiveIndex(0)
    setSpotlight(props.items?.[0]?.spotlightItem)
    setSecondaryCta(props.items?.[0]?.fieldTermSecondCta)
    setQuickLinks(props?.items?.[0]?.fieldQuickCta)

  }
  const handleSetActiveClass = (name) => {
    props.setActiveClass({ [name]: '' })
    props.setActiveClassDropdown({ [name]: 'menu-is-inactive' })
  }
  const NavLinks = ({quickLink}) => {
    const getGraphic = getImageObject(quickLink?.fieldImage, quickLink?.relationships?.fieldImage)
    return(
      <div className="nav-multiple-links tw-flex tw-gap-[5px] tw-text-accruent_sapphire tw-font-bold tw-items-center">
        {quickLink?.relationships?.fieldImage && <Picture className=" tw-max-w-[13px]" image={getGraphic}/>}
        {quickLink?.fieldCtaUrl?.url && <LocalizedLink className=" tw-text-accruent_sapphire tw-font-bold tw-whitespace-nowrap hover:tw-underline" to={quickLink?.fieldCtaUrl?.url} handleClick={() => { handleSetActiveClass(props.parent) }}>{quickLink?.fieldCtaUrl?.title}</LocalizedLink>}
      </div>
    )
  }
  return (
    <div className="navbar-with-graphics tw-w-full" onMouseLeave={() => resetState()}>
      <div className="columns">
        <div className="column is-4">
          {props?.items?.map((item, index) => {
            return(
              <>
                <NavItems active={activeIndex == index ? true: false} index={index} item={item} handleChange={handleChange} name ={props.parent}
                activeIndex={activeIndex} activeSecondaryCta={activeSecondaryCta} setActiveClass={props.setActiveClass} setActiveClassDropdown={props.setActiveClassDropdown}/>
                <hr className=" tw-bg-accruent_gray tw-block tw-h-[2px] tw-m-0" />
              </>
            )
          })}
          <div className="all-products tw-pl-[37px] tw-pr-[50px] tw-pt-[23px] tw-pb-[21px] tw-bg-accruent_lightgray">
            <LocalizedLink className=" hover:tw-text-accruent_sapphire hover:tw-underline" to={props?.parentLink} handleClick={() => { handleSetActiveClass(props.parent) }}>
              <p className=" tw-text-accruent_sapphire text-[14px] tw-font-bold tw-font-Poppins tw-mb-0">View All {props?.parent} {`>`}</p>
            </LocalizedLink>
          </div>
        </div>
        <div className="column is-8 is-relative">
          <div className="nav-content tw-min-h-[322px] tw-mr-5 tw-pt-[25px]">
            <div className="tw-text-[24px] tw-text-accruent-heading-color tw-font-bold ">{activeSpolitlight?.title}</div>
            <div className="image-with-description tw-flex tw-gap-[20px] tw-px-6 tw-py-10 tw-items-center">
              {activeSpolitlight?.image?.gatsbyImage && <div className="image-width tw-w-[30%]">
                <Picture className=" tw-min-h-[85px] tw-object-contain" image={activeSpolitlight?.image}/>
              </div>}
              <div className="desc-width tw-w-[70%]">
                <p className=" tw-text-heading_color tw-font-medium tw-text-[16px] tw-font-Roboto tw-tracking-[0.03px]">{activeSpolitlight?.description}</p>
              </div>
              
            </div>
            <div className="cta-links tw-flex tw-justify-center tw-items-center tw-gap-[20px] tw-left-0 tw-right-0 tw-bottom-[60px]">
              {activeSpolitlight?.ctaUrl && 
              <div className="tw-flex watch-demo-link tw-bg-blue_tag tw-rounded-[8px] tw-text-accruent_white tw-text-[12px] tw-font-bold tw-font-Poppins  hover:tw-text-accruent_white">
                <span className=""><svg class="svg-inline--fa fa-calendar-days tw-text-accruent_white tw-text-[30px] tw-h-[12px] tw-inline-block  tw-pl-[24px] tw-pr-[5px] tw-mt-[15px]" data-prefix="fal" data-icon="calendar-days" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M128 0c17.7 0 32 14.3 32 32V64H288V32c0-17.7 14.3-32 32-32s32 14.3 32 32V64h48c26.5 0 48 21.5 48 48v48H0V112C0 85.5 21.5 64 48 64H96V32c0-17.7 14.3-32 32-32zM0 192H448V464c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V192zm64 80v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm128 0v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H208c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V272c0-8.8-7.2-16-16-16H336zM64 400v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zm144-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H208zm112 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V400c0-8.8-7.2-16-16-16H336c-8.8 0-16 7.2-16 16z"></path></svg>
                </span>
                <LocalizedLink className="tw-text-accruent_white tw-pl-1  tw-pr-6 tw-py-[13px] tw-text-[12px] tw-font-bold tw-font-Poppins  hover:tw-text-accruent_white" to={activeSpolitlight?.ctaUrl} handleClick={() => { handleSetActiveClass(props.parent) }}>
                {activeSpolitlight?.ctaText}
                </LocalizedLink>
              </div>}
              {activeSecondaryCta?.url && <div><LocalizedLink className="product-detail-link tw-text-accruent_sapphire tw-text-[12px] tw-tracking-[0px] tw-font-bold tw-px-6 tw-py-2.5 tw-rounded-[8px] tw-border-[3px] tw-border-solid tw-border-accruent_sapphire tw-font-Poppins hover:tw-bg-accruent_sapphire hover:tw-text-accruent_white" to={activeSecondaryCta?.url} handleClick={() => { handleSetActiveClass(props.parent) }}>
                {activeSecondaryCta?.title}
              </LocalizedLink></div>}
            </div>
            {/* <hr /> */}
            {/* {!!activeQuickLinks?.length && activeQuickLinks?.[0]?.fieldCtaUrl && <div className="hr-with-footer-links tw-absolute tw-bottom-[30px] tw-left-[10px] tw-right-[35px]">
              <hr className="tw-bg-accruent_gray tw-block tw-h-[2px] tw-m-0 tw-mr-[28px]" />
              <div className="nav-links tw-flex tw-pt-[23px] tw-gap-[25px]">
                {!!activeQuickLinks?.length && activeQuickLinks?.map((quickLink, index) => {
                  return <NavLinks quickLink={quickLink}/>
                })}
              </div>
            </div>} */}
          </div>
        </div>
      </div>

    </div>
  )
}
export default NavbarWithGraphics;