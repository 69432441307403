import { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
const site_vars = require("src/get_site_data");
const target_site_tid = String(site_vars.drupal_site_id);
import { isEmpty } from "src/utils";
const useResourceFilterPageContext = ({ locale }) => {

  const data = useStaticQuery(graphql`
    {
      product:  allTaxonomyTermAccruentProduct(sort: {fields: field_page_title, order: ASC}) {
        nodes {
          entityId: id
          entityLabel: name
          fieldPageTitle: field_page_title
          fieldFilterLabel: field_filter_label
          fieldTermTargetSite: field_term_target_site {
            targetId: drupal_internal__target_id
          }
          fieldRemoveFromFilter: field_remove_from_filter
          langcode
        }
      }
      solution:  allTaxonomyTermSolutions(sort: {fields: field_page_title, order: ASC}) {
        nodes {
          entityId: id
          entityLabel: name
          fieldPageTitle: field_page_title
          fieldFilterLabel: field_filter_label
          fieldTermTargetSite: field_term_target_site {
            targetId: drupal_internal__target_id
          }
          fieldRemoveFromFilter: field_remove_from_filter
          langcode
        }
      }
    
      industry:   allTaxonomyTermIndustry(sort: {fields: field_page_title, order: ASC}) {
        nodes {
          entityId: id
          entityLabel: name
          fieldPageTitle: field_page_title
          fieldFilterLabel: field_filter_label
          fieldTermTargetSite: field_term_target_site {
            targetId: drupal_internal__target_id
          }
          fieldRemoveFromFilter: field_remove_from_filter
          langcode
        }
      }
    }
  `);
  // const uniqueTypesLocale = data.type.group.map(({ nodes }) => {
  //   return nodes.filter(node => node.context?.entityLanguage?.id == locale).filter((i, k) => k == 0)
  // });

  // const uniqueTypes = uniqueTypesLocale?.map((i) => {
  //   return {
  //     value: i[0]?.context.contentTypeId[0].id,
  //     label: i[0]?.context.contentTypeId[0].label,
  //   }
  // }).filter(i => (typeof i.value !== "undefined"))

  const uniqueIndustries = data.industry?.nodes
    ?.filter(
      (industry) =>
        industry.fieldTermTargetSite?.targetId == target_site_tid &&
        !industry.fieldRemoveFromFilter &&
        !isEmpty(industry) &&
        !isEmpty(industry.fieldFilterLabel) &&
        !isEmpty(industry.fieldPageTitle) &&
        locale === industry?.langcode
    )
    ?.map((industry) => {
      return {
        value: industry.fieldFilterLabel,
        label: industry.fieldPageTitle,
      };
    })
    ?.sort((a,b)=>{
      return  a?.label?.toLowerCase().localeCompare( b?.label?.toLowerCase());
    });
  const uniqueSolutions = data.solution?.nodes
    ?.filter(
      (solution) =>
        solution.fieldTermTargetSite?.targetId == target_site_tid &&
        !solution.fieldRemoveFromFilter &&
        !isEmpty(solution) &&
        !isEmpty(solution.fieldFilterLabel) &&
        !isEmpty(solution.fieldPageTitle) &&
        locale === solution?.langcode
    )
    ?.map((solution) => {
      return {
        value: solution.fieldFilterLabel,
        label: solution.fieldPageTitle,
      };
    })
    ?.sort((a,b)=>{
      return  a?.label?.toLowerCase().localeCompare( b?.label?.toLowerCase());
    });
  const uniqueProducts = data.product?.nodes
    ?.filter(
      (product) =>
        product.fieldTermTargetSite?.targetId == target_site_tid &&
        !product.fieldRemoveFromFilter &&
        !isEmpty(product) &&
        !isEmpty(product.fieldFilterLabel) &&
        !isEmpty(product.fieldPageTitle) &&
        locale === product?.langcode
    )
    ?.map((product) => {
      return {
        value: product.fieldFilterLabel,
        label: product.fieldPageTitle,
      };
    })
    ?.sort((a,b)=>{
      return  a?.label?.toLowerCase().localeCompare( b?.label?.toLowerCase());
    });

  const initialFilterState = {
    content_type: "",
    solution: "",
    product: "",
    industry: "",
  };

  const [filters, setFilter] = useState(initialFilterState);

  const [allQueryParams, setAllQueryParams] = useState({});


  const resetFilter = () => {
    setFilter(initialFilterState);
  };

  return {
    uniqueTypes:[],
    uniqueIndustries,
    uniqueSolutions,
    uniqueProducts,
    filters,
    setFilter,
    allQueryParams,
    setAllQueryParams,
    resetFilter
  };

};

export default useResourceFilterPageContext;
