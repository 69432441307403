import React, { useState } from "react";
/**
 * Pages that have sortable "content sections" can be configured to get anchor links.
 * This component renders the "Jump to section" at the top of those pages.
 */
export const JumpLinks = ({ title, anchors }) => {
  const [isActive, setActive] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  return (
    <>
      <div className="mb-2">
        <div className="is-hidden-touch jump-links">
          {title && (
            <div className="columns jump-links-title p-0 m-0">
              <div className="column">
                <p className="title is-6">{title}</p>
              </div>
            </div>
          )}
          <div className="columns is-multiline px-2">
            {anchors.map(({ title, id }) => (
              <div className="column is-3 is-flex" key={id}>
                <div className="jump-link-container px-2 pb-1">
                  <a className="is-size-7" href={`#${id}`}>
                    {title}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="columns is-hidden-desktop mx-1">
          <div className="column">
            <div
              className={`dropdown jump-links-dropdown tw-w-full ${
                isActive ? `is-active` : ""
              }`}
              onClick={toggleClass}
            >
              <div className="dropdown-trigger">
                <button
                  className="button"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                >
                  <span>{title || "Jump to Section"}</span>
                  <span className="icon is-small">
                    <i className="fas fa-angle-down" aria-hidden="true"></i>
                  </span>
                </button>
              </div>
              <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <div className="dropdown-content container">
                  {anchors.map(({ title, id }) => (
                    <div className="dropdown-item is-size-7" key={id}>
                      <a href={`#${id}`}>{title}</a>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
