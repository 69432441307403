import React, { useState } from "react";
import { get } from "src/utils";
import useMarketoForm from "src/components/helpers/useMarketoForm";
import { AccruentImg ,ShareFollowBox} from "src/components/commonComponents";
// import AccruentImg from "src/components/AccruentImg";
import getAuthorName from "../../../../helpers/getAuthorName";
import getResourceTagsList from "../../../../helpers/getResourceTagsList";
// import Hero from "src/components/hero/ResourcesHero";
import { ResourcesHero as Hero} from "src/components/templateComponents";
import cn from "classnames";
import extractMarketoValues from "../../helpers/extractMarketoIdAndSerial";
import loadable from '@loadable/component';
import useAddParamInHtmlLink from "src/components/helpers/useAddParamInHtmlLink";
const StickyBox = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.StickyBox
});
const Form = loadable(() => import('src/components/resources/ResourceForm'))
const getImageObject = require("src/../helpers/getImageObject")

export const SingleEbookPost = ({ data }) => {
  const { locale } = data;
  const [isFormLoaded, setFormLoaded] = useState(false)
  const formEntity = data?.relationships?.fieldMarketoFormNode

  const formCallback = () => {
    setFormLoaded(true)
  }
  const { id, serial, gaLabel } = extractMarketoValues(
    formEntity?.fieldEmbedCode
  );
  const fieldFormHeader = formEntity?.fieldFormHeader
  const followUpUrl = data?.relationships?.fieldThankyouPageReference?.fieldUrl
  const { formTag } = useMarketoForm(serial, id, formCallback, "", gaLabel, true, followUpUrl);

  const authorName = getAuthorName(data?.relationships);
  const resourceTags = getResourceTagsList([data.industryIds, data.solutionIds, data.productIds])
  let heroImage = get(data, "heroImage");
  let coverImage = get(data, "relationships.fieldCoverImage");
  coverImage = getImageObject(coverImage, {}, "fieldCoverImage");
  useAddParamInHtmlLink()
  return (
    <section className="resources-page full-header-bg content-visibility-auto-mobile">
      <div className="container tw-nt-[0.75rem] lg:tw-mt-0">
        <section className="medium-wrapper tw-p-[0_15px] md:tw-pl-[0px] tw-relative tw-mt-0">
          <div className="columns tw-flex tw-flex-col-reverse lg:tw-flex-row">
            <div className="column  tw-mt-6 tw-p-0 lg:tw-p-[12px] is-12-mobile">
              <div>
                <div className="columns">
                  <div className="column">
                    {!!authorName && (
                      <h3>
                        By {authorName}
                      </h3>
                    )}
                    {data.body && (
                      <div
                        className="new-single-resource-content-body dangerously-html-handle"
                        dangerouslySetInnerHTML={{ __html: data.body?.value }}
                      ></div>
                    )}
                    {data.fieldKeyFeaturesTitle && (
                      <div className="new-single-resource-content-body ebook-bullets-title tw-my-[36px] tw-text-[18px]">
                        {data.fieldKeyFeaturesTitle}
                      </div>
                    )}
                    {data.fieldKeyFeaturesBullets && (
                      <div className="new-single-resource-content-body ebook-bullets">
                        <ul>
                          {data.fieldKeyFeaturesBullets.map((key) => (
                            <li key={key}>{key}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
                <div className="columns">
                  <div className="column">
                    <div className="content">
                      {coverImage?.gatsbyImage && (
                        <AccruentImg
                          file={coverImage}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="tw-mt-[52px]">
                <ShareFollowBox />
              </div>
            </div>
            <div className="tw-column is-12-mobile lg:tw-max-w-[475px] lg:tw-mb-[-424px] tw-relative tw-right-0 lg:tw-top-[-424px]">
              <div className="lg:tw-mt-[135px] tw-w-full tw-bg-white tw-relative">
                <StickyBox
                  scrollContainerClass={"resource-form"}
                >
                  <div className={cn({ "right-box": isFormLoaded })}>
                    <div className="border-box-top" />
                    {formTag &&
                      <div className="tw-min-h-[100px]">
                        <Form
                          formTag={formTag}
                          title={fieldFormHeader}
                          isFormLoaded={isFormLoaded}
                        />
                      </div>
                    }
                    <div className="border-box-bottom" />
                  </div>
                </StickyBox>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  )
}

// export default SingleEbookPost;
