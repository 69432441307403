import React, { useEffect, useState } from "react";
import {Loader} from "./loader";
export const StoryLane = ({storyLane}) => {
  const [load, setLoad] = useState(false);
  useEffect(() => {
    try{
      let script=null;
      window.setTimeout(function () { 
        script = document.createElement('script');
        script.async = true;
        script.src = 'https://js.storylane.io/js/v1/storylane.js';
        document.querySelector('head').appendChild(script);
        setLoad(true)
      }, 3000);
      return () => {
        if(script){
          script.parentNode.removeChild( script );
        }
      }
    }catch(err){
      console.log("Caught error in storylane", err)
    }
  },[])
  return (
    <div className="iframe-pos">
      {load ? <div class="sl-embed" >
        <iframe class="sl-demo" src={storyLane?.storyLaneUrl} name="sl-embed" allow="fullscreen;"></iframe>
      </div>: <Loader />}
    </div>
  )
}
