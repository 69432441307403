/**
 * * React Component *
 * Basically, depending on the condition this function will render the wrapper function or the children itself
 * As the wrapper function receives children it will render whatever is inside this component in the place you
 * put the `children` prop
 * @param {Boolean} condition Condition to wrap or not the children
 * @param {Function} wrapper This needs to be a function that receives `children` as a parameter and render that children
 *    wrapped in whatever element
 * @param {JSX Element} children
 * For more explanation see https://blog.hackages.io/conditionally-wrap-an-element-in-react-a8b9a47fab2
 */
const ConditionalWrapper = ({ condition, wrapper, children }) =>
  condition ? wrapper(children) : children;

export default ConditionalWrapper;
