import React, { useEffect } from "react";
import extractROICalculatorAttributes from 'src/components/helpers/extractROICalculatorAttributes'
import getTitleId from "../helpers/getTitleId";

const ROICalculator = (props) => {
    const { data } = props;
    console.log(data)

    // const [scriptFlag, setScriptFlag] = useState(false)

    const scriptTags = extractROICalculatorAttributes(data.fieldEmbedCode)

    useEffect(() => {
         let script=null;
        if (document) {
            const headScripts = Array.from(document.querySelectorAll("script"));
            const hasRoiCalculatorScript = headScripts.some((scriptItem) =>{
              const isInclude= scriptItem.src.includes(scriptTags.src);
              if(isInclude){
               return scriptItem.getAttribute('calc') === scriptTags.calc
              }
              return false;
            }
            );
            if (!hasRoiCalculatorScript) {
                script = document.createElement("script");
                script.src = scriptTags.src
                script.async = true;
                script.setAttribute("width",scriptTags.width);
                script.setAttribute("height",scriptTags.width);
                script.setAttribute("calc",scriptTags.calc);
                script.setAttribute("currency_code",scriptTags.currency_code);
                script.setAttribute("lang",scriptTags.lang);
                script.setAttribute("company",scriptTags.company);
                // script.onload = () => {
                //     setScriptFlag(true);
                // };
                document.body.appendChild(script);
            }
            // else {
            //     setScriptFlag(true);
            // }
        }
        return () => {
            if (document && script) {
                try{
                    calc=undefined;
                    dl_width=undefined;
                    dl_height=undefined;
                    company=undefined;
                    currency_code=undefined;
                    language_code =undefined;
                }catch(e){}
              document.body.removeChild(script);
            }
          };
    }, [])

    return (
        <div
            className="roi-calculator-section tw-min-h-[950px]"
            component="ROICalculator"
            id={getTitleId(data.title)}
        >
            <div className={`header ${!data?.fieldShowTitleFullWidth ? '': '!tw-w-full'}`}>
                {!data.fieldHideTitle && (
                    <>{!data?.fieldShowTitleFullWidth ? <h2 className="title title-3 tw-text-left !tw-text-accruent_heading_color">{data.title}</h2>
                        :
                        <h3 className="title title-3 tw-text-left !tw-text-accruent_heading_color !tw-w-full">{data.title}</h3>
                }</>
                    
                )}
            </div>
            {!!data.fieldRoiCalculatorId && (
                <div className="roi-calculator-container tw-h-[900px]" id={data.fieldRoiCalculatorId}>
                    {/* {scriptFlag && data.fieldEmbedCode && (
                        <div
                            dangerouslySetInnerHTML={{
                                __html: data.fieldEmbedCode,
                            }}
                        />
                    )} */}
                </div>
            )}
        </div>
    );
};

export default ROICalculator;
