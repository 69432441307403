
import React from "react";

/**
 *
 * @prop {isOpen} boolean - Toggle if Modal is seen
 * @prop {onClose} function - To close the modal when click the x button
 */
export const Modal = ({ children, isOpen, onClose, className }) => {
  return (
    <div
      className={`modal tw-z-[120] tw-items-center tw-flex-col tw-justify-center tw-fixed tw-inset-0 tw-absolute is-clipped !tw-overflow-hidden ${isOpen ? "is-active tw-flex" : ""} ${className}`}
    >
      <div
        className="modal-background tw-bg-modal_bg_white tw-absolute tw-inset-0"
        onClick={onClose}
        onKeyDown={onClose}
        role="button"
        tabIndex="-1"
      ></div>
      <div className="modal-content tw-max-h-[calc(100vh_-_160px)] tw-w-full tw-mx-5 tw-my-0 md:tw-max-h-[calc(100vh_-_40px)] md:tw-w-[640px] md:tw-mx-auto md:tw-my-0 tw-relative tw-overflow-auto">
        <>{children}</>
        <button
          className="modal-close is-large"
          onClick={onClose}
          onKeyDown={onClose}
          aria-label="close"
        ></button>
      </div>
    </div>
  );
};

