/**
 * Very lightweight checker if string is URL. If a more precise checker is needed, think about adding library
 *
 * www.google.com -> true
 * http://www.example.com -> true
 * https://www.example.com -> true
 * https://nginx-ipsum-master.us.amazee.io/sites/default/files/2020-07/austin-texas-usa-sunrise-skyline-cityscape.jpg -> true
 * events/placeholder-image.png -> false
 * @param {String} str
 */
const isURL = (str) => {
  const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  if (typeof str !== "string") return false;
  return regexp.test(str);
}

export default isURL;
