import React from "react";
import cn from "classnames";
import { AccruentImg } from "./accruentImg";
import { BgPatternImage } from "./bgPatternImage";
import { BenefitsWithTabs } from "./benefitsWithTabs";
import { LocalizedLink } from "./localizedLink";
import ConditionalWrapper from "src/components/helpers/ConditionalWrapper";
import chunkArray from "src/components/helpers/chunkArray";
import BicolorCards from "src/components/solutions/BicolorCards";
import Benefits from "src/components/solutions/Benefits";
import { StaticImage } from "gatsby-plugin-image";
import getTitleId from "../helpers/getTitleId";
import CheckForCardDescription from "../helpers/CheckForCardDescription";
import useAddParamInHtmlLink from "../helpers/useAddParamInHtmlLink";
import "src/scss/components/_benefitComponents.scss";
import getPublishedData from "../../../helpers/getPublishedData";

const defaultImage = "../../../static/teaser_default.jpg";

const getImageObject = require("src/../helpers/getImageObject");

/**
 * * The condition boolean to be passed to ConditionalWrapper needs to be called `hasWrapper`
 * ? In the future it might be that the conditional wrapper needs to be passed from props
 * ? but for now both industry and product uses the same wrapper
 */

const BenefitCtaLink = ({ benefit }) => {
  return (
    <>
      {benefit.fieldCtaUrl && (
        <div className="mt-4">
          <div className="columns is-centered">
            <div className="column is-12">
              <div className="!tw-text-center">
                <LocalizedLink
                  to={benefit.fieldCtaUrl.url}
                  className="is-primary"
                  isButton
                >
                  {benefit.fieldCtaUrl.title}
                </LocalizedLink>
                {benefit.fieldSecondaryCta && (
                  <LocalizedLink
                    to={benefit.fieldSecondaryCta.url}
                    className="button tw-benefit-btn is-kingfisher ml-2"
                  >
                    {benefit.fieldSecondaryCta.title}
                  </LocalizedLink>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export const BenefitsWrapper = ({
  benefits,
  pageType,
  minHeight,
  className,
  entityId,
  locale = "en",
  path,
  isSolutionsSidebar = true
}) => {
  useAddParamInHtmlLink()
  return benefits.map((benefit) => {
    let descriptionCardCheck = false;
    let BenefitSectionCopy = {}
    let benefitItems = []
    let currentBenefitItems = []
    if (benefit?.relationships?.fieldBenefitItem) {
      BenefitSectionCopy = { ...benefit }
      BenefitSectionCopy.relationships.fieldBenefitItem = getPublishedData(BenefitSectionCopy?.relationships?.fieldBenefitItem)?.map(item => ({ entity: item }))

      benefitItems = BenefitSectionCopy?.relationships?.fieldBenefitItem.map(e => e?.entity)
      currentBenefitItems = benefitItems
    } else {
      benefitItems = benefit?.relationships?.queryFieldBenefitItem
      currentBenefitItems = getPublishedData(benefitItems)
    }

    if (benefit?.fieldBiStyle === "align_centre_with_graphic_top") {
      descriptionCardCheck = CheckForCardDescription(currentBenefitItems)
    }
    if (benefit.fieldLayout === "5050") {
      const holder = currentBenefitItems.map((entity) => {
        const title = entity.title;
        const description = entity.body?.value;
        const cta = entity.fieldCtaUrl;
        const secondaryCta = entity.fieldSecondaryCta;
        return { title, description, cta, secondaryCta };
      });

      const bicolorData = chunkArray(holder, 2);
      return (
        <div
          key={benefit?.nid}
          component="BenefitsCard"
          nid={benefit.nid}
          className={className}
          id={getTitleId(benefit.title)}
        >
          {!benefit.fieldHideTitle && (
            <h2 className={"title title-3 !tw-text-center benefit-5050-title " + benefit.fieldBenefitsSectionStyling}>
              {benefit.title}
            </h2>
          )}
          {bicolorData.map((cardArray, index) => (
            <BicolorCards key={index} cards={cardArray} />
          ))}
          {!isHalfLogoStyle && <BenefitCtaLink benefit={benefit} />}
        </div>
      );
    } else if (benefit?.fieldLayout === "offset") {
      const title = benefit.title;
      const description = benefit.body ? (
        <div
          nid={benefit.nid}
          component="BenefitsCard"
          className="dangerously-html-handle"
          dangerouslySetInnerHTML={{ __html: benefit.body?.value }}
        />
      ) : null;
      const content = currentBenefitItems.map((offset) => {
        return {
          title: offset.title,
          description: offset.body ? (
            <div dangerouslySetInnerHTML={{ __html: offset.body.value }} className="dangerously-html-handle" />
          ) : null,
          cta: offset.fieldCtaUrl,
          secondaryCta: offset.fieldSecondaryCta,
        };
      });

      return (
        <Benefits
          type="g"
          contentG={content}
          title={title}
          description={description}
          minHeight={minHeight < 300 ? 300 : minHeight}
          className={className}
          key={benefit.nid}
          nid={benefit.nid}
          headerClass={benefit.fieldBenefitsSectionStyling}
        />
      );
    } else if (benefit?.fieldBiStyle && benefit?.fieldBiStyle === 'align_centre_with_graphic_top') {
      return (
        <div>
          {!descriptionCardCheck ? <div className="logo-with-text">
            <h3 className={"title title-3 benefit-title " + benefit.fieldBenefitsSectionStyling}>
              {benefit?.title}
            </h3>
            <div className={`columns is-multiline is-mobile ${currentBenefitItems.length > 4 ? 'is-centered' : ''}`}>
              {currentBenefitItems.map((entity, index) => {
                const fieldGraphic = getImageObject(entity?.relationships?.fieldGraphic, entity?.fieldGraphic)
                const isCta = !!entity?.fieldCtaUrl?.url
                return (
                  <div className={`column is-6-mobile is-4-tablet is-6-desktop is-3-widescreen has-position-relative ${isCta ? "has-related-industy-cta" : ""}`} key={index}>
                    <div className="related-industy-section tw-relative">
                      <div className="logo">
                        <AccruentImg
                          file={fieldGraphic}
                          width={170}
                        />
                      </div>
                      <p className="related-industy-title tw-text-lgFont -md:tw-text-mobileLgFont tw-font-Poppins">{entity?.title}</p>
                     {entity?.fieldCtaUrl?.url && <div>
                        <LocalizedLink to={entity?.fieldCtaUrl?.url} className='tw-absolute tw-top-0 tw-left-0 tw-right-0 tw-bottom-0'/>
                      </div>}
                    </div>
                  </div>
                )
              })}
            </div>
          </div> :
            <div>
              <h3 className={"title title-3 benefit-title " + benefit.fieldBenefitsSectionStyling}>
                {benefit?.title}
              </h3 >
              <div className={`columns is-multiline is-mobile align_centre_with_graphic_top ${!isSolutionsSidebar ? "set-width-in-3-columns": ""} ${currentBenefitItems.length > 4 ? 'is-centered' : ''}`}>

                {currentBenefitItems.map((entity, index) => {
                  const fieldGraphic = getImageObject(entity?.relationships?.fieldGraphic, entity?.fieldGraphic)
                  return (
                    <div className={`column is-6-desktop is-12-mobile description-card dangerously-html-handle`} key={index}>
                      <div className="card-content-with-logo">
                        <div className="logo">
                          <AccruentImg
                            file={fieldGraphic}
                            width={170}
                          />
                        </div>
                        <div>
                          <p className="related-industy-title tw-text-lgFont -md:tw-text-mobileLgFont tw-font-Poppins" style={{ marginBottom: '0px ' }}>{entity?.title}</p>
                        </div>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: entity?.body?.value }}
                      ></div>
                      {entity?.fieldCtaUrl?.url && <div className="cta-card">
                        <LocalizedLink className='industries-cta-card' to={entity?.fieldCtaUrl?.url}>{entity?.fieldCtaUrl?.title}
                        </LocalizedLink>
                      </div>}
                    </div>
                  )


                })}
              </div>
            </div>
          }
        </div>
      )
    }
    else if (benefit?.fieldBiStyle && benefit?.fieldBiStyle === 'align_centre_with_graphic_top_with_title_below') {
      return (
        <div className="align_centre_with_graphic_top_with_title_below">
          <div>
            {!benefit.fieldHideTitle && (
              <div className={`benefit-title ${benefit.fieldBenefitsSectionStyling}`}>
                {benefit?.title}
              </div>
            )}
            <div
              className={`benefit-description-container ${benefit.fieldBenefitsSectionStyling}`}
              dangerouslySetInnerHTML={{ __html: benefit?.body?.value }}
            ></div>
            <div className="columns is-multiline is-mobile mt-0 cards">
              {currentBenefitItems.map((entity, index) => {
                const fieldGraphic = getImageObject(entity?.relationships?.fieldGraphic, entity?.fieldGraphic)
                return (
                  <div className="column is-6-desktop is-12-mobile description-card dangerously-html-handle" key={index}>
                    <div className="card-content-with-logo">
                      <div className="logo">
                        <AccruentImg
                          file={fieldGraphic}
                          width={170}
                        />
                      </div>
                      <div className="card-title title-5">{entity?.title}</div>
                    </div>
                    <div
                      className="body-1 dangerously-html-handle"
                      dangerouslySetInnerHTML={{ __html: entity?.body?.value }}
                    ></div>
                    {entity.fieldCtaUrl && (
                      <div className="cta-buttons-wrapper" key={entity.fieldCtaUrl.title}>
                        <div className="columns is-centered">
                          <div className="column is-12 !tw-text-center cta-buttons">
                            {entity.fieldCtaUrl && (
                              <LocalizedLink
                                to={entity.fieldCtaUrl.url}
                                className="button tw-benefit-btn is-primary"
                              >
                                {entity.fieldCtaUrl.title}
                              </LocalizedLink>
                            )}
                            {entity.fieldSecondaryCta && (
                              <LocalizedLink
                                to={entity.fieldSecondaryCta.url}
                                className="button tw-benefit-btn is-secondary"
                              >
                                {entity.fieldSecondaryCta.title}
                              </LocalizedLink>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      )
    }
    else if (benefit?.fieldBiStyle && benefit?.fieldBiStyle === 'content_align_left_with_icon_item_listing_right') {

      return (
        <div className='columns content_align_left_with_icon_item_listing_right'>
          <div className={'column is-6-desktop is-12-mobile ' + benefit.fieldBenefitsSectionStyling}>
            <div className="pre-heading">{benefit?.fieldSubtitle}</div>
            <h3 className="title-3 mb-2">{benefit?.title}</h3>
            <div
              className="subtitle-2 mb-4 dangerously-html-handle"
              dangerouslySetInnerHTML={{ __html: benefit?.body?.value }}
            />
            {benefit?.fieldCtaUrl &&
              <LocalizedLink className='landing-btn-class' to={benefit?.fieldCtaUrl?.url}>
                <button>{benefit?.fieldCtaUrl?.title}</button>
              </LocalizedLink>}
          </div>
          <div className='column is-6-desktop is-12-mobile'>
            {
              currentBenefitItems.map((benefitItem, index) => {
                return (
                  <div className="icon-header">
                    <div>
                      <span className="fa-stack fa-2x about-us-icon">
                        <i
                          className={`${benefitItem?.fieldIcon?.style} fa-${benefitItem?.fieldIcon?.iconName} !tw-text-accruent_text_black fa-stack-1x`}
                        ></i>
                      </span>
                    </div>
                    <div className="description-content">
                      <h6 className="tw-text-lgFont">{benefitItem?.title}</h6>
                      <div
                        dangerouslySetInnerHTML={{ __html: benefitItem?.body?.value }}
                        className="dangerously-html-handle"
                      />
                    </div>
                  </div>
                )
              })
            }

          </div>
        </div>

      )
    }
    else if (benefit?.fieldBiStyle && benefit?.fieldBiStyle === 'card_with_bigger_icon_content_centered_align') {

      return (
        <div className='columns card_with_bigger_icon_content_centered_align card-half-lightgray-bg'>
          {!benefit.fieldHideTitle && (
            <div className={'column is-12-desktop is-12-mobile ' + benefit.fieldBenefitsSectionStyling}>
              <div className="pre-heading">{benefit?.fieldSubtitle}</div>
              <h3 className="title-3 mb-2">{benefit?.title}</h3>
              <div
                className="subtitle-2 mb-4 dangerously-html-handle"
                dangerouslySetInnerHTML={{ __html: benefit?.body?.value }}
              />
              {benefit?.fieldCtaUrl &&
                <LocalizedLink className='landing-btn-class' to={benefit?.fieldCtaUrl?.url}>
                  <button>{benefit?.fieldCtaUrl?.title}</button>
                </LocalizedLink>}
            </div>
          )}
          <div className='column is-12-desktop is-12-mobile'>
            <div className='columns benefit-items tw-benefit-items tw-pt-[10px] md:tw-pt-0'>
              {currentBenefitItems.map((benefitItem, index) => {
                return (
                  <div className="column is-3-desktop is-9-mobile card tw-card">
                    <div className="icon-header tw-icon-header">
                      <span className="fa-stack fa-3x about-us-icon">
                        <i
                          className={`${benefitItem?.fieldIcon?.style} fa-${benefitItem?.fieldIcon?.iconName} !tw-text-accruent_text_black fa-stack-1x`}
                        ></i>
                      </span>
                    </div>
                    <div className="description-content">
                      <div>
                        <h6 className="title-5">{benefitItem?.title}</h6>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: benefitItem?.body?.value }}
                        className="dangerously-html-handle div-body-content"
                      />
                      {benefitItem?.fieldCtaUrl?.title && (
                        <>
                          <div className="div-cta-fill"></div>
                          <div className="div-cta">
                            <LocalizedLink to={benefitItem?.fieldCtaUrl?.url}>
                              {benefitItem?.fieldCtaUrl?.title}
                            </LocalizedLink>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

      )
    }

    else if (benefit?.fieldBiStyle && benefit?.fieldBiStyle === 'card_with_bigger_icon_content_left_align') {

      return (
        <div className='columns card_with_bigger_icon_content_left_align card-half-lightgray-bg card-half-lightgray-bg-top card-half-lightgray-bg-top-mobile'>
          {!benefit.fieldHideTitle && (
            <div className={'column is-12-desktop is-12-mobile ' + benefit.fieldBenefitsSectionStyling}>
              <div className="pre-heading">{benefit?.fieldSubtitle}</div>
              <h3 className="title-3 mb-2">{benefit?.title}</h3>
              <div
                className="subtitle-2 mb-4 dangerously-html-handle"
                dangerouslySetInnerHTML={{ __html: benefit?.body?.value }}
              />
              {benefit?.fieldCtaUrl &&
                <LocalizedLink className='landing-btn-class' to={benefit?.fieldCtaUrl?.url}>
                  <button>{benefit?.fieldCtaUrl?.title}</button>
                </LocalizedLink>}
            </div>
          )}
          <div className='column is-12-desktop is-12-mobile'>
            <div className='columns benefit-items'>
              {currentBenefitItems.map((benefitItem, index) => {
                return (
                  <div className="column is-4-desktop is-12-mobile card">
                    <div className="icon-header">
                      <span className="fa-stack fa-3x about-us-icon">
                        <i
                          className={`${benefitItem?.fieldIcon?.style} fa-${benefitItem?.fieldIcon?.iconName} !tw-text-accruent_text_black fa-stack-1x`}
                        ></i>
                      </span>
                    </div>
                    <div className="description-content">
                      <div>
                        <h6 className="title-5">{benefitItem?.title}</h6>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: benefitItem?.body?.value }}
                        className="dangerously-html-handle div-body-content"
                      />
                      {benefitItem?.fieldCtaUrl?.title && (
                        <div className="div-cta">
                          <LocalizedLink to={benefitItem?.fieldCtaUrl?.url}>
                            {benefitItem?.fieldCtaUrl?.title}
                          </LocalizedLink>
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

      )
    }
    else if (benefit?.fieldBiStyle && benefit?.fieldBiStyle === "tab_with_iframe") {
      return (
        <BenefitsWithTabs benefit={benefit} />
      )
    }
    else {
      const isAllBlackIcons = currentBenefitItems?.some(
        (item) => item && item.fieldIcon && item.fieldIcon.iconName !== "check-circle"
      );
      const isHalfLogoStyle = (benefit?.fieldBiStyle === 'half_content_half_logos')
      const overviewPath = ['/solution-overview', '/solutions', '/program-solutions', '/products', '/industries', '/uk/industries', '/industries-overview'].includes(path)
      const headerStyle = ["align_left_with_graphic_bottom_right", "card"].includes(benefit.fieldBiStyle)
      const isPattrnBg = ["align_left_no_icon_pattern_background", "centered_with_icon_background"].includes(benefit.fieldBiStyle)
      return (
        <div
          key={benefit?.nid}
          component="BenefitsCard"
          nid={benefit?.nid}
          className={cn(
            className,
            { [benefit?.fieldBiStyle]: !!benefit?.fieldBiStyle, "tw-full-width-bg-wrapper": isPattrnBg },
          )}
        >
          {isPattrnBg && <div className="tw-bg-image-container bg-image-container">
            <BgPatternImage className="tw-bg-image bg-image" >
            </BgPatternImage>
          </div>}
          <ConditionalWrapper
            condition={benefit?.hasWrapper} // * note how the boolean is inside each benefit item
            wrapper={(children) => (
              <div className="tw-columns is-multiline is-centered">
                <div className="tw-column is-8 benefit-card-conditional-wrraper tw-benefit-card-conditional-wrraper">{children}</div>
              </div>
            )}
          >
            <div className={"tw-header-benefits tw-header " + benefit?.fieldBenefitsSectionStyling + (benefit?.fieldShowTitleFullWidth ? "!tw-w-full": "")}>
              {!!benefit?.fieldSubtitle && (
                <div className="eyebrow" >{benefit?.fieldSubtitle}</div>
              )}
              {!benefit?.fieldHideTitle && (
                <div>
                  {(overviewPath && headerStyle) ?
                    <h1 className="title benefit-title title-2 mb-2">
                      {benefit?.title}
                    </h1>
                    :
                    <>
                    {!benefit?.fieldShowTitleFullWidth ?  <h2 className={`tw-benefit-title tw-title title-3 tw-title-3`}>
                      {benefit?.title}
                    </h2> :  <h3 className={`tw-benefit-title tw-title title-3 tw-title-3 !tw-w-full`}>
                      {benefit?.title}
                    </h3>}
                    </>
                   
                  }
                </div>

              )}
              {benefit?.body && (
                <div className="tw-mb-12 mb-5 tw-benefit-description-container">
                  {/* only if `hasWrappper` is true has column and offset column */}
                  <ConditionalWrapper
                    condition={benefit?.hasWrapper}
                    wrapper={(children) => (
                      <div className="tw-columns">
                        <div className="tw-column md:tw-flex-none md:tw-w-5/6 tw-ml-0 tw-benefit-description tw-is-10 tw-is-offset-1-tablet">
                          {children}
                        </div>
                      </div>
                    )}
                  >
                    <div
                      className="dangerously-html-handle subtitle-2"
                      dangerouslySetInnerHTML={{
                        __html: benefit?.body?.value,
                      }}
                    />
                  </ConditionalWrapper>
                </div>
              )}
              {isHalfLogoStyle && <BenefitCtaLink benefit={benefit} />}

            </div>
            <div className={`tw-columns tw-flex-wrap ${benefit.isCentered || (benefit.fieldBiStyle == 'align_left_with_icon' & currentBenefitItems.length > 3) ? 'tw-justify-center' : ''} tw-break-words !tw-items-stretch tw-about-us-benefit-cards`}>
              {currentBenefitItems.map((entity) => {
                if (!entity) return null;
                return (
                  <div
                    // ? This bulma classes might in the future also be passed by props, but for now this works
                    className={`tw-column ${(benefit.hasWrapper || benefit.solutionWrapper)
                      ? "tw-is-6"
                      : "tw-is-6-tablet tw-is-4-widescreen"
                      }  benefit-card-container tw-benefit-card-container ${benefit.fieldBiStyle == 'align_left_with_icon' ? "!tw-mt-6" : ""}`}
                    key={entity.uuid}
                  >
                    <BenefitsCard
                      item={entity}
                      minHeight={minHeight < 350 ? 350 : minHeight}
                      pageType={pageType}
                      blackIcons={isAllBlackIcons}
                      style={benefit.fieldBiStyle}
                      entityId={entityId}
                      isCentered={benefit?.isCentered}
                      isWrapTitleBody={benefit?.isWrapTitleBody}
                    // className={className}
                    />
                  </div>
                );
              })}
            </div>
          </ConditionalWrapper>
          {benefit.fieldCtaUrl && (
            <div className="mt-4" key={benefit.fieldCtaUrl.title}>
              <div className="columns is-centered">
                <div className="column is-12">
                  <div className="!tw-text-center">
                    {benefit.fieldCtaUrl && (
                      <LocalizedLink
                        to={benefit.fieldCtaUrl.url}
                        className="button tw-benefit-btn is-primary"
                      >
                        {benefit.fieldCtaUrl.title}
                      </LocalizedLink>
                    )}
                    {benefit.fieldSecondaryCta && (
                      <LocalizedLink
                        to={benefit.fieldSecondaryCta.url}
                        className="button tw-benefit-btn is-kingfisgher ml-2"
                      >
                        {benefit.fieldSecondaryCta.title}
                      </LocalizedLink>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
  });
};

/**
 * @param {boolean} isWrapTitleBody - used to wrap title(without H tag) and body
 */

export const BenefitsCard = ({
  item,
  minHeight,
  pageType,
  className = "",
  blackIcons,
  style,
  entityId,
  isCentered,
  isWrapTitleBody
}) => {
  const setTitle = ['align_left_with_icon_left', 'align_left_no_icon_grey_background', 'align_left_with_graphic_bottom_right', 'align_left_no_icon_pattern_background', 'card'].includes(style) ? 'tw-text-lgFont -md:tw-text-mobileLgFont' :

    (style === 'align_right_with_icon_left_3_9') ? 'title-3' : (style === 'centered_with_bigger_icon') ? 'tw-text-lgFont -md:tw-text-mobileLgFont' : 'tw-text-xlFont -md:tw-text-mobileXlFont'

  //checking for EMS page. to be reworked to make more scalable.
  const isEMS = entityId === "48";
  const selectedIcon = item.fieldIcon?.iconName
    ? item.fieldIcon?.iconName
    : "check-circle";
  const selectedIconStyle = item.fieldIcon?.style
    ? item.fieldIcon?.style
    : "fa";
  const selectedColor = blackIcons ? "!tw-text-accruent_text_black" : "has-text-sapphire";
  // * The benefit item has three styles: `align_left_no_icon`, `align_left_with_icon` and `centered_with_icon`.
  // * `centered_with_icon` is set to default.
  const isDefault = style === `centered_with_icon` || (style === `centered_with_bigger_icon`) || (style === `centered_with_icon_on_top_title_below`) || !style;
  const isCard = style === `card`
  const buttonArrow = (isCentered && !isCard) ? '' : '>'
  const noIconStyles = ['align_left_with_graphic_bottom_right', 'align_left_no_icon', 'align_left_no_icon_grey_background', 'card', 'block_centered_items_left_item_content_left', 'align_left_no_icon_pattern_background', 'block_left_items_centered_item_content_left', 'half_content_half_logos']
  const fieldGraphic = getImageObject(item?.relationships?.fieldGraphic, item?.fieldGraphic)
  if (isEMS) {
    return (
      // This creates the new benefits box design found on the Transform The Way People And Spaces Work Together
      // section of the EMS page.
      <div className="box tw-box">
        <article className="media">
          <div className="media-left is-align-self-center">
            {style !== "align_left_no_icon" && (
              <div className={cn({ "!tw-text-center": isDefault })}>
                {selectedIcon === "check-circle" ? (
                  <span className="icon">
                    <i
                      className={`${selectedIconStyle} fa-${selectedIcon} ${selectedColor} fa-2x mt-2`}
                    ></i>
                  </span>
                ) : (
                  <span className="fa-stack fa-2x">
                    <i
                      className={`fal fa-circle fa-stack-2x ${selectedColor} `}
                    ></i>
                    <i
                      className={`${selectedIconStyle} fa-${selectedIcon} ${selectedColor} fa-stack-1x`}
                    ></i>
                  </span>
                )}
              </div>
            )}
          </div>
          <div className="media-content">
            <div className="content">
              <h3 className="title-4">{item.title}</h3>
              {item.body && (
                <div
                  className={`${style === "align_left_with_icon" ? " " : ""
                    }tw-benefits-card-body dangerously-html-handle`}
                  dangerouslySetInnerHTML={{ __html: item.body.value }}
                />
              )}
            </div>
          </div>
        </article>
      </div>
    );
  }
  if (style === 'align_left_with_icon_left') {
    return (
      <div
        className={cn(
          "box tw-box tw-benefits-card-wrapper tw-h-full py-5",
          className,
          { "tw-benefits-card-product": isDefault }
        )}
        key={item.title}
      >
        <div
          className={cn("tw-benefits-icon", `${isDefault ? "" : "!tw-text-center"}`,"tw-text-left")}
        >
          {selectedIcon === "check-circle" ? (
            <span className="icon">
              <i
                className={`${selectedIconStyle} fa-${selectedIcon} ${selectedColor} fa-2x mt-2`}
              ></i>
            </span>
          ) : (
            <span className="fa-stack fa-2x">
              <i
                className={`${selectedIconStyle} fa-${selectedIcon} ${selectedColor} fa-stack-1x`}
              ></i>
            </span>
          )}
        </div>

        <div>
          <h6 className={`tw-title ${setTitle} mt-3 `}>{item.title}</h6>
          {item.body && (
            <div className="mb-2">
              <div
                className={` tw-benefits-card-body dangerously-html-handle`}
                dangerouslySetInnerHTML={{ __html: item.body.value }}
              />
            </div>
          )}
          {item.fieldKeyFeaturesBullets &&
            item.fieldKeyFeaturesBullets[0] &&
            item.fieldKeyFeaturesBullets.map((bullet) => (
              <div key={bullet} className="columns is-gapless is-mobile">
                <div className="column is-1">
                  <i className="fal fa-check"></i>
                </div>
                <div className="column">{bullet}</div>
              </div>
            ))}
          <div>
            {item.fieldLinkToOriginalArticle &&
              item.fieldLinkToOriginalArticle[0] &&
              item.fieldLinkToOriginalArticle.map((link) => (
                <div className="mt-1 is-underlined">
                  <LocalizedLink to={link.url}>{link.title}</LocalizedLink>
                </div>
              ))}
            {item.fieldCtaUrl && (
              <div className="align-btn">
                <LocalizedLink
                  className="button tw-benefit-btn is-primary mb-1 mt-1"
                  to={item.fieldCtaUrl.url}
                >
                  {item.fieldCtaUrl.title}  {buttonArrow}
                </LocalizedLink>
              </div>
            )}
            {item.fieldSecondaryCta && (
              <div>
                <LocalizedLink
                  className="button tw-benefit-btn is-kingfisher"
                  to={item.fieldSecondaryCta.url}
                >
                  {item.fieldSecondaryCta.title}  {buttonArrow}
                </LocalizedLink>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
  else if (isCard) {
    return (
      <LocalizedLink
        to={item.fieldCtaUrl.url}
        className={cn(
          "box tw-box benefits-card-wrapper tw-benefits-card-wrapper tw-h-full py-5",
          className,
          { "tw-benefits-card-product": isDefault }
        )}
        key={item.title}
      >
        {!noIconStyles.includes(style) && (
          <div className={cn({ "!tw-text-center": isDefault })}>
            {selectedIcon === "check-circle" ? (
              <span className="icon">
                <i
                  className={`${selectedIconStyle} fa-${selectedIcon} ${selectedColor} fa-2x mt-2`}
                ></i>
              </span>
            ) : (
              <span className="fa-stack fa-2x about-us-icon">
                <i
                  className={`${selectedIconStyle} fa-${selectedIcon} ${selectedColor} fa-stack-1x`}
                ></i>
              </span>
            )}
          </div>
        )}
        {fieldGraphic?.gatsbyImage ? (
          <div className="card-benefits-graphic tw-card-benefits-graphic">
            <AccruentImg
              aspectRatio={1.35}
              file={fieldGraphic}
            />
          </div>
        ) :
          (
            <figure className="card-benefits-graphic tw-card-benefits-graphic">
              <StaticImage
                src={defaultImage}
                aspectRatio={1.35}
                placeholder="blurred"
                layout="constrained"
                decoding = "async"
              />
            </figure>
          )}

        {item.fieldPreHeading && <div className="pre-heading">{item.fieldPreHeading}</div>}


        {!item.fieldHideTitle && <h6 className={`tw-title tw-mb-6 ${setTitle} mt-3 ems-vertical`}>{item.title}</h6>}
        {item.body && (
          <div className="mb-2 tw-benefits-card-body-wrapper">
            <div
              className={`${style === "align_left_with_icon" ? " " : ""
                }tw-benefits-card-body dangerously-html-handle`}
              dangerouslySetInnerHTML={{ __html: item.body?.value }}
            />
          </div>
        )}
        {item.fieldKeyFeaturesBullets &&
          item.fieldKeyFeaturesBullets[0] &&
          item.fieldKeyFeaturesBullets.map((bullet) => (
            <div key={bullet} className="columns is-gapless is-mobile">
              <div className="column is-1">
                <i className="fal fa-check"></i>
              </div>
              <div className="column">{bullet}</div>
            </div>
          ))}
        <div className="tw-cta-link-box">
          {item.fieldCtaUrl && (
            <div className="cta-link tw-cta-link">
              <span
                className="button tw-benefit-btn is-primary mb-1 mt-1"
              >
                {item.fieldCtaUrl.title} {buttonArrow}
              </span>
            </div>
          )}
        </div>
      </LocalizedLink>
    );
  }
  const cardHoverClass = (style === 'align_left_with_graphic_bottom_right' || style === 'align_left_no_icon_grey_background') ? true : false
  return (
    <div
      className={cn(
        "tw-box tw-benefits-card-wrapper tw-h-full !tw-py-12",
        className,
        { "tw-benefits-card-product": isDefault },
        {"!tw-rounded-t-3xl !tw-rounded-bl-none !tw-rounded-br-3xl !tw-bg-accruent_lightgray !tw-px-5 !tw-py-[22px] tw-mb-3": style == "align_left_with_icon"}
      )}
      key={item.title}
    >
      {!noIconStyles.includes(style) && (
        <div className={cn({ "!tw-text-center": isDefault })}>
          {selectedIcon === "check-circle" ? (
            <span className="icon">
              <i
                className={`${selectedIconStyle} fa-${selectedIcon} ${selectedColor} fa-2x mt-2`}
              ></i>
            </span>
          ) : (
            <span className="fa-stack fa-2x tw-about-us-icon">
              <i
                className={`${selectedIconStyle} fa-${selectedIcon} ${selectedColor} fa-stack-1x`}
              ></i>
            </span>
          )}
        </div>
      )}

      <ConditionalWrapper
        condition={(style === 'align_right_with_icon_left_3_9')} // * note how the boolean is inside each benefit item
        wrapper={(children) => (
          <div className="benefit-right-content tw-benefit-right-content">
            {children}
          </div>
        )}
      >
        {item.fieldPreHeading && <div className="pre-heading">{item.fieldPreHeading}</div>}

        <ConditionalWrapper
          condition={isWrapTitleBody} // * note how the boolean is inside each benefit item
          wrapper={(children) => (
            <div className="benefit-title-body">
              {children}
            </div>
          )}
        >
          {!item.fieldHideTitle && (!isWrapTitleBody ? <h6 className={`tw-break-normal tw-title ${setTitle} tw-mt-benefit tw-mt-benefit ems-vertical tw-mt-6 tw-mb-6`}>{item.title}</h6> :
            <div className="benefit-div-title" >{item.title}</div>
          )}
          {item.body && (
            <div className={`tw-mb-0 mb-2 tw-benefits-card-body-wrapper ${style == "align_left_with_icon" ? "!tw-mb-1" : ""}`}>
              <div
                className={`${style === "align_left_with_icon" ? " " : ""
                  }tw-benefits-card-body dangerously-html-handle`}
                dangerouslySetInnerHTML={{ __html: item.body.value }}
              />
            </div>
          )}
        </ConditionalWrapper>
        {item.fieldKeyFeaturesBullets &&
          item.fieldKeyFeaturesBullets[0] &&
          item.fieldKeyFeaturesBullets.map((bullet) => (
            <div key={bullet} className="columns is-gapless is-mobile">
              <div className="column is-1">
                <i className="fal fa-check"></i>
              </div>
              <div className="column">{bullet}</div>
            </div>
          ))}
        {cardHoverClass && item.fieldCtaUrl && <div className='tw-main-card-link main-card-link'>
          <LocalizedLink to={item.fieldCtaUrl.url}>

          </LocalizedLink>
        </div>}
        <div className={`tw-cta-link-box cta-link-box tw-set-width set-width ${cardHoverClass ? "set-z-index" : ""} ${style == "align_left_with_icon" || style == "align_left_with_graphic_bottom_right" ? "tw-bottom-3 tw-absolute" : ""}`}>
          {item.fieldLinkToOriginalArticle &&
            item.fieldLinkToOriginalArticle[0] &&
            item.fieldLinkToOriginalArticle.map((link) => (
              <div className="mt-1 is-underlined">
                <LocalizedLink to={link.url}>{link.title}</LocalizedLink>
              </div>
            ))}
          {item.fieldCtaUrl && (
            <div className="cta-link">
              <LocalizedLink
                className="button tw-benefit-btn is-primary h-auto mb-1 mt-1 tw-button"
                to={item.fieldCtaUrl.url}
              >
                {item.fieldCtaUrl.title} {buttonArrow}
              </LocalizedLink>
            </div>
          )}
          {item.fieldSecondaryCta && (
            <div className="cta-link">
              <LocalizedLink
                className="button tw-benefit-btn is-kingfisher"
                to={item.fieldSecondaryCta.url}
              >
                {item.fieldSecondaryCta.title} {buttonArrow}
              </LocalizedLink>
            </div>
          )}
        </div>
        {fieldGraphic?.gatsbyImage && (
          <div className="benefits-graphic tw-benefits-graphic">
            <AccruentImg
              aspectRatio={1.35}
              file={fieldGraphic}
            />
          </div>
        )}
      </ConditionalWrapper>
    </div>
  );
};
