import React from 'react'

/**
 * @param {Object} style - custom style {width:'100%'}
 */
export const LCPImage = ({style={}}) => {

  return (
    <img  loading="eager" fetchpriority="high" decoding = "async" alt="lcp-image" width="98765" height="98765" style={{pointerEvents: 'none', position: 'absolute', top: 0, left: 0, width: '100vw', height: '100vh', maxWidth: '100vw', maxHeight: '100vh', ...style}}  src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHdpZHRoPSI5OTk5OXB4IiBoZWlnaHQ9Ijk5OTk5cHgiIHZpZXdCb3g9IjAgMCA5OTk5OSA5OTk5OSIgdmVyc2lvbj0iMS4xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIj48ZyBzdHJva2U9Im5vbmUiIGZpbGw9Im5vbmUiIGZpbGwtb3BhY2l0eT0iMCI+PHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9Ijk5OTk5IiBoZWlnaHQ9Ijk5OTk5Ij48L3JlY3Q+IDwvZz4gPC9zdmc+"/>
  )
}

 