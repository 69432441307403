import React, { useRef, useEffect, useState } from 'react'
import useMountingForIcon from 'src/hooks/useMountingForIcon';
import angleDownBlack from "../../../static/angle-down-black.svg";
import angleDownWhite from "../../../static/angle-down-white.svg";
import angleUpWhite from "../../../static/angle-up-white.svg";
import globalDownDownBlack from "../../../static/global-dropdown-black.svg";
import globalDropDownWhite from "../../../static/global-dropdown-white.svg";
import "src/scss/components/_globalDropDown.scss";

const useOutsideAlerter = (ref, callback) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (callback) {
          callback()
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const countries = [
  { key: "", label: "America" },
  { key: "uk", label: "Europe" },
  { key: "uk", label: "Asia Pacific" },
  { key: "uk", label: "Middle East" },
  { key: "uk", label: "ANZ" },
]

export const GlobalDropDown = (props) => {
  const [show, setShow] = useState(false)
  const [activeCountry,setActiveCountry] = useState("")
  const [mouseOver, setMouseOver] = useState(false);
  const ref = useRef(null)
  const wrapperRef = useRef(null)
  const toggleDropDown = () => {
    if (ref?.current) {
      setShow(!show)
      ref?.current?.classList.toggle('show');
    }
  }
  const mounted = useMountingForIcon();

  const closeDropDown = () => {
    if (ref?.current) {
      setShow(false)
      ref?.current?.classList.remove('show');
    }
  }
  useOutsideAlerter(wrapperRef, closeDropDown);

  const onClickMenu = (current) => {
    if(activeCountry !== current)
    window?.open("/" + current,'_blank')
    closeDropDown()
  }
  useEffect(()=>{
   if(props.locale === 'en'){
    setActiveCountry("")
   }else{
    setActiveCountry("uk")
   }
  },[props.locale])

  return (
    <div ref={wrapperRef} className='global-dropdown tw-global-dropdown tw-z-[111]'  >
      {mounted && <div className="dropdown tw-dropdown" >
        <div key={show} className={"dropbtn tw-dropbtn tw-flex tw-h-[29px] tw-w-[59px] tw-cursor-pointer tw-items-center tw-text-accruent_black -md:tw-w-full tw-rounded-2xl tw-bg-[#f2f2f2] " + (show ? "dropbtn-active" : "")} onClick={toggleDropDown} onMouseEnter={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)}>
          <span className='location-text tw-location-text'>Location</span>
          {(!mouseOver) ? show ? <img src={globalDropDownWhite} decoding='async ' className='svg-inline--fa fa-earth-americas earth-icon tw-earth-icon' /> : <img src={globalDownDownBlack} decoding='async' className='svg-inline--fa fa-earth-americas earth-icon tw-earth-icon'/>
          :<img src={globalDropDownWhite} decoding='async ' className='svg-inline--fa fa-earth-americas earth-icon tw-earth-icon' />  
        }
          {/* <svg class="svg-inline--fa fa-earth-americas earth-icon tw-earth-icon" aria-hidden="true" data-prefix="fas" data-icon="earth-americas" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M57.7 193l9.4 16.4c8.3 14.5 21.9 25.2 38 29.8L163 255.7c17.2 4.9 29 20.6 29 38.5v39.9c0 11 6.2 21 16 25.9s16 14.9 16 25.9v39c0 15.6 14.9 26.9 29.9 22.6c16.1-4.6 28.6-17.5 32.7-33.8l2.8-11.2c4.2-16.9 15.2-31.4 30.3-40l8.1-4.6c15-8.5 24.2-24.5 24.2-41.7v-8.3c0-12.7-5.1-24.9-14.1-33.9l-3.9-3.9c-9-9-21.2-14.1-33.9-14.1H257c-11.1 0-22.1-2.9-31.8-8.4l-34.5-19.7c-4.3-2.5-7.6-6.5-9.2-11.2c-3.2-9.6 1.1-20 10.2-24.5l5.9-3c6.6-3.3 14.3-3.9 21.3-1.5l23.2 7.7c8.2 2.7 17.2-.4 21.9-7.5c4.7-7 4.2-16.3-1.2-22.8l-13.6-16.3c-10-12-9.9-29.5 .3-41.3l15.7-18.3c8.8-10.3 10.2-25 3.5-36.7l-2.4-4.2c-3.5-.2-6.9-.3-10.4-.3C163.1 48 84.4 108.9 57.7 193zM464 256c0-36.8-9.6-71.4-26.4-101.5L412 164.8c-15.7 6.3-23.8 23.8-18.5 39.8l16.9 50.7c3.5 10.4 12 18.3 22.6 20.9l29.1 7.3c1.2-9 1.8-18.2 1.8-27.5zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"></path></svg> */}
          {show ?
          <img src={angleUpWhite} className="svg-inline--fa fa-angle-up angle-icon tw-angle-icon" decoding='async'/> :
          mouseOver ? <img src={angleDownWhite} decoding='async' className='svg-inline--fa fa-angle-down angle-icon tw-angle-icon'/> : <img src={angleDownBlack} decoding='async' className='svg-inline--fa fa-angle-down angle-icon tw-angle-icon'/>}
             {/* <svg class="svg-inline--fa fa-angle-down angle-icon tw-angle-icon" aria-hidden="true" data-prefix="fas" data-icon="angle-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M201.4 342.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 274.7 86.6 137.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z"></path></svg>} */}
        </div>
        <div ref={ref} className="dropdown-content tw-dropdown-content">
          {countries.map((c, i) => {
            return (
              <button key={c.key+i} className={`${(activeCountry === c.key) ? "active-menu" : ""} tw-w-full tw-text-left tw-no-underline tw-block tw-text-[15px] tw-leading-[23px] tw-tracking-[0.03px] tw-text-accruent_black tw-cursor-pointer tw-font-semibold tw-m-0 tw-px-4 tw-py-1`} onClick={() => onClickMenu(c.key)} >{c.label}</button>
            )
          })}
        </div>
      </div>}
    </div>
  )
}


