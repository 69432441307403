const pushDataLayerTags = ({
  industryTags,
  solutionTags,
  productTags,
  fieldBuyerPersona,
  fieldBusinessUnit,
  fieldCustomerJourney,
}) => {
  if (typeof window === "undefined") {
    return;
  }
  const buyerPersona = [];
  if (Array.isArray(fieldBuyerPersona)) {
    fieldBuyerPersona.forEach((node) => {
      if (node?.entity?.name) {
        buyerPersona.push(node.entity.name);
      }
    });
  }
  const businessUnits = [];
  if (Array.isArray(fieldBusinessUnit)) {
    fieldBusinessUnit.forEach((node) => {
      if (node?.entity?.entityLabel) {
        businessUnits.push(node.entity.entityLabel);
      }
    });
  }
  const customerJourney = [];
  if (Array.isArray(fieldCustomerJourney)) {
    fieldCustomerJourney.forEach((node) => {
      if (node?.entity?.entityLabel) {
        customerJourney.push(node.entity.entityLabel);
      }
    });
  }

  const dataLayerObj = {
    buyer_persona: buyerPersona,
    customer_journey: customerJourney,
    business_unit: businessUnits,
    industries: industryTags || [],
    solutions: solutionTags || [],
    products: productTags || [],
  };

  if (!window.dataLayer) {
    window.dataLayer = [];
  }

  for (let i = window.dataLayer.length - 1; i >= 0; i -= 1) {
    if (
      window.dataLayer[i].industries &&
      window.dataLayer[i].solutions &&
      window.dataLayer[i].products &&
      window.dataLayer[i].buyer_persona &&
      window.dataLayer[i].customer_journey &&
      window.dataLayer[i].business_unit
    ) {
      window.dataLayer.splice(i, 1);
    }
  }

  window.getDataLayerObj = function() {
    /**
     * New strategy relies on GTM custom html tag that calls this function.
     * The dataLayer.push() is done from that script instead of from our side.
     */
    //console.log("dataLayerObj", dataLayerObj);
    return dataLayerObj;
  };
};

export default pushDataLayerTags;
