const styleMarketoForm = (formElement, allInputElements) => {
  formElement
    .querySelectorAll('input[type="text"]')
    .forEach((el) => allInputElements.push(el));
  formElement
    .querySelectorAll('input[type="email"]')
    .forEach((el) => allInputElements.push(el));
  formElement
    .querySelectorAll('input[type="tel"]')
    .forEach((el) => allInputElements.push(el));
  formElement
    .querySelectorAll("select")
    .forEach((el) => allInputElements.push(el));
  formElement
    .querySelectorAll("textarea")
    .forEach((el) => allInputElements.push(el));
  // insert common classes for every node in allInputElements array
  allInputElements.forEach((el) => {
    el.classList.add("input", "mt-1","mb-1");
  });

  // Add float:left to laves
  formElement.querySelectorAll("input[type='checkbox']").forEach((el) => {
    el.classList.add("is-pulled-left", "mr-1");
  });
  // Add display block to labels
  formElement.querySelectorAll(".mktoLogicalField label").forEach((el) => {
    el.classList.add("is-block", "mb-2");
  });
  // Add margib-bootom to all form row
  formElement.querySelectorAll(".mktoFormRow")?.forEach((el) => {
    el.classList.add("mb-2");
  });

  // Set flex to button wrapper
  formElement.querySelectorAll(".mktoButtonRow").forEach((el) => {
    el.classList.remove("is-justify-content-center");
    el.classList.remove("is-justify-content-end");
  });
  // Add style to submit button
  formElement.querySelectorAll("button[type='submit']").forEach((el) => {
    el.classList.add(
      "button",
      "is-primary",
      "is-medium",
      "is-fullwidth",
      "block"
    );
  });
  formElement
    .querySelectorAll("textarea")
    .forEach((el) => (el.style.minHeight = "76px"));
  // Add margin to label
  formElement.querySelectorAll(".mktoLabel").forEach((el) => {
    el.classList.add("mb-5", "mt-6");
  });
  // Add margin to checkbox list
  formElement.querySelectorAll(".mktoCheckboxList").forEach((el) => {
    el.classList.add("mb-3", "mt-2");
  });
  // remove the asterisk
  formElement.querySelectorAll(".mktoAsterix").forEach((el) => {
    el.classList.add("is-hidden");
  });
  const fieldDescriptors = formElement.querySelectorAll(".mktoFieldDescriptor");
  const arrayify = getSelection.call.bind([].slice);
  // to replace error messages on the front end
  const messages = [
    {
      search: "example@yourdomain.com",
      replace: "",
    },
  ];
  arrayify(fieldDescriptors)
    .map(function(desc) {
      return window.MktoForms2.$(desc).data("mktoFieldDescriptor");
    })
    .filter(function(descJ) {
      return descJ.validationMessage !== undefined;
    })
    .forEach(function(descJ) {
      messages.forEach(function(message) {
        descJ.validationMessage = descJ.validationMessage.replace(
          message.search,
          message.replace
        );
      });
    });
};

export default styleMarketoForm;
