import React, { useState, useEffect } from "react";
import useMarketoForm from "src/components/helpers/useMarketoForm";
import { AccruentImg } from "../accruentImg";
import { Loader } from "../../commonComponents";
import { LocalizedLink } from "../localizedLink";
import logo from "../../../../static/new-request-demo-form/logo.png";
import colorLine from "../../../../static/new-request-demo-form/color-line.svg";
import { DEMO_FORM_LOADED } from "src/constants";
import makeDynamicFormId from "src/components/helpers/makeDynamicFormId";
import { StaticImage } from "gatsby-plugin-image";
const colorLineN =  "../../../../static/new-request-demo-form/color-line.svg";
const accruentLogo = "../../../../static/new-request-demo-form/logo.png";
const DemoBody = ({ fieldFormHeader, pageTitle, serial, formId, gaLabel }) => {
  const [isFormLoaded, setFormLoaded] = useState(false);
  const formCallback = () => {
    setFormLoaded(true);
    localStorage.setItem(DEMO_FORM_LOADED, true)
    window.dispatchEvent(new CustomEvent("isFormLoaded", { 
      detail: {
        newValue: true,
        key: DEMO_FORM_LOADED
      }
    }));
  };
  useEffect(() => {
    localStorage.removeItem(DEMO_FORM_LOADED, false)
    window.dispatchEvent(new CustomEvent("isFormLoaded", { 
      detail: {
        newValue: false,
        key: DEMO_FORM_LOADED
      }
    }));
  }, []);
  const { formTag } = useMarketoForm(serial, formId, formCallback, "", gaLabel);
  return (
    <div className="!tw-p-[18px] md:!tw-p-[36px_49px] column second-column tw-relative !tw-mb-0  !tw-min-h-max">
      <div className="tw-flex tw-flex-col-reverse tw-items-center md:tw-items-start tw-gap-[20px] md:tw-flex-row tw-justify-between">
        <div>
          {fieldFormHeader && <h3 className="tw-pl-[10px] md:tw-pl-0 md:tw-text-xxxlFont tw-text-mobileXxxlFont tw-font-Poppins tw-text-accruent_heading_color">{fieldFormHeader}</h3>}
          {pageTitle && <p className=" tw-pl-[10px] md:tw-pl-0 tw-text-mobileXlFont md:tw-text-xlFont tw-font-Poppins">{pageTitle}</p>}
        </div>
        <div>
          <i className="fa-solid fa-computer tw-text-[40px] tw-text-[#001C71]"></i>
        </div>
      </div>
      {formTag && (
        <div >
          <div className="control" style={{ position: "initial" }}>
            {!isFormLoaded && (
              <div>
                <Loader />
              </div>
            )}
            <div
              className={`${!isFormLoaded ? "unset-display" : "set-display"}`}
            >
              {formTag}
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

const DemoFormWithBg = ({
  serial,
  formId,
  gaLabel,
  image,
  bgImage,
  fieldFormHeader,
  pageTitle,
  cta,
  fieldMarketoFormTitleId,
  shouldLoad = true,
  formFullWidth = false,
  demoImage = null
}) => {
  let id = fieldMarketoFormTitleId
  ? makeDynamicFormId(fieldMarketoFormTitleId)
  : "request-a-demo";
  return (
    <div>
      <div className="tw-full-width-bg-wrapper tw-relative">
        {<div className="tw-bg-image-container tw-absolute tw-h-full tw-left-0 tw-right-0 tw-top-0">
         {bgImage && <AccruentImg
            file={bgImage}
            className="bg-image"
          />}
        </div>}
        <div className={` tw-pt-[40px] ${formFullWidth ? "md:tw-pt-[20px]" : "md:tw-pt-[75px]"}`}></div>
        <div className={`tw-relative tw-m-auto tw-rounded-[48px]  tw-max-w-[1160px] tw-bg-accruent_white tw-min-h-0 tw-flex solutions-get-in-touch-wrapper demo-form-with-bg ${formFullWidth ? "tw-ml-[10px] tw-mr-[10px]": ""}`}>
          <div className="md:tw-w-1/4 md:tw-relative ">
           {(!demoImage && image )? <AccruentImg
              file={image}
              className="tw-h-full tw-rounded-[48px_0px_0px_48px]"
            />
            : 
            <img src={demoImage} className="tw-h-full tw-rounded-[48px_0px_0px_48px] tw-object-cover "/>
            }
            <div className="tw-absolute tw-hidden md:tw-block tw-bottom-[50px] tw-right-[50px] tw-w-[50px]">
              {/* <img src={logo} alt="accruent-logo" decoding="async"/> */}
              <StaticImage src={accruentLogo} quality={100} width={50} height={46}/>
            </div>
          </div>
          <div className="md:tw-w-3/4 tw-w-full">
            <div id={id}></div>
            {shouldLoad && <DemoBody fieldFormHeader={fieldFormHeader} pageTitle={pageTitle}
             serial={serial}
             formId={formId}
             gaLabel={gaLabel}
            />}
          </div>
          <div className="tw-absolute tw-top-[-2px] tw-left-[400px] tw-hidden md:tw-block">
            {/* <img src={colorLine} alt="line-logo" decoding="async"/> */}
            <StaticImage src={colorLineN}/>
          </div>
        </div>
        {(cta?.url || cta?.uri) && <LocalizedLink to={cta?.url || cta?.uri}>
          <p className=" hover:tw-underline tw-cursor-pointer tw-text-accruent_white tw-relative tw-font-bold tw-text-[12px] tw-font-Poppins tw-text-center tw-pt-[24px]">{cta?.title}</p>
        </LocalizedLink>}
        <div className="tw-pb-[20px]"></div>
      </div>
    </div>
  )

}
export default DemoFormWithBg;