const getResourceTagsList = (allTags) => {
  let tagsList = []
  if (allTags) {
    allTags.map((categoryTags) => {
      if (categoryTags) {
        categoryTags.map((tag) => {
          tagsList.push(tag)
        })
      }
    })
  }
  return tagsList;
}

export default getResourceTagsList;
