import React from "react";
import { HeroTextContainer } from "./HeroTextContainer";
import { HeroImageContainer } from "./HeroImageContainer";
import { LCPImage } from "../lcpImage";
import { Picture } from "../picture";

export const CategoryHeroV2 = ({ containerStyles, commonHeroProps,onPopupLoad }) => {
  const {
    heading,
    preHeading,
    body,
    cta,
    secondaryCta,
    formCta,
    heroImage,
    heroVideo,
    videoBackgroundImage,
    heroImageUrl,
    heroBackground,
    heroLeftBackground,
    heroRightBackground,
    subHeading,
    fieldHideHeroImage,
    heroGifImage,
  } = commonHeroProps;
  const currentHeroImage = fieldHideHeroImage ? null : heroImage;
  const currentHeroImageUrl = fieldHideHeroImage ? null : heroImageUrl;
  let backgroundNewImage = heroBackground?.gatsbyImage
    ? heroBackground
    : videoBackgroundImage;
  const heroImageAttrs =
    backgroundNewImage?.gatsbyImage?.images ||
    currentHeroImage?.gatsbyImage?.images;
  const foreGroundImage = !heroVideo
    ? backgroundNewImage?.gatsbyImage
      ? currentHeroImage
      : null
    : null;
  const heroGif = currentHeroImageUrl;
  return (
    <div className={containerStyles.commonHeroContainer}>
      <section
        className={`section hero is-block  product-page-hero-box tw-product-page-hero-box ${
          heroLeftBackground?.gatsbyImage ? "left-background-image-margin" : ""
        }`}
        // style={heroImageStyle}
        style={{ position: "relative" }}
      >
        <LCPImage />
        {heroLeftBackground?.gatsbyImage && (
          <Picture
            image={{ ...heroLeftBackground }}
            parentClassName={"left-background-image-container"}
            className={"left-background-image tw-absolute tw-w-auto tw-min-w-[433px] tw-h-[96%] tw-top-[12px] tw-hidden"}
          />
        )}
        {heroRightBackground?.gatsbyImage && (
          <Picture
            image={{ ...heroRightBackground }}
            parentClassName={"right-background-image-container"}
            className={"right-background-image tw-absolute tw-w-auto tw-h-[96%] tw-right-0 tw-top-[9px] tw-object-contain tw-hidden md:tw-block"}
          />
        )}
        <div className="container common-hero-container">
          <div className=" tw-flex tw-flex-col md:tw-flex-row tw-items-center">
            <div className=" md:tw-w-7/12 is-full-tablet hero-page-container text tw-px-4 md:tw-pl-[30px]
            tw-mb-[0px] tw-mt-[52px] md:tw-mt-[76px] md:tw-mb-[76px]">
              <HeroTextContainer
                preHeading={preHeading}
                heading={heading}
                body={body}
                cta={cta}
                secondaryCta={secondaryCta}
                formCta={formCta}
                subHeading={subHeading}
                onPopupLoad={onPopupLoad}
              />
            </div>
            <div className="md:tw-w-5/12 is-full-tablet hero-page-container tw-mt-[0px] tw-mb-[32px] tw-p-[0px] md:tw-my-[76px] tw-relative
            video">
              <HeroImageContainer
                heroVideo={heroVideo}
                backgroundNewImage={backgroundNewImage}
                foreGroundImage={foreGroundImage}
                heroGif={heroGif}
                heroImageAttrs={heroImageAttrs}
                heroImage={currentHeroImage}
                heroGifImage={heroGifImage}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
