/**
 * This method is used to fetch the height of the tallest container to render Masonry layout 
 * @param {HTMLElement} containerRef  - parent container reference,
 * @param {String} childContainerClassName - child container class name
 * @param {Number} numberOfColumnsPerRow - number of columns per row
 */
import getResourceTagsList from "../helpers/getResourceTagsList";
import { getResourceDate } from "../helpers/dateUtilities";
import { AVIF_MIME_TYPE, NAV_LIST_DIVIDE } from "src/constants"

export const getMultiRowContainerHeight = (containerRef = null, childContainerClassName = null, numberOfColumnsPerRow = 4 ) => {
    let result = 0
    const container = !childContainerClassName ? containerRef : containerRef?.getElementsByClassName(childContainerClassName);
    if (!container) return result;
    const colHeights = Array(numberOfColumnsPerRow).fill(0);
    const style = window.getComputedStyle(container);
    const containerPadding = parseFloat(style?.paddingTop) + parseFloat(style?.paddingBottom);
    Array.from(container.children)
        .slice(0, -3)
        .forEach((child, i) => {
            const order = i % numberOfColumnsPerRow;
            colHeights[order] += parseFloat(
                child.getBoundingClientRect()?.height
            );
        });
    result = Math.max(...colHeights) + containerPadding + "px";
    return result;
  };

export const getResourcesHeroData = (data) => {
    let heroImage = get(data, "heroImage");
    const heroVideoUrl = get(data, "relationships.fieldHeroVideo.fieldMediaVideoEmbedField");
    const heroVideoThumb = get(data, "relationships.fieldHeroVideo.relationships.thumbnail.url");
    const title = data?.title;
    const subTitle = data?.subTitle
    const resourceTags = getResourceTagsList([data?.industryIds, data?.solutionIds, data?.productIds])
    const fieldResourceDescription = data?.fieldResourceDescription
    const fieldPublishDate = getResourceDate(data?.fieldPublishDate)
    const fieldBrochureDownloadLink = data?.fieldBrochureDownloadLink
    const fieldReadTime = data?.fieldReadTime
    const fieldTagLine = data?.fieldTagLine
    const linkToCaseStudyPdf = data?.fieldCaseStudyPdfLink ? data?.fieldCaseStudyPdfLink.url : (data?.fieldCaseStudyPdf ? data?.fieldCaseStudyPdf.url : null)
    const pdfLink = data?.fieldLinkToInfographic?.url
    const fieldPodcastSeasonAndEpisode = data?.fieldPodcastSeasonAndEpisode
    const fieldHighlightsPoints = data?.fieldHighlightsPoints
    const fieldTags = data?.relationships?.fieldTags?.entityLabel
    const newswireLink = data?.fieldLinkToOriginalArticle?.[0]
    const fieldOverrideItem = data?.fieldOverrideItem
    return {
        heroVideoUrl: heroVideoUrl,
        heroImage: heroImage,
        heroVideoThumb: heroVideoThumb,
        title: title,
        subTitle: subTitle,
        resourceTags: resourceTags,
        fieldResourceDescription: fieldResourceDescription,
        fieldPublishDate: fieldPublishDate,
        id: data?.contentTypeId?.[0]?.id,
        contentType: data?.type?.id,
        locale: data?.locale,
        fieldBrochureDownloadLink: fieldBrochureDownloadLink,
        fieldReadTime: fieldReadTime,
        fieldTagLine: fieldTagLine,
        linkToCaseStudyPdf: linkToCaseStudyPdf,
        pdfLink: pdfLink,
        fieldPodcastSeasonAndEpisode: fieldPodcastSeasonAndEpisode,
        fieldHighlightsPoints: fieldHighlightsPoints,
        fieldTags: fieldTags,
        newswireLink: newswireLink,
        fieldOverrideItem: fieldOverrideItem
    }
}

export const getPageDataJsonPath = (pagePath = '') => {
     return `/page-data${pagePath}/page-data.json`;
  };

/**
 * This method is used to get image src object  like {src:'', srcSet:{}, sizes:"", ...} from GatsbyImage Object
 * @param {Object} gatsbyImgObj  - {gatsbyImage: {image:{fallback:{}, sources:[]}} or {fallback:{}, sources:[]}
 */

export const getImageSrcFromGatsbyImg = (gatsbyImgObj) => {
    const tmpObj = gatsbyImgObj?.gatsbyImage?.images
        ? gatsbyImgObj.gatsbyImage.images
        : gatsbyImgObj?.fallback
        ? gatsbyImgObj
        : null;
    if (!tmpObj) return undefined;
    let imageObj = {};
    const sources = tmpObj.sources;
    if (sources) {
        imageObj = sources.find((src) => src?.type == AVIF_MIME_TYPE);
        if(imageObj)
        imageObj.src = imageObj?.srcSet?.split(" ")?.[0];
    }
    if (!imageObj?.src) {
        imageObj = tmpObj.fallback;
    }
    return imageObj;
};
  
/**
 * This method is used to get all the images showing on common hero component 
 * @param {Object} commonHeroProps  - containing all props required for CommonHero component "src/components/hero/CommonHeroTemplate/CommonHero"
 */

export const getCommonHeroImages = (commonHeroProps) => {

    const { heroImage, heroVideo, videoBackgroundImage, heroImageUrl, heroBackground, heroLeftBackground, heroRightBackground, fieldHideHeroImage, heroGifImage } = commonHeroProps;
    const currentHeroImage = fieldHideHeroImage?null:heroImage
    const currentHeroImageUrl = fieldHideHeroImage?null:heroImageUrl
    let backgroundNewImage = heroBackground?.gatsbyImage ? heroBackground : videoBackgroundImage
    const heroImageAttrs = backgroundNewImage?.gatsbyImage?.images || currentHeroImage?.gatsbyImage?.images
    const foreGroundImage = !heroVideo ? (backgroundNewImage?.gatsbyImage) ? currentHeroImage: null : null
    const heroGif = currentHeroImageUrl
    const heroLeftBackgroundAttrs = heroLeftBackground
    const heroRightBackgroundArrts = heroRightBackground
    const isGifImage = !!heroGifImage?.url // coming from Gif field of drupal
    const images = [] // result of the function contains all the images showing on hero component

 {/* Background Image in case of no video */}
    if(!heroVideo && backgroundNewImage?.gatsbyImage){
         images.push(getImageSrcFromGatsbyImg(backgroundNewImage))
         if(foreGroundImage?.gatsbyImage || heroGif){
            if(foreGroundImage?.gatsbyImage){
                images.push(getImageSrcFromGatsbyImg(foreGroundImage))
            }else{
                images.push({src: heroGif}) //url
            }
         }
    }
     {/* Image in case of Video */}
    if(heroVideo && !!heroImageAttrs?.fallback?.src){
        images.push(getImageSrcFromGatsbyImg(heroImageAttrs))
    }
    
    {/* Gif image in case of No video and No background Image */}
    if(((heroGif && !heroImageAttrs?.fallback?.src) || isGifImage) && !heroVideo){
        images.push({src: isGifImage ? heroGifImage.url : heroGif})  //url
    }

    {/* In case of only Hero Image present */}
    if(!isGifImage && !heroVideo && !backgroundNewImage?.gatsbyImage && heroImageAttrs?.fallback?.src){
        images.push(getImageSrcFromGatsbyImg(heroImageAttrs))
    }

    if(heroVideo){
        images.push(getImageSrcFromGatsbyImg(heroVideo?.relationships?.thumbnail)) 
    }

     {/* Left Image in case of hero V2 */}
     if(heroLeftBackgroundAttrs){
        images.push(getImageSrcFromGatsbyImg(heroLeftBackgroundAttrs))
     }

     {/* Right Image in case of hero V2 */}
     if(heroRightBackgroundArrts){
        images.push(getImageSrcFromGatsbyImg(heroRightBackgroundArrts))
     }

     return images;
}
 
export const splitComponent = (component) => {
    if(Array.isArray(component)){
        if((component.length)%2 === 0){
            let firstContent = component.slice(0, component.length / 2);
            let secondContent = component.slice(component.length / 2, component.length);
            return {firstContent, secondContent}
        }else{
            let firstContent = component.slice(0, component.length / 2 + 1);
            let secondContent = component.slice(component.length / 2 + 1, component.length);
            return {firstContent, secondContent}
        }
       
    }
    return null
}

/**
 * Its purpose is to divide the input array into smaller arrays
 *
 * @param {array} arr - The input array of nav data
 * @param {integer} size - Size value in integer to divide
 * @returns {array} 
 */
export const chunk = (arr, size) =>
  Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size)
);

export const splitComponentIntoChunk = (component) => {
  if(Array.isArray(component) && component?.length>NAV_LIST_DIVIDE*4){
    let arr = chunk(component, NAV_LIST_DIVIDE+1) 
    return arr;
  }
  else if(Array.isArray(component) && component?.length>NAV_LIST_DIVIDE*2){
    let arr = chunk(component, NAV_LIST_DIVIDE) 
    return arr;
  }else{
    let {firstContent, secondContent} = splitComponent(component)
    let arr = [firstContent, secondContent]
    return arr;
  } 
}

/**
 * Remove <script> tags with specified 'src' URLs from an HTML string.
 *
 * @param {string} html - The input HTML string to sanitize.
 * @returns {string} - The sanitized HTML string with specified <script> tags removed.
 */
export const removeScriptTagsWithFastWistiaSrc = (html) => {
    if(!html) return html;
    const scriptTagPattern = /<script\b[^>]*\bsrc\s*=\s*("https:\/\/fast\.wistia\.com[^"]*")[^>]*>[\s\S]*?<\/script>/gi;
    const sanitizedHtml = html.replace(scriptTagPattern, '');
  
    return sanitizedHtml;
  };

/**
 * This function checks if any part of a specified DOM element is visible within the
 * visible area of the viewport.
 *
 * @param {Element} element - The DOM element to be checked.
 * @returns {boolean} True if the element is entirely within the viewport, false otherwise.
 */
export const isElementInViewport = (element) => {
    if(!element) return element;
    const { top, left, bottom, right } = element.getBoundingClientRect();
    const { innerHeight, innerWidth } = window;
  
    return (
      top <= innerHeight &&
      left <= innerWidth &&
      bottom >= 0 &&
      right >= 0
    );
  };
  
  
  export const isMobileDevice = () => {
    // Get the viewport width
    var viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  
    // Define a maximum width that you consider as the breakpoint for mobile devices
    var mobileDeviceWidth = 768; // You can adjust this value as needed
  
    // Check if the viewport width is less than the mobile device width
    return viewportWidth < mobileDeviceWidth;
  }
  
 /**
 * The provided get method is a function that retrieves a value from a nested object based on a given path. It takes three parameters:
 *
 * @param {Object} obj - The target object from which to retrieve the value.
 * @param {String} path - A string or an array representing the path to the desired property within the object. If it's a string, it assumes that the properties are separated by dots ('.').
 * @param {any} defaultValue - The default value to return if the specified property is not found. If not provided, it defaults to undefined.
 * @returns {any}
 */
  export const get = (obj, path, defaultValue) =>
    (Array.isArray(path) ? path : path.split('.'))
    .reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj) || defaultValue;

 /**
 * The isEmpty method is a function that checks if a given value is empty
 *
 * @param {any} value
 * @returns {Boolean}
 */
  export const isEmpty = (value) => {
    // Check for null or undefined values
    if (value == null) {
      return true;
    }
  
    // Check for non-object types
    if (typeof value !== 'object') {
      return !value;
    }
  
    // Check for empty arrays or plain objects
    if (Array.isArray(value) || (typeof value === 'object' && value.constructor === Object)) {
      return Object.keys(value).length === 0;
    }
    return false;
  };

  /**
 * The sampleSize method is a function designed to take a collection (an array or a string) and an integer n as parameters. 
 * It then returns a new array containing a random sample of n elements from the original collection.
 * The method ensures that the original collection remains unmodified.
 *
 * @param {Array|String} collection - The target collection (an array or a string).
 * @param {Integer} n - size of sample Array
 * @returns {Array}
 */
 export const sampleSize = (collection, n) => {
    if (!Array.isArray(collection) && typeof collection !== 'string') {
      throw new Error('Collection must be an array or a string.');
    }
    const length = collection.length;
    if (n >= length) {
      return collection.slice();
    }
    const sampled = [];
    const clone = collection.slice();
    for (let i = 0; i < n; i++) {
      const randomIndex = Math.floor(Math.random() * clone.length);
      const removed = clone.splice(randomIndex, 1)[0];
      sampled.push(removed);
    }
  
    // Return the array of sampled elements
    return sampled;
  };

  /**
 * The function then formats fieldStartEndTimes dates into their corresponding times using the given timezone.
 * returns an object containing the formatted start and end times.
 *
 * @param {Object} fieldStartEndTimes - This object is expected to have properties named startDate and endDate, which represent dates.
 * @param {String} timezone 
 * @returns {Object}
 */
  export const timeFormatter = (fieldStartEndTimes, timezone = 'America/Chicago') => {
    // Helper function to format time using Intl.DateTimeFormat with given timezone
    const formatTime = (date) => {
      // Options for formatting time
      const options = { hour: 'numeric', minute: 'numeric', hour12: true };

      const timezoneTime = new Intl.DateTimeFormat('en-US', { timeZone: timezone, ...options });

      return timezoneTime.format(date);
    };

    let startTime = ("startDate" in fieldStartEndTimes) ?
      formatTime(new Date(fieldStartEndTimes.startDate)) : "";

    let endTime = ("endDate" in fieldStartEndTimes) ?
      formatTime(new Date(fieldStartEndTimes.endDate)) : "";
  
    // Return the formatted start and end times
    return { startTime, endTime };
  };

  /**
 *  The function creates a Date object using the provided value and then uses the Intl.DateTimeFormat with the specified timezone (fieldTimeZone) to get the timezone abbreviation.
 *  The method returns the timezone abbreviation.
 *
 * @param {Date|String} value - A value that can be used to create a Date object.
 * @param {String} fieldTimeZone 
 * @returns {String}
 */

  export const getTimeZoneAbbreviation = (value, fieldTimeZone) => {
    const date = new Date(value);
    const timeZoneFormat = new Intl.DateTimeFormat('en-US', { timeZoneName: 'short', timeZone: fieldTimeZone });
    const timeZoneAbbreviation = timeZoneFormat.formatToParts(date).find(part => part.type === 'timeZoneName').value;
  
    // Manually map GMT offsets to three-letter abbreviations for CET and CEST
    const gmtOffset = parseInt(timeZoneAbbreviation.replace(/[^\d.-]/g, ''));
    let abbreviation = '';
  
    if (gmtOffset === 1) {
      abbreviation = 'CET';
    } else if (gmtOffset === 2) {
      abbreviation = 'CEST';
    }
    if(!abbreviation){
      abbreviation = timeZoneAbbreviation
    }
  
    return abbreviation;
  };
  
  

/**
 * Its purpose is to format a given date in a specified format using the Intl.DateTimeFormat API.
 *
 * @param {Date|String} dateValue -  This parameter represents the date that needs to be formatted.
 * @param {String} format - This parameter is a string that specifies the desired format for the output date.
 * @returns {String}
 */
  export const formatDate = (dateValue, format) => {

    const date = new Date(dateValue);
  
    // Define format patterns for different date formats
    const formatPatterns = {
      'MMMM D, YYYY': {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      },
      // You can add more format patterns as needed
    };
    const selectedFormat = formatPatterns[format] || formatPatterns['MMMM D, YYYY'];
    const formattedDate = new Intl.DateTimeFormat('en-US', selectedFormat).format(date);
  
    // Return the formatted date
    return formattedDate;
  };

  
/**
 * Its purpose is to capitalizes the first letter of a given string.
 *
 * @param {String} str -  input string.
 * @returns {String}
 */
  export const capitalize = (str) => {
    if (typeof str !== 'string') {
      throw new Error('Input must be a string');
    }
  
    if (str.length === 0) {
      return str;
    }
  
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

/**
 * Its purpose is to capitalizes the first letter of each word in a string
 *
 * @param {String} str -  input string.
 * @returns {String}
 */
  export const startCase = (str) => {
    if (typeof str !== 'string') {
      throw new Error('Input must be a string');
    }
  
    if (str.length === 0) {
      return str;
    }
  
    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  /**
 * Its purpose is to Sort a  collection.
 *
 * @param {Array} collection -  the array to be sorted.
 * @param {Array} iteratees -  an array of properties to sort by.
 * @param {Array} orders -  an array of sort orders corresponding to the properties in iteratees.
 * @returns {Array}
 */

  export const orderBy = (collection, iteratees, orders) => {
    if (!Array.isArray(collection)) {
      throw new Error('The collection must be an array');
    }
  
    iteratees = typeof iteratees === 'string' ? [iteratees] : iteratees || [];
    orders = typeof orders === 'string' ? [orders] : orders || [];
  
    if (iteratees.length !== orders.length) {
      throw new Error('The number of iteratees and orders must be the same');
    }
  
    const sortFunctions = iteratees.map((iteratee, index) => {
      const order = orders[index] || 'asc';
      return (a, b) => {
        const aValue = typeof a[iteratee] === 'undefined' ? a : a[iteratee];
        const bValue = typeof b[iteratee] === 'undefined' ? b : b[iteratee];
        if (order === 'asc') {
          return aValue > bValue ? 1 : -1;
        } else if (order === 'desc') {
          return aValue < bValue ? 1 : -1;
        }
      };
    });
  
    return collection.slice().sort((a, b) => {
      for (const sortFunction of sortFunctions) {
        const result = sortFunction(a, b);
        if (result !== 0) {
          return result;
        }
      }
      return 0;
    });
  };
  
  