const groupQuotes = (contentConnector) => {
  const newContentConnector = [];
  for (let index = 0; index < contentConnector.length; index += 1) {
    const quotes = [];
    let j = 0;
    let fieldMakeAnchor = false;
    let title;
    while (
      contentConnector[index + j]?.fieldProxy?.type?.id ===
      "block_quote"
    ) {
      let current = contentConnector[index + j++]
      quotes.push(current?.fieldProxy);
      title = current?.title
      fieldMakeAnchor = current?.fieldMakeAnchor
    }
    if (quotes.length) {
      newContentConnector.push({
        title,
        fieldMakeAnchor,
        fieldProxy: {
          quotes,
          type: {
            id: "block_quote"
          },
        },
      });
      index += quotes.length - 1;
    } else {
      newContentConnector.push(contentConnector[index]);
    }
  }
  return newContentConnector;
};


const getContentConnectorFields = (contentConnector, pageType, langcode) => {
  if (contentConnector) {
    let contentConnectorCopy = JSON.parse(JSON.stringify(contentConnector))
    contentConnectorCopy =
      contentConnectorCopy?.filter(entity => !!entity).map(entity => {
        entity["fieldProxy"] = entity?.relationships?.fieldProxy
        delete entity?.relationships
        return entity;
      })
    contentConnectorCopy = groupQuotes(contentConnectorCopy);
    return contentConnectorCopy;
  }
  return contentConnector;
};

export const getContentConnectorAnchors = (contentConnectors) => {
  const anchors = [];
  contentConnectors.forEach((data) => {
    if (data?.title && data?.fieldMakeAnchor && data?.fieldProxy) {
      anchors.push({
        title: data.title,
        id: data.title.replace(/\W/g, '_').toLowerCase(),
      });
    }
  });
  return anchors;
}

export default getContentConnectorFields;
