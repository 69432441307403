import React, { useEffect, useState } from "react";
import { Loader } from "./loader";
export const PricingCalc = ({pricing}) => {
  const [load, setLoad] = useState(false);
  useEffect(() => {
    window.addEventListener("message",function(t){
      var e="https://www.paypalobjects.com/api/checkout.4.0.141.js";
      if(!document.querySelector('script[src="'+e+'"]')&&t.data&&"LOAD_PAYPAL"===t.data.action){
      var a=document.createElement("script");a.src=e,a.async=!0,a.dataset={paypalCheckout:!0,noBridge:!0,state:"ppxo_meta",env:"production"},document.body.append(a)
      }if(t.data&&"CALCONIC_UPDATE_HEIGHT"===t.data.action){
      var s=document.querySelector('iframe[src="https://app.calconic.com/api/embed/calculator/'+t.data.payload.id+'"]');
      if(s){s.height=t.data.payload.height}}
    })
  },[load])
  useEffect(() => {
    window.setTimeout(function () { 
      setLoad(true)
    }, 3000);
  })
  return(
    <div className="pricing-calculator iframe-pos">
      {load ? <iframe className="pricing-iframe" src={pricing?.pricingUrl} sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups-to-escape-sandbox allow-popups" title="Calconic_ Calculator" name="Calconic_ Calculator" height="471px" scrolling="no" ></iframe>
    : <Loader />  
    }
    </div>
  )
}
