import React from "react";
import Helmet from "react-helmet";

/**
 * @param {Object} entity - entity conisiting of value as fieldSchemaMarkup | fieldVideoSchemaMarkup
*/

const SchemaMarkupWrapper = ({ entity }) => {
  try {
    return (
      <Helmet>
        <script type={`application/ld+json`}>
          {entity?.fieldSchemaMarkup || entity?.fieldVideoSchemaMarkup}
        </script>
      </Helmet>
    )
  } catch (err) {
    console.log(err)
    return null
  }
}

/**
 * @param {Object | Array of Objects} data - Data consisting of schema markup json value
*/
export const schemaMarkupRendering = (data) => {
  return (
    Array.isArray(data) ? data.map((entity, index) => {
      return (
        (entity?.fieldSchemaMarkup || entity?.fieldVideoSchemaMarkup) ?
          <SchemaMarkupWrapper entity={entity} /> :
          null
      )
    }) :
    <>{(data?.fieldSchemaMarkup || data?.fieldVideoSchemaMarkup) ? <SchemaMarkupWrapper entity={data} />: null}</>
      
  )
}