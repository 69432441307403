import React, { Fragment, useState } from "react";
import { get, isEmpty } from "src/utils";
import extractMarketoIdAndSerial from "src/components/helpers/extractMarketoIdAndSerial";
import useMarketoForm from "src/components/helpers/useMarketoForm";
import getAuthorName from "../../../../helpers/getAuthorName";
import getResourceTagsList from "../../../../helpers/getResourceTagsList";
// import Hero from "src/components/hero/ResourcesHero";
import { ResourcesHero as Hero} from "src/components/templateComponents";
import cn from "classnames";
import timeFormatter from "../../../../helpers/timeFormatter";
import { getTimeZoneAbbreviation } from "src/utils";
import loadable from '@loadable/component';
import useAddParamInHtmlLink from "src/components/helpers/useAddParamInHtmlLink";
import { ShareFollowBox } from "src/components/commonComponents";
const EventStickyDiv = loadable(() => import('src/components/commonComponents'), { resolveComponent: (components) => components.EventStickyDiv })
const StickyBox = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.StickyBox
});
const Form = loadable(() => import('src/components/resources/ResourceForm'))
const IndividualProfileCard = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.IndividualProfileCard
});
const LeadershipProfileCard = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.LeadershipProfileCard
});
const getImageObject = require("src/../helpers/getImageObject")

export const SingleWebinarPost = ({ data }) => {
  const { locale } = data;
  let timeZone = 'CST'
  const authorName = getAuthorName(data?.relationships);
  const resourceTags = getResourceTagsList([data.industryIds, data.solutionIds, data.productIds])
  const formEntity = data?.relationships?.fieldMarketoFormNode

  let id = null;
  let serial = null;
  const fieldMarketoFormEmbedCode = get(
    formEntity,
    "fieldEmbedCode"
  );
  const fieldFormHeader = get(formEntity, "fieldFormHeader")

  if (
    fieldMarketoFormEmbedCode &&
    fieldMarketoFormEmbedCode.includes("marketo")
  ) {
    id = extractMarketoIdAndSerial(fieldMarketoFormEmbedCode).id;
    serial = extractMarketoIdAndSerial(fieldMarketoFormEmbedCode).serial;
  }
  const gaLabel =
    data.fieldRegistrationType === "scheduled"
      ? "Webinar Registration"
      : "Content";
  const [isFormLoaded, setFormLoaded] = useState(false)
  const formCallback = () => {
    setFormLoaded(true)
  }
  const followUpUrl = data?.relationships?.fieldThankyouPageReference?.fieldUrl
  const { formTag } = useMarketoForm(serial, id, formCallback, "", gaLabel, true, followUpUrl);

  let leadershipData = [];

  const person = {
    id: "",
    name: "",
    title: "",
    image: "",
    altTag: "",
    sortOrder: 50,
    biography: <Fragment></Fragment>,
  };
  useAddParamInHtmlLink()
  data.relationships?.speakers.forEach((item) => {
    if (item) {
      let personObject = Object.create(person)
      personObject.id = item.nid
      personObject.name = item.title
      personObject.title = item.position
      personObject.company = item.company
      personObject.nid = item.nid
      personObject.biography = item.body?.value
      personObject.image = getImageObject(item?.relationships?.speakerHeadshot, item.speakerHeadshot);
      leadershipData.push(personObject)
    }
  })

  let startDate = null;
  let startTime = null;
  let startDateFormated = null;
  let startDateTime = null;
  let endDate = null;
  let endDateFormated = null;
  let endTime = null;
  let endDateTime = null;
  if (!isEmpty(data?.startEndTimes?.startDate)) {
    startDate = new Date(data?.startEndTimes?.startDate.replace(/-/g, '/'));
    let options = data.startEndTimes?.endDate ? data.startEndTimes?.endDate !== data.startEndTimes?.startDate ? {
      month: "long",
      day: "numeric",
    } : {
      month: "long",
      day: "numeric", year: "numeric"
    } : {
      month: "long",
      day: "numeric", year: "numeric"
    }
    startDateFormated = startDate.toLocaleString("en-US", options);
  }
  if (data?.fieldStartEndTimes) {
    let startEnd = timeFormatter(data?.fieldStartEndTimes)
    startDateTime = startEnd.startTime
    endDateTime = startEnd.endTime
  }

  if (!isEmpty(data?.startEndTimes?.endDate)) {
    if (data?.startEndTimes?.endDate !== data?.startEndTimes?.startDate) {
      endDate = new Date(data?.startEndTimes?.endDate.replace(/-/g, '/'));
      endDateFormated = endDate.toLocaleString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
      endDateTime = null;
    }

  }
  if (!isEmpty(data?.fieldStartEndTimes?.value) && !isEmpty(data?.startEndTimes?.value) && !isEmpty(data?.fieldTimeZone)) {
    let value = data?.startEndTimes?.value
    timeZone = getTimeZoneAbbreviation(value, data.fieldTimeZone);

  }

  const [selectedCard, setSelectedCard] = useState(null);

  const onSelectCard = (name) => {
    const filteredCard = leadershipData.filter((card) => card.name === name);
    setSelectedCard(filteredCard[0]);
  };

  let heroImage = get(data, "heroImage");

  let webinarFormLogo = getImageObject(data?.relationships?.fieldWebinarFormLogo, data?.fieldWebinarFormLogo);
  const checkForOnDemand = (!data.fieldWebinarType || data.fieldWebinarType == "on-demand") ? true : false
  return (
    <section className="md:tw-pt-[24px] resources-page webinar-page full-header-bg content-visibility-auto-mobile">
      <Hero
        type={"banner"}
        contentType={(checkForOnDemand) ? "Webinar On-Demand" : "Webinar"}
        heroImage={heroImage}
        heading={data.title}
        subTitle={data.fieldSubtitle}
        hashtags={resourceTags}
        body={data.fieldResourceDescription}
        checkForOnDemand={checkForOnDemand}
        overViewText={(checkForOnDemand) ? "All Webinars – On-Demand" : "All Webinars"}
        overviewLink={(checkForOnDemand) ? `/resources?content_type=${data.type?.id}` : `/events#webinars`}
        startDateFormated={startDateFormated}
        endDateFormated={endDateFormated}
        startDateTime={startDateTime}
        endDateTime={endDateTime}
        timeZone={timeZone}
        locale={locale}
      />

      <div className="container tw-nt-[0.75rem] lg:tw-mt-0">
        <section className="medium-wrapper tw-p-[0_15px] lg:tw-pl-0 tw-relative tw-mt-0">
          <div className="columns tw-flex tw-flex-col-reverse lg:tw-flex-row">
            <div className="column  tw-mt-6 tw-p-0 lg:tw-p-[12px] is-12-mobile">
              <div>
                {!!authorName && (
                  <h3>
                    By {authorName}
                  </h3>
                )}
                {data.body && (
                  <div
                    className="new-single-resource-content-body dangerously-html-handle"
                    dangerouslySetInnerHTML={{ __html: data.body.value }}
                  ></div>
                )}
                {data.fieldKeyFeaturesBullets &&
                  data.fieldKeyFeaturesBullets.length !== 0 && (
                    <div className="features-bullets">
                      {data.fieldKeyFeaturesTitle && (
                        <h3 className="title title-4 mt-2">{data.fieldKeyFeaturesTitle}</h3>
                      )}
                      {data.fieldKeyFeaturesBullets && (
                        <div className="single-resource-content-body">
                          <ul>
                            {data.fieldKeyFeaturesBullets.map((key) => (
                              <li key={key}><span>{key}</span></li>
                            ))}
                          </ul>
                        </div>
                      )}

                    </div>
                  )}

                {data?.relationships?.speakers && data?.relationships?.speakers.length !== 0 && (
                  <>
                    <div className="resources-speaker" id="arrow-down-focus">
                      <div className="container">
                        <div className="tw-columns">
                          <div className="tw-column">
                            <h3 className="title-4 tw-text-center">
                              Speakers
                            </h3>
                          </div>
                        </div>
                        {selectedCard && (
                          <IndividualProfileCard
                            name={selectedCard.name}
                            title={selectedCard.title}
                            company={selectedCard.company}
                            image={selectedCard.image}
                            altTag={selectedCard.altTag}
                            bio={selectedCard.biography}
                            key={selectedCard.id}
                            setSelectedCard={setSelectedCard}
                          />
                        )}
                        <div className="tw-columns leadership-tiles tw-flex tw-flex-wrap tw-justify-center tw-flex-col lg:tw-flex-row">
                          {leadershipData
                            .filter(({ name }) => name !== selectedCard?.name)
                            .map((card, i) => (
                              <LeadershipProfileCard
                                nid={card.nid}
                                key={`leadership__profile__${card.id}`}
                                name={card.name}
                                title={card.title}
                                company={card.company}
                                bio={card.biography}
                                image={card.image}
                                onSelectCard={onSelectCard}
                                tabIndex={i}
                              />
                            ))}
                        </div>
                      </div>
                    </div>
                  </>
                )}

              </div>
              <div className="tw-mt-[40px]">
                <ShareFollowBox />
              </div>
            </div>

            <div className="tw-column is-12-mobile lg:tw-max-w-[475px] lg:tw-mb-[-424px] tw-relative tw-right-0 lg:tw-top-[-424px]">
              <div className="lg:tw-mt-[135px] tw-w-full tw-bg-white tw-relative event-detail-sticky">
                {(!formTag && !webinarFormLogo?.gatsbyImage && !data?.fieldHeroCtaUrl) ? null :
                  <StickyBox
                    scrollContainerClass={"resource-form"}
                  >
                    <div className={cn({ "right-box": formTag ? isFormLoaded : true })}>
                      <div className="border-box-top"></div>
                      <div className={cn({ "righ-min-scroll": true, "set-height": !!formTag, "tw-min-h-[100px]" : true})}>
                        {formTag ?
                          <Form
                            formTag={formTag}
                            title={fieldFormHeader}
                            isFormLoaded={isFormLoaded}
                          /> :
                          <EventStickyDiv fieldEventLogo={webinarFormLogo} fieldHeroCtaUrl={data?.fieldHeroCtaUrl} />}
                      </div>
                      <div className="border-box-bottom"></div>
                    </div>
                  </StickyBox>}

              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  )
}

// export default SingleWebinarPost;
