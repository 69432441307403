const getAuthorName = ({ fieldAuthor }) => {
  if (Array.isArray(fieldAuthor)) {
    for(let index = 0; index < fieldAuthor.length; index += 1) {
      if (fieldAuthor[index]?.title) {
        return fieldAuthor[index].title;
      }
    }
  }
  return null;
}

export default getAuthorName;
