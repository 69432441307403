import React, { useState, useEffect } from "react";
import Scrollspy from 'react-scrollspy'
import "src/scss/components/_secondaryNavbar.scss";
/**
 * Pages that have sortable "content sections" can be configured to get anchor links.
 * This component renders the "scroll to section" at the top of those pages.
 */
export  const SecondaryNavbar=({ title, anchors,offset=null })=> {
  const [isActive, setActive] = useState(false);
  const [activeNav, setActiveNav] = useState(false);

  const toggleClass = () => {
    setActive(!isActive);
  };

  const  onNavChange = (e) => {
  setActiveNav(anchors?.find(x => x.id === e?.id)?.title)
  };

  const handleScroll = (e) => {
    let el = document.getElementsByClassName('secondary-navbar')
    if (el[0]?.getBoundingClientRect()?.y < 67) {
      el[0]?.classList.add('secondary-navbar-stuck')
    } else {
      el[0]?.classList.remove('secondary-navbar-stuck')
    }
  }

  useEffect(() => {
    if(window.scrollY == 0){
      document.getElementsByClassName('secondary-navbar')[0]?.classList.remove('secondary-navbar-stuck')
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, [])

  return (
    <div className="secondary-navbar secondary-navbar-stuck tw-sticky tw-top-16 tw-z-30">
      <div className="tw-mb-4">
        <div className="tw-hidden lg:tw-block tw-mx-auto tw-my-0 tw-bg-secondary_nav_bg_color sm:tw-max-w-[960px] md:tw-max-w-[960px] lg:tw-max-w-[960px] xl:tw-max-w-[1152px] 0xl:tw-max-w-[1152px] 01xl:tw-max-w-[1353px] 1xl:tw-max-w-[1344px] 2xl:tw-max-w-[1368px]">
          {title && (
            <div className="columns secondary-navbar-title p-0 m-0">
              <div className="column">
                <p className="title is-6">{title}</p>
              </div>
            </div>
          )}

          <Scrollspy offset={offset?offset:-130} items={anchors.map(a => a.id)}
            className="scrollspy tw-flex tw-flex-wrap tw-leading-[57px]"
            currentClassName="isCurrent">
            {anchors.map(({ title, id }) => (
              <li className="tw-flex tw-py-0 tw-px-4" key={id}>
                <div className="secondary-link-container">
                  <a className="tw-text-secondaryNavFont tw-px-1 tw-py-4" href={`#${id}`}>
                    {title}
                  </a>
                </div>
              </li>
            ))}
          </Scrollspy>

        </div>

        <div className="columns-1 lg:tw-hidden">
          <div className="tw-block">
            <div
              className={`dropdown tw-inline-flex tw-relative tw-align-top secondary-navbar-dropdown tw-mt-[5px] lg:tw-mt-0 tw-w-full ${isActive ? `is-active` : ""
                }`}
              onClick={toggleClass}
            >
              <div className="dropdown-trigger tw-w-full">
                <button
                  className="tw-font-Poppins tw-bg-accruent_gray tw-flex tw-justify-between tw-text-secondaryNavMobileFont tw-text-heading_color tw-py-3 tw-pr-5 tw-pl-[15px] tw-border-none tw-w-full tw-leading-4"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu"
                >
                  <span>{activeNav || anchors[anchors.length - 1].title}</span>
                  <span className="icon is-small">
                    <i className="fas fa-angle-down" aria-hidden="true"></i>
                  </span>
                </button>
              </div>
              <div className="dropdown-menu tw-text-secondary_nav_bg_color tw-hidden !tw-p-0 tw-w-full tw-text-center tw-absolute tw-top-full tw-z-20 tw-left-0 tw-min-w-[12rem]" id="dropdown-menu" role="menu">

                <Scrollspy onUpdate={onNavChange} offset={offset?offset:-130} items={anchors.map(a => a.id)}
                  className="scrollspy dropdown-content container tw-bg-secondary_nav_bg_color_dropdown tw-p-0 tw-rounded-[4px]"
                  currentClassName="isCurrent">
                  {anchors.map(({ title, id }) => (
                    <li className="dropdown-item tw-text-left tw-py-3 !tw-px-[15px] tw-leading-[25px] tw-relative tw-block tw-text-heading_color" key={id}>
                      <a className="tw-text-heading_color tw-tracking-normal !tw-text-secondaryNavMobileFont tw-font-Poppins tw-font-semibold tw-border-none" href={`#${id}`}>{title}</a>
                    </li>
                  ))}
                </Scrollspy>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
