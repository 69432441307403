/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { getImageSrcFromGatsbyImg } from "src/utils"
const site_vars = require('../../get_site_data');
const rawLocales = require("../../i18n/locales");
const truncate = (str) => {
  str = str?.replace(/(\r\n|\n|\r)/gm, " ")
  if (str?.length > 267) {
    return str.substring(0, 267) + '...';
  }
  return str;
}

export const SEO = ({
  title,
  description = null,
  pageUrl = "",
  lang,
  meta = [],
  schemaMarkup,
  schemaMarkupVideo,
  OGMarkup,
  alternatives = [],
  heroImage,
  preloads =[],
  preloadedImages = [],
  isPreloadImageOnMobile = true,
  jobPostings = false
}) => {
  let pageMeta = JSON.parse(JSON.stringify(meta));
  pageMeta = pageMeta.filter(({ name }) => name !== "canonical");
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
            title
            description
            author
          }
        }
      }
    `
  );
  const localeName = (!rawLocales?.[lang]?.default && rawLocales?.[lang]?.path) ? ' ' + rawLocales[lang].path.toUpperCase() : ''
  title = title || (site_vars.site_name ? site_vars.site_name + localeName : '');
  const siteUrl = site.siteMetadata.siteUrl
  const canonicalUrl = siteUrl + pageUrl;
  let hasTitle = false;
  let hasDescription = false;
  pageMeta.forEach((pageMetaObj) => {
    if (pageMetaObj.name === "title" && !jobPostings) {
      hasTitle = true;
      title = `${pageMetaObj?.content?.substring(0, pageMetaObj.content.lastIndexOf('|'))}| ${site.siteMetadata.title}${localeName}`;
      pageMetaObj.content = title;
    }
    if (pageMetaObj.name === "description") {
      hasDescription = true;
      description = truncate(pageMetaObj.content);
      pageMetaObj.content = description;
    }
  });
  if (!hasTitle) {
    pageMeta.push({
      name: "title",
      content: title || site.siteMetadata.title + localeName,
    });
  }
  if (!hasDescription) {
    pageMeta.push({
      name: "description",
      content: description || site.siteMetadata.description,
    });
  }


  /**
   * @Open_Graph_Markup
   */
  const withBaseURL = (relative) => site.siteMetadata.siteUrl.concat(relative);
  const rectifiedOGMarkup =
    OGMarkup &&
    OGMarkup.map((item) => {
      if (item.property === "image") {
        return {
          ...item,
          content: withBaseURL(item.localPath),
        };
      } else return item;
    });
  if (OGMarkup) {
    rectifiedOGMarkup.push({
      property: "description",
      content: description || site.siteMetadata.description,
    });
  }

  const imageObj= getImageSrcFromGatsbyImg(heroImage)
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
    >
      {imageObj?.src && 
       <link rel="preload" as="image" media={!isPreloadImageOnMobile?"(min-width: 768px)":""}  href={imageObj.src} imagesrcset={imageObj.srcSet} imagesizes={imageObj.sizes} />
      }
      {preloadedImages?.map((image, i) => {
        if(!image) return null;
        return (
          <link
            rel="preload"
            as="image"
            key={i}
            href={image.src}
            imagesrcset={image.srcSet}
            imagesizes={image.sizes}
            media={!isPreloadImageOnMobile?"(min-width: 768px)":""}
          />
        );
      })}
      {preloads?.map((src) => (
        <link
          rel="preload"
          href={src}
          as="fetch"
          crossOrigin="anonymous"
          key={src}
        />
      ))}
      
      <link rel="canonical" href={canonicalUrl} />
      {alternatives.map(a => (
        <link key={a.hreflang} rel="alternate" href={`${siteUrl}${a.href}`} hreflang={a.hreflang} />
      ))}
      {schemaMarkup && (
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      )}
      {schemaMarkupVideo && (
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkupVideo)}
        </script>
      )}
      {
        pageMeta.map(({ name, content }, index) => (
          <meta name={name} content={content} key={index} />
        ))
      }
      {rectifiedOGMarkup &&
        rectifiedOGMarkup.map((og, index) => (
          <meta property={`og:${og.property}`} content={og.content} key={index} />
        ))}
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
};

SEO.propTypes = {
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

