import React, { useState, forwardRef, useImperativeHandle } from "react";
import Select from "react-select";

/**
 * @prop {lead} - This prop return to the father component when selection an item, also when clicking the reset button.
 * It serves as a guide to do more complex work with more information about the dropdown.
 * It can also be seems as a "e.target.value" when dealing with forms, but this Dropdown returns no event.
 * See HeroFilter component to see an example of implementation using all the props.
 * @prop {ref} - Reference passed from parent component so it can call a child method from the parent instance
 * forwardRef receives all the props as a first parameter and the ref as the second, the rest of the
 * component is still the same, normal function react component, it is only encapsulated.
 * To understand more of the pattern see https://stackoverflow.com/a/37950970
 */
export const Dropdown = forwardRef(
  (
    { options, onSelect, placeholder, ariaLabel, className = "", onReset, lead = null },
    ref
  ) => {

    const [innerValue, setInnerValue] = useState(undefined);

    const handleOnChange = (event, { action }) => {
      if (action === "select-option") {
        if (onSelect) {
          onSelect(event.value, lead);
        }
      } else if (action === "clear") {
        reset();
      }
    };

    // Every function inside the object in this hook will be possible to be called from the parent component
    // as example refName.current.reset()
    useImperativeHandle(ref, () => {
      return {
        reset: () => reset(),
        // setValue: (value) => setInnerValue(value),
        setValue: (value) => {
          const option = options?.filter((opt) => opt.value === value);
          setInnerValue(option);
        },
      };
    });

    const reset = () => {
      if (onReset) {
        onReset(lead);
      }
    };
    return (
      <div className="hoc-dropdown-wrapper tw-mb-[10px] tw-relative tw-w-full ">
        <Select
          options={options}
          placeholder={placeholder}
          aria-label={ariaLabel}
          className={`hoc-dropdown tw-w-full ${className}`}
          classNamePrefix="accruent-select"
          isClearable
          isSearchable
          onChange={(e, action) => handleOnChange(e, action)}
          value={innerValue ? innerValue : ""}
        />
      </div>
    );
  }
);

// export default Dropdown;
