import React, { useState } from "react";
import { navigate, useLocation } from "@reach/router";
import { SearchField } from "../commonComponents/searchField";
import getPathWithQueryParam from "src/../helpers/getPathWithQueryParam";
import getCookie from '../../../helpers/getCookieParser';

const NavbarSearch = ({ locale }) => {
  const [input, setInput] = useState("");
  const location = useLocation();
  const cookies = getCookie();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (input) {
      navigate(getPathWithQueryParam(location,`${locale == "en" ? "/" : "/uk/"}search?query=${input}`, cookies));
    }
  };

  const handleChange = (e) => {
    setInput(e.target.value);
  };

  return (
    <div className="navbar-search">
      <form role="search" onSubmit={handleSubmit}>
        <div className="control is-full-width">
          <SearchField onChange={handleChange} value={input} autofocus={true} />
        </div>
      </form>
    </div>
  );
};

export default NavbarSearch;
