import React, { useContext, useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "@reach/router";
import ResourcesContext from "src/components/helpers/ResourcesMainPageContext";
// import Alertbar from "../Layout/Alertbar";
import NavbarMobile from "src/components/navigation/NavbarMobile";
import useToggleNavbarActiveState from "src/components/helpers/useToggleNavbarActiveState";
import NavbarSearch from "src/components/Search/NavbarSearch";
import site_vars from "src/get_site_data";
import findSiteType from "src/components/helpers/findSiteType";
import useMountingForIcon from "src/hooks/useMountingForIcon";
import { LocalizedLink } from "src/components/commonComponents";
import * as containerStyles from "./_navbar.module.scss";

const NavWithCta = ({ locale = 'en', navbarProps }) => {
  const {
    navData,
    setFilter,
    filters,
  } = useContext(ResourcesContext);
  const location = useLocation();

  const [currentNavItem, setCurrentNavItem] = useState(null);
  const [navigationLeft, setNavigationLeft] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [activeClass, setActiveClass] = useState({ menu: "" });
  const [activeClassDropdown, setActiveClassDropdown] = useState({ menu: "" });
  const [openDemoModal, setOpenDemoModal] = useState(false);

  let scrollListener = null;
  const [scrollState, setScrollState] = useState("top");
  const changeScroll = "scroll";
  const isMc = findSiteType('mc')
  const isEms = findSiteType('ems')
  const mounted = useMountingForIcon();
  let pageType = navbarProps?.pageType;
  let landingHeaderCta = navbarProps?.landingHeaderCta;


  useEffect(() => {
    if (window.scrollY !== 0) {
      setScrollState("scrolling");
      document
        .getElementsByClassName("wht-btn")[0]
        .classList.add(changeScroll);
    }
  }, [])

  useEffect(() => {
    scrollListener = document.addEventListener("scroll", (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 90) {
        if (scrollState !== "scrolling") {
          setScrollState("scrolling");
          document
            .getElementsByClassName("wht-btn")[0]
            .classList.add(changeScroll);
        }
      } else {
        if (scrollState !== "top") {
          setScrollState("top");
          document
            .getElementsByClassName("wht-btn")[0]
            .classList.remove(changeScroll);
        }
      }
    });
    return () => {
      document.removeEventListener("scroll", scrollListener);
      setActiveClass({ Products: "" });
    };
  }, [scrollState]);

  useEffect(() => {
    setShowSearch(false);
  }, [location]);

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", function (e) {
      setShowSearch(false);
    });
  }

  const innerCleanFilterWhenClickingResources = (link) => {
    if (link === "Resources" || ((link === "/resources" || link === "/uk/resources") && filters)) {
      const filterKeys = Object.keys(filters);
      const resettedFilters = {};
      filterKeys.forEach((key) => {
        resettedFilters[key] = "";
      });
      setFilter({ ...resettedFilters });
    }
  };

  //Below we are creating the arrays that will hold the left navigation (navbarStart) and the right navigation (navbarEnd)
  //to match Bulma styling. Following the mockup, the logic below is to grab the first five values and add them to the navbarStart
  //and grab the last three values and add them to navbarEnd.
  let navbarStart = isMc
    ? navData.slice(0, 6)
    : navData.slice(0, locale === 'en' ? 5 : 4);
  let navbarEnd = isEms || isMc ? navData.slice(-2) : navData.slice(-3);


  const navbarId = "main-mobile-nav-dropdown";
  const burgerId = "main-navbar-burger";
  useToggleNavbarActiveState(burgerId, true);
  const handleWatchDemoToggle = () => {
    setOpenDemoModal(!openDemoModal);
  };

  return (
    <div className={containerStyles.navbarContainer}>
    <header className="navbar-with-cta navigation" id="navbar-with-cta">
      {/* {!!alertBarData && <Alertbar />} */}
      <nav
        className={`cta-navbar navbar is-hidden-touch upper-nav landing-nav
        ${scrollState === "top" ? "" : "scroll"}
        `}
        role="navigation"
        aria-label="secondary navigation"
        id="navbar-with-cta-nav-mobile"
      >
        
      </nav>
      <nav
        className={`cta-navbar lower-nav ${scrollState === "top" ? "top" : "scroll"
          } navbar`}
        role="navigation"
        aria-label="main navigation"
        id="navbar-with-cta-nav-desktop"
      >
        <div className="container is-fullhd has-position-initial">

            <div className="navbar-brand is-justify-content-space-around landing-nav">
              <LocalizedLink to={locale === 'en' ? '/' : '/uk'} className="navbar-item">
                {/* <LocalizedLink to={'/'} className="navbar-item"> */}
                <img
                  className={`navbar-logo accruent-logo-blue ${!isMc ? "set-auto" : ""}`}
                  src={site_vars.logo}
                  alt="Home"
                  width="144"
                  height="36"
                  decoding = "async"
                />
              </LocalizedLink>
              {landingHeaderCta && <div className={`navbar-item is-hidden-desktop `}>
                <button
                  className={`button is-carmine has-carmine-border`}
                >
                  <LocalizedLink
                    to={landingHeaderCta?.url}
                  >
                    <span
                      className={`navbar-link is-arrowless has-text-weight-semibold testing has-text-white`}
                    >
                      {landingHeaderCta?.title}
                    </span>
                  </LocalizedLink>
                </button>
              </div>}
            </div>
          {showSearch && (
            <div className="navbar-mobile-search is-hidden-desktop">
              <NavbarSearch locale={locale} />
            </div>
          )}

          <div className="navbar-menu">
            
            {!!navbarEnd && (
              //There are three items in the navbar end section of the navbar; search, contact us, watch a demo.
              //Drupal is sending us these nav items in the correct order therefore we are using the index of the items
              //in the ternary conditional to style them individually. To make adjustment please be aware that the order
              //of the buttons [search,contact us, watch demo]
              <div className="cta-end-navbar"
                style={{
                  justifyContent: 'flex-end',
                  marginLeft: 'auto'
                }}>
                {pageType && pageType === "landing_page" ?
                  <Fragment>
                    <div className="navbar-item" style={{ padding: '0.5rem 0.75rem' }}>
                      {landingHeaderCta && <button
                        className={`button wht-btn landing-nav is-carmine has-carmine-border `}
                      >
                        <LocalizedLink
                          to={landingHeaderCta?.url}
                        >
                          <span
                            className={`navbar-link  is-arrowless has-text-weight-semibold testing has-text-white`}
                          >
                            {landingHeaderCta?.title}
                          </span>
                        </LocalizedLink>
                      </button>}
                      <div className="wht-btn landing-navbar"></div>
                    </div>
                  </Fragment>
                  :
                  navbarEnd.map(({ name, link }, index) =>
                    !isMc && !isEms && index === 0 ? (
                      <Fragment key={`${name}_${index}`}>
                        <div className="navbar-item" style={{ padding: '0.5rem 0.75rem' }}>
                          <button
                            onClick={() => setShowSearch(!showSearch)}
                            className="button is-text navbar-search-button"
                            aria-label="Search"
                            aria-expanded={showSearch}
                          >
                            {mounted && <span className="icon">
                            
                            {/* <svg class="svg-inline--fa fa-search" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path></svg> */}
                            </span>}
                          </button>
                        </div>
                        {showSearch && (
                          <div className="is-hidden-touch desktop-search-component">
                            <NavbarSearch locale={locale} />
                          </div>
                        )}
                      </Fragment>
                    ) : (((isEms || isMc) && index === 1)) ? (
                      <div className="navbar-item" key={name} style={{ padding: '0.5rem 0.75rem' }}>
                        <button
                          className={`button is-carmine has-carmine-border`}
                        >
                          <LocalizedLink
                            to={link}
                          >
                            <span
                              className={`navbar-link is-arrowless has-text-weight-semibold testing has-text-white`}
                            >
                              {isMc ? (
                                "Free Trial"
                              ) : "Watch Demo"}
                            </span>
                          </LocalizedLink>
                        </button>
                      </div>
                    ) : index == 2 ? (
                      <div className="navbar-item" key={name} style={{ padding: '0.5rem 0.75rem' }}>
                        <button
                          onClick={() => {
                            setOpenDemoModal(!openDemoModal);
                          }}
                          className={`button is-carmine has-carmine-border`}
                        >
                          <span
                            className={`navbar-link is-arrowless has-text-weight-semibold testing has-text-white`}
                          >
                            {"Watch Demo"}
                          </span>
                        </button>
                      </div>
                    ) : (
                      <Fragment key={`${name}_${index}`}>
                        {isEms && index === 0 && (
                          <Fragment>
                            <div className="navbar-item" style={{ padding: '0.5rem 0.75rem' }}>
                              <button
                                onClick={() => setShowSearch(!showSearch)}
                                className="button is-text navbar-search-button"
                                aria-label="Search"
                                aria-expanded={showSearch}
                              >
                                <span className="icon">
                                {/* <svg class="svg-inline--fa fa-search" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path></svg> */}
                                </span>
                              </button>
                            </div>
                            {showSearch && (
                              <div className="is-hidden-touch desktop-search-component">
                                <NavbarSearch locale={locale} />
                              </div>
                            )}
                          </Fragment>
                        )}
                        <div className="navbar-item" key={name} style={{ padding: '0.5rem 0.75rem' }}>
                          <button
                            className={`button wht-btn is-outlined navbar-contact-button`}
                          >
                            <LocalizedLink
                              to={link}
                              className={`navbar-link is-arrowless has-text-weight-semibold testing navbar-contact-button`}
                            >
                              {name}
                            </LocalizedLink>
                          </button>
                        </div>
                      </Fragment>

                    )
                  )}
              </div>
            )}
          </div>
          <div id={navbarId} className="navbar-menu is-hidden-desktop">
            <ResourcesContext.Consumer>
              {({ navData }) => (
                <NavbarMobile
                  currentNavItem={
                    currentNavItem ||
                    navData.filter(
                      ({ name }) =>
                        name !== "Search" &&
                        name !== "Request Info" &&
                        name !== "Request Demo" &&
                        name !== "Get Started" &&
                        name !== "Watch Demo" &&
                        name !== "Free Trial"
                    )
                  }
                  navCtaButtons={navData.filter(
                    ({ name }) =>
                      name === "Request Info" || name === "Request Demo" || name === "Watch Demo" || name === "Free Trial" || name === "Get Started"
                  )}
                  setCurrentNavItem={setCurrentNavItem}
                  navigationLeft={navigationLeft}
                  setNavigationLeft={setNavigationLeft}
                  handleWatchDemoToggle={handleWatchDemoToggle}
                  locale={locale}
                  innerCleanFilterWhenClickingResources={innerCleanFilterWhenClickingResources}
                />
              )}
            </ResourcesContext.Consumer>
          </div>
        </div>
      </nav>
    </header>
    </div>
  );
};

export default NavWithCta;
