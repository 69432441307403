import React, { useState } from 'react';
import { TabsBox } from './tabsBox';
import { LocalizedLink } from './localizedLink';
import { PricingCards } from './pricingCards';
import { PricingSectionBenefits } from './pricingSectionBenefits';
import "src/scss/components/_pricingComponent.scss";

/**
 * @param {Objects} fieldPricingSection - entity of Pricing Section : {fieldPricingItem: [Object],body: Object,fieldCtaUrl: Object ...}
 */

export const PricingSection = ({ fieldPricingSection, locale = 'en' }) => {
  const entity = fieldPricingSection;
  const [activeIndex, setActive] = useState(0);
  const [monthly, setMonthly] = useState(true);

  if (entity?.fieldPricingItemStyling === "mc_pricing_features_outside_cards") {
    const backgroundColor = entity?.fieldBackgroundColor ? entity?.fieldBackgroundColor : '#F8F8F8'
    const tabs = entity?.relationships?.fieldPricingItem?.map(item => item?.title)

    const pricingItems =
      entity?.relationships?.fieldPricingItem.map((pricingItem, i) => {
        return (
          <PricingSectionBenefits
            entity={entity}
            pricingItem={pricingItem}
            key={i}
            locale={locale}
          />)
      })
    return (
      <div className='full-width-bg-cover pricing-component-v2 tw-pricing-component-v2 tw-overflow-x-hidden tw-pb-[52px] md:tw-pb-[76px] tw-mt-[68px] md:tw-mt-[76px] tw-mx-[calc(100%-100vw)] tw-px-[calc(100vw-100%)]' style={{ backgroundColor }}>
        <TabsBox
          tabs={tabs}
          children={pricingItems}
          tabClass={"full-width-bg-cover price-section-tab"}
          TabElement={"h2"}
        />
        <div
          className='remarks-text dangerously-html-handle'
          dangerouslySetInnerHTML={{ __html: entity?.body?.value }}
        />
        {entity?.fieldCtaUrl &&
          <div className='line-link-container tw-text-center'>
            <LocalizedLink to={entity?.fieldCtaUrl?.url} className="button line-link tw-bg-accruent_white tw-border-[2px] tw-border-solid tw-border-accruent_sapphire tw-text-accruent_sapphire tw-rounded-xl tw-font-bold tw-py-[11px] tw-px-[23px] tw-mt-[18px] tw-inline-block hover:tw-bg-accruent_sapphire hover:tw-border-accruent_sapphire hover:tw-text-accruent_white" >
              {entity?.fieldCtaUrl?.title}
            </LocalizedLink>
          </div>
        }

      </div>
    )
  }

  return (
    <div className='pricing-component'>
      <div className="tab-box tw-pt-10 tw-mb-[52px] md:tw-mb-0">
        <ul className='tw-w-auto tw-text-center'>
          {entity?.relationships?.fieldPricingItem?.map((tabMap, index) => {
            return (
              <li
                key={index}
                className={activeIndex === index ? 'active' : ""}
              >
                <a
                  key={index}
                  onClick={() => setActive(index)}
                  className={activeIndex === index ? 'active' : ""}
                >
                  <p>{tabMap?.title}</p>
                </a>
              </li>
            )
          })
          }
        </ul>
      </div>
      <div className='background-graphic tw-rounded-[48px] tw-pt-8' style={{ backgroundColor: entity?.fieldBackgroundColor, border: `0px solid ${entity?.fieldBackgroundColor}` }}>
        {!entity?.fieldHideTitle && <h2 className='title-3 header-text has-text-white'>
          {entity?.title}
        </h2>}
        <div className={`${entity?.fieldPricingItem?.[activeIndex]?.entity?.fieldKeyFeaturesBullets?.length % 2 !== 0
          || entity?.fieldPricingItem?.[activeIndex]?.entity?.fieldKeyFeaturesBullets?.length % 3 !== 0
          ? "text-content set-middle" : "text-content"}`}>
          <div
            dangerouslySetInnerHTML={{ __html: entity?.relationships?.fieldPricingItem?.[activeIndex]?.body?.value }}
            className="dangerously-html-handle"
          />
          <ul>
            {entity?.fieldPricingItem?.[activeIndex]?.entity?.fieldKeyFeaturesBullets?.map((bullets, index) => {
              return (
                <li key={index}>{bullets}</li>
              )
            })
            }
          </ul>
          <div className="div-switch">
            <div className="div-switch-group">
              <div
                className={`switch-btn ${monthly ? 'active' : 'btn-pointer'}`}
                onClick={() => setMonthly(true)}
              >
                MONTHLY
              </div>
              <div
                className={`switch-btn ${!monthly ? 'active' : 'btn-pointer'}`}
                onClick={() => setMonthly(false)}
              >
                YEARLY
              </div>
            </div>
          </div>
        </div>
        <PricingCards key={activeIndex} isMobile fieldPricingItemCard={entity?.fieldPricingItem?.[activeIndex]?.entity?.fieldPricingItemCard} isMonthly={monthly} />
      </div>
      <PricingCards key={activeIndex} fieldPricingItemCard={entity?.fieldPricingItem?.[activeIndex]?.entity?.fieldPricingItemCard} isMonthly={monthly} />

      <div
        className='remarks-text dangerously-html-handle'
        dangerouslySetInnerHTML={{ __html: entity?.body?.value }}
      />
      {entity?.fieldCtaUrl &&
        <div className='btn-touch'>
          <LocalizedLink to={entity?.fieldCtaUrl?.url?.path}>
            <button className='in-touch-btn'><p>{entity?.fieldCtaUrl?.title}</p></button>
          </LocalizedLink>
        </div>}
    </div>
  )
}
