import React from "react"
export const ReviewIframeSection = ({fieldReviewsSection}) => {

  return (
    <div className="tw-flex tw-flex-wrap tw-justify-center tw-items-center tw-gap-[20px]">
      {fieldReviewsSection.map((section, index) => {
        return (
          <div key={index} className={`${index === 0 ? "tw-w-full": "tw-w-full"} review-section-img`}>
            {section?.value && 
            <div
                dangerouslySetInnerHTML={{
                    __html: section?.value
                }}
            />
            }
          </div>
        )
      })}
    </div>
  )
}
