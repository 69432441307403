import React from "react";

const Star = ({ size = "18px", gray = true, isWhite }) => (
  <em
    className={`star-count-item ${
      gray
        ? `has-text-grey-lighter`
        : isWhite
        ? "has-text-white"
        : "has-text-warning"
    }`}
  >
    <i className="fa fa-star " aria-hidden="true"></i>
  </em>
);

export const StarCount = ({ count, size, isWhite = false }) => {
  const starNumber = [
    <Star size={size} />,
    <Star size={size} />,
    <Star size={size} />,
    <Star size={size} />,
    <Star size={size} />,
  ];

  for (let i = 0; i <= count - 1; i++) {
    starNumber[i] = <Star gray={false} size={size} isWhite={isWhite} />;
  }

  return (
    <div className="star-count-wrapper">
      {starNumber.map((star, i) => (
        <div className="mr-1 is-inline" key={i}>
          {star}
        </div>
      ))}
    </div>
  );
};

