  /**
 * The function then formats fieldStartEndTimes dates into their corresponding times using the given timezone.
 * returns an object containing the formatted start and end times.
 *
 * @param {Object} fieldStartEndTimes - This object is expected to have properties named startDate and endDate, which represent dates.
 * @param {String} timezone 
 * @returns {Object}
 */
const timeFormatter = (fieldStartEndTimes, timezone = 'America/Chicago') => {
    // Helper function to format time using Intl.DateTimeFormat with given timezone
    const formatTime = (date) => {
      // Options for formatting time
      const options = { hour: '2-digit', minute: 'numeric', hour12: true };

      const timezoneTime = new Intl.DateTimeFormat('en-US', { timeZone: timezone, ...options });

      return timezoneTime.format(date);
    };

    let startTime = ("startDate" in fieldStartEndTimes) ?
      formatTime(new Date(fieldStartEndTimes.startDate)) : "";

    let endTime = ("endDate" in fieldStartEndTimes) ?
      formatTime(new Date(fieldStartEndTimes.endDate)) : "";
  
    // Return the formatted start and end times
    return { startTime, endTime };
  };

export default timeFormatter;