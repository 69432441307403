/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import './src/styles/global.css';
import './src/styles/appLayout.css';
import './src/styles/layout.css';
require("./src/scss/app.scss");
import CustomLayout from "./wrapPageElement";
import { loadableReady } from '@loadable/component'
import { hydrate } from 'react-dom'

//import './i18n';

export const onServiceWorkerUpdateReady = () => {
  if (navigator && navigator.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then(registrations => {
      registrations.forEach(registration => registration.unregister());
    });
  }
};


// Understand more here https://www.gatsbyjs.com/docs/browser-apis/#shouldUpdateScroll
// Scroll position is updated only if returning `true`
export const shouldUpdateScroll = ({
  routerProps: { location },
  prevRouterProps,
}) => {
  // If the pathname from the next page is different with the current one, update scroll position.
  // For example, on `/resources`, if a query param is added (i.e `/resources?industry='coportate'`)
  // the URL is changed, but the pathname doesn't, so do not update scroll (i.e scrolls to top of the page)
  // If coming from `/resource` to `/industry` return true, the page scrolls to top
  if (prevRouterProps) {
    if (location.pathname !== prevRouterProps.location.pathname) {
      return true;
    }
  }
  return false;
};

/* Global window, document */
const scrollTo = (id) => () => {
  let offsetTop = 0
  const header = document.querySelector('.navigation')
  if (header) offsetTop = offsetTop + 70
  const secondaryNav = document.querySelector('.secondary-navbar')
  if (secondaryNav) offsetTop = offsetTop + 70
  const el = document.querySelector(id)
  offsetTop = getOffsetTop(el) - offsetTop;
  if (el) return window.scrollTo(0, offsetTop)
  return false
}

/*
Gatsby onRouteUpdate API
Check for hash in url and scroll to the position accordingly
*/
export const onRouteUpdate = ({ location: { hash } }) => {
  if (hash) {
    window.setTimeout(scrollTo(hash), 50)
  }
}

/* Calculate offsettop based on relative elements in DOM */
const getOffsetTop = element => {
  let offsetTop = 0;
  while(element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  return offsetTop;
}

export const onClientEntry = () => {
  try {
    document.getElementById("__pageLoader").style.display = "flex"
  } catch (e) { }
}

export const onInitialClientRender = () => {
  try {
    document.getElementById("__pageLoader").style.display = "flex"
    setTimeout(function () {
      document.getElementById("__pageLoader").style.display = "none"
    }, 500)
  } catch (e) { }
}

export const replaceHydrateFunction = () => (element, container, callback) => {
  loadableReady(() => {
    hydrate(element, container, callback)
  })
}

export const wrapPageElement = CustomLayout;
