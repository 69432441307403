import React, { useState } from 'react'
import "src/scss/modals/_internationalUsersNoticeModal.scss";
// import "src/scss/modals/_SelectionModal.scss";
import bannerLogo from '../../../images/modal-header.png';
import { Modal } from '../../commonComponents';
export const SelectionModal = ({ title, subTitle, list = [], onSubmit, selectedValues = [], closeModal, onBack, nextBtnTxt="Next", maxSelection=undefined}) => {
  const [selected, setSelected] = useState(selectedValues)
  const [isOpen, setIsOpen] = useState(true)
  const [message, setMessage] = useState('')
  const isSingleSelection = maxSelection == 1;

  const closeModalAction = (isSkip) => {
    if (closeModal) {
      closeModal(isSkip)
    } else {
      setIsOpen(false);
    }
  }


  const onSelectAction = (key) => {
    if (isSingleSelection) {
      setSelected(key);
    } else {
      if (selected?.includes(key)) {
        var index = selected.indexOf(key);
        if (index !== -1) {
          selected.splice(index, 1);
        }
      } else {
        if (maxSelection && selected?.length >= maxSelection) {
          setMessage(`You cannot select more than ${maxSelection} solutions!`);
        } else {
          selected?.push(key);
        }
      }
      if (selected?.length < maxSelection && message) {
        setMessage("");
      }
      setSelected([...selected]);
    }
  }

  const onSubmitAction = () => {
    if (selected || selected?.length) {
      closeModalAction()
      if (onSubmit) {
        onSubmit(selected)
      }
    }

  }

  return (
    <div className="international-users-notice-modal selection-modal">
      {isOpen && (
        <div>
          <Modal isOpen={isOpen} onClose={closeModalAction}>
            <div className="int-box-2 tw-shadow-[0_6px_12px_rgba(0,0,0,0.161)] tw-m-[30px] tw-rounded-2xl tw-bg-white">
              <div className="main-content">
                <div className="main-header pad-3 tw-pt-[17px] tw-pb-[17px] tw-px-6">
                  <img
                    className=""
                    alt="modal-logo"
                    width="131px"
                    height="42px"
                    src={bannerLogo}
                    decoding = "async"
                  />
                </div>
                <div className="notice-title pad-3 tw-pt-0 tw-pb-[17px] tw-px-6">
                  {title && <h3 className="title-5 tw-text-2xl tw-tracking-[0.22px] tw-leading-[27.84px]"> {title}</h3>}
                  {subTitle && <p className="para-title">{subTitle}</p>}
                  {message && <p className="para-message">{message}</p>}
                </div>
                {isSingleSelection ? (
                  <div className="yes-no-btns pad-3 tw-pt-0 tw-pb-[17px] tw-px-6">
                    {list?.map((item, i) => {
                      return (
                        <button
                          onClick={() => onSelectAction(item?.key)}
                          className={`button tw-font-Poppins tw-text-accruent_sapphire tw-font-semibold tw-text-base tw-leading-4 tracking-[1.42px]  tw-block tw-w-full  ${
                            item?.key == selected ? "is-solid !tw-text-white !tw-font-bold !tw-px-[auto] !tw-py-[15px] !tw-rounded-xl !tw-border-2 !tw-border-solid !tw-border-accruent_sapphire !tw-bg-accruent_sapphire" : "is-light !tw-text-accruent_sapphire !tw-border-2 !tw-border-solid !tw-border-accruent_sapphire !tw-bg-white !tw-rounded-xl"
                          }`}
                          key={i}
                        >
                          {item.label}
                        </button>
                      );
                    })}
                  </div>
                ) : (
                  <div className="list-detail tw-mt-[15px] tw-pl-6 tw-pr-[15px] tw-pt-8 tw-pb-[37px] tw-bg-accruent_lightgray">
                    <ul className="list tw-flex tw-flex-row tw-flex-wrap tw-max-h-[30vh] tw-overflow-auto">
                      {list?.map((item, i) => {
                        return (
                          <li
                            onClick={() => onSelectAction(item?.key)}
                            className={`link-text list-item hover:tw-bg-tab-hover-color hover:!tw-text-white !tw-text-tab-hover-color tw-cursor-pointer tw-text-base tw-font-bold tw-tracking-[0] tw-text-left tw-w-fit tw-mr-2 tw-mb-2 tw-px-6 tw-py-3 tw-rounded-[34px] tw-border-2 tw-border-solid tw-border-tab-hover-color ${
                              selected?.includes(item?.key) ? "active tw-bg-tab-hover-color !tw-border-white !tw-text-white" : ""
                            }`}
                            key={item.key}
                          >
                            {item.label}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                )}
                <div
                  className={`int-footer tw-items-center tw-flex tw-justify-end tw-pt-[22px] tw-pb-6 tw-px-6 ${
                    !!onBack ? "is-justify-content-space-between" : ""
                  }`}
                >
                  {!!onBack && (
                    <span onClick={onBack} className="link-text">
                      {" "}
                      {"< Back"}
                    </span>
                  )}
                  <div className="btns-grbs">
                    <button
                      className="common-btn close-btn tw-text-accruent_sapphire tw-mr-2 tw-border-2 tw-border-solid tw-border-accruent_sapphire tw-bg-transparent tw-cursor-pointer tw-text-sm tw-font-bold tw-tracking-[0] tw-text-center tw-p-3.5 tw-rounded-xl tw-font-Poppins"
                      onClick={() => closeModalAction(true)}
                    >
                      skip
                    </button>
                    <button
                      className={`common-btn confirm-btn tw-cursor-pointer tw-text-sm tw-font-bold tw-tracking-[0] tw-text-center tw-p-3.5 tw-rounded-xl tw-font-Poppins tw-bg-secondary_nav_bg_color_dropdown tw-text-white ${
                        (selected.length || selected) ? "active !tw-bg-accruent_sapphire" : ""
                      }`}
                      onClick={onSubmitAction}
                    >
                      {nextBtnTxt}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );

}

