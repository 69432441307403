import React, { Fragment } from "react";
import { AccruentImg } from "./accruentImg";
export const LeadershipProfileCard = ({
  nid,
  name,
  title,
  company = null,
  bio = null,
  image,
  onSelectCard,
  tabIndex,
  aboutUs = false
}) => {
  let webinarCardClass = aboutUs ? 'is-4-desktop' : 'is-6-desktop';
  if (company) {
    webinarCardClass = 'is-6-desktop'
  }

  let leadershipCardStructure = (
    <div className="tw-webinar-profile-card tw-text-heading_color tw-block tw-rounded tw-p-5 tw-bg-accruent_white tw-border tw-border-solid tw-border-accruent_gray tw-shadow-[0px_10px_20px_rgba(31,32,65,0.05)]">
      <AccruentImg
        file={image}
        className="tw-mb-4 tw-rounded tw-relative tw-overflow-hidden"
      />
      <div aria-hidden="true"></div>
      <div aria-hidden="true" data-placeholder-image=""></div>
      <div className="resource-leadership-name tw-text-heading_color tw-font-bold">{name}</div>
      <div className={bio ? "leadership-job-title tw-border-b-[1px] tw-border-accruent_gray tw-pb-5 tw-mb-3.5" : "resource-leadership-title border-b tw-border-accruent_gray tw-pb-5 tw-mb-3.5"}>{title}{company ? ', ' + company : ''}</div>
      {
        bio && (
          <div className="view-bio tw-font-black tw-flex tw-align-baseline tw-justify-between">
            <span>View Bio</span>
            <span>
              <i
                className="fa fa-long-arrow-alt-right tw-text-accruent_sapphire tw-overflow-visible tw-box-content"
                aria-hidden="true"
              ></i>
            </span>
          </div>
        )
      }
    </div>
  )
  if (bio) {
    leadershipCardStructure = (
      <a href="#leadership-bio">
        {leadershipCardStructure}
      </a>
    )
  }
  return (
    <Fragment>
      <div
        className={`leadershipProfileItem column is-4-tablet ${webinarCardClass} is-12-mobile`} component="LeadershipProfileCard" nid={nid}
        onClick={bio ? () => onSelectCard(name) : undefined}
        onKeyDown={() => onSelectCard(name)}
        tabIndex={tabIndex}
        role="button"
      >
        {leadershipCardStructure}
      </div>
    </Fragment>
  );
};