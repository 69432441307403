import React from "react";
import { LocalizedLink } from "../commonComponents";
import { GlobalDropDown } from "../commonComponents";
import Userlogo from "../../../static/user-solid.svg";

export const TopNavbar = ({
  eyebrowNavData,
  translation,
  isAccruent,
  locale,
}) => {
  return (
    <div className={`container is-fullhd top-nav-bar`}>
      <div className="navbar-menu">
        <div className="navbar-end top-navbar">
          <a className="navbar-item skip-link" href="#main-site-content">
            Skip to Content
          </a>
          {eyebrowNavData?.map(({ name, link }, index) => (
            <LocalizedLink
              key={`${name}_${index}`}
              to={link}
              className="navbar-item"
              style={{
                padding: "0 12px",
                margin: "0px",
              }}
            >
              {name.toLowerCase() === "login" && (
                <img src={Userlogo} alt="user logo"  decoding = "async"/>
              )}
              {translation(name)}
            </LocalizedLink>
          ))}
          {isAccruent && (
            <span className="navbar-item">
              <GlobalDropDown locale={locale} />
            </span>
          )}
        </div>
      </div>
    </div>
  );
};
