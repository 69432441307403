import React, { Fragment } from "react";
import navbarSearch from "../../../static/navbar-search.svg";
export const SearchField = ({ onChange, value, autofocus, placeholder="Type here...", searchBarClass=null, searchPadding=null }) => {

  return (
    <Fragment>
      <div className="tw-pointer-events-auto tw-border-0 tw-flex tw-justify-start">
        <div className="tw-grow tw-shrink tw-box-border tw-clear-both tw-text-base tw-relative tw-align-inherit">
          <input
            className={`tw-text-2xl tw-font-Poppins tw-max-w-full tw-w-full tw-bg-accruent_white tw-text-accruent_pencil_black tw-items-center tw-border-1 tw-border-transparent tw-inline-flex tw-h-[3.75rem] tw-rounded-l-2xl tw-px-5 tw-relative focus:tw-outline-[rgba(59,130,246,.5)] tw-z-[1] tw-py-[calc(0.5em-1px)] tw-px-[calc(0.75em-1px)] ${searchBarClass}`}
            type="search"
            onChange={onChange}
            value={value}
            aria-label="Search"
            placeholder={placeholder}
            minLength="3"
            autoFocus={autofocus}
          />
        </div>

        <div className="tw-box-border tw-clear-both tw-text-base tw-relative tw-align-inherit">
          <button className={`tw-justify-center tw-px-6 tw-items-center tw-border-1 tw-border-transparent tw-shadow-none tw-inline-flex tw-h-full tw-bg-accruent_gray tw-rounded-l-none !tw-rounded-r-2xl tw-cursor-pointer tw-text-btnFont tw-font-Poppins tw-leading-4 tw-tracking-[1.42px] tw-font-semibold tw-text-accruent_sapphire ${searchPadding}`}>
            <span className="lg:tw-flex tw-text-2xl tw-text-accruent_black_input tw-leading-9 tw-hidden tw-items-center">
              Search
              {/* <span className="icon">
                <i className="fa fa-angle-right"></i>
              </span> */}
            </span>
            <span className="is-hidden-desktop">
              <span className="icon tw-text-accruent_black_input">
                <img src={navbarSearch} className="svg-inline--fa fa-search tw-block tw-w-6 tw-h-4"/>
                {/* <svg class="svg-inline--fa fa-search" data-prefix="fas" data-icon="search" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path></svg> */}
              </span>
            </span>
          </button>
        </div>
      </div>
    </Fragment>
  );
};