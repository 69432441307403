const getAlertbarData = (entities, dismissedEntityIds) => {
  const alertBars = [];
  entities?.forEach(({ entityTranslations }) => {
    const now = new Date();
    const {
      entityId,
      fieldDatesActive,
      fieldAlertBody,
      fieldAlertBarText: title,
    } = entityTranslations[0];
    // Note: firefox doesn't allow '-' in date string, must replace with '/'
    const startDate = new Date(fieldDatesActive.startDate.replace(/-/g,'/'));
    const endDate = new Date(fieldDatesActive.endDate.replace(/-/g,'/'));

    if (
      !dismissedEntityIds.includes(entityId) &&
      startDate <= now &&
      now <= endDate
    ) {
      alertBars.push({
        entityId,
        title,
        text: fieldAlertBody?.processed,
      });
    }
  });
  const length = alertBars.length;
  if (!length) return null;
  return alertBars[Math.floor(Math.random() * length)];
};

export default getAlertbarData;
