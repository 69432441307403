import React, {useEffect, useMemo} from "react";
import { get } from "src/utils";
import getAuthorName from "../../../../helpers/getAuthorName";
import getResourceTagsList from "../../../../helpers/getResourceTagsList";
import { ResourcesHero as Hero} from "src/components/templateComponents";
import { getResourceDate } from "../../../../helpers/dateUtilities";
import BackendResourcesInternalLink from "../../helpers/BackendResourcesInternalLink";
import { useLocation } from '@reach/router';
import getContentConnectorFields from "src/components/helpers/getContentConnectorFields";
import { Eyebrow } from "src/components/commonComponents";
import loadable from '@loadable/component';
import useAddParamInHtmlLink from "src/components/helpers/useAddParamInHtmlLink";

const ContentConnector = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.ContentConnector
 });
// const LocalizedLink = loadable(() => import('src/components/LocalizedLink'))
const LocalizedLink = loadable(() => import("src/components/commonComponents"), {
  resolveComponent: (components) => components.LocalizedLink
});
// const SideBar = loadable(() => import('src/components/templateComponents'));
const SideBar = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.SideBar
});
const StickyBox = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.StickyBox
});
// const Picture = loadable(() => import('src/components/Picture'))
const Picture = loadable(() => import("src/components/commonComponents"), {
  resolveComponent: (components) => components.Picture
});
// const PopupVideo = loadable(() => import('src/components/popupVideo/PopupVideo'))
const PopupVideo = loadable(() => import("src/components/commonComponents"), {
  resolveComponent: (components) => components.PopupVideo
});


export const SingleArticlePost = ({ data }) => {
  const { locale } = data;
  const authorName = getAuthorName(data?.relationships);
  const resourceTags = getResourceTagsList([data.industryIds, data.solutionIds, data.productIds])
  let heroImage = get(data, "heroImage");
  const heroVideoUrl = get(data, "relationships.fieldHeroVideo.fieldMediaVideoEmbedField");
  const heroVideoThumb = get(data, "relationships.fieldHeroVideo.relationships.thumbnail.url");
  const location = useLocation();

  let contentConnectors = [];
  if (Array.isArray(data?.relationships?.fieldContentSections)) {
    contentConnectors = getContentConnectorFields(
      data?.relationships?.fieldContentSections,
      "basic_page",
    );
  }

  useEffect(() => {
    BackendResourcesInternalLink("new-single-resource-content-body", location);
  }, [])
  useAddParamInHtmlLink()
  return (
    <section className="section !tw-pt-0 !tw-pb-0 content-visibility-auto-mobile">
      <div className="container article-page">
        <section className="medium-wrapper tw-text-[16px] md:tw-text-[18px] tw-py-0 tw-px-[15px] md:tw-px-0 md:tw-pl-[12px] md:tw-py-0 tw-mt-[32px] md:tw-mt-[72px]">
          <div className="columns md:tw-flex">
            <div className=" tw-w-full lg:tw-w-[calc(100%-376px)] lg:tw-max-w-[calc(100%-376px)] [&]:tw-px-0 [&]:md:tw-pr-3">
              {!!authorName && (
                <h3>
                  By {authorName}
                </h3>
              )}
              {data.body && <div
                className="new-single-resource-content-body dangerously-html-handle"
                dangerouslySetInnerHTML={{ __html: data.body.value?.replace(/href/g, "target='_blank' href") }}
              ></div>
             }
              {contentConnectors.map((data, index) => (
                <div key={index} className="section-container">
                  <ContentConnector
                    key={index}
                    data={data}
                    locale={locale}
                    formFullWidth={true}
                  />
                </div>
              ))}
              <div className="middle-of-content">
               {heroVideoUrl ?
                  <PopupVideo
                    videoUrl={heroVideoUrl}
                    thumbnail={heroVideoThumb}
                    thumbnailClass={`thumbnail-box  tw-min-h-[376px] [&]:tw-p-0 is-12-mobile  thumbnail-hero`}
                    isHero
                  />
                  :
                  (heroImage) ?
                    <div className={`tw-min-h-[376px] [&]:tw-p-0`}>
                      <Picture
                        image={heroImage}
                        className={'tw-h-full tw-w-full tw-min-h-[376px] md:tw-min-h-0 tw-object-cover'}
                      />
                    </div> : null
                }
              </div>
              {data.fieldLinkOriginalArticle?.title && (
                <div className="tw-mb-8">
                  <LocalizedLink
                    className="link-btn tw-font-Roboto"
                    to={data.fieldLinkOriginalArticle.url}
                  >
                    {data.fieldLinkOriginalArticle.title}
                  </LocalizedLink>
                </div>
              )}

            </div>
            <div className="tw-w-full lg:tw-max-w-[376px] lg:tw-p-[12px]">
              <div className="tw-bg-accruent_white tw-w-full md:tw-w-[376px] tw-relative">
                <StickyBox
                  scrollContainerClass={"sidebar-scroll"}
                >
                  <div className="right-box">
                    <SideBar recommendedResources={data.rectifiedRecommended} />
                  </div>
                </StickyBox>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  )
}

// export default SingleArticlePost;
