import React from 'react';
import { StaticImage } from "gatsby-plugin-image";
import { AccruentImg } from './accruentImg';
const imageUrl = "../../../static/placeholders/accruent_default-hero-placeholder.jpg";

export const HeroImage = ({ image, className }) => {
    return !image?.gatsbyImage ? (
        <AccruentImg file={image} className={className} loading="eager"/>
      ) : (
        <StaticImage
          src={imageUrl}
          alt="Default Hero Image"
          className={className}
          aspectRatio={2.0}
          placeholder="blurred"
          layout="constrained"
          decoding = "async"
        />
      );
}

