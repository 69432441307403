
import React from 'react'

const BackendResourcesInternalLink = (wrapperClass,location) => {
  let getElements = document.getElementsByClassName(wrapperClass);
  let currentLoc = location?.pathname;
  if(getElements?.length !==0){
    Array.from(getElements?.[0].querySelectorAll('a')).forEach((el) => {
      el.onclick = function(event){ 
        if(event.target.href.includes('#') && event.target.href.includes(currentLoc)){
          event.preventDefault();
          //navigate(event.target.href)
          window.location = event.target.href
        }
      }
    });
  }
  return (null);
}
export default BackendResourcesInternalLink;