const getImageObject = require("src/../helpers/getImageObject");

const getBannerData = (bannerSection) => {
    const bannerData = bannerSection
    let obj = {}
    if (!bannerData) {
        return obj
    }
    
  let linkText= bannerData?.fieldCtaUrl?.title   
    obj = {
        logo : getImageObject(bannerData?.relationships?.fieldMediaImage, {}, "fieldMediaImage"),
        title: bannerData?.title,
        description: bannerData?.body?.value,
        isHtmlDescription: true,
        linkText: linkText?linkText+'>':'',
        linkUrl: bannerData?.fieldCtaUrl?.url,
        fieldShowReportBanner:bannerData?.fieldShowReportBanner,
        status: bannerData?.status
    }
    return obj;
}

export default getBannerData;