import React from "react";
import { AccruentImg, LocalizedLink } from "src/components/commonComponents";
import { StaticImage } from "gatsby-plugin-image";
import getTeaserCarouselData from "../../../helpers/getTeaserCarouselData";
import trimText from "../../../helpers/trimText";
import getUrlPathBySiteId from 'src/../helpers/getUrlPathBySiteId';
import findSiteType from "../helpers/findSiteType";
const prefixLangcode = require('src/../helpers/prefixLangcode');

const TeaserCarouselItem = (props) => {
  const isMc = findSiteType('mc');
  const isEms = findSiteType('ems');
  const { entity, locale, isHome, homepageBackground, myAccruentModified = false } = props;
  const fieldResourceCarouselItems = entity?.relationships?.fieldResourceCarouselItems
  const versionTeaserLogo = entity?.versionTeaserLogo
  const versionTeaserImage = entity?.versionTeaserImage
  const { fieldBrief, fieldCustomerDesignation, fieldCustomerFeedback, fieldCustomerName, fieldTeaserImage, fieldTeaserLogo, fieldTestimonialType } = getTeaserCarouselData(entity, fieldResourceCarouselItems)
  let isBrief = false
  if (fieldTestimonialType === "tesimonial_type_brief") {
    isBrief = true
  }
  const cs_image_mobile = fieldTeaserImage?.gatsbyImage ? (
    <AccruentImg
      file={fieldTeaserImage}
      className="is-hidden-tablet tw-rounded-[48px_48px_0_0] md:tw-rounded-[48px_0_0_0] tw-h-full tw-relative tw-overflow-hidden tw-inline-block tw-align-top"
    />
  ) :
  versionTeaserImage ? (
      <div className="version-teaser-image">
        <StaticImage src="../../../static/Assets_Version_A/Accruent-Case-Study-Genesco.jpg" alt="image" width={544} height={450}/>
      </div>
  ) : (
      <StaticImage
        src="../../../static/teaser_default.jpg"
        alt={`Default Image`}
        aspectRatio={2.0}
        placeholder="blurred"
        layout="constrained"
        className={'teaser-image tw-w-full'}
      />
  );

  const cs_image = fieldTeaserImage ? (
    <AccruentImg
      file={fieldTeaserImage}
      className="is-hidden-mobile tw-teaser-image-wrapper-child"
      layout="constrained"
    />
  ) : versionTeaserImage ? (
        <div className="version-teaser-image">
          <StaticImage src="../../../static/Assets_Version_A/Accruent-Case-Study-Genesco.jpg" alt="image" width={544} height={450} />
        </div>
    ) : (
      <StaticImage
        src="../../../static/teaser_default.jpg"
        alt={`Default Image`}
        aspectRatio={2.0}
        placeholder="blurred"
        layout="constrained"
        className={'teaser-image tw-w-full'}
      />
  );
  const csLogoAttrs = fieldTeaserLogo?.gatsbyImage?.images?.fallback
  const cs_logo = fieldTeaserLogo?.gatsbyImage ? (
    // <AccruentImg file={fieldTeaserLogo} />
    <img
      {...csLogoAttrs}
      alt={fieldTeaserLogo?.alt}
      loading="eager"
      fetchpriority="high"
      className={'teaser-image tw-w-full'}
      decoding = "async"
    />
  ) : null;
  const overRideCheck = entity?.fieldOverrideItem
  const urlPath = overRideCheck ? (entity?.fieldCtaUrl?.url ? entity?.fieldCtaUrl?.url : prefixLangcode(fieldResourceCarouselItems?.entityUrl?.path, fieldResourceCarouselItems?.locale)) : prefixLangcode(fieldResourceCarouselItems?.entityUrl?.path, fieldResourceCarouselItems?.locale)
  const urlText = entity?.fieldCtaUrl?.title ? entity?.fieldCtaUrl?.title : 'Read Story'
  const UrlPath = getUrlPathBySiteId(fieldResourceCarouselItems?.fieldTargetSite?.targetId, urlPath);

  return (
    <div className="tw-m-0 md:tw-flex tw-justify-center">
      <div className="product-slick-slider-box tw-m-0 tw-p-0 tw-w-full md:tw-w-[85%] tw-block tw-flex-1">
      <div className={`tw-teaser-carousel-item ${isHome ? 'is-home' : ''} ${homepageBackground ? 'white-bg' : ''}`}>
          {!myAccruentModified && 
            (
              <div className="tw-block tw-flex-1 tw-m-0 tw-p-0">
                <div className="tw-teaser-carousel-image-wrapper">
                  {cs_image}
                  {cs_image_mobile}
                </div>
              </div>
            )
          }
          <div className="column is-12-mobile right-side tw-column tw-m-0 !tw-p-0 tw-rounded-tr-none md:tw-rounded-tr-[48px] tw-border-l-[#eeeded] tw-border-l tw-border-solid md:tw-border-0 md:tw-border-l-0">
            <div className="tw-section tw-h-full tw-p-[20px_30px] md:tw-p-[32px_42px_42px_20px] tw-flex tw-flex-col">
              {!isBrief ? <div className={`tw-moff-quotes ${isEms ? 'tw-moff-ems' : ''} ${isMc ? 'tw-moff-mc' : ''}`}></div>
                :
                <div className="tw-items-center tw-flex tw-justify-start tw-mb-2 md:tw-mb-0">
                  <div className="tw-mb-0 tw-mr-[15px]">
                    <div
                      className="tw-block tw-relative tw-bg-transparent tw-w-[125px]"
                      style={{ maxWidth: "125px" }}
                    >
                      {cs_logo ? cs_logo : versionTeaserLogo ?
                          <StaticImage src="../../../static/Assets_Version_A/genesco-logo_4.png" alt="image" /> : null
                    }
                    </div>
                  </div>
                </div>

              }
              <div className="tw-mb-8">
                <div className={`tw-product-content-box ${isBrief ? "brief-content" : ""}`}>
                  {!isBrief ?
                    fieldCustomerFeedback && <p className="tw-text-[#222223] tw-font-Roboto tw-text-[22px] tw-font-black tw-tracking-[0] tw-leading-[29px] !tw-mb-0">{trimText(fieldCustomerFeedback, 220)}</p>
                    :
                    fieldBrief && <p className="tw-text-accruent-heading-color tw-font-Roboto tw-text-[22px] tw-font-semibold tw-tracking-[0] tw-leading-[29px] !tw-mb-0 tw-mt-6">{trimText(fieldBrief, 300)}</p>
                  }
                </div>
                {!isBrief &&
                  <div className="slick-company-logo-name tw-items-center tw-flex tw-justify-start">
                    <div className="block tw-mr-[15px] !tw-mb-0">
                      <div
                        className="image homepage-slider-logo tw-w-[125px] tw-bg-transparent tw-block tw-relative"
                        style={{ maxWidth: "125px" }}
                      >
                        {cs_logo ? cs_logo : versionTeaserLogo ?
                            <StaticImage src="../../../static/Assets_Version_A/genesco-logo_4.png" alt="" className="tw-block tw-h-full tw-w-full"/> : null
                        }
                      </div>
                    </div>
                    <div className="tw-pl-4 md:tw-pl-0">
                      <p className="tw-text-[#222223] tw-font-Roboto tw-text-[16px] md:tw-text-[16px] tw-font-bold tw-tracking-[0] tw-leading-[21px] !tw-mb-0">{fieldCustomerName}</p>
                      <p className="tw-text-accruent-heading-color tw-font-Roboto tw-text-[16px] tw-tracking-[0] tw-leading-[21px] !tw-mb-0">{fieldCustomerDesignation}</p>
                    </div>
                  </div>}
              </div>
              <div className="block">
                <LocalizedLink
                  to={UrlPath}
                  className="tw-bg-transparent tw-text-accruent_sapphire tw-text-[16px] tw-font-bold tw-leading-[16px] tw-tracking-[1.46px] tw-p-[14px_24px] tw-rounded-[12px] tw-border-[2px] tw-border-solid tw-border-accruent_sapphire tw-font-Poppins tw-whitespace-break-spaces tw-h-auto hover:tw-text-white hover:tw-bg-accruent_sapphire hover:tw-border-accruent_sapphire tw-inline-flex tw-items-center tw-relative"
                >
                  {urlText}
                </LocalizedLink>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeaserCarouselItem;
