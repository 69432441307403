import React, { Fragment } from "react";
import { AccruentImg } from "./accruentImg";
export const IndividualProfileCard = ({ name, title, image, bio, company = null, setSelectedCard }) => {
  return (
    <Fragment>
      <div className="box selected-leadership-card tw-my-5 tw-scroll-mt-48" id="leadership-bio">
        <div className="leadership-cursor tw-text-[#757575] lg:tw-right-[26px] lg:tw-top-[66px]  tw-right-[8px] tw-top-[41px] tw-absolute tw-text-[22px] lg:tw-text-lgFont tw-cursor-pointer" onClick={() =>  setSelectedCard(null)}><i className="fal fa-times tw-h-5 tw-overflow-visible tw-box-content"></i></div>
        <div className="columns is-vcentered selected-leadership-content tw-m-3 tw-flex tw-items-center tw-flex-col md:tw-flex-row">
          <div className="column is-4 image-wrapper tw-block tw-p-3 !tw-w-full md:tw-flex-none md:!tw-w-2/6">
            <AccruentImg
              file={image}
              className="has-border-radius-medium tw-rounded tw-relative tw-overflow-hidden tw-mt-4 md:tw-mt-0"
            />
          </div>
          <div className="column is-8 content-wrapper tw-p-3 md:tw-flex-none md:tw-w-4/6 leadership-content">
            <h3 className="title title-4 tw-mb-6 tw-break-normal tw-!text-4xl tw-leading-10">{name}</h3>
            <p className="subtitle !tw-text-heading_color tw-mt-[-1.25rem] tw-mb-6 tw-text-xl tw-font-light tw-leading-5 tw-break-words">{title}{company ? ', ' + company : ''}</p>
            <div dangerouslySetInnerHTML={{ __html: bio }} className="dangerously-html-handle" ></div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};