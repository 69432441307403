import React from 'react';
import {AccruentImg,LocalizedLink} from "src/components/commonComponents";
import getImageObject from '../../../helpers/getImageObject';
const defaultImage = "placeholders/accruent_default-hero-placeholder.jpg";

const VerticalStackItem = (entity) => {
  const imgObj = getImageObject(entity?.entity?.relationships?.fieldMediaImage?.relationships?.fieldImage, entity?.entity?.fieldMediaImage?.fieldImage)
  return(
    <div className="vertical-stack-item-wrapper tw-bg-accruent_white tw-box-shadow-vertical-cards tw-rounded-[20px] tw-m-[0px_16px_0px_0px] tw-min-w-[90%] md:tw-min-w-fit md:tw-m-[16px_10%] tw-min-h-[164px] tw-flex ">
    <div className="tw-flex md:tw-flex-row tw-flex-col">
      <div className="tw-w-[210px] tw-h-[130px] tw-m-[-70px_auto_0] md:tw-m-[auto_0_auto_-90px] stack-item-image tw-p-[12px]">
        <AccruentImg
          file={imgObj || defaultImage}
          className=""
        />
      </div>
      <div className=" is-8 tw-w-full md:tw-w-2/3 is-12-mobile stack-item-content tw-m-0 tw-p-[16px_24px]">
        <div className="stack-item-title tw-text-[22px] tw-leading-[26px] md:tw-text-[24px] md:tw-leading-[28px] tw-tracking-[0.22px] tw-font-Poppins tw-font-bold">{entity?.entity?.title}</div>
        <div className="stack-item-body dangerously-html-handle tw-pt-[8px] tw-text-[16px] md:tw-text-[18px] tw-leading-[22px] md:tw-leading-[26px] tw-font-Roboto tw-tracking-[0px] tw-text-accruent-base-color"
             dangerouslySetInnerHTML={{
               __html: entity?.entity?.body?.value ?? '',
             }}
        />
      </div>
      <div className="columns is-hidden-desktop is-12-mobile stack-item-cta-fill tw-min-h-[75px] tw-p-0 tw-m-0"></div>
      <div className="tw-w-full md:tw-w-[22%] is-3 is-12-mobile stack-item-cta tw-pl-[24px] md:tw-pl-[0px] md:tw-m-auto md:tw-min-w-[160px] tw-absolute md:tw-relative tw-bottom-[16px] md:tw-bottom-0 tw-pb-[12px] md:tw-pb-0">
        <LocalizedLink
          to={entity?.entity?.fieldCtaUrl?.url}
          className="btn tw-p-[12px_24px] tw-bg-accruent_sapphire tw-rounded-[10px] tw-text-[16px] tw-leading-[39px] tw-font-Poppins tw-tracking-[0.14px] tw-text-accruent_white tw-font-bold hover:tw-text-accruent_white"
        >
          {entity?.entity?.fieldCtaUrl?.title}
        </LocalizedLink>
      </div>
    </div>
  </div>
  )
}

export default VerticalStackItem;
