import React, { useState, useEffect } from 'react'

import "src/scss/components/_popupVideo.scss";
import { AccruentImg,Video,Modal,Picture } from '../commonComponents';
import popupvideoIcon from "../../../static/popupvideo-play.svg";
/**
 * @param {string} alt - Alt for thumbnail image.
 * @param {string} videoUrl
 * @param {string} thumbnail - {gatsbyImage:...}
 * @param {string} thumbnailClass
 * @param {boolean} openVideoExt
 * @param {object} children
 * @param {boolean} isForegroundThumbnail
 * @param {boolean} isHero
 * @param {boolean} checkForContentType
 *  @param {boolean} thumbnailImage -  {gatsbyImage:...}
  *  @param {Function} handleClick
 */

export const PopupVideo = (
  {
    videoUrl,
    thumbnail,
    thumbnailClass,
    openVideoExt,
    children,
    isForegroundThumbnail= false,
    isHero = false,
    checkForContentType = null,
    thumbnailImage = null,
    alt = "Video Thumbnail",
    handleClick,
    listenEvent,
    isCatergoryHero = false,
  }
) => {
  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    if(listenEvent){
      listenEvent("open")
    }
    if(handleClick){
      handleClick()
    }else {
      setIsOpen(true);
    }
  };
  const closeModal = () => {
    if(listenEvent){
      listenEvent("close")
    }
    setIsOpen(false);
  };

  useEffect(() => {
    if (openVideoExt === true) {
      openModal();
    }
  }, [openVideoExt]);

  return (
    <div className={`popup-video tw-popup-video tw-min-h-[288px] ${isCatergoryHero ? 'tw-min-h-0' : ""} ${isHero ? 'tw-min-h-[199px] tw-mt-8 md:tw-mt-0' : ''} ${checkForContentType ? "tw-hidden md:tw-block": ""}`}>
      {isOpen &&
      <Modal isOpen={isOpen} onClose={closeModal}>
      <div className='new-video-box' >
        <Video videoUrl={videoUrl} />
      </div>
      </Modal>
      }
      {children ? <div className={"popup-video-handler"} onClick={openModal}>
        {children}
      </div> :
        <div className={thumbnailClass} onClick={openModal}>
          {!isForegroundThumbnail ?
          (!!thumbnail?.gatsbyImage ?
             <Picture image={{alt: alt, ...thumbnail}} className="!tw-rounded-3xl tw-w-auto"/>
            :
            thumbnailImage ? <div className='picture-pos-btn tw-relative tw-h-full'>
              <a href="#" className="play-btn" onClick={(e) => e.preventDefault()}>
                <i className="fad fa-play-circle"></i>
              </a>
              <Picture image={{alt: alt, ...thumbnailImage}} />
            </div>
            : <div className="video-thumbnail" style={{ backgroundImage: `url(${thumbnail})` }} />
            ) :
            <AccruentImg
              file={thumbnail}
              className="video-thumbnail"
              width={"auto"}
              height={"auto"}
            />
          }
          {!thumbnailImage && <a href="#" className="play-btn" onClick={(e) => e.preventDefault()}>
            {/* <img src={popupvideoIcon} decoding='async' className='svg-inline--fa fa-play-circle'/> */}
            <svg class="svg-inline--fa fa-play-circle" data-prefix="fad" data-icon="play-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><g class="fa-duotone-group"><path class="fa-secondary" fill="currentColor" d="M0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zM188.3 147.1c-7.6 4.2-12.3 12.3-12.3 20.9V344c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88c-7.4-4.5-16.7-4.7-24.3-.5z"></path><path class="fa-primary" fill="currentColor" d="M212.5 147.5c-7.4-4.5-16.7-4.7-24.3-.5s-12.3 12.3-12.3 20.9V344c0 8.7 4.7 16.7 12.3 20.9s16.8 4.1 24.3-.5l144-88c7.1-4.4 11.5-12.1 11.5-20.5s-4.4-16.1-11.5-20.5l-144-88z"></path></g></svg>          
          </a>}
        </div>
      }
    </div>
  )
}

