import React from "react";
import { LocalizedLink } from "../commonComponents";

/**
 * @type "a" - Object `contentA` -> { `headerTitle`: String, `headerDescription`: String, `items`: Array of Strings, `buttonText`: String, `buttonLink`: String }
 * @type "b" - Array of Objects `contentB` -> [{ `title`: String, `description`: String }, ...]
 * @type "c" - Array of Objects `contentC` -> [{ `title`: String, `description`: String }, ...]
 * @type "d" - Array of Objects `contentD` -> [{ `title`: String, `description`: String }, ...]
 * @type "e" - Array of Objects `contentE` -> [{ `title`: String, `description`: String, `links`: [{ title: String, href: String }], `buttonText`: "String", `buttonLink`: String }]
 * @type "f" - Array of Objects `contentF` -> [{ `title`: String, `description`: String, `links`: [{ title: String, href: String }], `buttons`: [{ buttonText: String, buttonLink: String }] }]
 * @type "g" - Array of Objects `contentG` -> [{ `title`: String, `description`: String, `links`: [{ title: String, href: String }], `buttonText`: "String", `buttonLink`: String }]
 * @type "h" - Array of Objects `contentH` -> [{ `title`: String, `bullets`: Array of String, `textLink`: String, `badge`: String [optional], `isEvidence`: Boolean [optional] }]
 */
const Benefits = ({
  type,
  contentA,
  contentB,
  contentC,
  contentD,
  contentE,
  contentF,
  contentG,
  contentH,
  title,
  description,
  nid,
  headerClass
}) => {
  if (type === "a") {
    return (
      <div
        className="solutions-benefits-wrapper benefits-content-a has-text-centered"
        component="BenefitsCard"
        type={type}
        nid={nid}
      >
        <div className="mb-4">
          <div className="is-size-2 has-text-weight-bold mb-2">
            {contentA.headerTitle}
          </div>
          <p className="benefits-a-header-description">
            {contentA.headerDescription}
          </p>
        </div>
        <div className="columns is-centered mb-5">
          {contentA.items &&
            contentA.items.map((item) => (
              <div className="column is-3">
                <div className="has-text-centered">
                  <span className="icon">
                    <i
                      className="fa fa-check-circle fa-3x has-text-sapphire"
                      aria-hidden="true"
                    />
                  </span>
                </div>
                <p>{item}</p>
              </div>
            ))}
        </div>
        <button className="button is-sapphire">
          <a href={contentA.buttonLink}>{contentA.buttonText}</a>
        </button>
      </div>
    );
  }

  if (type === "b") {
    return (
      <div
        className="solutions-benefits-wrapper"
        component="BenefitsCard"
        type={type}
        nid={nid}
      >
        <div className="columns is-centered is-multiline">
          {contentB &&
            contentB.map((item) => (
              <div className="column is-3 has-text-centered">
                <span className="icon">
                  <i
                    className="fa fa-check-circle fa-3x has-text-sapphire"
                    aria-hidden="true"
                  />
                </span>
                <div className="is-size-5 has-text-weight-medium mb-3">
                  {item.title}
                </div>
                <p>{item.description}</p>
              </div>
            ))}
        </div>
      </div>
    );
  }

  if (type === "c") {
    return (
      <div
        className="solutions-benefits-wrapper"
        component="BenefitsCard"
        type={type}
        nid={nid}
      >
        <div className="columns is-centered is-multiline">
          {contentC &&
            contentC.map((item) => (
              <div className="column is-one-third has-text-centered ">
                <div className="content-c-wrapper">
                  <span className="icon">
                    <i
                      className="fa fa-check-circle fa-3x has-text-sapphire"
                      aria-hidden="true"
                    />
                  </span>
                  <div className="is-size-5 has-text-weight-medium mb-3">
                    {item.title}
                  </div>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }

  if (type === "d") {
    return (
      <div
        className="solutions-benefits-wrapper"
        component="BenefitsCard"
        type={type}
        nid={nid}
      >
        <div className="columns is-centered is-multiline">
          {contentD &&
            contentD.map((item) => (
              <div className="column is-4 has-text-centered ">
                <div className="content-c-wrapper">
                  <span className="icon">
                    <i
                      className="fa fa-check-circle fa-3x has-text-sapphire"
                      aria-hidden="true"
                    />
                  </span>
                  <div className="is-size-5 has-text-weight-medium mb-3">
                    {item.title}
                  </div>
                  <p>{item.description}</p>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }

  if (type === "e") {
    return (
      <div
        className="solutions-benefits-wrapper"
        component="BenefitsCard"
        type={type}
        nid={nid}
      >
        <div className="benefits-e-background-color has-text-white">
          <div className="columns is-multiline benefits-e-border">
            {contentE &&
              contentE.map((item) => (
                <div className="column is-6 border-target">
                  <div className="benefits-e-item">
                    <div>
                      <div className="is-size-4 has-text-weight-bold mb-2">
                        {item.title}
                      </div>
                      <p className="mb-4">{item.description}</p>
                      {item.links.map((link) => (
                        <div>
                          <a href={link.href}>{link.title}</a>
                        </div>
                      ))}
                    </div>
                    <button className="button is-white benefits-e-button">
                      <a href={item.buttonLink}>{item.buttonText}</a>
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }

  if (type === "f") {
    return (
      <div
        className="solutions-benefits-wrapper"
        component="BenefitsCard"
        type={type}
        nid={nid}
      >
        <div className="columns">
          <div className="column is-2">
            <h3 className="title title-4">{title}</h3>
          </div>
          <div className="column is-8 is-offset-2">
            <div className="columns is-multiline">
              {contentF &&
                contentF.map((item) => (
                  <div className="column is-5" key={item.title}>
                    <div className="benefits-f-item">
                      <div>
                        <span className="icon">
                          <i
                            className="fa fa-check-circle fa-3x has-text-sapphire"
                            aria-hidden="true"
                          />
                        </span>
                        <div className="is-size-4 has-text-weight-bold mb-2 mt-2">
                          {item.title}
                        </div>
                        <p className="mb-4">{item.description}</p>
                        {item.links &&
                          item.links.map((link) => (
                            <div key={link.title}>
                              <a href={link.href}>{link.title}</a>
                            </div>
                          ))}
                      </div>
                      {item.buttons ? (
                        <div>
                          {item.buttons.map((button, i) => (
                            <a
                              href={button.buttonLink}
                              key={`${button.buttonText}${Math.random()}`}
                              className="button is-primary mb-2 mr-3"
                            >
                              {button.buttonText}
                            </a>
                          ))}
                        </div>
                      ) : (
                        <div>
                          <a
                            href={item.buttonLink}
                            className="button is-primary"
                          >
                            {item.buttonText}
                          </a>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (type === "g") {
    return (
      <div
        className="solutions-benefits-wrapper"
        component="BenefitsCard"
        type={type}
        nid={nid}
      >
        <div className="pt-5">
          <div className="columns">
            <div className={"column is-3 benefit-offeset-head" + headerClass}>
              <h3 className="title title-3">{title}</h3>
              <p>{description}</p>
            </div>
            <div className="column is-9">
              <div className="columns is-multiline is-align-items-stretch">
                {contentG &&
                  contentG.map((item) => (
                    <div className="column is-6 mb-5" key={item.title}>
                      <div className="box is-flex is-flex-direction-column is-justify-content-space-between benefits-g-item">
                        <div>
                          <span className="icon">
                            <i
                              className="fa fa-2x fa-check-circle has-text-sapphire"
                              aria-hidden="true"
                            />
                          </span>
                          <h3 className="title mt-2 title-5">{item.title}</h3>
                          <div className="mb-4">{item.description}</div>
                        </div>
                        {item.cta && (
                          <div>
                            <LocalizedLink
                              className="button is-primary"
                              to={item.cta.url.path}
                            >
                              {item.cta.title}
                            </LocalizedLink>
                          </div>
                        )}
                        {item.secondaryCta && (
                          <div>
                            <LocalizedLink
                              className="button is-kingfisher"
                              to={item.secondaryCta.url.path}
                            >
                              {item.secondaryCta.title}
                            </LocalizedLink>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (type === "h") {
    return (
      <div
        className="solutions-benefits-wrapper benefits-h"
        component="BenefitsCard"
        type={type}
        nid={nid}
      >
        <div className="has-text-centered has-text-weight-bold is-size-2 mt-4 mb-4 benefits-h-title">
          Accruent provides software and services that enable you to shape,
          drive, manage and control your physical world.
        </div>
        <div className="columns is-multiline is-centered">
          {contentH &&
            contentH.map((item) => (
              <div className="column is-4">
                <div
                  className={`benefits-h-card ${
                    item.isEvidence ? "benefits-h-card-evidence" : ""
                  }`}
                >
                  {item.badge && (
                    <div className="benefits-h-card-badge">{item.badge}</div>
                  )}
                  <div>
                    <div className="is-size-4 has-text-weight-bold mb-5">
                      {item.title}
                    </div>
                    {item.bullets.map((bullet) => (
                      <div className="benefits-h-bullet has-text-weight-medium">
                        <span className="icon">
                          <i
                            className={
                              "fa fa-check fa-2x" +
                              (item.isEvidence
                                ? "has-text-white"
                                : "has-text-sapphire")
                            }
                            aria-hidden="true"
                          />
                        </span>
                        {bullet}
                      </div>
                    ))}
                  </div>
                  <button className="button is-white">
                    <LocalizedLink to={item.buttonLink}>Explore</LocalizedLink>
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  }
};

export default Benefits;
