import React, { useState } from 'react';
import { LocalizedLink, Picture, FormModal } from 'src/components/commonComponents';

const ContentWrapper = ({ heroProps }) => {
  const [showModal, setShowModal] = useState(false);
  const formCta = heroProps?.formCta;
  const handleToggle = () => {
    setShowModal(!showModal);
  };

  const btnClass =`tw-font-Poppins tw-text-[1rem] tw-leading-4 !tw-text-accruent_white tw-tracking-[0.03px] tw-font-bold tw-border-[2px] tw-border-white tw-inline-block tw-rounded-[12px] tw-py-[11px] tw-px-[23px] hover:tw-bg-accruent_white hover:!tw-text-[#001c71]`

  
  return (
    <div className='container'>
      <div className="content-wrapper-container tw-w-full tw-max-w-[511px] tw-text-left tw-pl-0 tw-ml-0 md:tw-max-w-[700px] lg:tw-max-w-full lg:tw-pt-[52px]">
        <div className="content-height tw-min-h-[285px]">
          <h1 className=' !tw-text-accruent_white tw-my-0 md:tw-mb-4 lg:tw-w-[54%] lg:!tw-leading-[46.4px] xl:!tw-leading-[56px] lg:!tw-tracking-[0.36px] lg:!tw-text-[40px] xl:tw-w-[54%] 2xl:!tw-text-[48px]'>{heroProps?.title}</h1>
          <p className=' tw-text-[22px] tw-leading-[26px] tw-font-medium tw-tracking-[0.13px] tw-text-accruent_white !tw-mb-[20px] md:!tw-mb-[33px] md:tw-text-[18px] md:tw-leading-[22px] lg:tw-w-[46%]'>{heroProps?.fieldHeroDescription}</p>
          {formCta?.fieldShowPopUp && (
            <LocalizedLink
              onClick={() => setShowModal(true)}
              className={`${btnClass}`}
            >
              {formCta?.fieldLabel}
            </LocalizedLink>
          )}
          {heroProps?.fieldHeroCtaUrl && !formCta?.fieldShowPopUp && (
            <LocalizedLink
              to={heroProps?.fieldHeroCtaUrl?.url}
              className={`${btnClass}`}
            >
              {heroProps?.fieldHeroCtaUrl?.title}
            </LocalizedLink>
          )}
        </div>
      </div>
      {showModal && (
        <FormModal
          title={formCta?.fieldLabel}
          fieldEmbeddedCode={formCta?.fieldEmbeddedCode}
          handleToggle={handleToggle}
        />
      )}
    </div>
  );
}


export const LandingPageHero = ({ heroProps }) => {
  return (
    <div className="landing-page-hero-wrapper">
      <div className="extend-width">
        <section className="header-wrapper tw-flex tw-flex-col-reverse tw-mb-[52px] tw-relative lg:tw-block lg:tw-mb-[72px]">
          <div className="image-graphic">
            {heroProps?.fieldHeroMedia?.gatsbyImage && <Picture image={heroProps?.fieldHeroMedia} className="landing-hero-img tw-h-[211px] lg:tw-h-[457px]" />}
          </div>
           <div className="header-content tw-relative tw-top-0 tw-left-0 tw-w-full tw-text-left tw-m-0 tw-h-[95%] tw-p-[53px_16px_16px_16px] tw-z-auto tw-bg-[#4f2b83]
              lg:tw-bg-[url('../../static/newhomepage/Hero-background.svg')] lg:tw-bg-transparent  lg:tw-h-[104%] lg:tw-absolute lg:tw-p-0 lg:tw-mb-[21px] lg:tw-bg-cover lg:tw-bg-no-repeat lg:tw-bg-[-595px_0] xl:tw-bg-[-458px_bottom] 1xl:tw-bg-[-512px_bottom] 3xl:!tw-bg-[-110px_0] 3xl:tw-bg-contain  ">
            <ContentWrapper heroProps={heroProps} />
          </div>
        </section>
      </div>
    </div>
  )
}
// export default LandingPageHero;

