import React from "react";
const OutreachCheck = (data) => {
  if(!data) {
    return 
  }
  else{
    // Regular expression pattern to extract the full URL path
    var urlRegex = /(https?:\/\/[^\s]+)(\/[^\s]+)/;
    // Extract the full URL path from the string
    var match = data.match(urlRegex);
    var fullPath = match && match[0];
    if (!fullPath) {
      return
    }
    // Remove the "</p>" tag from the string
    var finalModifiedString = fullPath.replace(/<\/p>/g, '');
    // let arrayConversionFromHtml = data.split('\n').filter(x => x!=="")
    if(data.includes("hello.accruent.com")){
      const outreachUrl = finalModifiedString
      return {checkForOutreach: true, outreachUrl: outreachUrl, checkForProductDemo: false, checkForCalendly: false, checkForPricing: false}
    }else if(data.includes("app.storylane.io")){
      const storyLaneUrl = finalModifiedString
      return {checkForOutreach: false, storyLaneUrl: storyLaneUrl, checkForProductDemo: true, checkForCalendly: false, checkForPricing: false}
    }else if(data.includes("calendly.com")){
      const calendlyUrl = finalModifiedString
      return {checkForOutreach: false, calendlyUrl: calendlyUrl, checkForProductDemo: false, checkForCalendly: true, checkForPricing: false}
    }else if(data.includes("calconic.com")){
      const pricingUrl = finalModifiedString
      return {checkForOutreach: false, pricingUrl: pricingUrl, checkForProductDemo: false, checkForCalendly: false, checkForPricing: true}
    }
    return {checkForOutreach: false, checkForProductDemo: false, checkForCalendly: false, checkForPricing: false}
  }
}
export default OutreachCheck;