import React, { useState, useRef, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import extractMarketoValues from "src/components/helpers/extractMarketoIdAndSerial";
import useMarketoForm from "src/components/helpers/useMarketoForm";
import "src/scss/_FormModal.scss";
import { Loader } from "./loader";
import { DEMO_FORM_LOADED } from "src/constants";

/**
 * Modal component to display Marketo Form
 * @param {string} title -(required) heading title
 * @param {String} fieldEmbeddedCode -(required) Marketo form embedded code
 * @param {Function} handleToggle -(required) to close the Modal
 *
 */

export const FormModal = ({ title, fieldEmbeddedCode, handleToggle, isFormLoaded, formTag }) => {
  return (
    <div className={`form-modal tw-fixedtw-z-[999] tw-left-0 tw-right-0 tw-top-0 tw-bottom-0 tw-w-full tw-h-full tw-overflow-visible tw-flex tw-items-center tw-justify-center`}>
      <div className="container">
        <div className="form-popup tw-w-[931px] md:tw-w-auto tw-m-[0 auto] md:tw-m-[5% 1rem] tw-h-auto tw-top-0 md:-tw-top-[30px] tw-relative tw-rounded-[20px] tw-max-h-screen">
          <div className="modal-header tw-text-white tw-rounded-r-[20px] tw-flex tw-items-center tw-p-[1.5rem 81px] md:tw-p-4">
            <div className="modal-title tw-font-Poppins tw-text-white tw-text-[22px] md:tw-text-[34px] md:leading-[39.44px] md:tracking-[0.31px] tw-leading-[25.52px] tw-tracking-[0.2px] tw-font-bold tw-z-[3]">Request Live Demo</div>
            <StaticImage
              className="header-img tw-right-0 tw-top-0 tw-rounded-tr-[20px] tw-w-[113px] md:w-auto"
              src={"../../../static/FormModal/modal-bg.png"}
              alt="form-modal-img"
              width={175}
              style={{ position: "absolute" }}
              decoding = "async"
            />
            {isFormLoaded &&   <a
              onClick={handleToggle}
              className="close md:tw-bottom-[unset] md:tw-m-[unset] md:-tw-right-[60px] md:-tw-top-[51px] md:tw-left-auto tw-absolute tw-left-0 tw-right-0 tw-m-[0 auto] tw-border-2 tw-border-[#0f2158] tw-rounded-[50%] tw-h-[52px] tw-flex tw-justify-center tw-items-center tw-opacity-100 tw-text-[#0f2158]"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span className="tw-text-[35px]" aria-hidden="true">×</span>
            </a> }
          </div>
          <div className="modal-body-container">
            <div
              className={`modal-body tw-relative tw-overflow-auto tw-py-[21px] tw-px-[23px] tw-pt-[32px] tw-max-h-[calc(100vh - 196px)] tw-bg-accruent_lightgray md:tw-max-h-[calc(100vh - 181px)] md:tw-py-[51px] md:tw-px-[81px] ${!isFormLoaded ? "set-min-height tw-min-h-[70vh]" : ""}`}
            >
              {!isFormLoaded && <Loader />}
                {formTag && formTag}
              <div className="caption tw-text-center tw-mt-[11px] tw-text-[#888888]">* fields required.</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const Form = ({ id, serial, gaLabel, formCallback }) => {
  const { formTag } = useMarketoForm(serial, id, formCallback, "", gaLabel);
  return formTag;
};


