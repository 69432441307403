/**
 * Function taken from here: https://blog.teknkl.com/destyle-a-marketo-form/
 * It removes every style form Marketo so it can be set again
 */
const destyleMktoForm = (mktoForm, moreStyles) => {
  var formEl = mktoForm.getFormElem()[0],
    arrayify = getSelection.call.bind([].slice);

  // remove element styles from <form> and children
  var styledEls = arrayify(formEl.querySelectorAll("[style]")).concat(formEl);
  styledEls.forEach(function(el) {
    el.removeAttribute("style");
  });

  // disable remote stylesheets and local <style>s
  var styleSheets = arrayify(document.styleSheets);
  styleSheets.forEach(function(ss) {
    if (
      [window.mktoForms2BaseStyle, window.mktoForms2ThemeStyle].indexOf(
        ss.ownerNode
      ) !== -1 ||
      formEl.contains(ss.ownerNode)
    ) {
      ss.disabled = true;
    }
  });

  if (!moreStyles) {
    formEl.setAttribute("data-styles-ready", "true");
    //console.log("Styles ready at: " + performance.now());
  }
};

export default destyleMktoForm;
