const parseStringFromHTML = require("../src/components/helpers/parseStringFromHTML");

const truncate = (str, length, omission = '...') => {
  if (typeof str !== 'string') {
    throw new Error('Input must be a string');
  }

  if (str.length <= length) {
    return str;
  }

  return str.slice(0, length - omission.length) + omission;
};

const parseAndTruncate = (str, length = 200) => {
  let parsed = parseStringFromHTML(str);
  if(parsed){
    parsed = parsed.replace(/&amp;/g, "&").replace(/&nbsp;/g, " ");
  }else{
    return str;
  }
    
  const trun = truncate(parsed, length);
  return trun;
};

module.exports = parseAndTruncate;
