const build_mode = `${process.env.GATSBY_BUILD_MODE || 'accruent'}`;
const site_vars = {
    "accruent": {
        "site_name": "People, Facilities, and Asset Management Software | Accruent",
        "logo": "/Accruent-Logo-color.svg",
        "drupal_site_id": 451,
        "favicon": "src/favicons/favicon-accruent-256px.png",
        "locales": ["en", "en-gb", "es"],
    },
    "ems" : {
        "site_name": "Workplace Management Software Made Easy | EMS Software",
        "logo": "/ems_logo.svg",
        "drupal_site_id": 452,
        "favicon": "src/favicons/favicon-ems-256px.png",
        "locales": ["en"],
    },
    "mc" : {
        "site_name": "Multi-site, multi-industry CMMS software | Maintenance Connection",
        "logo": "/mc-logo.svg",
        "drupal_site_id": 453,
        "favicon": "src/favicons/favicon-mc-256px.png",
        "locales": ["en"],
    },
}
module.exports = site_vars[build_mode]
