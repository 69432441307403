import React, { useEffect, useState } from 'react'
import { EventCard } from "src/components/templateComponents"
import { sampleSize } from "src/utils";
import { useStaticQuery, graphql } from "gatsby";
import { useLocation } from "@reach/router";

export const EventYouMayLike = ({ noHeading = null, locale }) => {

  const [events, setEvents] = useState(null)

  const location = useLocation()

  const data = useStaticQuery(graphql`
    {
      eventsData: allSitePage(
        filter: {context: {eventType: {name: {in: ["Accruent Forward", "Accruent Insights", "Synergy", "Trade Shows"]}}}}
      ) {
        group(field: path, limit: 3) {
          nodes {
            ...eventCards
          }
        }
      }
      webinarsData: allSitePage(
        filter: {
          context: {
            content_type: { eq: "webinar" }
            fieldWebinarType: { eq: "future" }
            fieldHidePage: {ne: true}
          }
        }
      ) {
        group(field: path, limit: 3) {
          nodes {
            context {
              type: content_type
              locale
              entityLabel
              title
              entityId
              entityUrl {
                path
              }
              heroImage {
                alt
                publicUrl
                url
                gatsbyImage {
                  height
                  layout
                  width
                  placeholder {
                    fallback
                  }
                  images {
                    fallback {
                      sizes
                      src
                      srcSet
                    }
                    sources {
                      sizes
                      srcSet
                      type
                    }
                  }
                }
              }
              fieldVirtualEvent
              startEndTimes {
                endDate
                startDate
                value
              }
              fieldStartEndTimes {
                endDate
                startDate
                value
              }
              fieldTimeZone
              fieldResourceDescription
              fieldOverviewPageButtonText
              fieldExternalLink {
                title
                url {
                  path
                }
              }
            }
            path
          }
        }
      }
    }
  `);
  useEffect(() => {
    let mostRecentEvent = []

    for (const [queryType, queryData] of Object.entries(data)) {
      queryData.group.forEach((group) => {
        group.nodes.forEach((node) => mostRecentEvent.push(node));
      })
    }

    let mostRecentEvents = null

    if (location) {
      mostRecentEvents = mostRecentEvent.filter(
        (item) => item.path !== location.pathname && item?.context?.locale === locale
      )
    }

    const sampleEvents = sampleSize(mostRecentEvents, 3).sort((a, b) => {
      return new Date(a?.context?.startEndTimes?.startDate) - new Date(b?.context?.startEndTimes?.startDate);
    })
    setEvents(sampleEvents);
  }, [data])

  return (
    (events && (
      <div>
        {!noHeading && <div className="you-may-like-events">
          <h3 className='title title-2 !tw-mb-4 md:!tw-mb-[48px]' id="you-may-enjoy-these-articles">You May Also Like</h3>
        </div>}
        <div className="category-wrapper">
          <div className="tw-m-[0px_-12px_-12px_-12px] tw-mt-0 tw-flex tw-flex-wrap md:tw-gap-3 tw-gap-5 tw-mb-[52px] md:tw-mb-[76px]">

            {events.map((event) => {
              return <EventCard key={event.context.entityLabel} data={event.context} />;
            })}
          </div>
        </div>
      </div>
    ))
  )
}
// export default EventYouMayLike;




