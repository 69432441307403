import { useEffect, useCallback } from "react";

/**
 * Used to toggle the `is-active` state from a giver navbar.
 * @param {string} burgerId - the id from the bulma burger from navbar.
 *  - The burger element should also have a data-target={navbarId}
 *  - {navbarId} should be add to element with className="navbar-menu"
 */
const useToggleNavbarActiveState = (burgerId, shouldHidePage, navbar) => {
  const handleBurgerClick = useCallback((event) => {
    // useCallback guarantees the event will be removed on unmount
    if(!navbar) {
      navbar = document.getElementById(event.target.dataset.target);
    }
    if (navbar) {
      event.target.classList.toggle("is-active");
      navbar.classList.toggle("is-active");
      if (shouldHidePage) {
        document.documentElement.classList.toggle("has-mobile-nav-open");
      }
    }
  }, []);

  useEffect(() => {
    if (typeof window !== undefined) {
      const burger = document.getElementById(burgerId);
      if (burger) {
        burger.addEventListener("click", handleBurgerClick);
        return () => {
          burger.removeEventListener("click", handleBurgerClick);
        };
      }
    }
  }, [burgerId, handleBurgerClick]);
};

export default useToggleNavbarActiveState;
