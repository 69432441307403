import React, { useContext, useRef, useEffect } from "react";
import ResourcesContext from "src/components/helpers/ResourcesMainPageContext.js";
import findSiteType from "src/components/helpers/findSiteType";
import { Dropdown } from "./dropdown";
export const FilterForm = () => {
  const {
    uniqueTypes,
    uniqueIndustries,
    uniqueSolutions,
    uniqueProducts,
    filters,
    setFilter,
    allQueryParams,
    languageCode
  } = useContext(ResourcesContext);

  // References passed to all dropdown components so the Reset function (to clear the dropdown option)
  // can be called from this parent component, also any other function that the Dropdown might have inside the
  // returning object of useImperativeHandle()
  // To understand more see: https://stackoverflow.com/a/37950970
  const solutionsRef = useRef();
  const productRef = useRef();
  const industryRef = useRef();
  const typeRef = useRef();
  const checkForUk = (languageCode === "en-gb") ? true: false

  // Watch for changes in the content_type that comes from context, based on that change set the value
  // to the dropdown inner value using a function that is inside Dropdown component, called setValue
  useEffect(() => {
    typeRef?.current?.setValue(filters?.content_type);
    solutionsRef?.current?.setValue(filters?.solution);
    productRef?.current?.setValue(filters?.product);
    industryRef?.current?.setValue(filters?.industry);
  }, [filters]);

  // When a item in the dropdown is selected, put the new option direct to Context
  const onSelectFilter = (filter, lead) => {
    setFilter({ ...filters, [lead]: filter });
  };

  // When a item in the dropdown is cleared, put the empty value direct to Context
  const onResetFilter = (lead) => {
    setFilter({ ...filters, [lead]: "" });
    allQueryParams[lead].setParam(undefined);
  };
  const onResetAllFilter = () => {
    setFilter({content_type:"",solution:"",product:"",industry:""});
    Object.entries(filters).forEach(([key, _]) => allQueryParams[key].setParam(undefined));

  };
  const isMC = findSiteType('mc')
  const isEMS = findSiteType('ems')
  return (
    <div className="hero-filter-wrapper" id="hero-filter-wrapper">
      <div>
        <h1 className="tw-text-xxxlFont tw-font-bold tw-font-Poppins md:tw-text-headingFont tw-mt-[52px] tw-mb-[29px] md:mt-[76px] tw-text-center md:tw-mt-[76px] md:tw-mb-[12px]">
        What Resources Are You Looking For? 
        </h1>

        <div className="filter-wrapper tw-max-w-full tw-mt-0 tw-p-0 md:tw-max-w-[70%] tw-w-full md:tw-m-[0_auto] md:tw-pt-[12px] tw-text-[12px]">
          <div className="columns">
            <div className="hoc-dropdown-wrapper tw-mb-[10px] tw-relative tw-w-full md:tw-w-[300px] tw-text-[12px] tw-text-left tw-text-accruent_base_color tw-pr-[39px] tw-whitespace-nowrap -md:tw-flex -md:tw-justify-between -md:tw-p-0">
              <div className="tw-p-[12px_0px_12px_12px] tw-text-left">
                Filter By
              </div>
              <ResetButton className={" md:tw-hidden"} resetFn={onResetAllFilter} />
            </div>
       { !(isMC|| isEMS) &&     <Dropdown
              options={uniqueProducts}
              className="column"
              placeholder="Product"
              ariaLabel="Filter by product"
              lead="product"
              onSelect={onSelectFilter}
              onReset={onResetFilter}
              ref={productRef}
            />
       }
            {!(uniqueSolutions?.length === 0) && <Dropdown
              options={uniqueSolutions}
              className="column"
              placeholder="Solutions"
              ariaLabel="Filter by solutions"
              lead="solution"
              onSelect={onSelectFilter}
              onReset={onResetFilter}
              ref={solutionsRef}
            />}
            {!(uniqueIndustries?.length === 0) && <Dropdown
              options={uniqueIndustries}
              className="column"
              placeholder="Industry"
              ariaLabel="Filter by industry"
              lead="industry"
              onSelect={onSelectFilter}
              onReset={onResetFilter}
              ref={industryRef}
            />}
            <Dropdown
              options={uniqueTypes}
              className="column"
              placeholder="Type"
              ariaLabel="Filter by type"
              lead="content_type"
              onSelect={onSelectFilter}
              onReset={onResetFilter}
              ref={typeRef}
            />
            <ResetButton className={" -md:tw-hidden"} resetFn={onResetAllFilter} />
          </div>
        </div>
      </div>
    </div>
  );
};


const ResetButton = ({ resetFn, className}) => {
  return (
    <div onClick={resetFn} className={` ${className} tw-underline tw-text-[12px] tw-tracking-[0.4px] tw-leading-4 tw-text-[#001C71] tw-text-left md:tw-ml-6 tw-cursor-pointer tw-whitespace-nowrap tw-flex tw-flex-col tw-justify-center md:tw-mb-[10px] tw-p-[12px_12px_12px_0px]`}>Reset All</div>
  );
}


