
import findSiteType from "./findSiteType";
const getBenefitsData = (entity, pageType, checkForRelatedProductCard, locale = 'en') => {
  const isMc = findSiteType('mc');
  const isEms = findSiteType('ems');

  const entityCopy =  entity?JSON.parse(JSON.stringify(entity)):{}

  let currentEntity = entityCopy


  let items = []
  if (currentEntity?.relationships?.fieldBenefitItem) {
    items = currentEntity
    ?.relationships?.fieldBenefitItem
  } else {
    items = currentEntity
    ?.relationships?.queryFieldBenefitItem
  }

  const length = items?.length;

  if (!length) return null;
  // setting for product hub pages
  const styles = ['align_left_with_graphic_bottom_right', 'align_left_no_icon', 'align_left_no_icon_grey_background', 'centered_with_icon_background']
  if (styles.includes(currentEntity?.fieldBiStyle)) {
    if (currentEntity?.fieldBiStyle === 'centered_with_icon_background') return { ...currentEntity, hasWrapper: ((!isMc && !isEms) ? true : false), isCentered: true };
    if (currentEntity?.fieldBiStyle === 'align_left_with_graphic_bottom_right' || currentEntity?.fieldBiStyle === 'align_left_no_icon') return { ...currentEntity, hasWrapper: false, isCentered: false }
    if (length % 2 === 0 && length % 3 !== 0) {
      return { ...currentEntity, hasWrapper: true, isCentered: false };
    } else {
      return { ...currentEntity, hasWrapper: false, isCentered: false };
    }
  }
  // end
  if (currentEntity?.fieldBiStyle === 'half_content_half_logos') {
    return { ...currentEntity, hasWrapper: false, isCentered: true }
  }
  if (pageType === 'accruent_product') return { ...currentEntity, hasWrapper: false, isCentered: false };
  if (pageType === 'solutions') {
    if (checkForRelatedProductCard || currentEntity?.fieldBiStyle === 'card') {
      return { ...currentEntity, hasWrapper: false, isCentered: false, solutionWrapper: true };
    }
    if (currentEntity?.fieldBiStyle === 'align_left_with_icon') {
      return { ...currentEntity, hasWrapper: false, isCentered: false }
    }
  }
  if (pageType === 'about_us') {
    if (currentEntity?.fieldBiStyle === 'align_left_with_icon') {
      return { ...currentEntity, hasWrapper: true, isCentered: true }
    }
  }
  if (pageType === 'landing_page'){
    return { ...currentEntity, hasWrapper: false, isCentered: false };
  }
  if (pageType === 'pricing_section'){
    return { ...currentEntity, hasWrapper: false, isCentered: true, isWrapTitleBody: true };
  }
  if (length % 2 === 0 && length % 3 !== 0) {
    return { ...currentEntity, hasWrapper: true, isCentered: true };
  } else {
    return { ...currentEntity, hasWrapper: false, isCentered: true };
  }
};
export default getBenefitsData;