import React from 'react'
import loadable from '@loadable/component'
const CompanyLogos = loadable(() => import("src/components/solutions/CompanyLogos"))
export const HeroLogos = ({relatedProductLogos, isHeroWithSideContent}) => {
  return(
    <div className={`container  hero-company-logos ${isHeroWithSideContent ? "!tw-mt-[52px] ": ""}`}>
        <CompanyLogos
          data={relatedProductLogos}
          isProductLogo={true}
        />
    </div>
  )
}