import { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getCookie } from "tiny-cookie";
import getNavbarData from "../../../helpers/getNavbarData";
import getAlertbarData from "../../../helpers/getAlertbarData";
import extractNavData from "../../../helpers/extractNavData";

const useMainPageContext = (pageLocale = 'en') => {
  let data = useStaticQuery(graphql`
  {
    allSitePage(filter: {path: {eq: "/layout-data"}}) {
      nodes {
        pageContext
      }
    }
  }`)

  data = data?.allSitePage?.nodes?.[0]?.pageContext?.layoutData?.data

  if (!data) return {}
  const navData = getNavbarData(data.navData, pageLocale);
  const eyebrowNavData = extractNavData(data.eyebrowNavData?.nodes, pageLocale);
  const footerData = getNavbarData(data.footerData, pageLocale);
  const copyrightFooterData = getNavbarData(data.copyrightFooterData, pageLocale);
  const secondaryFooterData = getNavbarData(data.secondaryFooterData, pageLocale);
  const landingPageFooterData = getNavbarData(data.landingPageFooterData, pageLocale);

  let dismissedAlerts = [];
  let initialAlertBar = null;
  if (typeof document === "object") {
    dismissedAlerts = JSON.parse(getCookie("dismissedAlerts")) || [];
    initialAlertBar = getAlertbarData(
      data?.alertBars?.nodeQuery?.entities || [],
      dismissedAlerts
    );
  }

  const [alertBarData, setAlertBarData] = useState(initialAlertBar);


  return {
    navData,
    eyebrowNavData,
    alertBarData,
    setAlertBarData,
    footerData,
    copyrightFooterData,
    secondaryFooterData,
    landingPageFooterData
  };
};

export default useMainPageContext;
