import React from "react";
import { ResourcesHero } from "../templateComponents";
import { Eyebrow } from "./eyebrow";
export const CommonResourceHero = ({ data }) => {
  let eyebrowTitle, resourceClass, contentType, fullHeaderBg = "", ctaFile = null, hideShare, isPressRelease = false;
  const fullHeaderBanner = ['white_paper', 'ebook']
  const headerClassName = data?.contentType === "press_release" ? "medium-wrapper tw-text-[16px] md:tw-text-[18px] tw-py-0 tw-px-[15px] md:tw-px-0 md:tw-py-0": "banner-wrapper tw-mb-[35px]"
  const sectionClass =  !["press_release", "ebook", "white_paper" ].includes(data?.contentType) ? "section !tw-pb-0" : ""
  switch (data?.contentType) {
    case "blog_entry":
      eyebrowTitle = "All Blog Posts"
      resourceClass = "blog-page"
      contentType = "Blog"
      break;
    case "article":
      eyebrowTitle = "All Articles"
      resourceClass = "article-page"
      contentType = "Article"
      break;
    case "brochure":
      eyebrowTitle = "All Brochures"
      resourceClass = "brochure-page"
      contentType = "BROCHURE"
      ctaFile = data?.fieldBrochureDownloadLink?.url
      break;
    case "ebook":
      eyebrowTitle = "All Ebooks"
      fullHeaderBg = "full-header-bg md:tw-pt-[24px]"
      contentType = "Ebook"
      break;
    case "case_study":
      eyebrowTitle = "All Case Studies"
      resourceClass = "case-study-page"
      contentType = "CASE STUDY"
      ctaFile = data?.linkToCaseStudyPdf
      if(data?.fieldOverrideItem){
        fullHeaderBg = "video-page-wrapper"
      }
      break;
    case "infographic":
      eyebrowTitle = "All Infographics"
      contentType = "INFOGRAPHICS"
      ctaFile = data?.pdfLink
      break;
    case "podcast_episode":
      eyebrowTitle = "All Podcasts"
      contentType = "PODCAST"
      break;
    case "company_news":
      contentType = "CompanyNews"
      eyebrowTitle = "All Company News"
      resourceClass = "company-news-page"
      break;
    case "knowledge_hub_":
      contentType = "Knowledge Hub"
      eyebrowTitle = "All Knowledge Hub"
      resourceClass = "article-page"
      break;
    case "video":
      eyebrowTitle = "All Videos"
      fullHeaderBg = "video-page-wrapper"
      break;
    case "press_release":
      resourceClass = "resources-page full-header-bg resources-press-release"
      eyebrowTitle = "All Press Releases"
      break;
    case "white_paper":
      eyebrowTitle = "All White Papers"
      fullHeaderBg = "full-header-bg md:tw-pt-[24px]"
      contentType = "White Paper"
      break;
  }

  const CompanyNewsTag = () => {
    return (
      <div className={`feature-tag-date ${!(data?.fieldTags) ? "tag-no-date" : ""}`}>
        {data?.fieldTags && <div className="feature-tag">
          <p>{data?.fieldTags}</p>
        </div>}
        {data?.fieldPublishDate && <div>
          <p className="company-date">{data?.fieldPublishDate}</p>
        </div>}
      </div>
    )
  }
  const PressRealeaseHero = () => {
    return (
      <>
        <ResourcesHero
          type="body"
          contentType={data?.fieldTag || 'PRESS RELEASE'}
          heading={data?.title}
          subHeading={data?.fieldSubtitle}
          body={data?.fieldResourceDescription}
          hideShare={true}
          publishDate={data?.fieldPublishDate}
          newswireLink={data?.newswireLink}
          isPressRelease
        />
      </>
    )
  }

  const CommonHero = ({ banner = false }) => {
    return (
      <>
        <ResourcesHero
          type={banner ? "banner" : ""}
          contentType={contentType}
          heroImage={data?.heroImage}
          videoUrl={data?.heroVideoUrl}
          thumbnail={data?.heroVideoThumb}
          heading={data?.title}
          subTitle={data?.subTitle}
          hashtags={data?.resourceTags}
          body={data?.fieldResourceDescription}
          date={(data?.fieldPublishDate)}
          overViewText={eyebrowTitle}
          overviewLink={`/resources?content_type=${data?.contentType}`}
          locale={data?.locale}
          readTime={data?.fieldReadTime}
          subHeading={data?.fieldTagLine}
          cta={ctaFile}
          season={data?.fieldPodcastSeasonAndEpisode}
        />
      </>
    )
  }
  const VideoHero = () => {
    return (
      <>
        <ResourcesHero
          type="body"
          heading={data.title}
          hashtags={data?.resourceTags}
          hideShare={true}
        />
      </>
    )
  }

  return (
    <section className={`${sectionClass} resources-page ${fullHeaderBg}`}>
      {!(fullHeaderBanner?.includes(data?.contentType)) ? <div className={`container tw-container_custom ${resourceClass}`}>
        <header className={headerClassName}>
          <Eyebrow
            to={`/resources?content_type=${data?.contentType}`}
            title={eyebrowTitle}
            locale={data?.locale}
          />
          {data?.contentType === "company_news" && <CompanyNewsTag />}
          {data?.contentType === "video" || (data?.contentType === "case_study" && data?.fieldOverrideItem) ? <div className="video-page-box"><VideoHero /></div> :
          data?.contentType === "press_release" ? <div className="video-page-box"><PressRealeaseHero /></div> :
          <CommonHero />}
        </header>
      </div>
        :
        <CommonHero banner={true} />
      }
    </section>
  )
}
