import React, { useState, useRef } from 'react'
import cn from "classnames";
import ConditionalWrapper from '../helpers/ConditionalWrapper';
/**
 *
 * @param {String} parentClass - entity of QA List
 * @param {String} tabClass - list of QA Pair
 * @param {String} TabElement - to pass Html tag as props for tab list eg- h1,h2.
 * @param {Boolean} isToogle - to use toggle tab
 */

export const TabsBox = ({ title, desc, tabs, children, parentClass = "", tabClass = "", TabElement = "", isToogle = false  }) => {
  const currentTabs = isToogle?["",...tabs]:tabs
  const [active, setActive] = useState(0)
  const [currentTab, setCurrentTab] = useState(currentTabs?.length ? isToogle ? currentTabs[1] : currentTabs[0] : '')
  const isChildrenList = Array.isArray(children)
  const isChildrenFunction = typeof children === 'function'
  const checkBoxRef = useRef(null)

  const toggleTab = () => {
    if (!checkBoxRef?.current?.checked) { setActive(1); setCurrentTab(currentTabs[1]) }
    else {
      setActive(2);
      setCurrentTab(currentTabs[2]);
    }
  }
  return (
    <section className={cn("flexible-options-box tw-p-[initial] tw-m-auto tw-text-center tw-bg-none tw-bg-no-repeat tw-bg-cover tw-mt-0 md:tw-pt-0 tw-pt-[37px]", parentClass)} >
      <div className="container">
        {!!title && <h2>{title}</h2>}
        {!!desc && <p className="sub-heading-content sub-heading-box">
          {desc}
        </p>}

        <div className="tabs-section">
          {!!TabElement ?
            (!!currentTabs?.length && <div className={cn("nav nav-tabs is-flex is-justify-content-center tw-border-0 tw-text-accruent_black tw-font-bold tw-bg-accruent_white tw-mx-[calc(100%-100vw)] tw-px-[calc(100vw-100%)]", tabClass)}>
              {currentTabs?.map((tab, i) => {
                return <TabElement key={i} className={cn("is-flex nav-tab tw-max-w-[33%] md:tw-text-xxxlFont tw-text-[1rem] tw-leading-4 md:tw-leading-[46.4px] tw-tracking-[0.41px] md:tw-tracking-[0.36px]", { 'active': (i === active) })} onClick={() => { setActive(i); setCurrentTab(tab) }} ><a data-toggle="tab" >{tab}</a></TabElement>
              })}
            </div>)
              : (!!currentTabs?.length &&
              <ConditionalWrapper
                condition={isToogle}
                wrapper={
                  (children) => {
                    return (
                      <label class="switch tw-relative tw-inline-block">
                        <input className='tw-opacity-0 tw-w-0 tw-h-0' ref={checkBoxRef} type="checkbox" onChange={toggleTab} />
                        {children}
                      </label>
                    )
                  }
                }
              >
                <ul className={cn("nav nav-tabs tw-m-[12px_auto_auto]", tabClass)}>
                  {currentTabs?.map((tab, i) => {
                    return <li key={i} className={cn("nav-tab tw-min-w-[116px] tw-block tw-mr-[2px] !tw-pb-0", { 'active': (i === active), 'first': (i === 0) })} onClick={() => { setActive(i); setCurrentTab(tab) }} ><a data-toggle="tab" >{tab}</a></li>
                  })}
                </ul>
              </ConditionalWrapper>
            )
          }


          <div className="tw-p-0 tw-mt-[30px] tab-content">
            {isChildrenFunction ?
              (<div className="tab-pane fade in  show">
                {children(currentTab)}
              </div>) : (
                <>
                  {isChildrenList ? children?.map((c, i) => {
                    if (i !== active) {
                      return null;
                    }
                    return (
                      <div key={i} className=''>
                        {c}
                      </div>
                    )
                  }) :
                    <div className="tab-pane fade in  show">
                      {children}
                    </div>
                  }
                </>
              )
            }

          </div>
        </div>

      </div>
    </section>
  )
}
