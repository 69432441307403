import React from "react";
import { CategoryHeroV1 } from "./CategoryHeroV1";
import { CategoryHeroV2 } from "./CategoryHeroV2";
import { CategoryHeroV3 } from "./CategoryHeroV3";
import * as containerStyles from "./_commonHero.module.scss";
export const CommonHero = ({commonHeroProps, contentProp, onPopupLoad}) => {
  const heroCatergoryV1 = ["Product","Solution", "Service"]
  const heroCatergoryV2 = ["AboutUs", "MC Solution", "Industry"]
  if(commonHeroProps?.fieldHeroHalfMask){
    return(<CategoryHeroV3 containerStyles={containerStyles} commonHeroProps={commonHeroProps}  onPopupLoad={onPopupLoad} />)
  }
  else if(heroCatergoryV1.includes(contentProp)){
    return(<CategoryHeroV1 containerStyles={containerStyles} commonHeroProps={commonHeroProps} onPopupLoad={onPopupLoad}  />)
  }else if(heroCatergoryV2.includes(contentProp)){
    return(<CategoryHeroV2 containerStyles={containerStyles} commonHeroProps={commonHeroProps} onPopupLoad={onPopupLoad}  />)
  }else{
    return null;
  }
}