import React, {useState, useEffect} from "react";
//Mounting funtion to solve hydration warning while rendering font-awsome icons
export default function useMountingForIcon() {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  return mounted;
}