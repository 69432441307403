import React from "react";
import { useCountUp } from "react-countup";
import decimalCount from "src/../helpers/decimalCount"
import { LocalizedLink } from "./localizedLink";
import { BgPatternImage } from "./bgPatternImage";
const btnClass = "tw-font-Poppins tw-whitespace-break-spaces tw-bg-accruent_white tw-border-link-color tw-text-link-color tw-inline-block tw-font-bold tw-tracking-[0] tw-h-auto tw-text-secondaryNavMobileFont tw-leading-4 tw-relative tw-align-top tw-mt-8 tw-mb-0 tw-px-[23px] tw-py-3.5 tw-rounded-xl tw-border-2 tw-border-solid hover:tw-bg-link-color hover:tw-border-link-color hover:tw-text-accruent_white";
export const Statistics = ({
  entity,
  statistics,
  descriptionOnSide = false,
  isPurpleSection = false,
  nid = undefined,
  spread = false,
  sectionStyle=undefined
}) => {
  const fieldShowBackgroundPattern = entity?.fieldShowBackgroundPattern
  if (isPurpleSection) {
    return (
      <div className="purple-cross-section tw-w-screen tw-relative -tw-ml-[50px] tw-left-1/2 tw-bg-accruent_kingfisher_daisy tw-text-white tw-text-center tw-items-stretch lg:tw-py-4 tw-py-8" component="Statistics" nid={nid} id={entity?.title}>
        <div className="section">
          <div className="container">
            <div
              className={`tw-columns tw-justify-center ${
                isPurpleSection ? " tw-items-stretch" : "is-vcentered "
              }`}
            >
              {statistics.map((item) => (
                <StatisticItem
                  item={item}
                  key={item.uuid}
                  isPurpleSection={isPurpleSection}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    const isCenterAllign = (entity?.fieldStatisticSectionType === 'percentage_value_content_centered_align')
    const isLeftAllignContentRight = (entity?.fieldStatisticSectionType === 'statistics_left_align_content_right_align')
    const isDescriptionOnTop=  !(entity?.fieldStatisticSectionType === 'percentage_value_left_content_right' || isLeftAllignContentRight || (sectionStyle === 'statistics_left_content_right'))
    const titleStyle= (sectionStyle==='form_statistics')?'title-4':'title-3';
    const itemCount = statistics?.length
     const responsiveClass = (itemCount > 3)?'responsive-statistics':''
    return (
      <div className={`statistics-wrapper tw-statistics-wrapper ${entity?.fieldStatisticSectionType}  ${fieldShowBackgroundPattern?'full-width-bg-wrapper':''} ${responsiveClass}`} component="Statistics" nid={nid}>
        {fieldShowBackgroundPattern &&
          <div className="bg-image-container">
            <BgPatternImage className="bg-image" >
            </BgPatternImage>
          </div>
        }
       {entity && isDescriptionOnTop && <StatisticDescription entity={entity} titleClass={titleStyle} hideCta={true} /> }
        <div
          className={`tw-columns is-multiline ${
            spread ? " tw-justify-around" : " tw-justify-center"
          } service-statistics-container`}
        >
          {statistics && statistics.map((item) => (
            <StatisticItem
              item={item}
              key={item.uuid}
              descriptionOnSide={descriptionOnSide}
              isPurpleSection={isPurpleSection}
              titleClass={isCenterAllign?'title-1':'title-4'}
            />
          ))}
        </div>
        {entity && isDescriptionOnTop &&
          <div className="link-button">
            {entity.fieldCtaUrl && (
              <LocalizedLink
                to={entity.fieldCtaUrl.url}
                className={btnClass}
              >
                {entity.fieldCtaUrl.title}
              </LocalizedLink>
            )}
          </div>
        }
        {entity && !isDescriptionOnTop && <StatisticDescription entity={entity} titleClass={(sectionStyle || isLeftAllignContentRight)?"title-3":"title-5"} /> }
      </div> 
    );
  }
};

const StatisticDescription = ({
  entity,
  titleClass,
  hideCta=false
  }) => {
    return (
      <div className="statistcs-description-container">
        {!!entity.fieldPreHeading && (
          <div className="pre-heading">{entity.fieldPreHeading}</div>
        )}
        {!!entity.title && !(entity?.fieldHideTitle) && (
          <h2 className={`title ${titleClass} tw-transform-none`}>
            {entity.title}
          </h2>
        )}
        {entity.body && (
          <div
            className="body dangerously-html-handle"
            dangerouslySetInnerHTML={{
              __html: entity.body.value,
            }}
          />
        )}
        {!hideCta &&
        <div className="link-button">
          {entity.fieldCtaUrl && (
            <LocalizedLink
              to={entity.fieldCtaUrl.url}
              className={btnClass}
            >
              {entity.fieldCtaUrl.title}
            </LocalizedLink>
          )}
        </div>
        }
      </div>
    )}

const StatisticItem = ({
  item,
  descriptionOnSide,
  isPurpleSection,
  titleClass='title-4'
}) => {
  const  decimals = decimalCount(item.fieldSta)
  const { countUp: numberCountUp } = useCountUp({
    start: 0,
    end: item.fieldSta,
    delay: 1,
    duration: 5,
    decimals:decimals
  });
  const { countUp: numberRangeCountUp } = useCountUp({
    start: 0,
    end: item.fieldStatisticsRange,
    delay: 1,
    duration: 5,
    decimals:decimals
  });
  const numberToDisplay =
    typeof item.fieldSta === "number" ? numberCountUp : item.fieldSta;
  const numberRangeToDisplay =
    typeof item.fieldStatisticsRange === "number" ? numberRangeCountUp : item.fieldStatisticsRange;
  const statisticToDisplay = `
    ${item.fieldPrefix ? item.fieldPrefix
      : item.fieldShowDollarSign ? "$" : "" }${numberToDisplay}${numberRangeToDisplay?'-'+numberRangeToDisplay:''} ${
    item.fieldStatisticSuffix ? item.fieldStatisticSuffix : ""
  }
  `;
  if (descriptionOnSide) {
    return (
      <div className="tw-column lg:tw-w-[41.66%] tw-w-[33.33%] md:tw-w-[50%] has-description-on-side">
        <div className="tw-columns tw-justify-center tw-items-center tw-text-center">
          <div className="tw-column is-5 centralize tw-flex tw-items-center tw-justify-center">
            <div className="statistic-item-circle tw-h-[132px] tw-w-[132px] tw-bg-accruent_kingfisher_daisy  tw-text-accruent_white tw-text-center tw-flex tw-flex-col tw-justify-center tw-shadow-[0px_10px_20px_rgba(31,32,65,0.2)] tw-rounded-[50%] tw-mb-6">
              <div className="title title-4  !tw-text-white">{statisticToDisplay}</div>
            </div>
          </div>
          <div className="tw-column is-4">
            <div
              className="statistic-description tw-text-center tw-text-[0.8em] dangerously-html-handle"
              dangerouslySetInnerHTML={{
                __html: item.fieldStatisticsDefinition?.value || item.title,
              }}
            />
          </div>
        </div>
      </div>
    );
  } else if (isPurpleSection) {
    return (
      <div className="tw-column tw-w-[58.33%] cross-section-item">
        <div className="title !tw-text-white title-4">
          {statisticToDisplay}
          {item.fieldShowPlusSign && <sup className=" tw-text-6">+</sup>}
        </div>
        <div
          className="tw-font-medium statistic-description tw-text-center tw-text-[0.8em] dangerously-html-handle"
          dangerouslySetInnerHTML={{
            __html: item.fieldStatisticsDefinition?.value || item.title,
          }}
        />
      </div>
    );
  } else {
    return (
      <div className="tw-column is-2 about-statistics-item">
        <div className="statistic-item-wrapper tw-flex tw-items-center tw-flex-col">
          <div className="statistic-item-circle tw-h-[132px] tw-w-[132px] tw-bg-accruent_kingfisher_daisy  tw-text-accruent_white tw-text-center tw-flex tw-flex-col tw-justify-center tw-shadow-[0px_10px_20px_rgba(31,32,65,0.2)] tw-rounded-[50%] tw-mb-6">
            <div className={"title !tw-text-accruent_white "+ titleClass}>
              {statisticToDisplay}
              {item.fieldShowPlusSign && <sup className="is-size-5">+</sup>}
            </div>
          </div>
        {!item.fieldHideTitle &&  <div
            className="statistic-description tw-text-center tw-text-[0.8em]  dangerously-html-handle"
            dangerouslySetInnerHTML={{
              __html: item.fieldStatisticsDefinition?.value || item.title,
            }}
          /> }
        </div>
      </div>
    );
  }
};
