import React from "react";
import ZigZagBlockItem from "./ZigZagBlockItem";

const ImageLeftRightBlock = (props) => {
  const { data: blockData ,sectionType} = props;
   const index=sectionType==='images_left'?3:2;
  return (
    <div className={sectionType}>
      {blockData &&
        blockData.map((entity) => {
          return (
            <ZigZagBlockItem entity={entity} index={index} key={entity.nid} />
          );
        })}
    </div>
  );
};

export default ImageLeftRightBlock
