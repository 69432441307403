import React from "react";
import { InpLink } from "../commonComponents";
import site_vars from "src/get_site_data";
import crossImg from "src/images/xmark-solid.png";
import barsImg from "src/images/bars-solid.png";
export const NavbarBrand = ({
  locale,
  isMc,
  isEms,
  burgerId,
  navbarId,
  toggleIsActive,
  isActive,
}) => {
  return (
    <div className="navbar-brand !tw-justify-between">
      <InpLink to={locale === "en" ? "/" : "/uk"} className="navbar-item logo">
        <img
          className={`navbar-logo accruent-logo-blue ${
            isEms? "ems-brand-logo":!isMc ? "set-auto" : ""
          }`}
          src={site_vars.logo}
          alt="Home"
          width="144"
          height="36"
          decoding = "async"
        />
      </InpLink>
      <div className={`navbar-item is-hidden-desktop menu-btn`}>
        <button
          aria-label="menu"
          aria-expanded="false"
          data-target={navbarId}
          id={burgerId}
          onClick={toggleIsActive}
        >
          <img src ={barsImg} className={`${isActive ? 'tw-hidden' : 'tw-block'}`} />
          <img src = {crossImg} className={`${!isActive ? 'tw-hidden' : 'tw-block'}`}/>
        </button>
      </div>
      </div>
  );
};
