import React from "react";
import { CommonFormHeader } from "src/components/templateComponents";
import { Modal } from "src/components/commonComponents";
const PopupWithForm = ({form, fieldFormHeader, isFormLoaded, popup}) => {
  return(
    <div>
       {popup && <Modal isOpen={true}>
        <div className="tw-bg-white tw-p-10 tw-min-h-[350px]">
          <CommonFormHeader 
            formTag={form}
            fieldFormHeader={fieldFormHeader}
            isFormLoaded={isFormLoaded}
          />
        </div>
     
        </Modal>
        }
       
    </div>
  )
}
export default PopupWithForm;