import React from "react";
import { HeroTextContainer } from "./HeroTextContainer";
import { HeroImageContainer } from "./HeroImageContainer";
import { LCPImage } from "../lcpImage";
export const CategoryHeroV1 = ({ containerStyles, commonHeroProps }) => {
  const {
    heading,
    preHeading,
    body,
    cta,
    secondaryCta,
    formCta,
    heroImage,
    heroVideo,
    videoBackgroundImage,
    heroImageUrl,
    heroBackground,
    subHeading,
    fieldHideHeroImage,
  } = commonHeroProps;
  const currentHeroImage = fieldHideHeroImage ? null : heroImage;
  const currentHeroImageUrl = fieldHideHeroImage ? null : heroImageUrl;
  let backgroundNewImage = heroBackground?.gatsbyImage
    ? heroBackground
    : videoBackgroundImage;
  const heroImageAttrs =
    backgroundNewImage?.gatsbyImage?.images ||
    currentHeroImage?.gatsbyImage?.images;
  const foreGroundImage = !heroVideo
    ? backgroundNewImage?.gatsbyImage
      ? currentHeroImage
      : null
    : null;
  const heroGif = currentHeroImageUrl;
  return (
    <div className={containerStyles.commonHeroContainer}>
      <section className="section hero is-block  tw-min-h-full product-page-hero-box tw-product-page-hero-box">
        <div className="container common-hero-container">
          <LCPImage />
          <div className=" tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-items-center tw-m-[-12px]">
            <div className=" is-6 is-full-tablet hero-page-container  text tw-px-4 md:tw-pl-[30px]
            tw-mb-[0px] tw-mt-[52px] md:tw-mt-[76px] md:tw-mb-[76px]">
              <HeroTextContainer
                preHeading={preHeading}
                heading={heading}
                body={body}
                cta={cta}
                secondaryCta={secondaryCta}
                formCta={formCta}
                subHeading={subHeading}
              />
            </div>
            <div className="is-6 hidden-on-mobile tw-hidden md:tw-block hero-page-container 
            tw-my-0 tw-p-[0px] md:tw-my-[76px] tw-relative
            video">
              <HeroImageContainer
                heroVideo={heroVideo}
                backgroundNewImage={backgroundNewImage}
                foreGroundImage={foreGroundImage}
                heroGif={heroGif}
                heroImageAttrs={heroImageAttrs}
                heroImage={currentHeroImage}
              />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
