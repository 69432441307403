import React from "react";

export const MapMarker = ({ text }) => {
  return (
    <div className="google-map-marker">
      <div className="marker-icon">
        <i className="fas fa-map-marker-alt"></i>
      </div>
      <div className="box marker-box">{text}</div>
    </div>
  );
};

