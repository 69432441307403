import React, { useContext, useEffect, useState } from "react";
import ResourcesContext from "src/components/helpers/ResourcesMainPageContext";
import { Loader } from "./loader";
import getCookie from "../../../helpers/getCookieParser";
export const Calendly = ({ calendly }) => {
  const [calendlyUrl, setCalendlyUrl] = useState(calendly?.calendlyUrl)
  const { utmCookiesCreated } = useContext(ResourcesContext);

  useEffect(() => {
    let script = null
    const headScripts = Array.from(document.querySelectorAll("script"));
    const hasCalendlyScript = headScripts.some((script) =>
      script.src.includes("https://assets.calendly.com/assets/external/widget.js")
    );
    if (!hasCalendlyScript) {
      script = document.createElement('script');
      script.async = true;
      script.src = 'https://assets.calendly.com/assets/external/widget.js';
      script.onload = () => {
        updateCalendlyUrl();
      };
      document.body.appendChild(script);
    } else {
      updateCalendlyUrl();
    }
    return () => {
      if (script) {
        script.parentNode.removeChild(script);
      }
    }
  }, [])

  useEffect(() => {
    if (utmCookiesCreated) {
      updateCalendlyUrl()
    }
  }, [utmCookiesCreated])

  const updateCalendlyUrl = () => {
    const fetchedCookies = getCookie()
    const validFetchedCookies = (typeof fetchedCookies !== 'undefined') && fetchedCookies.filter(obj => {
      const keys = Object.keys(obj);
      return keys.length > 0 && keys[0] !== "" && (keys[0].includes("utm"));
    });

    const utmParams = validFetchedCookies && validFetchedCookies.reduce((acc, obj) => {
      const key = Object.keys(obj)[0];
      const value = obj[key];
      return acc + `${key}=${value}&`;
    }, '').slice(0, -1);

    const finalUrl = utmParams ? `${calendly?.calendlyUrl}?${utmParams}` : calendly.calendlyUrl;
    setCalendlyUrl(finalUrl)
  }

  return (
    <div className="iframe-pos">
      <Loader />
      <div id="calendly">
        <div className="calendly-inline-widget" data-url={calendlyUrl} />
      </div>
    </div>
  );
}
