import React from 'react'
import { LocalizedLink } from 'src/components/commonComponents';
import "../../../scss/components/_quickLinks.scss";
import getImageObject from '../../../../helpers/getImageObject';
import { MyAccruentBlock } from './myAccruentBlock';
import { Picture } from 'src/components/commonComponents/picture';
import "../../../styles/_quickLinks.css";
export const QuickLinks = ({ fieldCtaBlock, locale }) => {
  const quickLinks = fieldCtaBlock?.relationships?.fieldCtaS
  const title = fieldCtaBlock?.title
  const QuickLinks = () => {
    return(
      <>
      <div className='tw-hidden md:tw-flex tw-flex-col quick-links tw-bg-accruent_slightgray tw-rounded-[0px_32px_32px_0px]'>
        <div className='tw-p-[24px_44px_40px_24px]'>
          <div className='tw-w-full'>
            {title && <p className='tw-text-accruent_sapphire tw-font-bold tw-text-[18px] tw-font-Poppins !tw-mb-[31px]'>{title}</p>}
          </div>
          <div className='tw-w-full tw-pl-[30px] tw-pr-[30px]'>
            <div className='tw-flex-row tw-flex tw-gap-[64px]'>
              {!!quickLinks?.length && quickLinks?.map((quicklink, index) => {
                const image = getImageObject(quicklink?.relationships?.fieldImage, quicklink?.fieldImage)
                return(
                  <div className='tw-w-full tw-text-center tw-relative tw-max-w-[130px]' key={index}>
                    <div className='tw-pb-[16px]'>
                      <Picture image={image} className="quick-links-image"/>
                    </div>
                    {quicklink?.fieldCtaUrl?.url && <div>
                      <LocalizedLink className="tw-text-blue_tag tw-font-bold tw-text-[16px] tw-font-Poppins tw-block " to={quicklink?.fieldCtaUrl?.url}>{quicklink?.fieldCtaUrl?.title}</LocalizedLink>
                    </div>}
                    <div className=''>
                      <LocalizedLink className="tw-absolute tw-left-0 tw-right-0 tw-bottom-0 tw-top-0 tw-w-full" to={quicklink?.fieldCtaUrl?.url}/>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
      <div className='md:tw-hidden tw-flex tw-w-full tw-flex-col quick-links tw-bg-accruent_slightgray tw-rounded-[0px_32px_32px_0px]'>
        <div className='tw-flex tw-flex-col tw-p-[24px]'>
          <div>
            {title && <p className='tw-text-accruent_sapphire tw-font-bold tw-text-[18px] tw-font-Poppins'>{title}</p>}
          </div>
          <div>
            {quickLinks?.map((quicklink, index) => {
              return(
                <div key={index}>
                  {quicklink?.fieldCtaUrl?.url && 
                    <LocalizedLink to={quicklink?.fieldCtaUrl?.url} className="tw-flex tw-flex-row tw-gap-4 tw-mb-3">
                      <i class="fa-sharp fa-solid fa-arrow-right tw-text-blue_tag"></i>
                      <p className='tw-mb-0 tw-text-blue_tag tw-font-Poppins tw-text-[16px] tw-gap-[16px]'>{quicklink?.fieldCtaUrl?.title}</p>
                    </LocalizedLink>}
                </div>
              )
            })}
          </div>
        </div>
      </div>
      </>
    )
  }
  return (
   <div className='tw-flex tw-flex-col md:tw-flex-row tw-gap-4 tw-mx-4 md:tw-mx-0'>
    <MyAccruentBlock locale={locale} fieldCtaBlock={fieldCtaBlock}/>
    <QuickLinks />
   </div>
  )
}

// export default QuickLinks;
