import React, { useState } from 'react'
import "src/scss/modals/_internationalUsersNoticeModal.scss";
import { Modal } from './modal';
import { LocalizedLink } from '../localizedLink';
import modalGlobal from "../../../../static/modal-globe.svg";
export const InternationalUsersNoticeModal = ({ukPagePath}) =>  {

  const [isOpen, setIsOpen] = useState(true)

  const openModal = () => {
    setIsOpen(true);
  }

  const closeModal = () => {
    setIsOpen(false);
  }

  const noticeText = "You're currently browsing the U.S website. For EMEA, APAC, and ANZ, please click below"

  return (
    <div className='international-users-notice-modal'>
      {isOpen &&
        <div>
          <Modal isOpen={isOpen} onClose={closeModal}>
          <div className='int-box tw-text-center tw-m-[50px_15px_0px_15px] md:tw-m-[30px] tw-rounded-[20px] tw-bg-accruent_kingfisher_daisy'>
            <img className='svg-inline--fa fa-globe tw-inline tw-text-[48px] tw-h-[80px] tw-pt-[32px]' src={modalGlobal} decoding='async'/>
            {/* <svg class="svg-inline--fa fa-globe tw-inline" data-prefix="fal" data-icon="globe" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M256 480c16.7 0 40.4-14.4 61.9-57.3c9.9-19.8 18.2-43.7 24.1-70.7H170c5.9 27 14.2 50.9 24.1 70.7C215.6 465.6 239.3 480 256 480zM164.3 320H347.7c2.8-20.2 4.3-41.7 4.3-64s-1.5-43.8-4.3-64H164.3c-2.8 20.2-4.3 41.7-4.3 64s1.5 43.8 4.3 64zM170 160H342c-5.9-27-14.2-50.9-24.1-70.7C296.4 46.4 272.7 32 256 32s-40.4 14.4-61.9 57.3C184.2 109.1 175.9 133 170 160zm210 32c2.6 20.5 4 41.9 4 64s-1.4 43.5-4 64h90.8c6-20.3 9.3-41.8 9.3-64s-3.2-43.7-9.3-64H380zm78.5-32c-25.9-54.5-73.1-96.9-130.9-116.3c21 28.3 37.6 68.8 47.2 116.3h83.8zm-321.1 0c9.6-47.6 26.2-88 47.2-116.3C126.7 63.1 79.4 105.5 53.6 160h83.7zm-96 32c-6 20.3-9.3 41.8-9.3 64s3.2 43.7 9.3 64H132c-2.6-20.5-4-41.9-4-64s1.4-43.5 4-64H41.3zM327.5 468.3c57.8-19.5 105-61.8 130.9-116.3H374.7c-9.6 47.6-26.2 88-47.2 116.3zm-143 0c-21-28.3-37.5-68.8-47.2-116.3H53.6c25.9 54.5 73.1 96.9 130.9 116.3zM256 512A256 256 0 1 1 256 0a256 256 0 1 1 0 512z"></path></svg> */}
              <div className='main-content'>
                <div className='notice-text tw-text-accruent_white tw-p-[12px_32px_32px_32px] tw-text-mobileLgFont md:tw-text-mobileXlFont !tw-font-medium'>
                  {noticeText}
                </div>
                <div className='int-btn tw-text-center tw-w-fit tw-m-auto'>
                <LocalizedLink to={ukPagePath?.path ? ukPagePath?.path : '/uk'} class="int-rest-link">
                    <p className=' tw-w-max tw-bg-accruent_white tw-m-auto tw-p-[14px_19px_14px_21px] tw-rounded-[12px] tw-text-accruent_sapphire tw-font-bold tw-mb-[30px]'>Rest of the world</p>
                  </LocalizedLink>
                  <button class="int-us-link tw-border-none tw-bg-none" onClick={closeModal}>
                    <p className='tw-m-[50px_15px_0px_15px] md:tw-mb-0 tw-pb-[46px] tw-text-accruent_white tw-font-bold tw-cursor-pointer tw-text-[16px] tw-mt-[0px]'>Continue to U.S Site ›</p>
                  </button>
                </div>
              </div>
            </div>
           
          </Modal>
            
        </div>
      }
    </div>
  )
}

// export default InternationalUsersNoticeModal;
