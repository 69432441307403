import React from "react";
import { LocalizedLink } from "src/components/commonComponents";
import "src/scss/components/_contactSupportCard.scss";

const ContactSupportCard = ({ cardContent }) => {
  const { field_card_title: title, field_card_content: content, field_card_cta: cta } = cardContent
  let re = /internal:/gi;
  let rawlink = cta?.uri;
  let finallink = rawlink && rawlink.replace(re, "");
  
  return (
    <div className="tw-contact-support-card tw-shadow-[0px_3px_6px_#00000029] tw-opacity-100 tw-h-auto tw-max-w-[480px] tw-mt-5 tw-pt-0 tw-pb-5 tw-px-0 tw-rounded-[20px] -md:tw-mb-9">
      <p className="tw-card-title tw-text-accruent_heading_color tw-mb-2 tw-pt-6 tw-pb-0 tw-px-8">{title}</p>
      <div className="tw-card-content tw-text-[20px] tw-mb-8 tw-px-8 tw-py-0"
        dangerouslySetInnerHTML={{
          __html: content?.value,
        }}
      />
      <div className="tw-tw-font-bold tw-font-Poppins tw-ml-8 tw-text-accruent_sapphire tw-font-bold tw-ml-8">
        <LocalizedLink to={finallink}>
          {cta?.title}
        </LocalizedLink>
      </div>
    </div>
  );
}

export default ContactSupportCard
