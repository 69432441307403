import React, { useState } from "react";
import { get } from "src/utils";
import extractMarketoIdAndSerial from "src/components/helpers/extractMarketoIdAndSerial";
import useMarketoForm from "src/components/helpers/useMarketoForm";
import getAuthorName from "../../../../helpers/getAuthorName";
import getResourceTagsList from "../../../../helpers/getResourceTagsList";
// import Hero from "src/components/hero/ResourcesHero";
import { ResourcesHero as Hero} from "src/components/templateComponents";
import cn from "classnames";
import loadable from '@loadable/component';
import useAddParamInHtmlLink from "src/components/helpers/useAddParamInHtmlLink";
import { ShareFollowBox } from "src/components/commonComponents";
const Form = loadable(() => import('src/components/resources/ResourceForm'))
const StickyBox = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.StickyBox
});

export const SingleWhitePaperPost = ({ data }) => {
  const { locale } = data;
  const authorName = getAuthorName(data?.relationships);
  const resourceTags = getResourceTagsList([data.industryIds, data.solutionIds, data.productIds])
  const formEntity = data?.relationships?.fieldMarketoFormNode

  let id = null;
  let serial = null;
  let gaLabel = null;
  let fieldFormHeader = null;


  if (formEntity?.fieldEmbedCode) {
    id = extractMarketoIdAndSerial(formEntity?.fieldEmbedCode).id;
    serial = extractMarketoIdAndSerial(formEntity?.fieldEmbedCode).serial;
    gaLabel = extractMarketoIdAndSerial(formEntity?.fieldEmbedCode).gaLabel;
    fieldFormHeader = formEntity?.fieldFormHeader
  }

  const [isFormLoaded, setFormLoaded] = useState(false)
  const formCallback = () => {
    setFormLoaded(true)
  }
  const { formTag } = useMarketoForm(serial, id, formCallback, "", gaLabel);

  let heroImage = get(data, "heroImage");
  useAddParamInHtmlLink()
  const featureTitle = (data?.fieldKeyFeaturesTitle) ? (data?.fieldKeyFeaturesTitle) : "Features covered are:"
  return (
    <section className="resources-page full-header-bg content-visibility-auto-mobile">
      <div className="container tw-nt-[0.75rem] lg:tw-mt-0">
        <section className="medium-wrapper tw-p-[0_15px] md:tw-pl-[0px] tw-relative tw-mt-0">
          <div className="columns tw-flex tw-flex-col-reverse lg:tw-flex-row">
            <div className="column tw-mt-6 tw-p-0 lg:tw-p-[12px] is-12-mobile">
              <div className="contents">
                {!!authorName && (
                  <h3>
                    By {authorName}
                  </h3>
                )}
                {data.body && (
                  <div
                    className="contents block dangerously-html-handle"
                    dangerouslySetInnerHTML={{ __html: data.body.value }}
                  ></div>
                )}
                {(data?.fieldKeyFeaturesBullets && data?.fieldKeyFeaturesBullets.length !== 0) && (
                  <div className="features-bullets">
                    <h3 className="title title-4 mt-2">
                      {featureTitle}
                    </h3>
                    <div className="single-resource-content-body">
                      <ul>
                        {data.fieldKeyFeaturesBullets.map((key) => (
                          <li key={key}><span>{key}</span></li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}

              </div>
              <div className="tw-mt-[52px]">
                <ShareFollowBox />
              </div>
            </div>

            <div className="tw-column is-12-mobile lg:tw-max-w-[475px] lg:tw-mb-[-424px] tw-relative tw-right-0 lg:tw-top-[-424px]">
              <div className="lg:tw-mt-[135px] tw-w-full tw-bg-white tw-relative">
                <StickyBox
                  scrollContainerClass={"resource-form"}
                >
                  <div className={cn({ "right-box": isFormLoaded })}>
                    <div className="border-box-top"></div>
                    {formTag &&
                      <div className="tw-min-h-[100px]">
                        <Form
                          formTag={formTag}
                          title={fieldFormHeader}
                          isFormLoaded={isFormLoaded}
                        />
                      </div>}
                    <div className="border-box-bottom"></div>

                  </div>
                </StickyBox>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  )
}

// export default SingleWhitePaperPost;
