import React from 'react'
import { LocalizedLink } from 'src/components/commonComponents';
import { StaticImage } from "gatsby-plugin-image";
import getImageObject from '../../../../helpers/getImageObject';
import "../../../scss/components/_myAccruentBox.scss";
import { Picture } from 'src/components/commonComponents/picture';

export const MyAccruentBlock = ({fieldCtaBlock, locale}) => {
  const {fieldQuickCtaTitle, fieldCtaUrl, fieldQuickCtaDesc } = fieldCtaBlock || ""
  const image = getImageObject(fieldCtaBlock?.relationships?.fieldQuickCtaImage, fieldCtaBlock?.fieldQuickCtaImage)

  return (
    <div className="tw-relative tw-bg-accruent_slightgray tw-rounded-[32px_0px_0px_32px]">
      <div className='tw-absolute tw-left-0 tw-right-0 tw-ml-auto tw-mr-auto tw-top-[-40px] '>
        <Picture image={image}
        className={"tw-m-auto !tw-block tw-w-[100px]"} 
        /> 

      </div>
      <div className=' tw-p-[40px] tw-pt-[70px]  tw-text-center'>
        {fieldQuickCtaTitle && <p className="tw-text-[24px] !tw-mb-[19px] tw-font-bold tw-font-Poppins tw-leading-[22px] tw-tracking-[0.18px] tw-pb-1 tw-text-accruent_sapphire">
          {fieldQuickCtaTitle}
        </p>}
        {fieldQuickCtaDesc && <p className="box-desc tw-text-heading-color tw-mt-[6px] !tw-mb-[19px] tw-text-base tw-leading-[22px] tw-tracking-[0.29px] md:tw-w-[200px]">
          {fieldQuickCtaDesc}
        </p>}
        {fieldCtaUrl?.url && <LocalizedLink className="tw-text-blue_tag tw-font-Poppins tw-font-bold tw-text-base tw-leading-4 tw-whitespace-nowrap" to={fieldCtaUrl?.url}>
          {fieldCtaUrl?.title}
        </LocalizedLink>}
      </div>
    </div>
  );
}

// export default MyAccruentBlock;

