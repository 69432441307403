const getCookie = () => {
  // Split cookie string and get all individual name=value pairs in an array
  var cookieArr = document.cookie.split(";");
  let arrCookie = []
  // Loop through the array elements
  for (var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");
    let obj
    /* Removing whitespace at the beginning of the cookie name
    and compare it with the given string */
    if (cookiePair[0].trim() == "OptanonConsent") {
      var cookiePairConsentParams = cookieArr[i].split("&");
      for (let j = 0; j < cookiePairConsentParams.length; j++) {
          var cookiePairConsentParam = cookiePairConsentParams[j].split("=");
          for (let k = 0; k < cookiePairConsentParam.length; k++) {
              if (cookiePairConsentParam[0] == 'groups')
              var optanonConsentVal = decodeURIComponent(cookiePairConsentParam[1])
          }
      }
      obj = {
          [cookiePair[0].trim()]: optanonConsentVal
      }
    } else {
      obj = {
        [cookiePair[0].trim()]: cookiePair[1]
      }
    }
    arrCookie.push(obj)
  }

  // Return null if not found
  return arrCookie;
}

export default getCookie;