import React from "react";
import { getImage } from "gatsby-plugin-image";
import { BgImage } from "gbimage-bridge";
import { LocalizedLink } from "../commonComponents";
const getImageObject = require("src/../helpers/getImageObject")
const gradient = `linear-gradient(52.08deg, rgba(37, 46, 102, 0.8) 26.65%, rgba(43, 82, 146, 0.75) 46.07%, rgba(49, 122, 195, 0.55) 61.22%, rgba(69, 162, 224, 0.3) 80.64%, rgba(86, 196, 249, 0) 101.23%)`;

const ContentBlockGrid = ({ data }) => {
  return (
    <div className="content-block-grid" component="ContentBlockGrid">
      <div className="columns is-multiline">
        {data.map((row, rowIndex) => {
          // this is the outermost array, it contains 1 or 2 items inside it, which are also arrays
          return (
            <div className="column is-12" key={rowIndex}>
              <div className="columns is-multiline">
                {row.map((entity, entityIndex) => {
                  // this is the inner array and each array is a
                  let image =
                    (getImage(getImageObject(entity?.relationships?.fieldContentBlock?.[0]?.relationships?.fieldMediaImage,{},"fieldMediaImage"))) ||
                    null;
                  if (!image) {
                    image = getImage(getImageObject(entity.relationships?.fieldMediaImage,{},"fieldMediaImage")) || null;
                  }
                  let correctSize;
                  if ((rowIndex + 1) % 2 !== 0 && entityIndex === 0)
                    correctSize = "is-7";
                  if ((rowIndex + 1) % 2 !== 0 && entityIndex === 1)
                    correctSize = "is-5";
                  if ((rowIndex + 1) % 2 === 0 && entityIndex === 0)
                    correctSize = "is-5";
                  if ((rowIndex + 1) % 2 === 0 && entityIndex === 1)
                    correctSize = "is-7";
                  if (row.length === 1) correctSize = "is-12";

                  let entityData;
                  if (entity.fieldContentBlock && entity.fieldContentBlock[0]) {
                    entityData = entity.fieldContentBlock[0].entity;
                  } else {
                    entityData = entity;
                  }

                  return (
                    <div
                      className={`column ${correctSize}`}
                      key={entityData.title}
                    >
                      <div>
                        {!!image && <BgImage
                          image={[gradient, image]}
                          className="content-block-grid-item--wrapper"
                        >
                          <div className="content-block-grid-item--item tw-h-full tw-flex tw-justify-between tw-flex-col tw-p-4 tw-text-white">
                            <div className="content-block-grid-item--content lg:tw-w-[70%]">
                              <h3 className="title has-text-white title-4">
                                {entityData.title}
                              </h3>
                              {!!entityData?.body?.value && <div
                                dangerouslySetInnerHTML={{
                                  __html: entityData.body.value,
                                }}
                                className="dangerously-html-handle"
                              />}
                            </div>
                            {entityData.fieldCtaUrl?.url &&
                              entityData.fieldCtaUrl?.title && (
                                <div>
                                  <LocalizedLink
                                    to={entityData.fieldCtaUrl.url}
                                    className="has-text-white"
                                  >
                                    {entityData.fieldCtaUrl.title}
                                  </LocalizedLink>
                                </div>
                              )}
                          </div>
                        </BgImage>}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ContentBlockGrid;
