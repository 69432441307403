import React from "react";
import { get } from "src/utils";
import extractMarketoIdAndSerial from "src/components/helpers/extractMarketoIdAndSerial";
import useMarketoForm from "src/components/helpers/useMarketoForm";
import getAuthorName from "../../../../helpers/getAuthorName";
import getResourceTagsList from "../../../../helpers/getResourceTagsList";
// import Hero from "src/components/hero/ResourcesHero";
import { ResourcesHero as Hero} from "src/components/templateComponents";
import isURL from "src/components/helpers/isURL";
import { Eyebrow } from "src/components/commonComponents";
import loadable from '@loadable/component';
import useAddParamInHtmlLink from "src/components/helpers/useAddParamInHtmlLink";
// const SideBar = loadable(() => import('../../components/resources/ResourceSideBar'));
const SideBar = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.SideBar
});
const StickyBox = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.StickyBox
});// const Picture = loadable(() => import('src/components/Picture'))
const Picture = loadable(() => import("src/components/commonComponents"), {
  resolveComponent: (components) => components.Picture
});
// const PopupVideo = loadable(() => import('src/components/popupVideo/PopupVideo'))
const PopupVideo = loadable(() => import("src/components/commonComponents"), {
  resolveComponent: (components) => components.PopupVideo
});

export const SingleInfographicPost = ({ data }) => {
  const { locale } = data;
  const authorName = getAuthorName(data?.relationships);
  const resourceTags = getResourceTagsList([data.industryIds, data.solutionIds, data.productIds])
  const formEntity = data?.relationships?.fieldMarketoFormNode

  let id = null;
  let serial = null;
  let gaLabel = null;
  const fieldMarketoFormEmbedCode = get(
    formEntity,
    "fieldEmbedCode"
  );
  const fieldFormHeader = get(formEntity, "fieldFormHeader")
  if (
    fieldMarketoFormEmbedCode &&
    fieldMarketoFormEmbedCode.includes("marketo")
  ) {
    id = extractMarketoIdAndSerial(fieldMarketoFormEmbedCode).id;
    serial = extractMarketoIdAndSerial(fieldMarketoFormEmbedCode).serial;
    gaLabel = extractMarketoIdAndSerial(fieldMarketoFormEmbedCode).gaLabel;

  }
  const { formTag } = useMarketoForm(serial, id, null, "", gaLabel);
  let heroImage = get(data, "heroImage");
  const heroVideoUrl = get(data, "relationships.fieldHeroVideo.fieldMediaVideoEmbedField");
  const heroVideoThumb = get(data, "relationships.fieldHeroVideo.relationships.thumbnail.url");
  const pdfLink = get(data, "fieldLinkToInfographic.url");
  useAddParamInHtmlLink()
  return (
    <section className="resources-page section !tw-pt-0 content-visibility-auto-mobile">
      <div className="container">
        <section className="medium-wrapper tw-text-[16px] md:tw-text-[18px] tw-py-0 tw-px-[15px] md:tw-px-0 md:tw-py-0 tw-mt-[32px] md:tw-mt-[72px]">
          <div className="columns md:tw-flex">
            <div className="tw-w-full lg:tw-w-[calc(100%-376px)] lg:tw-max-w-[calc(100%-376px)] [&]:tw-px-0 [&]:md:tw-p-[12px]">
              <div className="content">
                {!!authorName && (
                  <h3>
                    By {authorName}
                  </h3>
                )}

                {data.body && (
                  <div className=" tw-flex">
                    <div className=" tw-w-full">
                      <div claassName="content">
                        <div
                          className="single-resource-content-body dangerously-html-handle"
                          dangerouslySetInnerHTML={{ __html: data.body.value }}
                        ></div>
                      </div>
                    </div>
                  </div>
                )}
                <div className="middle-of-content tw-mb-[28px]">
                  {heroVideoUrl ?
                      <PopupVideo
                        videoUrl={heroVideoUrl}
                        thumbnail={heroVideoThumb}
                        thumbnailClass={`thumbnail-box banner-graphic tw-p-0 is-12-mobile  thumbnail-hero`}
                        isHero
                      />
                      :
                      (heroImage) ?
                        <div className={`banner-graphic tw-p-0 tw-min-h-[376px]`}>
                          <Picture
                            image={heroImage}
                            className={'resources-hero-image tw-h-full tw-w-full tw-min-h-[376px] md:tw-min-h-0 tw-object-cover'}
                          />
                        </div> : null
                    }
                </div>
                {pdfLink && isURL(pdfLink)
                  && pdfLink?.replace("/", "")
                    ?.trim()
                    ?.toUpperCase()
                    ?.endsWith('.PDF')
                  && <div className="tw-columns tw-flex">
                    <div className=" tw-w-full">
                      <div className="content tw-h-full tw-mt-[1rem]">
                        {/* <PDFViewer /> */}
                        {/* <embed className="pdf-viewer" src={pdfLink + '#toolbar=0'} /> */}
                        <object
                          data={pdfLink}
                          type="application/pdf"
                          className="pdf-viewer tw-flex tw-w-full tw-h-[856px] tw-flex-col"
                        >
                          Your browser does not support PDF viewing. Download the PDF <a href={pdfLink}>here</a>.
                        </object>
                      </div>
                    </div>
                  </div>
                }
                {formTag && (
                  <div className="tw-columns">
                    <div className="tw-column">
                      {fieldFormHeader && (
                        <h3 className="title-4">
                          {fieldFormHeader}
                        </h3>
                      )}
                      {formTag}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className=" tw-w-full tw-max-w-[376px] lg:tw-p-[12px] tw-mt-[32px] md:tw-mt-[0]">
              <div className="right-content sidebar-content tw-bg-accruent_white tw-w-full md:tw-w-[376px] tw-relative">
                <StickyBox
                  scrollContainerClass={"sidebar-scroll"}
                >
                  <div className="right-box">
                    <SideBar recommendedResources={data.rectifiedRecommended} />
                  </div>
                </StickyBox>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  )
}

// export default SingleInfographicPost;
