import React from "react";
import classNames from "classnames";
import Resources from "src/components/solutions/Resources";
import { StaticImage } from "gatsby-plugin-image";
import loadable from '@loadable/component';
import { LocalizedLink } from "../commonComponents";
const Video = loadable(() => import('src/components/commonComponents'), {resolveComponent: (components) => components.Video});
const videoPlaceholder = "../../../static/placeholders/video-placeholder.jpg";


/**
 * @type "a" - contentA - { `title`: String, `subtitle`: String, `image`: React Node,`buttonLink`: String, `cta`: { title, url: { path } }, `secondaryCta`: { title, url: { path } } `date`: String, `cards`[optional]: [{ TODO }], baseInfo: node }
 */
const CaseStudy = ({ type = "a", className, contentA, contentB }) => {
  if (type === "a") {
    const caseStudyLink = contentA.cta ? (contentA.cta.url.path || "") : contentA.buttonLink;
    return (
      <div className={classNames(className, "solutions-case-study-wrapper")}>
        <div className="columns">
          <div className="column">
            <div className="has-text-centered">
              {contentA.title && (
                <h2 className="title mt-3 title-3">
                  {contentA.title}
                </h2>
              )}
              <div className="columns is-gapless is-centered">
                <div className="column is-9-desktop">
                  <div className="box">
                    {contentA.wistiaVideoId ? (
                      <Video videoUrl={contentA.wistiaVideoId} />
                    ) : contentA.image ? (
                      <LocalizedLink to={caseStudyLink}>
                        <figure className="image has-position-relative">
                          <span className="case-study-play-icon">
                            <i
                              className="fa fa-play-circle fa-3x has-text-white"
                              aria-hidden="true"
                            ></i>
                          </span>
                          {contentA.image}
                        </figure>
                      </LocalizedLink>
                    ) : (
                      <LocalizedLink to={contentA.cta?.url?.path || ""}>
                        <figure className="image">
                          <StaticImage
                            src={videoPlaceholder}
                            aspectRatio={1.77}
                            alt="Placeholder"
                            placeholder="blurred"
                            layout="constrained"
                            decoding = "async"
                          />
                        </figure>
                      </LocalizedLink>
                    )}
                  </div>
                </div>
              </div>
              <div className="section">
                {contentA.subtitle && (
                 <h3 className="title case-study-subtitle title-3">
                    {contentA.subtitle}
                  </h3>
                )}
                <h4 className="subtitle is-size-5">
                  <LocalizedLink to="/resources/all-case-studies">
                    #CaseStudies
                  </LocalizedLink>
                </h4>
                {contentA.description && (
                  <div className="mt-2 mb-2">{contentA.description}</div>
                )}
                {contentA.buttonLink && (
                  <LocalizedLink
                    to={contentA.buttonLink}
                    className="is-primary"
                    isButton
                  >
                    Read Case Study
                  </LocalizedLink>
                )}
                {contentA.cta && (
                  <LocalizedLink
                    to={contentA.cta.url?.path || ""}
                    className="is-primary mx-1 my-1"
                    isButton
                  >
                    {contentA.cta.title}
                  </LocalizedLink>
                )}
                {contentA.secondaryCta && (
                  <LocalizedLink
                    to={contentA.secondaryCta.url?.path || ""}
                    className="is-kingfisher mx-1 my-1"
                    isButton
                  >
                    {contentA.secondaryCta.title}
                  </LocalizedLink>
                )}
              </div>
            </div>
          </div>
        </div>
        {contentA.cards && (
          <div>
             <h2
              className="title title-3 has-text-centered"
              id={`${contentA.industryId}-related-resources`}
            >
              Related Resources
            </h2>
            <Resources
              data={contentA.cards}
              isCarousel={false}
              sliderId={`${contentA.industryId}-related-resources`}
            />
          </div>
        )}
      </div>
    );
  }

  if (type === "b") {
    return (
      <div className={classNames(className, "solutions-case-study-wrapper")}>
        <div className="has-text-centered mb-4">
          <div className="is-size-3 is-uppercase has-text-weight-semibold mb-3">
            {contentB.title}
          </div>
          <div className="box">
            <figure className="image is-16by9 video-card-with-text">
              <div className="text-over-video">
                <div className="text-over-video-content">
                  <div className="has-text-centered">
                    <i
                      className="fa fa-play-circle fa-4x has-text-white"
                      aria-hidden="true"
                    ></i>
                  </div>
                  <div className="is-size-3 mb-3">{contentB.videoTitle}</div>
                  <p className="has-text-weight-light mb-3">
                    {contentB.videoSubtitle}
                  </p>
                  <LocalizedLink to={contentB.videoButtonLink}>
                    <button className="button is-white">
                      {contentB.videoButtonText}
                    </button>
                  </LocalizedLink>
                </div>
              </div>
            </figure>
          </div>
          {contentB.cards && (
            <>
              <h2
                className="title title-3 has-text-centered"
                id={`${contentB.industryId}-related-resources`}
              >
                Related Resources
              </h2>
              <Resources
                data={contentB.cards}
                isCarousel={false}
                sliderId={`${contentB.industryId}-related-resources`}
              />
            </>
          )}
        </div>
      </div>
    );
  }
};

export default CaseStudy;
