export const fieldEmbedCode = `<script src="//app-abc.marketo.com/js/forms2/js/forms2.min.js"></script>
<form id="mktoForm_1565"></form>
<script>MktoForms2.loadForm("//app-abc.marketo.com", "167-BOY-362", 1565 , function(form) {
    dataLayer.push({
      'event': 'gaTriggerEvent',
      'gaCategory': 'CTA Engagement',
      'gaAction': 'Form Load',
      'gaLabel': 'Demo Request',
    });
    form.onSuccess(function(values, followUpUrl) {
      dataLayer.push({
        'event': 'gaTriggerEvent',
        'gaCategory': 'CTA Engagement',
        'gaAction': 'Form Submission',
        'gaLabel': 'Demo Request',
      });
    });
  });
</script>`;