import React, { useContext } from "react";
import loadable from '@loadable/component';
import getBenefitsData from "../helpers/getBenefitsData";
import getTeaserCarouselDataFromResource from "../helpers/getTeaserCarouselDataFromResource"
import getQaData from "../helpers/getQaData";
import createRelatedResources from "src/components/helpers/createRelatedResources";
import ResourcesContext from "src/components/helpers/ResourcesMainPageContext";
import useAddParamInHtmlLink from "src/components/helpers/useAddParamInHtmlLink";
import SupportProductComponent from "../supportProductComponent";
import { QuickLinks } from "../templateComponents/solutionsTemplate/quickLinks";
import ROICalculator from "../blocks/ROICalculator";
import getBannerData from "src/components/helpers/getBannerData";
import {  Banner  } from "src/components/commonComponents";
const AccruentImg = loadable(() => import("src/components/commonComponents"),{resolveComponent: (components) => components.AccruentImg});
// const ContentBlockCloseUp = loadable(() => import("src/components/industries/ContentBlockCloseUp"));
const ContentBlockCloseUp = loadable(() => import("src/components/commonComponents"),{resolveComponent: (components) => components.ContentBlockCloseUp});
const IntroContentSection = loadable(() => import("src/components/solutions/IntroContentSection"));
const BenefitsWrapper = loadable(() => import("./benefitsCard"), { resolveComponent: (components) => components.BenefitsWrapper });
const Testimonial = loadable(() => import("src/components/solutions/QuoteTestimonial"));
const Statistics = loadable(() => import("./statistics"), { resolveComponent: (components) => components.Statistics });;
const CompanyLogos = loadable(() => import("src/components/solutions/CompanyLogos"));
const FooterCta = loadable(() => import("src/components/services/footerCta"));
const VideoSection = loadable(() => import("src/components/services/VideoSection"));
const Resources = loadable(() => import("src/components/solutions/Resources"));
const Features = loadable(() => import("src/components/products/Features"));
const LocalizedLink = loadable(() => import("./localizedLink"), { resolveComponent: (components) => components.LocalizedLink });
const QAList = loadable(() => import("src/components/QAList"));
const FullWidthImg = loadable(() => import("./fullWidthImg"), { resolveComponent: (components) => components.FullWidthImage });
const Helmet = loadable(() => import("react-helmet"));
const FeatureItemsSection = loadable(() => import("../products/FeatureItemsSection"));
const CaseStudiesBlock = loadable(() => import("src/components/blocks/CaseStudiesBlock"));
const TeaserCarouselBlock = loadable(() => import('./teaserCarouselBlock'), {
  resolveComponent: (components) => components.TeaserCarouselBlock
 });
const CareerOpportunities = loadable(() => import("src/components/careers/CareerOpportunities"));
// const ROICalculator = loadable(() => import("src/components/blocks/ROICalculator"));
const TabsSectionBlock = loadable(() => import("../blocks/TabsSectionBlock"));
const TextAreaBlock = loadable(() => import("./TextAreaBlock"), { resolveComponent: (components) => components.TextAreaBlock });
const SimpleResourceBlock = loadable(() => import("../blocks/SimpleResourceBlock"));
const KeyFeatureSection = loadable(() => import("../blocks/KeyFeatureSection"));
const QuoteBlock = loadable(() => import('./quoteBlock'), { resolveComponent: (components) => components.QuoteBlock });
const ContentMarketoForm = loadable(() => import("./forms/ContentMarketoForm"), { resolveComponent: (components) => components.ContentMarketoForm });
const TabSectionBlockWithFlag = loadable(() => import("../blocks/TabSectionBlockWithFlag"));
const EventsContent = loadable(() => import("../eventSection/EventsContent"));
const PricingSection = loadable(() => import("./pricingSection"), { resolveComponent: (components) => components.PricingSection });
const Locations = loadable(() => import("src/components/locations/Locations"));
const AboutUsContent = loadable(() => import("src/components/AboutUsContent"));
const BannerTiles = loadable(() => import("./bannerTiles"), { resolveComponent: (components) => components.BannerTiles });
const getImageObject = require("src/../helpers/getImageObject")

const getIntroContentData = (entity, locale) => {
  let image = null;
  let target = null;
  let rel = null;
  const imgObj = getImageObject(entity?.relationships?.fieldImgBullets?.relationships?.fieldImgBulletImg, entity?.relationships?.fieldImgBullets?.fieldImgBulletImg)
  if (imgObj?.gatsbyImage) {
    image = (
      <AccruentImg
        file={imgObj}
      />
    );
  }
  let cta = null;
  let translatedFieldIntroContentItem = []
  translatedFieldIntroContentItem = entity?.relationships?.fieldIntroContentItem
  const { pageTypeContext } = useContext(ResourcesContext);
  const checkForLandingPage = pageTypeContext && pageTypeContext === "landing_page";

  // Below we are checking if the the target="blank" and rel values have been selected in Drupal. Then
  // we are setting the variables to those values then later adding them to the appropriate a tag below.
  if (
    translatedFieldIntroContentItem?.[0]?.fieldCtaUrl?.options?.attributes
      ?.target
  ) {
    target =
      translatedFieldIntroContentItem?.[0]?.fieldCtaUrl.options.attributes
        .target;
  }
  if (
    translatedFieldIntroContentItem?.[0]?.fieldCtaUrl?.options?.attributes
      ?.rel
  ) {
    rel =
      translatedFieldIntroContentItem?.[0]?.fieldCtaUrl.options.attributes.rel;
  }
  let preHeading = '';
  // items is an array of data to be displayed below the image
  const items = translatedFieldIntroContentItem?.map((contentItem, i) => {
    // if (contentItem.fieldCtaUrl) cta = contentItem.fieldCtaUrl;
    if (i === 0) preHeading = entity?.fieldPreHeading
    return {
      type: contentItem?.fieldStyle === "staggered"
        ? "c"
        : (contentItem?.fieldStyle === "align_left" || contentItem?.fieldStyle === "align_left_with_tick_content")
          ? "h"
          : (contentItem?.fieldStyle === "content_left_cta_right_patterned_bg" || contentItem?.fieldStyle === "content_left_cta_right_grey_bg")
            ? "j"
            :
            (contentItem?.fieldStyle === "content_left_cta_left_image_right_grey_bg")
              ?
              "k" :
              (contentItem?.fieldStyle === "image_left_content_centered_cta_right")
                ?
                "l"
                : "d",
      preHeading: preHeading,
      title: contentItem?.title,
      nid: contentItem?.nid,
      description: (
        <>
          <div dangerouslySetInnerHTML={{ __html: contentItem?.body?.value }} className="dangerously-html-handle" />
          {contentItem?.fieldCtaUrl && (
            <div className="mt-5">
              <LocalizedLink
                isButton
                className="is-primary"
                to={contentItem.fieldCtaUrl.url}
                target={checkForLandingPage ? target && target === "_self" ? "" : "_blank" : target}
                rel={rel}
              >
                {contentItem.fieldCtaUrl.title}
              </LocalizedLink>
            </div>
          )}
        </>
      ),
      fieldStyle: contentItem?.fieldStyle,
      fieldGraphic: getImageObject(contentItem?.relationships?.fieldGraphic, contentItem?.fieldGraphic),
      fieldLogoImages: getImageObject(entity?.relationships?.fieldLogoImages, entity?.fieldLogoImages, "fieldLogoImages")
    };
  });
  const bullets = entity?.relationships?.fieldImgBullets?.fieldBulletPoint || null;
  const hasBulletsMultipleOfThree = bullets && bullets.length % 3 === 0;

  return { image, items, bullets, cta, hasBulletsMultipleOfThree };
};

const getQuoteData = (quote) => {
  let imgObj = getImageObject(quote.relationships?.fieldImage, quote.fieldImage)
  return {
    name: quote.title.split(",")[0],
    position: quote.title.split(",")[1],
    quote: quote.fieldQuoteBody,
    image: imgObj?.gatsbyImage ? imgObj : null,
    nid: quote.nid,
  };
};

const getFeatureData = (entity) => {
  const image =
    entity.fieldMediaImage?.localFile || null;
  const bullets = entity?.relationships?.fieldFeatureItem?.map(({ entity: item }) => {
    return {
      title: item.title,
      hideTitle: item.fieldHideTitle,
      body: item.fieldFeatureItemBody,
    };
  });
  const sectionBody = entity.body === null ? null : entity.body.value;
  const title = entity.title;
  return { image, bullets, title, sectionBody };
};

export const BrandLogoSection = ({ entityId, contentEntity, isTeaser = false }) => {
  const logos = getImageObject(contentEntity?.relationships?.fieldLogoImages, contentEntity?.fieldLogoImages, "fieldLogoImages")
  return (
    <CompanyLogos
      data={logos}
      fieldTitle={
        !contentEntity?.fieldHideTitle ? contentEntity?.title : null
      }
      nid={contentEntity.nid}
      entityId={entityId}
      fieldBody={contentEntity?.fieldBody}
      fieldLogoStyle={contentEntity?.fieldLogoStyle}
      fieldShowCtaButton={contentEntity?.fieldShowCtaButton}
      fieldCtaUrl={contentEntity?.fieldCtaUrl}
      isTeaser={isTeaser}
    />
  )
};

const QuoteNode = ({ contentEntity }) => {
  if (contentEntity?.type?.id === 'block_quote') {
    const quoteData = {
      quote: contentEntity.quotes?.[0]?.fieldQuoteBody,
      quoteAuthor: contentEntity.quotes?.[0]?.title,
      quoteAuthorDesignation: contentEntity.quotes?.[0]?.fieldCustomerDesignation,
      quoteAuthorCompany: contentEntity.quotes?.[0]?.company,
    }
    return (
      <QuoteBlock
        data={quoteData}
        isCompany
        isNarrow
        hasTextBig
        hasAuthorLeft
        hasBackgroundTransparent
      />
    );
  }
  return null;
};


export const ContentConnector = ({ data, entityId, index, pageType = null, checkForRelatedProductCard, path = null, locale = "en", heroImage = null, isSolutionsSidebar = true, pageTitleForMarketo="", formFullWidth = false }) => {
  useAddParamInHtmlLink()
  const contentEntity = data?.fieldProxy;
  const newResourceReferenceField = contentEntity?.relationships?.fieldResourceReference?.length > 0 && contentEntity?.relationships?.fieldRelatedItem?.length === 0 ? true : false
  const title = data?.title;
  const hasAnchor = data?.fieldMakeAnchor && !!title;
  const isEMS = entityId === "48";
  let id;
  if (hasAnchor) {
    id = title.replace(/\W/g, "_").toLowerCase();
  }
  const BrandLogoSection = ({ entityId, contentEntity, isTeaser = false }) => (
    <CompanyLogos
      data={getImageObject(contentEntity?.relationships?.fieldLogoImages, contentEntity?.fieldLogoImages, "fieldLogoImages")}
      fieldTitle={
        !contentEntity.fieldHideTitle ? contentEntity.title : null
      }
      nid={contentEntity.nid}
      entityId={entityId}
      fieldBody={contentEntity?.fieldBody}
      fieldLogoStyle={contentEntity?.fieldLogoStyle}
      fieldShowCtaButton={contentEntity?.fieldShowCtaButton}
      fieldCtaUrl={contentEntity?.fieldCtaUrl}
      isTeaser={isTeaser}
    />
  );
  switch (contentEntity?.type?.id) {
    case "benefits_section":
      const benefitsData = getBenefitsData(contentEntity, pageType, checkForRelatedProductCard, locale);
      return (
        !!benefitsData && (
          <>
            <section className="section tw-benefits-section" id={id}>
              <BenefitsWrapper key={entityId} benefits={[benefitsData]} entityId={entityId} path={path} locale={locale} isSolutionsSidebar={isSolutionsSidebar}/>
            </section>
          </>
        )
      );
    case "roi_calculator":
      return (
        <section className="section" id={id}>
          <ROICalculator
            data={contentEntity}
          />
        </section>
      );
    case "content_block_section":
      // Todo: Add content block grid after adding masonry format.
      return (
        <section className="section" id={id}>
          <ContentBlockCloseUp
            contentBlockSection={contentEntity}
            reverseRow={true}
            locale={locale}
          />
        </section>
      );
    case "video_section":
      return (
        <section className="section" id={id}>
          <VideoSection entity={contentEntity} />
        </section>
      );
    case "brand_logos_section":
      return (
        <section className="section" id={id}>
          {isEMS ? (
            <div id="customers" className="jumplinks-anchor tw-block relative tw-top-[-132px] tw-invisible"></div>
          ) : null}
          <BrandLogoSection entityId={entityId} contentEntity={contentEntity} />
        </section>
      );
    case "statistics":
      return (
        <section className="section" id={id}>
          <Statistics
            entity={contentEntity}
            statistics={contentEntity?.relationships?.fieldStatistic}
            nid={contentEntity.nid}
          />
        </section>
      );
    case "block_quote":
      return (
        <section className="section quote-section" id={id}>
          <QuoteNode contentEntity={contentEntity} />
        </section>
      );
    case "cta":
      return (
        <section className="section" id={id} component="FooterCta" nid={contentEntity.nid}>
          <FooterCta entity={contentEntity} isEMS={isEMS} />
        </section>
      );
    case "intro_content_section":
      return (
        <section className="section" id={id} component="IntroContentSection" nid={contentEntity.nid}>
          <IntroContentSection
            nid={contentEntity.nid}
            data={[getIntroContentData(contentEntity, locale)]}
            entityId={entityId}
            path={path}
          />
        </section>
      );
    case "related_section":
      return (
        <section className="section" id={id}>
          <Resources
            isCarousel={false}
            title={
              contentEntity.fieldHideTitle ? undefined : contentEntity.title
            }
            fieldPreHeading={contentEntity?.fieldPreHeading}
            fieldBody={contentEntity?.fieldBody}
            fieldRelatedFormat={contentEntity?.fieldRelatedFormat}
            fieldCtaUrl={contentEntity?.fieldCtaUrl}
            data={createRelatedResources(newResourceReferenceField ? contentEntity?.relationships?.fieldResourceReference : contentEntity?.relationships?.fieldRelatedItem, {
              isTaxonomyPage: true,
              hasDescription: true,
              locale,
              newResourceReferenceField: newResourceReferenceField
            })}
          />
        </section>
      );
    case "industry_features":
      const feature = getFeatureData(contentEntity);
      return (
        <section className="section" id={id} nid={contentEntity.nid} component="FeatureItemsSection">
          {isEMS ? (
            <FeatureItemsSection
              image={feature.image}
              bullets={feature.bullets}
              title={feature.title}
              sectionBody={feature.sectionBody}
            />
          ) : (
            <Features
              image={feature.image}
              bullets={feature.bullets}
              title={feature.title}
              sectionBody={feature.sectionBody}
              hasTitleAndBodyCentered
            />
          )}
        </section>
      );
    case "qa_list":
      const questionAnswersData = getQaData(contentEntity);
      return (
        <section className="section" id={id}>
          {contentEntity.fieldQaSchema ? (
            <Helmet>
              <script type="application/ld+json">{`
                ${contentEntity.fieldQaSchema}
              `}</script>
            </Helmet>
          ) : (
            ""
          )}

          <QAList
            contentEntity={contentEntity}
            data={questionAnswersData}
          />

        </section>
      );
    case "full_width_img":
      return (
        <section className="section" id={id}>
          <FullWidthImg data={contentEntity} />
        </section>
      );
    case "case_studies_section":
      return (
        <section className="section" id={id}>
          <CaseStudiesBlock
            locale={locale}
            contentCaseStudiesSection={contentEntity}
          />
        </section>
      );
    case "resource_carousel_teaser":
      const contentCarouselSection = getTeaserCarouselDataFromResource(contentEntity);
      if (pageType && pageType === 'homepage') {
        return (
          <div className="customer-section">
            <section>
              <div className="container carousel-wrapper">
                <div className="columns py-0">
                  <div className="column is-full">
                    {contentEntity?.relationships?.fieldBrandLogosSection && (
                      <BrandLogoSection
                        contentEntity={contentEntity.relationships.fieldBrandLogosSection}
                        entityId={contentEntity.relationships.fieldBrandLogosSection.nid}
                        isTeaser
                      />
                    )}
                    {contentEntity?.relationships?.fieldResourceCarouselItem && (
                      <TeaserCarouselBlock
                        contentCarouselSection={contentCarouselSection}
                        fieldResourceCarouselItem={contentEntity?.relationships?.fieldResourceCarouselItem}
                        locale={locale}
                        isHome={true}
                        homepageBackground={contentEntity?.fieldHomepageBackground}
                      />
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
        )
      } else {
        return (
          <section className="section" id={id}>
            {contentEntity?.relationships?.fieldBrandLogosSection && (
              <BrandLogoSection
                contentEntity={contentEntity.relationships.fieldBrandLogosSection}
                entityId={contentEntity.relationships.fieldBrandLogosSection?.nid}
                isTeaser
              />
            )}
            <TeaserCarouselBlock
              contentCarouselSection={contentCarouselSection}
              fieldResourceCarouselItem={contentEntity?.relationships?.fieldResourceCarouselItem}
              locale={locale}
            />
          </section>
        );
      }
    case "career_opportunities":
      if (!contentEntity?.fieldFetchCareerOpportunities) {
        return <></>;
      }
      return (
        <section className="section" id={id}>
          <CareerOpportunities data={contentEntity} isLoadMore={true} />
        </section>
      );
    case "tabs_section":
      if (contentEntity?.fieldTabSectionFormat && contentEntity?.fieldTabSectionFormat === "tab_section_left_align") {
        return (
          <TabSectionBlockWithFlag
            contentTabsSection={contentEntity}
          />
        )
      } else {
        return (
          <section className="section" id={id}>
            <TabsSectionBlock
              contentTabsSection={contentEntity}
            />
          </section>
        )
      }

    case "text_area":
      return (
        <section className="section tw-section" id={id}>
          <TextAreaBlock
            contentEntity={contentEntity}
            index={index}
            pageType={pageType}
            heroImage={heroImage}
          />
        </section>
      )
    case "simple_resource_block":
      return (
        <section className="section" id={id}>
          <SimpleResourceBlock
            contentEntity={contentEntity}
          />
        </section>
      )
    case "key_features":
      return (
        <section className="section" id={id}>
          <KeyFeatureSection
            entity={contentEntity}
            locale={locale}
          />
        </section>
      )
    case "marketo_forms":
      return (
        <section className="section" id={id}>
          <ContentMarketoForm
            entity={contentEntity}
            locale={locale}
            pageTitle={pageTitleForMarketo}
            formFullWidth={formFullWidth}
          />
        </section>
      )
    case "headquarter_section":
      return (
        <section className="section" id={id}>
          <Locations
            entity={contentEntity}
          />
        </section>
      )
    case "about_us_content_section":
      return (
        <section className="section" id={id}>
          <AboutUsContent
            entity={contentEntity}
            checkForTable={true}
          />
        </section>
      )
    case "pricing_section":
      return (
        <section className="section" id={id}>
          <PricingSection
            fieldPricingSection={contentEntity}
          />
        </section>
      )
    case "banner_tiles":
      return (
        <section className="section" id={id}>
          <BannerTiles data={contentEntity} />
        </section>
      )
    case "event_overview_section":
      return (
        <section className="section tw-event-overview-section" id={id}>
          <EventsContent
            entity={contentEntity}
            locale={locale}
          />
        </section>
      )
    case "products_cards_section":
      return (
        <section className="section" id={id}>
          <SupportProductComponent
            entity={contentEntity}
            locale={locale}
          />
        </section>
      )
    case "quick_cta_block": 
    return(
      <section className="section" id={id}>
          <QuickLinks
            quickLinks={contentEntity?.relationships?.fieldCtaS}
            fieldCtaBlock={contentEntity}
          />
        </section>
    )
    case "report_banner":
      return(
        <section className="section lg:tw-max-w-[1160px] lg:tw-m-auto" id={id}>
          <Banner
            {...getBannerData(contentEntity)}
          />
        </section>
      )
    default:
      return <React.Fragment />;
  }
};

