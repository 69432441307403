import React, { useContext } from "react";
import CaseStudy from "src/components/solutions/CaseStudy";
import MasonryGrid from "src/components/products/ContentBlockGrid";
import chunkArray from "src/components/helpers/chunkArray";
import ExpandableBlock from "src/components/blocks/ExpandableBlock";
import ZigZagBlock from "src/components/blocks/ZigZagBlock";
import ContentBlockCarousel from "src/components/blocks/ContentBlockCarousel";
import ImageLeftRightBlock from "src/components/blocks/ImageLeftRightBlock";
import FullWidthImageBlock from "../blocks/FullWidthImageBlock";
import { get } from "src/utils";
import getTitleId from "../helpers/getTitleId";
import getPublishedData from "src/../helpers/getPublishedData";
import findSiteType from "../helpers/findSiteType";
import VerticalStack from "src/components/blocks/VerticalStack";
import { schemaMarkupRendering } from "../helpers/schemaMarkupRendering";
import ResourcesContext from "../helpers/ResourcesMainPageContext";
import { StaticImage } from "gatsby-plugin-image";
import "src/scss/components/_contentBlockSection.scss";
import { AccruentImg } from "./accruentImg";
import { BgPatternImage } from "./bgPatternImage";
import { LocalizedLink } from "./localizedLink";
import { PopupVideo } from "./popupVideo";
const getImageObject = require("src/../helpers/getImageObject")

export const ContentBlockCloseUp = ({
  contentBlockSection,
  reverseRow = false,
  className = "",
  locale = "en",
}) => {
  const contentBlockSectionCopy = { ...contentBlockSection }
  contentBlockSectionCopy.relationships.fieldContentBlock = getPublishedData(contentBlockSectionCopy?.relationships?.fieldContentBlock)

  const {
    blockSectionTitle: title,
    blockSectionTitleLink: titleLink,
    hideBlockSectionTitle: fieldHideTitle,
    blockSectionDesc: body,
    fieldSectionCta: ctaList,
    fieldShowTitleFullWidth: fieldShowTitleFullWidth
  } = contentBlockSectionCopy;
  const fieldContentBlock = contentBlockSectionCopy.relationships.fieldContentBlock
  const isMc = findSiteType('mc');
  const isEms = findSiteType('ems');

  const blockSectionType = contentBlockSectionCopy.blockSectionType
  const blockSectionFormat = blockSectionType === 'vertical_stacked_cards'
    ? `content-block-vertical-stack ${isMc ? 'is-mc' : isEms ? 'is-ems' : ''}`
    : contentBlockSectionCopy.blockSectionFormat;
  const masonryGrid = getContentBlockGridData(contentBlockSectionCopy);
  if (masonryGrid && blockSectionType !== 'vertical_stacked_cards') {
    return <MasonryGrid data={masonryGrid} />;
  }

  const { pageTypeContext } = useContext(ResourcesContext);
  const fieldShowBackgroundPattern = contentBlockSectionCopy.fieldShowBackgroundPattern
  const fieldBackgroundColor = contentBlockSectionCopy.fieldBackgroundColor
  const fieldIcon = contentBlockSectionCopy.fieldIcon
  const isBackground = pageTypeContext === 'about_us' ? false : (fieldShowBackgroundPattern || !!fieldBackgroundColor)
  const selectedIcon = fieldIcon?.iconName
  const selectedIconStyle = fieldIcon?.style ? fieldIcon?.style : "fa";
  const { fieldSectionCta } = contentBlockSectionCopy || ""
  const renderBlockSection = (sectionType, sectionData, fieldSectionCta) => {
    switch (sectionType) {
      case "expandable":
        return <ExpandableBlock data={sectionData} fieldSectionCta={fieldSectionCta || ""} />
      case "zigzag":
        return <ZigZagBlock data={sectionData} isArrowLink={!fieldShowBackgroundPattern} fieldShowBackgroundPattern={fieldShowBackgroundPattern}/>
      case "carousel":
        return <ContentBlockCarousel carouselData={sectionData} />
      case "images_left":
      case "images_right":
        return <ImageLeftRightBlock data={sectionData} sectionType={sectionType} />
      case "full_width_image":
        return <FullWidthImageBlock data={sectionData} />
      case "vertical_stacked_cards":
        return <VerticalStack data={sectionData} />
      default:
        return <React.Fragment />
    }
  }
  return (
    <div
      className={`content-block-section tw-content-block-section tw-content-block-close-up tw-relative tw-pb-3 ${isBackground ? 'content-block-bg-pattern-color tw-pt-[76px] full-width-bg-wrapper' : ''}  ${blockSectionFormat} ${blockSectionType}`}
      component="ContentBlockCloseUp"
      nid={contentBlockSection.nid}
      id={getTitleId(title)}
    >
      {schemaMarkupRendering(fieldContentBlock)}
      {isBackground &&
        (fieldShowBackgroundPattern ?
          <div className="bg-image-container">
            <BgPatternImage className="bg-image" >
            </BgPatternImage>
          </div> :
          <div className={`content-block-bg content-block-bg-color `} style={{ background: fieldBackgroundColor }}></div>)
      }
      {!!selectedIcon &&
        <i
          className={`${selectedIconStyle} fa-${selectedIcon} has-text-sapphire content-block-icon tw-h-20 tw-w-[100px] tw-text-[100%] tw-mb-5`}
        ></i>
      }
      {!fieldHideTitle &&
        (titleLink ? (
          <LocalizedLink to={titleLink} className="title-link">
            {!fieldShowTitleFullWidth ? <h2 className="tw-content-title">{title + ' >'}</h2> : <h3 className="tw-content-title !tw-w-full">{title + ' >'}</h3>}
          </LocalizedLink>
        ) : (
          <>
            {!fieldShowTitleFullWidth ? <h2 className="tw-content-title">{title}</h2> : <h3 className="tw-content-title !tw-w-full">{title}</h3>}
          </>
          
        ))}
      {!!body?.value && (
        <div className="tw-content-subtitle">
          <div dangerouslySetInnerHTML={{ __html: body.value }} className="dangerously-html-handle tw-cta-content-block" />
        </div>
      )}
      {blockSectionType && blockSectionType === "full_width_image" && ctaList?.map((cta, i) => {
        return (
          <LocalizedLink
            className={`${
              i == 0
                ? "tw-bg-accruent_sapphire tw-text-accruent_white hover:tw-text-[#e6e6e6] lg:tw-mr-[22px] tw-mb-4 "
                : " tw-bg-transparent tw-text-accruent_sapphire tw-border-[1px] tw-border-accruent_sapphire hover:tw-bg-accruent_sapphire hover:tw-text-accruent_white tw-mb-4 "
            } tw-inline-block tw-h-auto tw-rounded-[8px] tw-px-6 tw-py-[13px] tw-text-[12px] tw-font-bold tw-font-Poppins`}
            to={cta?.url}
          >
            {cta?.title + " >"}
          </LocalizedLink>
        );
      })}
      <div className="tw-mb-[32px] md:tw-mb-[76px] tw-flex md:tw-block tw-flex-col">
      {blockSectionType && blockSectionType === "carousel" && ctaList?.map((cta, i) => {
        return (
          <LocalizedLink
            className={`${
              i == 0
                ? "tw-bg-accruent_sapphire hover:tw-bg-[#001964] tw-text-accruent_white hover:tw-text-[#e6e6e6] tw-border-transparent tw-whitespace-break-spaces tw-mt-1 md:-tw-mt-1 "
                : " tw-bg-transparent tw-text-accruent_sapphire tw-border-accruent_sapphire hover:tw-bg-accruent_sapphire hover:tw-text-accruent_white tw-whitespace-nowrap tw-mt-[22px] md:-tw-mt-1 "
            } md:tw-mr-[22px] tw-rounded-xl tw-px-6 tw-py-[14px] tw-text-[16px] tw-font-bold tw-font-Poppins tw-h-[68px] md:tw-h-auto tw-border-[1px] tw-inline-flex tw-relative tw-align-top tw-leading-4 tw-tracking-[1.42px] tw-justify-center tw-items-center tw-text-center tw-cursor-pointer`}
            to={cta?.url}
          >
            {cta?.title}
          </LocalizedLink>
        );
      })}
      </div>
      {blockSectionType
        ? renderBlockSection(blockSectionType, fieldContentBlock, fieldSectionCta)
        : fieldContentBlock.map((entity, index) => {
          if (!entity) return <React.Fragment />;
          switch (entity.fieldFormat) {
            case "case_study":
              return getCaseStudy(entity);
            case "ebook":
              return getEBook(entity);
            default:
              return getContentBlockCard(
                entity,
                className,
                reverseRow,
                index
              )
          }
        })}
    </div>
  );
};

const getCaseStudy = (entity) => {

  const fieldImage = getImageObject(entity?.relationships?.fieldImage, entity?.fieldImage)

  // aspectRatio: 1.777
  const imageToDisplay = (
    <AccruentImg
      file={getImageObject(entity?.relationships?.fieldMediaImage, {}, "fieldMediaImage")}
      className="has-border-radius-medium"
    />
  );
  const description = (
    <>
      {entity.fieldSubtitle && (
        <h6 className="title title-6 is-uppercase dangerously-html-handle">
          <div dangerouslySetInnerHTML={{ __html: entity.fieldSubtitle }} />
        </h6>
      )}
      {entity.body && (
        <div
          className="is-size-5 dangerously-html-handle"
          dangerouslySetInnerHTML={{ __html: entity.body.value }}
        />
      )}
      {fieldImage?.gatsbyImage && (
        <div className="content-block-case-study-description">
          <AccruentImg file={fieldImage} />
        </div>
      )}
    </>
  );
  const caseStudyContent = {
    title: "",
    subtitle: entity.title,
    image: imageToDisplay,
    date: entity.entityCreated,
    cta: entity.fieldCtaUrl,
    secondaryCta: entity.fieldSecondaryCta,
    description,
  };
  return <CaseStudy type="a" contentA={caseStudyContent} key={entity.uuid} />;
};

const getEBook = (entity) => (
  <React.Fragment>
    <div className="is-size-3 is-uppercase has-text-centered has-text-weight-semibold mb-3">
      {entity.title}
    </div>
    {getImageToDisplay(entity, "content-block-ebook-media")}
    {entity.body && (
      <div
        className="mt-3 dangerously-html-handle"
        dangerouslySetInnerHTML={{
          __html: entity.body.value,
        }}
      />
    )}
    <div className="has-text-centered">
      <LocalizedLink
        to={entity.fieldCtaUrl?.url || ""}
        className="is-primary mt-2"
        isButton
      >
        {entity.fieldCtaUrl?.title}
      </LocalizedLink>
    </div>
  </React.Fragment>
);

const getContentBlockCard = (entity, className, reverseRow, index) => {
  let imgClassName = "";
  let aspectRatio = 1.77;
  if (entity.fieldFormat === "min_img_height") {
    aspectRatio = null;
  } else {
    imgClassName = "content-block-closeup-image has-height-100-percent";
  }

  return (
    <div
      className={`${className}${entity.fieldFormat === "min_img_height" ? "" : "tw-bg-white tw-rounded-md tw-text-heading_color tw-block tw-shadow-[1px_10px_20px_rgba(0,0,0,.05)] tw-border-[hsla(180,3%,57%,.3)] tw-border tw-border-solid "
        }${reverseRow && index % 2 ? "reverse-rows " : ""
        } tw-relative tw-block lg:tw-flex tw-mt-9 tw-mb-0 tw-p-0 tw-justify-center`}
      key={entity.uuid}
    >
      <div className="tw-m-0 tw-p-0 tw-flex-none lg:tw-w-2/4 tw-block">
        {getImageToDisplay(entity, imgClassName, aspectRatio)}
      </div>
      <div className="tw-m-0 tw-p-0 tw-flex-none lg:tw-w-2/4 tw-block">
        <div
          className={`${entity.fieldFormat === "min_img_height"
            ? ""
            : "tw-h-full tw-p-9 tw-flex tw-justify-center tw-flex-col"
            }`}
        >
          {!!entity.fieldPreHeading && (
            <div className="pre-heading tw-text-[12px] tw-leading-4 tw-tracking-[1.8px] tw-uppercase tw-text-[#3a3a3a] tw-font-medium tw-mb-2">
              {entity.fieldPreHeading}
            </div>
          )}
          {!entity.fieldHideTitle && (
            <h2 className="tw-normal-case tw-text-xxlFont -lg:tw-text-mobileXxlFont tw-mb-4 tw-mt-[52px] tw-break-normal">
              {entity.title}
            </h2>
          )}
          {entity.fieldSubtitle && (
            <div className="is-size-5">{entity.fieldSubtitle}</div>
          )}
          {entity.body && (
            <div
              dangerouslySetInnerHTML={{
                __html: entity.body.value,
              }}
              className="dangerously-html-handle [&>p]:tw-mb-4"
            />
          )}
          <div>
            {entity.fieldCtaUrl && (
              <LocalizedLink
                to={entity.fieldCtaUrl.url}
                className="tw-h-auto tw-whitespace-break-spaces tw-bg-link-color tw-border-transparent tw-text-white hover:tw-text-white tw-bg-accruent_sapphire tw-base_transition tw-font-Poppins tw-text-[16px] tw-font-semibold tw-leading-4 tw-tracking-[1.42px] tw-w-full tw-mb-6 tw-rounded tw-inline-flex tw-justify-center tw-text-center tw-py-[7px] tw-px-4 tw-relative"
              >
                {entity.fieldCtaUrl.title}
              </LocalizedLink>
            )}
            {entity.fieldSecondaryCta && (
              <LocalizedLink
                to={entity.fieldSecondaryCta.url}
                className="is-kingfisher tw-h-auto tw-whitespace-break-spaces tw-bg-link-color tw-border-transparent tw-text-white tw-bg-accruent_sapphire tw-base_transition tw-font-Poppins tw-text-[16px] tw-font-semibold tw-leading-4 tw-tracking-[1.42px] tw-w-full tw-m-6 tw-rounded tw-inline-flex tw-justify-center tw-text-center tw-py-[7px] tw-px-4 tw-relative"
              >
                {entity.fieldSecondaryCta.title}
              </LocalizedLink>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
const getImageToDisplay = (entity, className) => {

  const fieldMediaImage = getImageObject(entity?.relationships?.fieldMediaImage, {}, "fieldMediaImage")
  const fieldVideoRef = entity?.relationships?.fieldVideoRef
  const videoUrl = get(fieldVideoRef, "fieldMediaVideoEmbedField");
  const videoThumbnail = get(fieldVideoRef, "relationships.thumbnail");

  let contentImage = fieldMediaImage ? (
    <AccruentImg file={fieldMediaImage} className={`${className} tw-h-full tw-inline-block tw-relative tw-overflow-hidden tw-align-top`} />
  ) : (
    <StaticImage
      src="../../../static/placeholders/accruent_default-hero-placeholder.jpg"
      alt={'Default Image'}
      className="tw-h-full tw-inline-block tw-relative tw-overflow-hidden tw-align-top"
      aspectRatio={2.0}
      placeholder="blurred"
      layout="constrained"
      decoding = "async"
    />
  );

  const contentMedia = !videoUrl ? contentImage : (
    <PopupVideo videoUrl={videoUrl} thumbnail={videoThumbnail} thumbnailClass={className + " is-relative"} isForegroundThumbnail={true} />
  )

  return contentMedia;
};

const getContentBlockGridData = (entity) => {
  if (
    entity.blockSectionType == "expandable" ||
    entity.blockSectionType == "zigzag" ||
    entity.blockSectionType == "images_right" ||
    entity.blockSectionType == "images_left" ||
    entity.blockSectionType == "full_width_image" ||
    entity.blockSectionType == "carousel"
  )
    return false;
  let contentBlockGrid = null;
  let hasCaseStudyFormat = false;
  if (Array.isArray(entity?.relationships?.fieldContentBlock)) {
    hasCaseStudyFormat = entity.relationships.fieldContentBlock.some(
      (contentBlockEntity) =>
        contentBlockEntity?.fieldFormat !== "blocks"
    );
  }
  if (entity && entity?.relationships?.fieldContentBlock?.length > 2 && !hasCaseStudyFormat) {
    contentBlockGrid = chunkArray(entity.relationships.fieldContentBlock, 2);
  }
  return contentBlockGrid;
};

