import React, {useMemo} from "react";
import {LocalizedLink,AccruentImg, PopupVideo} from "../commonComponents";
import getPublishedData from "src/../helpers/getPublishedData";
import EventSection from "../eventSection/EventSection";
import "src/scss/components/_keyFeatureSection.scss";
const getImageObject = require("src/../helpers/getImageObject");
const KeyFeatureSection = ({ entity, locale }) => {
  if (!entity.status) return null
  const eventsData = (entity?.fieldFeatureSectionFormat !== 'image_above_content_below_listing_Zigzag')
    ? []
    : useMemo(() => {
      let blocks = [];
      entity?.relationships?.fieldContentBlock.map(block => {
        const data = block
        if (!data) {
          return;
        }
        const newBlock = {
          id: data?.nid,
          title: data?.title,
          description: data?.body?.value?.replace(/(<([^>]+)>)/gi, ""),
          linkTitle: data?.fieldCtaUrl?.title,
          link: data?.fieldCtaUrl?.url,
          logo: getImageObject(data?.relationships?.fieldMediaImage, {}, "fieldMediaImage"),
        }
        blocks.push(newBlock);
      });

      return blocks;
    }, [entity]);
  let publishedEntityContentBlock = [], checkForBullets = entity?.fieldShowBulletNumbers
  publishedEntityContentBlock = getPublishedData(entity?.relationships?.fieldContentBlock)
  let fieldBiSStyle;
  const fieldImg = getImageObject(entity?.relationships?.fieldImage, entity?.fieldImage)
  const videoUrl = entity?.relationships?.fieldContentVideo?.fieldMediaVideoEmbedField
  const videoThumbnail =  entity?.relationships?.fieldContentVideo?.relationships?.thumbnail

  if (entity?.fieldFeatureSectionFormat === "content_Align_left_item_listing_left_image_right"
    || entity?.fieldFeatureSectionFormat === null) {
    fieldBiSStyle = "content_Align_left_item_listing_left_image_right"
  } else {
    fieldBiSStyle = entity?.fieldFeatureSectionFormat
  }
  if (entity?.fieldFeatureSectionFormat === "content_Align_left_item_listing_left_image_right"
    || entity?.fieldFeatureSectionFormat === null) {
    return (
      <div className={`key-feature-section ${fieldBiSStyle}`}>
        <div className='header-content tw-w-full tw-text-center tw-m-auto tw-mb-[21px]'>
          <div className='eyebrow tw-pb-[7px]'>{entity?.fieldPreHeading}</div>
          <h2 className='title-3'>{entity?.title}</h2>
          <div
            className="subtitle-2 tw-pt-4 dangerously-html-handle"
            dangerouslySetInnerHTML={{
              __html:
                entity?.body?.value
            }}
          />
        </div>
        <div className='columns'>
          <div className='column is-12-mobile is-5-mobile'>

            {publishedEntityContentBlock?.map((blockItem, index) => {
              return (
                <div className='bullet-header tw-flex tw-gap-[12px] tw-mb-[32px]' key={index}>
                  {checkForBullets && <span className='bullet-style tw-self-start tw-border-[#4f2b83] tw-rounded-[34px] tw-bg-[#4f2b83] tw-text-accruent_white tw-text-[22px] tw-font-bold tw-p-[1px_9px]'>{index + 1}</span>}
                  <div>
                    <h6 className='title-6 tw-pb-[8px] tw-w-full md:tw-w-[60%]'>
                      {blockItem?.title}
                    </h6>
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          blockItem?.body?.value
                      }}
                      className="dangerously-html-handle"
                    />
                  </div>
                </div>
              )
            })}
          </div>
          <div className='column is-12-mobile is-7-mobile'>
            {videoUrl ?
              <PopupVideo videoUrl={videoUrl} thumbnail={videoThumbnail} thumbnailClass={"content-block-closeup-image tw-h-full tw-relative key-feature-block-closeup-image"} isForegroundThumbnail={true} />
              : <AccruentImg file={fieldImg} />
            }

          </div>
        </div>
      </div>
    )
  }

  else if (entity?.fieldFeatureSectionFormat === "content_Align_left_item_listing_zigzag_right") {
    return (
      <div className={`key-feature-section ${fieldBiSStyle} tw-relative tw-pt-[20px]`}>
        {/* <img src= {Image1} /> */}
        <div className="background-image tw-hidden md:tw-block">
          <AccruentImg file={fieldImg} />
        </div>
        <div className="text-content tw-relative tw-w-full md:tw-w-[50%] tw-mb-[49px]">
          <div className="pre-heading tw-mb-[7px] tw-text-[12px] tw-leading-4 tw-text-[#3A3A3A]">
            {entity?.fieldPreHeading}
          </div>
          <h3 className="title-3 tw-mb-4">{entity?.title}</h3>
          <div
            className="subtitle-2 dangerously-html-handle"
            dangerouslySetInnerHTML={{
              __html: entity?.body?.value,
            }}
          />
          {entity?.fieldCtaUrl &&
            <LocalizedLink className='landing-btn-class' to={entity?.fieldCtaUrl?.url}>
              <button>{entity?.fieldCtaUrl?.title}</button>
            </LocalizedLink>}
        </div>
        <div className="tw-columns set-gap tw-gap-[32px]">
          <div className='tw-column is-12-mobile is-6-desktop lg:tw-w-[50%] lg:tw-flex-none'>
            {publishedEntityContentBlock?.map((contentBlock, index) => {
              const fieldMediaImage = getImageObject(contentBlock?.relationships?.fieldMediaImage, {}, "fieldMediaImage")
              if (index % 2 !== 0) {
                return (
                  <div className="card !tw-bg-[#F5F5F5] !tw-rounded-[30px_30px_30px_0px] md:!tw-rounded-[30px_30px_0px_30px] !tw-border-none tw-mb-[52px] lg:tw-min-w-[548px]" key={index}>
                    <div className="tw-columns tw-flex tw-flex-col-reverse md:tw-flex-row !tw-m-0 inner-card-odd">
                      <div className='tw-column tw-w-full lg:tw-w-[33.33%] tw-h-[135px] md:tw-h-auto !tw-p-[12px_12px_0px_12px] md:!tw-p-0'>
                        {fieldMediaImage?.gatsbyImage &&
                          <AccruentImg file={fieldMediaImage} />
                        }
                      </div>
                      <div className='tw-column tw-w-full lg:tw-w-[66.66%] !tw-p-0'>
                        {!contentBlock?.fieldHideTitle &&<h6 className="title-6 tw-m-[31px_30px_16px_32px]">{contentBlock?.title}</h6>}
                        <div
                          className={`dangerously-html-handle ${contentBlock?.fieldHideTitle ? 'set-margin-top tw-mt-[31px]': ""}`}
                          dangerouslySetInnerHTML={{
                            __html: contentBlock?.body?.value,
                          }}
                        />
                        {contentBlock?.fieldCtaUrl?.url && <LocalizedLink className="key-feature-link tw-font-Poppins tw-tracking-[0.03px] tw-text-[#001c71] tw-text-[16px] tw-leading-4 tw-font-bold tw-ml-[14px] tw-block tw-mt-[-15px] tw-mb-[32px] md:tw-ml-[32px] hover:tw-underline " to={contentBlock?.fieldCtaUrl?.url}>{contentBlock?.fieldCtaUrl?.title}</LocalizedLink>}
                      </div>
                    </div>
                  </div>
                )
              }
            })}
          </div>
          <div className='tw-column is-12-mobile is-6-desktop margin-remove tw-mt-0 md:tw-mt-[-208px] lg:tw-w-[50%] lg:tw-flex-none'>
            {publishedEntityContentBlock?.map((contentBlock, index) => {
              const fieldMediaImage = getImageObject(contentBlock?.relationships?.fieldMediaImage, {}, "fieldMediaImage")
              if (index % 2 === 0) {
                return (
                  <div className="card !tw-bg-[#F5F5F5] !tw-rounded-[30px_30px_30px_0px] md:!tw-rounded-[30px_30px_0px_30px] !tw-border-none tw-mb-[52px] lg:tw-min-w-[548px]" key={index}>
                    <div className="tw-columns tw-flex tw-flex-col-reverse md:tw-flex-row !tw-m-0 inner-card-even">
                      <div className='tw-column tw-w-full lg:tw-w-[33.33%] tw-h-[135px] md:tw-h-auto !tw-p-[12px_12px_0px_12px] md:!tw-p-0'>
                        {fieldMediaImage?.gatsbyImage &&
                          <AccruentImg file={fieldMediaImage} />
                        }
                      </div>
                      <div className='tw-column tw-w-full lg:tw-w-[66.66%] !tw-p-0'>
                        {!contentBlock?.fieldHideTitle &&<h6 className="title-6 tw-m-[31px_30px_16px_32px]">{contentBlock?.title}</h6>}
                        <div
                          className={`dangerously-html-handle ${contentBlock?.fieldHideTitle ? 'set-margin-top tw-mt-[31px]': ""}`}
                          dangerouslySetInnerHTML={{
                            __html: contentBlock?.body?.value,
                          }}
                        />
                        {contentBlock?.fieldCtaUrl?.url && <LocalizedLink className="key-feature-link tw-font-Poppins tw-tracking-[0.03px] tw-text-[#001c71] tw-text-[16px] tw-leading-4 tw-font-bold tw-ml-[14px] tw-block tw-mt-[-15px] tw-mb-[32px] md:tw-ml-[32px] hover:tw-underline" to={contentBlock?.fieldCtaUrl?.url}>{contentBlock?.fieldCtaUrl?.title}</LocalizedLink>}
                      </div>
                    </div>
                  </div>
                )
              }
            })}
          </div>
        </div>

      </div>
    )
  }

  else if (entity?.fieldFeatureSectionFormat === "image_above_content_below_listing_Zigzag") {
    return (
      <div className={`homepage-content-wrapper ${fieldBiSStyle}`}>
        <div className="homepage-news-section !tw-bg-accruent_white">
          <EventSection
            data={eventsData}
            title={entity.title}
            locale={locale}
          />
        </div>
      </div>
    )
  }
  else {
    return null;
  }
}
export default KeyFeatureSection;
