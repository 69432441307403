import { useLayoutEffect } from "react";
import getPathWithQueryParam from "src/../helpers/getPathWithQueryParam";
import { useLocation } from '@reach/router';
import getRelAtrribute from "./getRelAtrribute";
import getCookie from '../../../helpers/getCookieParser';
const useAddParamInHtmlLink = (classes = []) => {
  const location = useLocation();
  // dangerously-html-handle className used for selecting all tags using dangerouslySetInnerHTML and  updating all a tag child of it.
  let correctParameter = ['dangerously-html-handle']; 
  if (typeof classes === "string") {
    correctParameter.push(classes);
  } else {
    correctParameter = [...correctParameter, ...classes];
  }
  correctParameter = [...new Set(correctParameter)];

  useLayoutEffect(() => {
    const cookies = getCookie();
    correctParameter.forEach((individualClass) => {
      // select the wrapper element that have the <a> element inside it
      const wrapperElements = document.querySelectorAll(`.${individualClass}`);
      if (wrapperElements && wrapperElements.length !== 0) {
        wrapperElements.forEach((wrapperElement) => {
          // select all the <a> inside the wrapper element
          const aElements = wrapperElement.querySelectorAll("a");
          if (aElements && aElements.length !== 0) {
            // for each <a> add UTM parmas in URL into the href field
            aElements.forEach((aEl) => {
              if (aEl.href) {
                aEl.href = getPathWithQueryParam(location, aEl.href?.replace(location.origin,""), cookies)
               
                let relAttr = getRelAtrribute(aEl.href)
                if(relAttr?.rel){
                  aEl.rel = relAttr?.rel
                }
           
              }
            });
          }
        })
      }
    });
  }, [correctParameter]);
};

export default useAddParamInHtmlLink;
