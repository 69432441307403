import React from "react";
import extractMarketoValues from "src/components/helpers/extractMarketoIdAndSerial";
import { GetYourQuoteForm } from "./GetYourQuoteForm";
import DemoFormWithBg from "./DemoFormWithBg";
import getImageObject from "../../../../helpers/getImageObject";
export const ContentMarketoForm = ({ entity, locale, pageTitle, formFullWidth, demoImage = null }) => {
  const { id, serial, gaLabel } = extractMarketoValues(
    entity?.fieldEmbedCode
  );
  return (
    <div>
      { entity?.fieldVersions !== 'advanced' ?
        <GetYourQuoteForm
          fieldFormHeader={entity?.fieldFormHeader}
          fieldMarketoFormText={entity?.fieldFormText}
          fieldMarketoFormTitleId={entity?.title}
          staticFormText={entity?.staticFormText}
          fieldEmbedCode={entity?.fieldEmbedCode}
          formFullWidth={formFullWidth}
        />
        :
        <div className="tw-demo-form-bg">
          <DemoFormWithBg 
            fieldFormHeader={entity?.fieldFormHeader}
            description={entity?.fieldFormText?.value}
            image={getImageObject(entity?.relationships?.fieldFormImageWithCover, entity?.fieldFormImage)}
            bgImage={getImageObject(entity?.relationships?.fieldMediaImage?.relationships?.field_image, entity?.relationships?.fieldMediaImage?.fieldImage)}
            fieldMarketoFormTitleId={entity?.title}
            serial={serial}
            formId={id}
            gaLabel={gaLabel}
            pageTitle={pageTitle}
            cta={entity?.fieldCtaUrl}
            formFullWidth={formFullWidth}
            demoImage={demoImage}
          />
        </div>
      }
    </div>
  );
}
