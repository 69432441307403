const getImageObject = (image, extra, type) => {
    let imgObj = {}
    try {
       switch (type) {
          case "fieldMediaImage":
          case "fieldHeroMedia":
          case "fieldHeadshot":
          case "fieldCoverImage":
          case "fieldBackgroundImage":
          case "fieldLeftBackgroundImage":
          case "fieldRightBackgroundImage":
             imgObj = { gatsbyImage: image?.relationships?.fieldImage?.gatsbyImage, shareImage: image?.relationships?.fieldImage?.shareImage, caption: image?.fieldImageCaption }
             if (image?.fieldImage) {
                imgObj = { ...imgObj, ...image.fieldImage }
             }
             break;
          case "fieldLogoImages":
             if (Array.isArray(image)) {
                return image?.map((img, i) => {
                   imgObj = { gatsbyImage: img?.gatsbyImage }
                   if (extra?.[i]) {
                      imgObj = { ...imgObj, ...extra?.[i] }
                   }
                   return imgObj;
                })
             }
             return []
             break;
          default:
             imgObj = image ? { ...image } : {}
             break;
 
       }
 
       if (extra) {
          imgObj = { ...imgObj, ...extra }
       }
    } catch (error) {
 
    }
 
    return imgObj;
 }

module.exports = getImageObject;