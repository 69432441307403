import React from "react";
import { LocalizedLink } from "./localizedLink";
const prefixLangcode = require('src/../helpers/prefixLangcode')
const getImageObject = require("src/../helpers/getImageObject")

export const RelatedProductCard = ({ entity, locale, isRelatedProductReferences = false }) => {

  const relatedItemes = isRelatedProductReferences ? entity?.relationships?.fieldProductReference : entity?.relationships?.fieldRelatedItems

  return (
    <div className="right-gray-box tw-bg-accruent_slightgray tw-p-[76px_16px_16px_16px]">
      <h3 className="tw-text-mobileXxxlFont md:tw-text-xxxlFont tw-text-center tw-mb-[36px]">{entity?.title}</h3>
      {relatedItemes?.length !== 0 && relatedItemes?.map((product, index) => {
        if (isRelatedProductReferences) {
          if (product?.status) {
            product = product?.relationships?.fieldProductReferenceItem
          } else {
            product = undefined
          }
        }
        const ImgSrc = getImageObject(product?.relationships?.fieldProductLogo, product?.fieldProductLogo)
        const ImgSrcAttr = ImgSrc?.gatsbyImage?.images?.fallback
        return (
          <>
            {product && (
              <div className="white-box tw-bg-accruent_white tw-box-shadow-related-products tw-rounded-[24px_24px_24px_0px] tw-p-[19px_16px_16px_16px] tw-mb-[22px] " key={index}>
                {ImgSrc?.gatsbyImage &&

                  <img
                    {...ImgSrcAttr}
                    alt={ImgSrc?.alt}
                    loading="eager"
                    fetchpriority="high"
                    className={'tw-max-h-[35px] tw-h-auto tw-max-w-[99%] md:tw-max-w-[85%] tw-mb-[16px]'}
                    decoding = "async"
                  />
                  }
                <p className="tw-text-accruent_base_color tw-text-[18px] tw-font-Roboto tw-pb-[32px] tw-mb-0">{product?.fieldProductDescription}</p>
                {product?.entityUrl?.path &&
                  <LocalizedLink to={prefixLangcode(product?.entityUrl?.path, locale)} className="learn-more tw-text-accruent_dark_imp_blue tw-font-Poppins tw-text-[16px] tw-tracking-[0.03px] tw-font-bold hover:tw-text-accruent_dark_imp_blue">
                    Learn More ›
                  </LocalizedLink>}
              </div>
            )}
          </>
        )
      })}
    </div>
  )
}
