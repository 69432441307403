import { useStaticQuery, graphql } from "gatsby";

export default function useResourceData() {
  const data = useStaticQuery(graphql`
  {
    allSitePage(
      filter: {
        componentChunkName: {
          eq: "component---src-templates-common-resources-template-js"
        }
        
      }
    ) {
      group(field: context___content_type, limit: 3) {
        nodes {
          ...resourceCards
        }
      }
    }
  }
  `);

  return { data };
}
