const getQaData = (entity) => {
  let remappedValues = [];
  if (entity?.relationships?.fieldQa) {
    let qa_schema = entity?.fieldQaSchema || false;
    remappedValues = entity?.relationships?.fieldQa.map((item) => {
      return {
        id: `id${item?.entityId}`,
        title: item?.title,
        content: item?.fieldFormattedAnswer?.value || item?.fieldAnswer,
        qa_schema: qa_schema,
      };
    });
  }
  return remappedValues;
};
export default getQaData;