import React from "react";
import getAuthorName from "../../../../helpers/getAuthorName";
import { isEmpty, get } from "src/utils";
// import ResourcesHero from "src/components/hero/ResourcesHero";
import { ResourcesHero} from "src/components/templateComponents";
// import AccruentImg from "src/components/AccruentImg";
import { ShareFollowBox, Picture, AccruentImg } from "src/components/commonComponents";
// import Picture from "src/components/Picture";
import splitBodyContent from "../../helpers/splitBodyContent";
import useAddParamInHtmlLink from "src/components/helpers/useAddParamInHtmlLink";


export const SinglePressReleasePost = ({ data }) => {
  const { locale } = data;
  const authorName = getAuthorName(data);
  let heroImage = get(data, "heroImage");
  const introSection = data?.relationships?.fieldContentSections?.[0]?.relationships?.fieldProxy?.relationships?.fieldIntroContentItem?.[0];
  const fieldTag = data?.relationships?.fieldTags?.entityLabel || 'PRESS RELEASE';
  let splitFunc = splitBodyContent(data?.body?.value);
  let firstContentToString = splitFunc?.firstContentToString;
  let secondContentToString = splitFunc?.secondContentToString;
  useAddParamInHtmlLink()
  return (
    <section className="resources-page full-header-bg resources-press-release content-visibility-auto-mobile">
      <div className="container">
        <section className="medium-wrapper tw-text-[16px] md:tw-text-[18px] tw-py-0 tw-px-[15px] md:tw-px-0 md:tw-py-0">
          <div className="tw-columns md:tw-flex">
            <div className="tw-column tw-w-full tw-pt-0">
              <div className="tw-mt-[22px] tw-mb-[40px]">
                <ShareFollowBox newswireLink={data?.fieldLinkToOriginalArticle?.[0]} />
              </div>
              <div className="content">
                {!!authorName && (
                  <h3>
                    By {authorName}
                  </h3>
                )}
                {!data.fieldHideImage && !!heroImage?.gatsbyImage && 
                <AccruentImg file={heroImage} className={'press-release-left-image'} loading="eager" />
                }
                {data.body && (
                  <div
                    className="single-resource-content-body dangerously-html-handle"
                    dangerouslySetInnerHTML={{ __html: firstContentToString }}
                  ></div>
                )}
                <div className="middle-of-content tw-mb-4">
                  {
                    (heroImage) ?
                      <div className={`banner-graphic tw-p-0 tw-min-h-[376px]`}>
                        <Picture
                          image={heroImage}
                          className={'resources-hero-image tw-h-full tw-w-full tw-min-h-[376px] md:tw-min-h-0 tw-object-cover'}
                        />
                      </div> : null
                  }
                </div>
                 {secondContentToString && (
                  <div
                  className="single-resource-content-body dangerously-html-handle"
                  dangerouslySetInnerHTML={{ __html: secondContentToString }}
                  ></div>
                )}
                {data.fieldKeyFeaturesTitle && (
                  <h3>{data.fieldKeyFeaturesTitle}</h3>
                )}
                {!isEmpty(data.fieldKeyFeaturesBullets) && (
                  <ul>
                    {data.fieldKeyFeaturesBullets.map((bullet) => (
                      <li key={bullet}>{bullet}</li>
                    ))}
                  </ul>
                )}
                {data.fieldTextAfterKeyPoints &&
                  data.fieldTextAfterKeyPoints.value && (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data.fieldTextAfterKeyPoints.value,
                      }}
                      className="dangerously-html-handle"
                    />
                  )}
                {introSection && (
                  <div className="intro-section-wrapper tw-py-8 tw-px-4">
                    <div className="intro-section-title tw-tracking-normal tw-text-accruent_black_olive uppercase">{introSection.title}</div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: introSection.body?.value,
                      }}
                      className="intro-section-body tw-pt-2 tw-tracking-[0.29px] tw-text-accruent_black_olive"
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  )

}

// export default SinglePressReleasePost;
