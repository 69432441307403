import React, { useState } from "react";
import { LocalizedLink } from "../localizedLink";
import { FormModal } from "../formModal";
export const HeroTextContainer = ({ preHeading, heading, body, cta, secondaryCta, formCta = {}, subHeading, isFormLoad, onPopupLoad, version = null }) => {
   const [showModal, setShowModal] = useState(false);
   const { fieldEmbeddedCode, fieldShowPopUp, fieldLabel } = formCta;
   const handleToggle = () => {
     setShowModal(!showModal);
     if(onPopupLoad){
      onPopupLoad(!showModal);
     }

   };

  return (
    <div>
     {preHeading && <div className="pre-heading tw-h-[16px] tw-whitespace-nowrap tw-overflow-hidden 
      md:tw-h-auto md:tw-whitespace-normal">{preHeading}</div>}
      {!!heading && <h1 className=" text-accruent-heading-color tw-min-h-[120px] md:tw-min-h-full md:tw-mb-4 tw-font-Helvetica md:tw-font-Poppins">{heading}</h1>}
      {!!body && version !=="v3" && (
        <div
          className="content dangerously-html-handle tw-font-Roboto tw-flex-col !tw-mb-0 lg:tw-pb-0 tw-pb-[22px]"
          dangerouslySetInnerHTML={{
            __html: body.value,
          }}
        />
      )}
      {subHeading && <p className="tw-content sub-heading tw-sub-heading">{subHeading}</p>}
     {(!!secondaryCta || !!cta || !!fieldShowPopUp) && 
       <div className="btn-inline  tw-flex tw-flex-col md:tw-block tw-mb-8 tw-pt-[8px] md:tw-pt-0 md:tw-mb-0">
        {!!fieldShowPopUp && (
           <LocalizedLink
           onClick={!showModal?handleToggle:null}
             className="tw-text-center tw-pt-[22px] md:tw-p-[14px_24px] tw-border tw-rounded-[12px]
             tw-bg-accruent_sapphire tw-text-accruent_white tw-font-bold tw-font-Poppins md:tw-mr-[22px] tw-mr-0 tw-tracking-[1.42px]
             tw-h-[68px] md:tw-h-full tw-leading-[16px] tw-mt-[22px] hover:tw-text-accruent_white tw-inline-block"
           >
            {fieldLabel}
          </LocalizedLink>
        )}
        {!!cta && !fieldShowPopUp && (
          <LocalizedLink
            to={cta.url}
            className="tw-text-center tw-pt-[22px] md:tw-p-[14px_24px] tw-border tw-rounded-[12px]
            tw-bg-accruent_sapphire tw-text-accruent_white tw-font-bold tw-font-Poppins md:tw-mr-[22px] tw-mr-0 tw-tracking-[1.42px]
            tw-h-[68px] md:tw-h-full tw-leading-[16px] tw-mt-[22px] hover:tw-text-accruent_white tw-inline-block"
          >
            {cta.title}
          </LocalizedLink>
        )}
        {!!secondaryCta && (
          <LocalizedLink
            to={secondaryCta.url}
            className="tw-text-center tw-pt-[22px] md:tw-p-[14px_24px] tw-border tw-border-accruent_sapphire tw-rounded-[12px]
            tw-bg-accruent_white  tw-font-bold tw-font-Poppins tw-text-accruent_sapphire hover:tw-text-accruent_white 
            hover:tw-bg-accruent_sapphire tw-tracking-[1.42px] tw-h-[68px] md:tw-h-full tw-leading-[16px] tw-mt-[22px] tw-inline-block"
          >
            {secondaryCta.title}
          </LocalizedLink>
        )}
      </div> }
        {showModal && (
          <FormModal
            title={fieldLabel}
            fieldEmbeddedCode={fieldEmbeddedCode}
            handleToggle={handleToggle}
          />
        )}

    </div>
  );
}