import React from "react";
import { get } from "src/utils";
// import LocalizedLink from "src/components/LocalizedLink";
import getAuthorName from "../../../../helpers/getAuthorName";
import getResourceTagsList from "../../../../helpers/getResourceTagsList";
// import Hero from "src/components/hero/ResourcesHero";
import { ResourcesHero as Hero} from "src/components/templateComponents";
import { Eyebrow, LocalizedLink } from "src/components/commonComponents";
import loadable from '@loadable/component';
import useAddParamInHtmlLink from "src/components/helpers/useAddParamInHtmlLink";
// const SideBar = loadable(() => import('../../components/resources/ResourceSideBar'));
const SideBar = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.SideBar
});
const StickyBox = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.StickyBox
});
// const Picture = loadable(() => import('src/components/Picture'))
const Picture = loadable(() => import("src/components/commonComponents"), {
  resolveComponent: (components) => components.Picture
});
// const PopupVideo = loadable(() => import('src/components/popupVideo/PopupVideo'))
const PopupVideo = loadable(() => import("src/components/commonComponents"), {
  resolveComponent: (components) => components.PopupVideo
});

export const SinglePodcastPost = ({ data }) => {
  const { locale } = data;
  const authorName = getAuthorName(data?.relationships);
  const resourceTags = getResourceTagsList([data.industryIds, data.solutionIds, data.productIds])
  let heroImage = get(data, "heroImage");
  const heroVideoUrl = get(data, "relationships.fieldHeroVideo.fieldMediaVideoEmbedField");
  const heroVideoThumb = get(data, "relationships.fieldHeroVideo.relationships.thumbnail.url");
  useAddParamInHtmlLink()
  return (
    <section className="resources-page section !tw-pt-0 content-visibility-auto-mobile">
      <div className="container">
        <section className="medium-wrapper tw-text-[16px] md:tw-text-[18px] tw-py-0 tw-px-[15px] md:tw-px-0 md:tw-pl-[12px] md:tw-py-0 tw-mt-[32px] md:tw-mt-[72px]">
          <div className="columns md:tw-flex">
            <div className="tw-w-full lg:tw-w-[calc(100%-376px)] lg:tw-max-w-[calc(100%-376px)] [&]:tw-px-0 [&]:md:tw-pr-3">
              {!!authorName && (
                <h3>
                  By {authorName}
                </h3>
              )}
              <div>
                {data.fieldPodcastEmbedCode && (
                  <div
                    className="new-single-resource-content-body podcast-body has-text-centered dangerously-html-handle tw-mb-[39px]"
                    dangerouslySetInnerHTML={{
                      __html: data.fieldPodcastEmbedCode,
                    }}
                  ></div>
                )}
                <div className="podcast-info-wrapper tw-flex tw-flex-wrap tw-w-full tw-mb-[15px]">
                  {data?.fieldSpecialGuest && <div className="podcast-info tw-text-left tw-leading-4 tw-mb-[31px] tw-flex-1 tw-min-w-[50%]">
                    <div className="tw-uppercase tw-tracking-[1.8px] tw-text-[#3A3A3A] tw-text-[12px] tw-font-Roboto tw-font-medium tw-mb-4">Special Guest</div>
                    <div className=" tw-tracking-[0.15px] tw-text-[22px] tw-text-[#3a3a3a] tw-font-Roboto tw-font-medium">{data?.fieldSpecialGuest}</div>
                  </div>
                  }
                  {data?.fieldPodcastHosts && <div className="podcast-info tw-text-left tw-leading-4 tw-mb-[31px] tw-flex-1 tw-min-w-[50%]">
                    <div className="tw-uppercase tw-tracking-[1.8px] tw-text-[#3A3A3A] tw-text-[12px] tw-font-Roboto tw-font-medium tw-mb-4">Hosts</div>
                    <div className="tw-tracking-[0.15px] tw-text-[22px] tw-text-[#3a3a3a] tw-font-Roboto tw-font-medium">{data?.fieldPodcastHosts}</div>
                  </div>
                  }
                  {data?.relationships?.fieldPodcastSeries?.name && <div className="podcast-info tw-text-left tw-leading-4 tw-mb-[31px] tw-flex-1 tw-min-w-[50%]">
                    <div className="tw-uppercase tw-tracking-[1.8px] tw-text-[#3A3A3A] tw-text-[12px] tw-font-Roboto tw-font-medium tw-mb-4">Series</div>
                    <div className="tw-tracking-[0.15px] tw-text-[22px] tw-text-[#3a3a3a] tw-font-Roboto tw-font-medium">{data.relationships.fieldPodcastSeries.name}</div>
                  </div>}
                </div>
                {data.fieldEpisodeContent?.value && (
                  <>
                    <h2 className="title-5">What's in this Episode?</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data.fieldEpisodeContent.value,
                      }}
                      className="dangerously-html-handle"
                    />
                  </>
                )}
                
                 <div className="middle-of-content tw-mb-4">
                  {heroVideoUrl ?
                      <PopupVideo
                        videoUrl={heroVideoUrl}
                        thumbnail={heroVideoThumb}
                        thumbnailClass={`thumbnail-box banner-graphic tw-p-0  thumbnail-hero`}
                        isHero
                      />
                      :
                      (heroImage) ?
                        <div className={`banner-graphic tw-p-0 tw-min-h-[376px]`}>
                          <Picture
                            image={heroImage}
                            className={'resources-hero-image tw-h-full tw-w-full tw-min-h-[376px] md:tw-min-h-0 tw-object-cover'}
                          />
                        </div> : null
                    }
                  </div>
                {data.body && (
                  <div
                    className="new-single-resource-content-body podcast-body dangerously-html-handle tw-mb-[39px]"
                    dangerouslySetInnerHTML={{
                      __html: data.body?.value,
                    }}
                  ></div>
                )}
                <div className="podcast-links">
                  {!!data.fieldPodcastLink?.length && data.fieldPodcastLink?.map((entity, index) => (
                    <LocalizedLink
                      key={index}
                      to={entity.url}
                      className="is-primary mr-2 line-btn"
                      isButton
                    >
                      {entity.title}
                    </LocalizedLink>
                  ))}
                </div>
              </div>
            </div>
            <div className="tw-w-full lg:tw-max-w-[376px] lg:tw-p-[12px]">
              <div className="right-content sidebar-content tw-bg-accruent_white tw-w-full md:tw-w-[376px] tw-relative">
                <StickyBox
                  scrollContainerClass={"sidebar-scroll"}
                >
                  <div className="right-box">
                    <SideBar recommendedResources={data.rectifiedRecommended} />
                  </div>
                </StickyBox>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  )
}

// export default SinglePodcastPost;
