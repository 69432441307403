import { useStaticQuery, graphql } from "gatsby";
export default function useGetAllUkPages(locale = 'en') {
  const siteUKPaths = useStaticQuery(graphql`
    {
      allSitePage(
        filter: {context: {locale: {eq: "en-gb"}}}
      ) {
        nodes {
          path
        }
      }
    }
  `);
  return { siteUKPaths: siteUKPaths };
}
