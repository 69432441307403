import React from "react";

/**
 * @param {Object} image -(required)  it should be gatsbyImage object
* @param {String} className - it used to set html class  attribute
* @param {String} parentClassName - it used to set html class  attribute for pictureTag
* @param {Any} extra - extra attribute like width, height etc.
 */
export const Picture = ({ image, className, parentClassName, loading = "eager", fetchpriority = "high", ...extra }) => {

    const heroImageAttrs = image?.gatsbyImage?.images
    const width = extra?.width ? extra.width : image?.gatsbyImage?.width;
    const height = extra?.height ? extra.height : image?.gatsbyImage?.height;

    return (
        <picture className={parentClassName?parentClassName:className ? className + "-container" : ""}>
            {heroImageAttrs?.sources?.map(src => <source {...src} />)}
            <img
                {...heroImageAttrs?.fallback}
                alt={image?.alt}
                loading={loading}
                fetchpriority={fetchpriority}
                className={className}
                decoding = "async"
                {...extra}
                width={width}
                height={height}
            />
        </picture>
    )

}
