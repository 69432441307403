import React from "react";
import { HeroTextContainer } from "./HeroTextContainer";
import { HeroImageContainer } from "./HeroImageContainer";
import { LCPImage } from "../lcpImage";
export const CategoryHeroV3 = ({ containerStyles, commonHeroProps, onPopupLoad }) => {
  const { heading, preHeading, body, cta, secondaryCta, formCta, heroImage, heroVideo, videoBackgroundImage, heroImageUrl, heroBackground, heroLeftBackground, heroRightBackground, subHeading, fieldHideHeroImage, heroGifImage } = commonHeroProps;
  const currentHeroImage = fieldHideHeroImage ? null : heroImage
  const currentHeroImageUrl = fieldHideHeroImage ? null : heroImageUrl
  let backgroundNewImage = heroBackground?.gatsbyImage ? heroBackground : videoBackgroundImage
  const heroImageAttrs = backgroundNewImage?.gatsbyImage?.images || currentHeroImage?.gatsbyImage?.images
  const foreGroundImage = !heroVideo ? (backgroundNewImage?.gatsbyImage) ? currentHeroImage : null : null
  const heroGif = currentHeroImageUrl
  const heroV3Styles = 'tw-mt-0 tw-pt-[52px] md:tw-pt-[72px] md:tw-text-center'
  const ListItems = ({sidebar}) => {
    return(
      <div className="list-items tw-min-w-[calc(100%-70px)]">
        {sidebar?.fieldCardTitle && <h2 className="list-heading tw-text-accruent-heading-color tw-text-[20px] tw-tracking-[0.18px] tw-leading-[23.2px] tw-mb-[9px] !tw-text-newH6Mobile  md:!tw-text-newH6Desktop">{sidebar?.fieldCardTitle}</h2>}
        {sidebar?.fieldCardContent?.value && <div
            dangerouslySetInnerHTML={{
                __html: sidebar?.fieldCardContent?.value,
            }}
            className="list-text tw-text-[18px] tw-text-accruent_base_color tw-leading-[26px] tw-mb-[36px]"
        />}
      </div>
    )
  }

  return (
    <div className={containerStyles.commonHeroContainer}>
      <section
        className={`section hero is-block  hero-v3-container product-page-hero-box tw-product-page-hero-box ${heroLeftBackground?.gatsbyImage ? "left-background-image-margin" : ""}`}
        style={{ position: 'relative' }}
      >
        <LCPImage />
        <div className="container common-hero-container">
          <div>
            <div className={`hero-page-container text hero-v3 ${heroV3Styles}`} >
              <HeroTextContainer
                preHeading={preHeading}
                heading={heading}
                body={body}
                cta={cta}
                secondaryCta={secondaryCta}
                formCta={formCta}
                subHeading={subHeading}
                onPopupLoad={onPopupLoad}
                version="v3"
              />
            </div>
            <div className={`hero-page-container video hero-v3 ${heroV3Styles}`}>
              <div className=" tw-flex tw-flex-col md:tw-flex-row md:tw-gap-[50px] md:tw-items-center">
                <div className=" md:tw-w-8/12 is-8-desktop is-12-mobile is-relative">
                  <HeroImageContainer
                    heroVideo={heroVideo}
                    backgroundNewImage={backgroundNewImage}
                    foreGroundImage={foreGroundImage}
                    heroGif={heroGif}
                    heroImageAttrs={heroImageAttrs}
                    heroImage={currentHeroImage}
                    heroGifImage={heroGifImage}
                    tailwindStandClassNames={'!tw-max-h-[374px] !tw-max-w-[660px] !tw-p-0'}
                  />
                </div>
               {!!commonHeroProps?.fieldHeroSidebarSection?.length && <div className=" md:tw-w-2/6 is-4 is-12-mobile list-container tw-text-left tw-flex tw-overflow-auto tw-gap-[25px] tw-mt-[52px] md:tw-flex-col md:tw-overflow-hidden md:tw-gap-0 md:tw-mt-0">
                  {commonHeroProps?.fieldHeroSidebarSection?.map((sidebar, index) => {
                    return(
                      <ListItems key={index} sidebar={sidebar}/>
                    )
                  })}
                </div>}
              </div>
            </div>  
            <div
                className="dangerously-html-handle tw-font-Roboto tw-flex-col !tw-mb-0 tw-pb-[76px] hero-v3-description-content"
                dangerouslySetInnerHTML={{
                  __html: body.value,
                }}
              />
          </div>
        </div>
      </section>
    </div>
  );
};