import React, { useLayoutEffect } from "react";
import Slider from "react-slick";
import prevIcon from '../../../static/chevron-left-solid-grey.svg'
import nextIcon from '../../../static/chevron-right-solid-grey.svg'
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

export const CustomPrevArrow = (props) => {
  const { className, onClick, userStyle } = props;
  return (
    <div>
      <img src={prevIcon} alt="previous icon" decoding = "async" onClick={onClick} className={className} style={{ ...userStyle, display: "block", color: "grey" }} />
    </div>
  )
}

export const CustomNextArrow = (props) => {
  const { className, onClick, userStyle } = props;
  return (
    <div>
      <img src={nextIcon} alt="next icon" decoding = "async" onClick={onClick} className={className} style={{ ...userStyle, display: "block", color: "grey" }} />
    </div>
  )
}

export const AccruentSlider = ({
  children,
  settings,
  sliderId,
  className = null
}) => {
  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      document
        .querySelectorAll(".slick-track")
        .forEach((track) => track.setAttribute("role", "listbox"));
      if (!!sliderId) {
        const thisSlider = document.querySelector(`#slider-${sliderId}`);
        if (thisSlider) {
          const slickTag = thisSlider.querySelector(".slick-track");
          if (slickTag) {
            slickTag.setAttribute("aria-labelledby", sliderId);
          }
        }
      }
    }
  }, [sliderId]);

  const defaultSettings = {
    infinite: true,
    accessibility: true,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
    speed: 500,
  };

  const settingsToPass = {
    ...defaultSettings,
    ...settings,
  };
  return (
    <div className={className} id={`slider-${sliderId}`}>
      <Slider {...settingsToPass} ref={settings?.sliderRef}>
        {children?.map((child) => (
          <div className="tw-w-full tw-inline-block tw-py-0 md:tw-px-[32px] lg:tw-px-4 lg:tw-py-[1.5rem]" key={child.key}>{child}</div>
        ))}
      </Slider>
    </div>
  );
};

// export default AccruentSlider;
