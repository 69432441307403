import * as variables from "../src/scss/_layout_vars.scss";
/**
 * @Use
 * import variable from "src/layoutVariablet
 *  @InsideComponent
 *   const { tablet } = variable
 */

/**
 * This function takes what is exported by the file _layout_vars.scss and parse it
 * In other words, it takes off the "px" part of the value and turn it into a number
 * so "768px" turns into 768
 */
const variableKeys = Object.keys(variables);

const parsedVariables = {};
// eslint-disable-next-line
variableKeys.map((key) => {
  parsedVariables[key] = parseInt(variables[key], 10);

  // If after parsing it return a NaN as a value, turn back to original value
  // Will work with colors such as `blue` and `#dedede` but not with complex values such as `0px 10px 20px rgba(31, 32, 65, 0.05)`
  if (Number.isNaN(parsedVariables[key])) parsedVariables[key] = variables[key];
});

export default parsedVariables;
