/**
 * get path with query param
 * It is mainly created for transfer UTM param from one page to another page
 * @param {Object} location -(required) it should be object return by useLocation or  browser location object 
 * @param {string} path - (required) it is a path of a page on which you are going to navigate.
 * 
 */
const site_var = require("../src/get_site_data");
const target_site_tid = site_var.drupal_site_id;

const getPathWithQueryParam = (location, path, cookies) => {

  let parsedCookies = cookies, utmMarketing = false, checkForUsUk = false;
  let checkToUrlForEms = path?.includes("https://www.emssoftware.com") ? true: false, checkToUrlForMc = path?.includes("https://website.maintenanceconnection.com") ? true : false, checkToUrlForAccruent = path?.includes("https://www.accruent.com") || path?.includes("accruentdev") ? true : false
  let isExternalMarketingWeb = false, hashPath;
  let checkForSameWebsite = false
  const utmArray = ["utm_source","utm_medium","utm_campaign","utm_term","utm_content","utm_id","gclid","msclkid"]
  const marketingWebsites = ["emssoftware","maintenanceconnection","website.maintenanceconnection","accruent"]
  const skipForWeb = ["https://info.accruent.com", "mailto:"]
  let params = ''
  let hash = ''
  let currentPath = path
  if((target_site_tid === 451 && checkToUrlForAccruent) || (target_site_tid === 452 && checkToUrlForEms) || (target_site_tid === 453 && checkToUrlForMc)){
    checkForSameWebsite = true
  }
  try {
    const isInternalLink = /^\/(?!\/)/.test(path);
    if(isInternalLink || checkForSameWebsite){
      return path
    }
    if(!isInternalLink){
      marketingWebsites.forEach((val) => {
        if(path?.includes(val)){
          isExternalMarketingWeb=true
        }
      })
      skipForWeb.forEach((val) => {
        if(path?.includes(val)){
          isExternalMarketingWeb=false
        }
      })
    }
    
    if(path?.startsWith("#")){
      hashPath = 'https://localhost:8080' + location?.pathname + path
    }
    let url = path ? new URL((isInternalLink ? 'https://localhost:8080' + path: hashPath ? hashPath : path)) : "";

    // Check for the redirect path if it has any search params
    let pathSearch = new URLSearchParams(url.search);


    if(!parsedCookies?.length){
      return path;
    }
    if(!isInternalLink && !isExternalMarketingWeb){
      return path;
    }
    parsedCookies?.forEach((val, index) => {
      for(let key in val){
        // Checking if the current URL path has any key from utm array
        if (utmArray.includes(key)) {
          // Setting that key into redirect path
          pathSearch.set(key, val[key])
          utmMarketing = true
        }
      }
    })
    if(utmMarketing){
      pathSearch.set("internal_site", true)
    }
    params = pathSearch?.toString()
    if (params){
      currentPath = isInternalLink ? url.pathname: url?.origin + url?.pathname
      hash = url.hash
      let search = '?' + params 
      return currentPath + search + hash
    }
      
  } catch (e) { console.log(e) }
  return path
}

export default getPathWithQueryParam;