import React from "react";
import { AccruentImg, LocalizedLink, PopupVideo } from "src/components/commonComponents";
import { StaticImage } from "gatsby-plugin-image";
const getImageObject = require("src/../helpers/getImageObject");

const defaultImage = "../../../static/placeholders/accruent_default-hero-placeholder.jpg";

const FullWidthImageBlock = (props) => {
  const { data: fullWidthData } = props;
  return (
    <div className="content-block-item is-centered is-gapless ">
      <div className="tw-flex tw-flex-col">
        <div className="full-width-block">
          {fullWidthData.map((fullWidth, index) => {
            const fieldMediaImage = getImageObject(fullWidth?.relationships?.fieldMediaImage, {}, "fieldMediaImage")
            return (
              <div key={index} className="inner-container tw-mt-[76px] ">
                <h3 className="md:tw-text-xlFont tw-text-mobileXlFont tw-mb-[0.5rem] tw-mt-[1rem]">{fullWidth?.title} </h3>
                <div dangerouslySetInnerHTML={{ __html: fullWidth?.body?.value }} className="dangerously-html-handle" />
                
                {fullWidth?.relationships?.fieldVideoRef?.fieldMediaVideoEmbedField ? (
                  <PopupVideo videoUrl={fullWidth?.relationships?.fieldVideoRef?.fieldMediaVideoEmbedField} thumbnail={fullWidth?.relationships?.fieldVideoRef?.relationships?.thumbnail} thumbnailClass={"content-block-closeup-image has-height-100-percent is-relative"} isForegroundThumbnail={true} />
                ) :
                  (fieldMediaImage?.gatsbyImage ? (
                    <AccruentImg
                      file={fieldMediaImage}
                      className="content-block-closeup-image tw-h-full"
                    />
                  ) : (
                    <StaticImage
                      src={defaultImage}
                      alt={'Default Image'}
                      className="tw-h-full"
                      aspectRatio={2.0}
                      placeholder="blurred"
                      layout="constrained"
                    />
                  )
                  )}
                  {(fullWidth?.fieldCtaUrl || fullWidth?.fieldSecondaryCta) &&
                  <div className="button-box-full-width-content tw-mt-[16px] md:tw-mt-[32px]">
                    {fullWidth?.fieldCtaUrl && (
                      <LocalizedLink
                        to={fullWidth?.fieldCtaUrl?.url}
                        className="learn-more hover: tw-bg-[#fff] tw-border-solid tw-border-[2px] tw-border-[accruent_sapphire] tw-text-accruent_sapphire tw-rounded-[12px] tw-font-bold tw-bottom-[24px] tw-text-[16px] tw-p-[14px_24px_14px_24px] hover:tw-bg-hove_bg hover:tw-border-transparent hover:tw-text-accruent_white"
                      >
                        {`${fullWidth?.fieldCtaUrl?.title} >`}
                      </LocalizedLink>
                    )}
                    {fullWidth?.fieldSecondaryCta && (
                      <LocalizedLink
                        to={fullWidth?.fieldSecondaryCta?.url}
                        className="learn-more hover: tw-bg-[#fff] tw-border-solid tw-border-[2px] tw-border-[accruent_sapphire] tw-text-accruent_sapphire tw-rounded-[12px] tw-font-bold tw-bottom-[24px] tw-text-[16px] tw-p-[14px_24px_14px_24px] hover:tw-bg-hove_bg hover:tw-border-transparent hover:tw-text-accruent_white"
                      >
                        {`${fullWidth?.fieldSecondaryCta?.title} >`}
                      </LocalizedLink>
                    )}
                  </div>}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
};

export default FullWidthImageBlock;
