import React from "react";
import loadable from '@loadable/component';
import { CommonFormHeader } from "src/components/templateComponents";
import ContactSupportCard from "src/components/blocks/ContactSupportCard"
import HeadquartersCard from "src/components/locations/HeadquartersCard";
import getFirstOneHavingData from "src/../helpers/getFirstOneHavingData";
import { AccruentImg, Video, LocalizedLink, PopupVideo, Statistics } from "src/components/commonComponents";
import getContentConnectorFields from "src/components/helpers/getContentConnectorFields";
import getBenefitsData from "src/components/helpers/getBenefitsData";
import { BenefitsWrapper } from "src/components/commonComponents/benefitsCard";
import KeyFeatureSection from "src/components/blocks/KeyFeatureSection";
import "src/styles/genericForm.css";
const ContentConnector = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.ContentConnector
 });

const Calendly = loadable(() => import("src/components/commonComponents"), { resolveComponent: (components) => components.Calendly });
const getImageObject = require("src/../helpers/getImageObject")

const ImageVideoRendering = ({ mobile, fieldImage, fieldMediaVideoEmbedField, thumbnail, fieldImageAttrs }) => {

  return (
    <div>
      {fieldImage && !!fieldImageAttrs?.src && <div className={`mc-image-box ${mobile ? "" : "for-web tw-hidden md:tw-block"} product-page-hero-box`}>
        <img
          {...fieldImageAttrs}
          alt={fieldImage?.alt}
          loading="eager"
          fetchpriority="high"
          className={'hero-image'}
          decoding = "async"
        />
        <div>
          <PopupVideo videoUrl={fieldMediaVideoEmbedField} thumbnail={thumbnail} thumbnailClass={"p-0 is-full-tablet hero-video"} />
        </div>
      </div>}
      {fieldMediaVideoEmbedField && !fieldImageAttrs?.src && <div className={`${mobile ? "" : "for-web tw-hidden md:tw-block"}`}>
        <Video videoUrl={fieldMediaVideoEmbedField} />
      </div>}
    </div>
  )
}

export const GenericForm = ({ form, pageContext, fieldFormHeader, isFormLoaded }) => {
  const { fieldSubtitle, body, title, fieldPreHeading } = pageContext
  const locale = pageContext.locale || "en";
  let fieldImage = getImageObject(pageContext?.relationships?.fieldImage, pageContext?.fieldImage)
  const fieldImageAttrs = fieldImage?.gatsbyImage?.images?.fallback
  const fieldShowHeroVideo = pageContext?.fieldShowHeroVideo
  const fieldMediaVideoEmbedField = pageContext?.relationships?.fieldContentVideo?.fieldMediaVideoEmbedField
  const thumbnail = pageContext?.relationships?.fieldContentVideo?.relationships?.thumbnail?.url
  const fieldShowBackgroundPattern = pageContext?.fieldShowBackgroundPattern
  const fieldContactSuport = pageContext?.relationships?.fieldContactSupportCard
  const statisticsEntity = getFirstOneHavingData(pageContext?.relationships?.fieldParagraphStatisticsSect, pageContext?.relationships?.fieldStatisticsSection)
  const fieldLocations = pageContext?.relationships?.fieldLocations
  const isLocation = !!fieldLocations?.length
  const checkForOutreach = pageContext?.fieldUrl?.includes("hello.accruent.com") ? true : false;
  const checkForCalendly = pageContext?.fieldUrl?.includes("calendly.com") ? true : false;
  const checkForBenefit = !!pageContext?.relationships?.fieldContent
  let contentConnectors = [];
  const keyFeatures = pageContext?.relationships?.fieldKeyFeatures
  if (Array.isArray(pageContext?.relationships?.fieldContentSection)) {
    contentConnectors = getContentConnectorFields(
      pageContext?.relationships?.fieldContentSection,
      "basicPage"
    );
  }
  const benefitsData = getBenefitsData(pageContext?.relationships?.fieldContent?.[0], "generic_form", false, locale)
  return (
    <div className="generic-form-wrapper">
      <div className={` ${fieldShowBackgroundPattern ? "tw-relative form-graphic" : ""}`}>
        {fieldShowBackgroundPattern && <div className=" tw-bg-accruent_whitish_gray tw-h-[410px] tw-left-0 tw-absolute tw-right-0 tw-top-0 tw-z-[1]">
        </div>}
        <div className="header-bg tw-relative tw-z-[3] tw-pt-12 md:tw-pt-[74px] tw-px-4 md:tw-px-0 tw-pb-10 md:tw-pb-[76px] tw-h-auto tw-bg-full tw-bg-no-repeat tw-bg-s-518 tw-min-h-screen tw-w-auto">
          {/* <div className="left-bg-color tw-hidden lg:tw-block tw-absolute tw-bg-[#EE2A37] tw-w-[489px] tw-h-auto tw-top-[1975px] tw-right-0 tw-bottom-0"></div> */}
          <div className="container">
            {(<div className="md:tw-flex tw-block tw-gap-6 tw-items-start tw-justify-start tw-w-full main-box tw-gap-[25px]">
              <div className="tw-w-full md:tw-w-[calc(100%-486px)] left-content-box tw-w-full md:tw-w-[calc(100% - 486px)]">
                {fieldShowHeroVideo && <div className="mc-image-box for-mobile tw-block md:tw-hidden">
                  {(fieldImage?.gatsbyImage) && !pageContext?.relationships?.fieldContentVideo?.fieldMediaVideoEmbedField && <AccruentImg file={fieldImage} />
                  }
                  {pageContext?.relationships?.fieldContentVideo && <ImageVideoRendering mobile={true} fieldImageAttrs={fieldImageAttrs} fieldMediaVideoEmbedField={fieldMediaVideoEmbedField} thumbnail={thumbnail} fieldImage={fieldImage} />}
                </div>}
                <div className="tw-text-accruent_base_color tw-font-Roboto tw-text-xs tw-leading-none">
                  {fieldPreHeading && <p className="tw-mb-[10px]">{fieldPreHeading}</p>}
                </div>
                <div className="header-left-box ">
                  {title && (<h1 className="tw-mb-4 tw-text-xxxlFont md:tw-text-headingFont tw-text-accruent_heading_color tw-font-Poppins tw-font-bold">{title}</h1>)}
                  {fieldSubtitle && <h5 className="tw-mb-4 tw-text-mobileXlFont md:tw-text-xlFont tw-text-accruent_heading_color tw-font-Poppins tw-font-bold">{fieldSubtitle}</h5>}
                  <span>
                    {body?.value && <div dangerouslySetInnerHTML={{ __html: body.value }} className="dangerously-html-handle" />}

                  </span>
                </div>
                {fieldContactSuport && <ContactSupportCard cardContent={fieldContactSuport} />}


                {statisticsEntity?.status &&
                  <div className={"form-Statistics " + (isLocation ? 'form-statistics-with-location' : '')}>
                    <Statistics
                      entity={statisticsEntity}
                      statistics={
                        statisticsEntity?.relationships?.fieldStatistic
                      }
                      sectionStyle={'form_statistics'}
                      spread
                    />
                  </div>}
                {!pageContext?.relationships?.fieldContentVideo?.fieldMediaVideoEmbedField && fieldImage?.gatsbyImage && <div className="mc-image-box for-web tw-hidden md:tw-block product-page-hero-box tw-product-page-hero-box">
                  {(fieldImage?.gatsbyImage) && <AccruentImg file={fieldImage} />
                  }
                </div>}
                {pageContext?.relationships?.fieldContentVideo && <ImageVideoRendering fieldImageAttrs={fieldImageAttrs} fieldMediaVideoEmbedField={fieldMediaVideoEmbedField} thumbnail={thumbnail} fieldImage={fieldImage} />}
                <div className="generic-form-ContentConnector -tw-mt-4">
                  {contentConnectors.map((data, index) => (
                    <div key={index} className="generic-section tw-py-[32px]">
                      <ContentConnector
                        key={index}
                        data={data}
                        pageType={pageContext.type?.id}
                        locale={locale}
                      />
                    </div>
                  ))}
                </div>

              </div>
              <div className="md:tw-w-[512px] tw-w-full tw-mr-0 md:tw-pt-0 tw-pt-2">
                {(!pageContext?.fieldUrl) && <div>
                  <div className="border-box"></div>
                  <div className="form-box request-demo-form mc-form-box">
                    <CommonFormHeader
                      formTag={form}
                      fieldFormHeader={fieldFormHeader}
                      isFormLoaded={isFormLoaded}
                    />
                  </div>
                  <div className="border-box"></div>
                  {!fieldShowHeroVideo && fieldImage?.gatsbyImage && <div className="mc-image-box for-mobile tw-block md:tw-hidden">
                    {(fieldImage?.gatsbyImage) && !pageContext?.relationships?.fieldContentVideo?.fieldMediaVideoEmbedField && <AccruentImg file={fieldImage} />
                    }
                    {pageContext?.relationships?.fieldContentVideo && <ImageVideoRendering fieldImageAttrs={fieldImageAttrs} fieldMediaVideoEmbedField={fieldMediaVideoEmbedField} thumbnail={thumbnail} fieldImage={fieldImage} mobile={true} />}
                  </div>}
                </div>}
                {pageContext?.fieldUrl && checkForOutreach && <div class="sl-embed" >
                  <iframe class="sl-demo" src={pageContext?.fieldUrl} name="sl-embed" allow="fullscreen;"></iframe>
                </div>}
                {pageContext?.fieldUrl && checkForCalendly &&
                  <Calendly calendly={{ calendlyUrl: pageContext?.fieldUrl }} />
                }
              </div>
            </div>)}
            {keyFeatures && <div className="tw-pt-[32px]"> <KeyFeatureSection entity={keyFeatures}
            locale={locale}/> </div> }
            {checkForBenefit &&
              pageContext?.relationships?.fieldContent.map((benefit, index) => {
                return (
                  <>
                  {benefit?.fieldBiStyle !== "align_left_no_icon_grey_background" ? <div className='tw-flex-none md:tw-flex tw-mb-3 tw-bg-[linear-gradient(0deg,#f8f8f8_50%,transparent_0)] -tw-mx-[600px] tw-px-[600px] tw-pb-12 tw-flex-col tw-mt-6'>
                    {!benefit.fieldHideTitle && (
                      <div className={'column is-12-desktop is-12-mobile ' + benefit.fieldBenefitsSectionStyling}>
                        <div className="pre-heading">{benefit?.fieldSubtitle}</div>
                        <h3 className="title-3 mb-2">{benefit?.title}</h3>
                        <div
                          className="subtitle-2 mb-4 dangerously-html-handle"
                          dangerouslySetInnerHTML={{ __html: benefit?.body?.value }}
                        />
                        {benefit?.fieldCtaUrl &&
                          <LocalizedLink className='landing-btn-class' to={benefit?.fieldCtaUrl?.url}>
                            <button>{benefit?.fieldCtaUrl?.title}</button>
                          </LocalizedLink>}
                      </div>
                    )}
                    <div className='tw-flex-none tw-w-full md:tw-block tw-basis-0 tw-grow tw-shrink tw-p-3'>
                      <div className='tw-px-8 md:tw-px-2 tw-flex -tw-mb-3 md:-tw-mx-3 -tw-mx-8 -tw-mt-3 tw-overflow-x-scroll md:tw-overflow-x-visible'>
                        {benefit?.relationships?.fieldBenefitItem?.map((benefitItem, index) => {
                          return (
                            <div className=" tw-bg-accruent_white tw-flex-none md:tw-w-1/4 tw-w-3/4 tw-block tw-rounded-[48px_48px_48px_0px] tw-py-8 tw-border-0 tw-box-shadow-column-card tw-my-8 tw-mx-2 tw-opacity-100 tw-p-0 tw-text-accruent_heading_color tw-max-w-full tw-relative">
                              <div className="-tw-mt-[52px] tw-text-center tw-mr-5">
                                <span className="tw-inline-block tw-h-8 tw-w-10 tw-relative tw-text-5xl">
                                  <i
                                    className={`fal fa-${benefitItem?.fieldIcon?.iconName} tw-text-accruent_sapphire tw-text-[52px] tw-h-[1em] tw-w-[1.25em] tw-bottom-0 tw-left-0 tw-right-0 tw-top-0 tw-m-auto tw-absolute`}
                                  ></i>
                                </span>
                              </div>
                              <div className="generic-description-content">
                                <div className="tw-py-0 tw-px-6 tw-text-center tw-mt-6">
                                  <h6 className="tw-text-accruent_heading_color tw-font-bold tw-text-[19px] tw-leading-[22px] tw-tracking-[0.22px] tw-mt-8 tw-font-Poppins">{benefitItem?.title}</h6>
                                </div>
                                <div
                                  dangerouslySetInnerHTML={{ __html: benefitItem?.body?.value }}
                                  className="tw-text-[17px] tw-leading-[25px] tw-text-accruent_heading_color tw-font-normal tw-font-Roboto tw-p-[8px_24px_32px] tw-text-center"
                                />
                                {benefitItem?.fieldCtaUrl?.title && (
                                  <>
                                    <div className="tw-py-0 tw-px-6 tw-text-center"></div>
                                    <div className="tw-bottom-4 tw-absolute tw-w-full tw-py-0 tw-px-6 tw-text-center generic-form">
                                      <LocalizedLink to={benefitItem?.fieldCtaUrl?.url}>
                                        {benefitItem?.fieldCtaUrl?.title}
                                      </LocalizedLink>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          )
                        })}
                      </div>
                    </div>
                  </div>
                  :
                  <div><BenefitsWrapper benefits={[benefitsData]} locale={locale}/></div>  
                }
                  </>
                )
              })
            }
            <div className="container">

              {isLocation &&
                <div className="md:tw-text-lg tw-text-base md:tw-pb-[100px] tw-pb-[52px] md:tw-pt-[61px] tw-pt-[52px] md:tw-px-0 tw-px-[15px] md:tw-w-[calc(100%-486px)] tw-w-full">
                  <h2 className="tw-mb-4 md:tw-text-xxxlFont tw-text-accruent_heading_color tw-font-Poppins tw-font-bold tw-text-mobileXxxlFont">Headquarters</h2>
                  <div className="tw-flex tw-flex-wrap -tw-m-3">
                    {fieldLocations.map((location, index) => {
                      return (
                        <div className="md:tw-w-1/2 tw-w-full" key={index}>
                          <HeadquartersCard key={index} headquarter={location} />
                        </div>)
                    })}
                  </div>
                </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// export default GenericForm;
