import React from "react";
import { get } from "src/utils";
import getResourceTagsList from "../../../../helpers/getResourceTagsList";
// import Hero from "src/components/hero/ResourcesHero";
import { ResourcesHero as Hero} from "src/components/templateComponents";
import { getResourceDate } from "../../../../helpers/dateUtilities";
import getContentConnectorFields from "src/components/helpers/getContentConnectorFields";
import { Eyebrow } from "src/components/commonComponents";
import loadable from '@loadable/component';
import useAddParamInHtmlLink from "src/components/helpers/useAddParamInHtmlLink";
const ContentConnector = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.ContentConnector
 });
 // const SideBar = loadable(() => import('../../components/resources/ResourceSideBar'));
const SideBar = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.SideBar
});
const StickyBox = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.StickyBox
});

export const SingleKnowledgeHubPost = ({ data }) => {
  const { locale } = data;
  const resourceTags = getResourceTagsList([data.industryIds, data.solutionIds, data.productIds])
  let heroImage = get(data, "heroImage");
  const heroVideoUrl = get(data, "relationships.fieldHeroVideo.fieldMediaVideoEmbedField");
  const heroVideoThumb = get(data, "relationships.fieldHeroVideo.relationships.thumbnail.url");

  const inThisArticle =  data?.relationships?.fieldInThisArticleSidebar
  let contentConnectors = [];
  if (Array.isArray(data?.relationships?.fieldContentSections)) {
    contentConnectors = getContentConnectorFields(
      data.relationships.fieldContentSections,
      "basicPage"
    );
  }

  let pageType = data?.type?.id
  useAddParamInHtmlLink() 
  return (
    <section className="resources-page section !tw-pt-0 content-visibility-auto-mobile">
      <div className="container article-page">
        <section className="medium-wrapper tw-text-[16px] md:tw-text-[18px] tw-py-0 tw-px-[15px] md:tw-px-0 md:tw-py-0 tw-mt-[32px] md:tw-mt-[72px]">
          <div className="columns md:tw-flex">
            <div className="tw-w-full lg:tw-w-[calc(100%-376px)] lg:tw-max-w-[calc(100%-376px)] [&]:tw-px-0">
              {contentConnectors.map((data, index) => (
                <div key={index} className="section-container form-full-width">
                  <ContentConnector
                    key={index}
                    data={data}

                    locale={locale}
                    index={index}
                    pageType={pageType}
                    heroImage={heroImage}
                    formFullWidth={true}
                  />
                </div>
              ))}
            </div>

            <div className="tw-w-full lg:tw-max-w-[376px] lg:tw-p-[12px]">
              <div className="right-content sidebar-content tw-bg-accruent_white tw-w-full md:tw-w-[376px] tw-relative">
                <StickyBox
                  scrollContainerClass={"sidebar-scroll"}
                >
                  <div className="right-box knowledge-hub-right-box">
                    <SideBar
                      recommendedResources={data.rectifiedRecommended}
                      inThisArticle={inThisArticle}
                    />
                  </div>
                </StickyBox>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  )
}

// export default SingleKnowledgeHubPost;
