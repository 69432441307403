import React from 'react'
import { LocalizedLink, AccruentImg, LCPImage } from 'src/components/commonComponents';
import { isEmpty } from "src/utils";
import dateFormatter from '../../helpers/dateFormatter';
import { StaticImage } from "gatsby-plugin-image";


export const LegalPageHero = ({title, fieldHeroDescription, fieldPreHeading, fieldOverviewPage, image, fieldLastUpdate, fieldEffectiveDate, fieldHideHeroImage, showViewAll}) => {
  let effectiveDateFormatter = null, lastUpdateDateFormatter = null
  if (!isEmpty(fieldEffectiveDate)) {
    effectiveDateFormatter = dateFormatter(fieldEffectiveDate)
  }if (!isEmpty(fieldLastUpdate)) {
    lastUpdateDateFormatter = dateFormatter(fieldLastUpdate)
  }
  return (
    <>
      <section
        className=" hero is-block is-halfheight legal-page-home-hero ems-home-hero !tw-pt-0 !tw-pb-0
      tw-py-6 lg:!tw-px-4 md:!tw-px-10 !tw-px-4"
      >
        <LCPImage />
        <div className="container extend-width !tw-max-w-[1800px]">
          <div
            className={`hero-content md:tw-flex !tw-ml-[-1rem] md:!-tw-mr-3 tw-relative tw-overflow-hidden ${
              !fieldOverviewPage
                ? "non-overview tw-non-overview -tw-my-3 -tw-mr-3 lg:tw-bg-circle_red tw-flex md:tw-flex-row tw-flex-col-reverse tw-bg-accruent_lightgray"
                : ""
            }`}
          >
            <div className="tw-flex md:tw-flex-none tw-p-3 tw-w-full lg:tw-w-3/4 hero-left-container !tw-pb-[31px] lg:!tw-pt-[30px] !tw-pt-1 lg:tw-min-h-[250px] tw-box-border">
              {!fieldOverviewPage ? (
                <StaticImage
                  src={"../../../../static/legalhub-bw-hero-right.png"}
                  alt="left-hero-image"
                  className="side-hero-img md:tw-w-[192px] tw-left-0 !tw-hidden lg:!tw-flex !tw-absolute md:tw-top-0 md:tw-h-full"
                  quality={90}
                  loading="eager"
                  placeholder="none"
                  decoding="async"
                />
              ) : (
                <StaticImage
                  src={"../../../../static/legalhub-hero-left.png"}
                  alt="left-hero-image"
                  className="side-hero-img !tw-absolute lg:tw-top-0 
                 !tw-hidden lg:!tw-block lg:tw-h-full lg:tw-left-[-19px] lg:tw-w-[192px]"
                  quality={90}
                  loading="eager"
                  placeholder="none"
                  decoding="async"
                />
              )}
              <div className="hero-main-content tw-hero-main-content tw-m-auto tw-w-full lg:tw-w-[65%]">
                {(fieldPreHeading || !!showViewAll) && (
                  <div className="pre-heading md:tw-block tw-mb-2 lg:tw-text-[12px] tw-font-medium  tw-text-accruent_base_color tw-leading-4 tw-tracking-[1.8px] tw-font-Roboto desktop-view tw-mt-[17px]">
                    <div>
                      {!!showViewAll && (
                        <LocalizedLink
                          to={"/legal-hub"}
                          className="view-all tw-mt-8 md:tw-mt-0
                       tw-font-Roboto tw-text-[14px] tw-text-accruent-base-color tw-leading-4 tw-tracking-[2.1px] tw-font-medium"
                        >
                          {"‹ VIEW ALL "}
                        </LocalizedLink>
                      )}
                      {!!fieldPreHeading && (
                        <p
                          className={`${
                            !!showViewAll ? "pre-heading-text lg:tw-pt-32" : ""
                          }`}
                        >
                          {fieldPreHeading}
                        </p>
                      )}
                    </div>
                  </div>
                )}
                <h1 className="title-2 tw-mt-0 lg:tw-mt-0 tw-mb-2 md:tw-mb-[13px] md:tw-text-headingFont tw-text-xxxlFont tw-font-Poppins tw-text-accruent_heading_color">
                  {title}
                </h1>
                {fieldHeroDescription && (
                  <div className="subtitle-1 tw-text-[22px] lg:tw-text-2xl lg:tw-leading-7 lg:tw-tracking-wide">
                    {fieldHeroDescription}
                  </div>
                )}
                {(fieldEffectiveDate || fieldLastUpdate) && (
                  <div className="legal-dates md:!tw-pb-0 lg:tw-mt-[13px]">
                    {fieldEffectiveDate && (
                      <p className="tw-m-0 tw-text-accruent_base_color md:tw-text-[22px] tw-text-[18px] tw-font-roboto tw-font-medium">
                        <span className="tw-font-bold">Effective Date:</span>{" "}
                        {effectiveDateFormatter}
                      </p>
                    )}
                    {fieldLastUpdate && (
                      <p className="tw-m-0 tw-text-accruent_base_color md:tw-text-[22px] tw-text-[18px] tw-font-roboto tw-font-medium">
                        <span className="tw-font-bold">Last Update:</span>{" "}
                        {lastUpdateDateFormatter}
                      </p>
                    )}
                  </div>
                )}
              </div>
            </div>

            <div className="leadership-img-container lg:tw-block tw-leadership-img-container tw-hidden tw-flex-1 md:tw-flex-none tw-w-full md:tw-w-1/4 tw-overflow-auto md:tw-overflow-hidden tw-pl-5 md:!tw-pl-0 tw-pb-0">
              <StaticImage
                src={"../../../../static/legalhub-hero-right.png"}
                alt="right-hero-image"
                className="side-hero-img tw-right-[-92px] tw-w-[457px] tw-h-full tw-hidden md:tw-flex"
                quality={90}
                width={465}
                loading="eager"
                placeholder="none"
                decoding="async"
              />
              {!fieldOverviewPage && !!showViewAll && (
                <LocalizedLink
                  to={"/legal-hub"}
                  className="view-all tw-block lg:tw-hidden tw-font-Roboto tw-text-sm tw-text-accruent_base_color tw-leading-4 tw-tracking-[2.1px] tw-font-medium tw-mt-8 md:tw-mt-0"
                >
                  {"‹ VIEW ALL "}
                </LocalizedLink>
              )}
              {!fieldHideHeroImage && <AccruentImg file={image} />}
              {fieldPreHeading && (
                <p className="pre-heading mobile-view tw-hidden">
                  {fieldPreHeading}
                </p>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
// export default LegalPageHero;