import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { Fragment } from "react";
import { LocalizedLink } from "./localizedLink";
/**
 *
 * @param {Array of Objects} crumbs - [ { title: String, link: String[optional] }, { ... } ]
 */
export const Breadcrumbs = ({ crumbs }) => {
  const {
    site: {
      siteMetadata: { siteUrl },
    },
  } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
          title
        }
      }
    }
  `);

  const withBaseURL = (relative) => siteUrl.concat(relative);

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: crumbs.map((crumb, index) => {
      const listItem = {
        "@type": "ListItem",
        position: index + 1,
        name: crumb.title,
      };
      if (crumb.link) {
        listItem.item = withBaseURL(crumb.link);
      }
      return listItem;
    }),
  };

  return (
    <>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>
      <nav
        className="breadcrumb has-succeeds-separator tw-mb-[1.5rem] tw-uppercase !tw-text-[0.75rem] tw-flex-nowrap"
        aria-label="breadcrumbs"
      >
        <ul className=" tw-list-none tw-items-start tw-flex tw-flex-wrap tw-justify-start">
          {crumbs.map((crumb, i) => {
            return (
              <Fragment key={i}>
                {crumb.link ? (
                  <li className=" tw-items-center tw-flex">
                    <LocalizedLink className=" tw-cursor-pointer tw-items-center tw-text-[#485fc7] tw-flex tw-justify-center tw-px-[0.75rem] tw-py-0" to={crumb.link}>
                    {crumb.title}
                  </LocalizedLink>

                  </li>
                ) : (
                  <li className="is-active tw-whitespace-pre-wrap">
                  <a href="#" aria-current="page" className=" tw-items-center tw-flex tw-justify-center tw-px-[0.75rem] tw-py-0 tw-text-[#363636] tw-cursor-default tw-pointer-events-none">{crumb.title}</a>
                  </li>
                )}
              </Fragment>
            );
          })}
        </ul>
      </nav>
    </>
  );
};

