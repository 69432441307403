import React, { useState, useRef } from 'react'
import { LocalizedLink } from "src/components/commonComponents";
import { StaticImage } from "gatsby-plugin-image";
import "src/scss/_watchDemoModal.scss";
//This code should be replaced and transform images into graphQl node in gatsby-node.js
const drupal_api_base = `${process.env.DRUPAL_API_BASE_URL || "https://live-accruent.pantheonsite.io/"}`;

// Adding WATCH_DEMO_MODAL_WIDTH as a constant here, since while importing it from constants file, it was not getting accepted by StaticImage component.
const WATCH_DEMO_MODAL_WIDTH = 600

const modalData = {
  tabs: [
    {
      name: "EMS",
      image: <StaticImage src={"../../../../static/watchModal/Logo-EMS-gray.png"} alt="ems" />,
      activeImage: <StaticImage src={"../../../../static/watchModal/ems-logo.png"} alt="ems-active" />
    },
    {
      name: "MC",
      image: <StaticImage src={"../../../../static/watchModal/Logo-MC-gray.png"} alt="mc" />,
      activeImage: <StaticImage src={"../../../../static/watchModal/mc-logo.png"} alt="mc-active" />
    },
    {
      name: "Meridian",
      image: <StaticImage src={"../../../../static/watchModal/Logo-meridian-gray.png"} alt="merdian" />,
      activeImage: <StaticImage src={"../../../../static/watchModal/meridian-logo.png"} alt="merdian-active" />
    },
    {
      name: "lucernex",
      image: <StaticImage src={"../../../../static/watchModal/Logo-lucernex-gray.png"} alt="lucernex" />,
      activeImage: <StaticImage src={"../../../../static/watchModal/lucernex-logo.png"} alt="lucernex-active" />
    },
    {
      name: "lucernex",
      image: <StaticImage src={"../../../../static/watchModal/SC-logo-gray.png"} alt="lucernex" />,
      activeImage: <StaticImage src={"../../../../static/watchModal/SC-logo.png"} alt="lucernex-active" />
    }
  ],
  tabContents: [
    {
      topImage: <StaticImage src={"../../../../static/watchModal/Modal-EMS-background-right.png"} alt="ems" quality={90} width={221} className="top-image" />,
      title: "EMS",
      body: "Space Management & Room Scheduling Software",
      image: <StaticImage src="https://live-accruent.pantheonsite.io/sites/default/files/2021-02/accruent-ems-space-management_0.png" alt="EMS Space Management and Room Scheduling Software" />,
      cta: { title: "Watch Demo", url: { path: "/watch-demo-ems" } },
      secondaryCta: { title: "View Product Details", url: { path: "/products/ems" } }
    },
    {
      topImage: <StaticImage src={"../../../../static/watchModal/Modal-MC-background-right.png"} alt="mc" quality={90} className="top-image" />,
      title: "MAINTENANCE CONNECTION",
      body: "Computerized Maintenance Management System (CMMS)",
      image: <StaticImage src="https://live-accruent.pantheonsite.io/sites/default/files/2021-02/accruent-product-maintenance-connection-screenshot.png" alt="Maintenance Connection | Computerized Maintenance Management System Software (CMMS)" />,
      cta: { title: "Watch Demo", url: { path: "/watch-demo-mc" } },
      secondaryCta: { title: "Pricing Calculator", url: { path: "/pricing-calculator#maintenance_connection" } },
      secondaryCtaUK: { title: "View Product Details", url: { path: "/products/maintenance-connection" } }
    },
    {
      topImage: <StaticImage src={"../../../../static/watchModal/Modal-Meridian-background-right.png"} alt="merdian" quality={90} className="top-image" />,
      title: "MERIDIAN",
      body: "Engineering Document Management System (EDMS)",
      image: <StaticImage src="https://live-accruent.pantheonsite.io/sites/default/files/2021-02/accruent-product-meridian.png" alt="Meridian | Engineering Document Management System (EDMS)" />,
      cta: { title: "Watch Demo", url: { path: "/watch-demo-meridian" } },
      secondaryCta: { title: "Pricing Calculator", url: { path: "/pricing-calculator#meridian" } },
      secondaryCtaUK: { title: "View Product Details", url: { path: "/products/meridian" } }
    },
    {
      topImage: <StaticImage src={"../../../../static/watchModal/Modal-Lucernex-background-right.png"} alt="lx" quality={90} className="top-image" />,
      title: "LX SUITE/LUCERNEX",
      body: "Commercial Lease Management, Site Selection/Planning, and Construction Project Management Software",
      image: <StaticImage src="https://live-accruent.pantheonsite.io/sites/default/files/2021-02/accruent-product-lucernex-screenshot.png" alt="LX SUITE/LUCERNEX | Commercial Lease Management" />,
      cta: { title: "Watch Demo", url: { path: "/watch-demo-lucernex" } },
      secondaryCta: { title: "View Product Details", url: { path: "/products/lucernex" } }
    },
    {
      topImage: <StaticImage src={"../../../../static/watchModal/bg-graphics.png"} alt="lx" quality={90} className="top-image" />,
      title: "LX SUITE/LUCERNEX",
      body: "Retail Computerized Maintenance Management System",
      image: <StaticImage src="../../../../static/watchModal/sc-laptop.png" alt="ServiceChannel | Accruent" />,
      cta: { title: "Watch Demo", url: { path: "/watch-product-demo-servicechannel" } },
      ctaUkAsUs: true,
      secondaryCta: { title: "View Product Details", url: { path: "/products/servicechannel" } }
    }
  ]
}


export const WatchDemoModal = ({ handleToggle, locale = 'en' }) => {
  const [currentTab, setCurrentTab] = useState(0)
  const topRef = useRef(null)
  const handleClose = (e) => {
    if (e.target.tagName === 'A') {
      handleToggle()
    }
  }
  const changeTab = (i) => {
    topRef.current?.scrollIntoView()
    setCurrentTab(i)
  }

  return (
    <div className='watch-demo-modal' >

      <div className="container">
        <div className="demo-watch-popup" >
          <div className="modal-header">
            <h5 className="modal-title title-6">
              <span className="eyebrow">WATCH DEMO NOW</span>
              <span className="heading">Which Product are you interested in?</span>
            </h5>
            <a onClick={handleToggle} className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </a>
          </div>
          <div className='modal-body-container'>
            <div className="modal-body">
              <div ref={topRef}></div>
              <div className="accordion-box">
                <div className="left-tabs-box">
                  <ul className="nav nav-tabs" role="tablist">
                    {modalData.tabs.map((tab, i) => {
                      return (
                        <li
                          className={`nav-item ${tab.name}`}
                          onClick={() => setCurrentTab(i)}
                        >
                          <a className={`nav-link ${(i === currentTab) ? 'active' : ''} `} data-toggle="tab" role="tab">
                            {(i === currentTab) ? tab.activeImage : tab.image}
                          </a>
                        </li>
                      )
                    })}
                  </ul>
                </div>
                {modalData.tabContents.filter((content, i) => i === currentTab).map(content => {
                  let secondaryCta = ((locale === 'en-gb') && content.secondaryCtaUK) ? content.secondaryCtaUK : content.secondaryCta
                  console.log("secondaryCta", secondaryCta)
                  return (
                    <div className={`tab-content`}>
                      {content.topImage}
                      <div className="card tab-pane tab-pane-a fade show active">
                        <div className="card-header">
                          <h5 className="title-6 mb-0">
                            <a>
                              <span className={`logo ${modalData.tabs[currentTab].name}`}> {modalData.tabs[currentTab].activeImage}</span>
                              <span className="arrow"><i className="fal fa-angle-up" /></span>
                            </a>
                          </h5>
                        </div>
                        <div onClick={handleClose} className={`collapse`}>
                          <div className="card-body">
                            <div className="image">
                              {content.image}
                            </div>
                            {content.body && <p>{locale === "en-gb" ? content.body.replace("Computerized", "Computerised") : content.body}</p>}
                            <div className="btn-box">
                              {content.cta && (
                                <LocalizedLink
                                  className="btn primary-btn"
                                  to={`${(locale === 'en' || content?.ctaUkAsUs) ? '' : '/uk'}${content.cta.url.path}`}
                                >
                                  {content.cta.title}
                                </LocalizedLink>

                              )}
                              {secondaryCta && (
                                <LocalizedLink
                                  className="btn line-btn"
                                  to={`${locale === 'en' ? '' : '/uk'}${secondaryCta.url.path}`}
                                >
                                  {secondaryCta.title}
                                </LocalizedLink>

                              )}

                            </div>
                          </div>
                        </div>
                      </div>
                      {modalData.tabs.map((tab, i) => {
                        if (i === currentTab) {
                          return <></>
                        }
                        return (
                          <div onClick={() => changeTab(i)} className="card tab-pane below-menu fade">
                            <div className="card-header" role="tab"  >
                              <h5 className="title-6 mb-0">
                                <a className="collapsed" >
                                  <span className={`logo ${tab.name}`}> {tab.image} </span>
                                  <span className="arrow"><i className="fal fa-angle-down" /></span>
                                </a>
                              </h5>
                            </div>
                          </div>
                        )
                      })}


                    </div>
                  )
                })}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
