import React, { useEffect, useState } from "react";
import { get } from "src/utils";
import getAuthorName from "../../../../helpers/getAuthorName";
import getResourceTagsList from "../../../../helpers/getResourceTagsList";
// import Hero from "src/components/hero/ResourcesHero";
import { ResourcesHero as Hero} from "src/components/templateComponents";
import getPublishedData from "src/../helpers/getPublishedData";
import { Eyebrow } from "src/components/commonComponents";
import loadable from '@loadable/component';
import useAddParamInHtmlLink from "src/components/helpers/useAddParamInHtmlLink";
const Statistics = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.Statistics
});
// const QuoteBlock = loadable(() => import('src/components/QuoteBlock'))
const QuoteBlock = loadable(() => import("src/components/commonComponents"), {
  resolveComponent: (components) => components.QuoteBlock
});
// const SideBar = loadable(() => import('../../components/resources/ResourceSideBar'));
const SideBar = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.SideBar
});
const StickyBox = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.StickyBox
});
// const Picture = loadable(() => import('src/components/Picture'))
const Picture = loadable(() => import("src/components/commonComponents"), {
  resolveComponent: (components) => components.Picture
});
// const PopupVideo = loadable(() => import('src/components/popupVideo/PopupVideo'))
const PopupVideo = loadable(() => import("src/components/commonComponents"), {
  resolveComponent: (components) => components.PopupVideo
});

export const SingleCaseStudyPost = ({ data }) => {
  const { locale } = data;
  const authorName = getAuthorName(data?.relationships);
  const resourceTags = getResourceTagsList([data.industryIds, data.solutionIds, data.productIds])
  const companyQuoteDataEntity = data?.relationships?.fieldPgQuoteTheCompany || data?.relationships?.quoteTheCompany
  const challengeQuoteDataEntity = data?.relationships?.fieldPgQuoteTheChallenge || data?.relationships?.fieldQuoteTheChallenge
  const solutionQuoteDataEntity = data?.relationships?.fieldPgQuoteTheSolution || data?.relationships?.quoteTheSolution
  const resultQuoteDataEntity = data?.relationships?.fieldPgQuoteTheResult || data?.relationships?.fieldQuoteTheResult

  const companyQuoteData = {
    quote: companyQuoteDataEntity?.fieldQuoteBody,
    quoteAuthor: companyQuoteDataEntity?.title,
    quoteAuthorDesignation: companyQuoteDataEntity?.fieldCustomerDesignation,
    quoteAuthorCompany: companyQuoteDataEntity?.company,
  };
  const challengeQuoteData = {
    quote: challengeQuoteDataEntity?.fieldQuoteBody,
    quoteAuthor: challengeQuoteDataEntity?.title,
    quoteAuthorDesignation: challengeQuoteDataEntity?.fieldCustomerDesignation,
    quoteAuthorCompany: challengeQuoteDataEntity?.company,
  };
  const solutionQuoteData = {
    quote: solutionQuoteDataEntity?.fieldQuoteBody,
    quoteAuthor: solutionQuoteDataEntity?.title,
    quoteAuthorDesignation: solutionQuoteDataEntity?.fieldCustomerDesignation,
    quoteAuthorCompany: solutionQuoteDataEntity?.company,
  };
  const resultQuoteData = {
    quote: resultQuoteDataEntity?.fieldQuoteBody,
    quoteAuthor: resultQuoteDataEntity?.title,
    quoteAuthorDesignation: resultQuoteDataEntity?.fieldCustomerDesignation,
    quoteAuthorCompany: resultQuoteDataEntity?.company,
  };
  const linkToCaseStudyPdf = data.fieldCaseStudyPdfLink ? data.fieldCaseStudyPdfLink.url : (data.fieldCaseStudyPdf ? data.fieldCaseStudyPdf.url : null)
  let heroImage = get(data, "heroImage");
  const heroVideoUrl = get(data, "relationships.fieldHeroVideo.fieldMediaVideoEmbedField");
  const heroVideoThumb = get(data, "relationships.fieldHeroVideo.relationships.thumbnail.url");
  
  const [thumbnail, setThumbnail] = useState('')
  let fieldCsStatistics = []
  const statisticsSets = !(data?.relationships?.fieldCsStatistic?.length) ? data?.relationships?.fieldStatisticSet : data?.relationships?.fieldCsStatistic
  if (statisticsSets) {
    fieldCsStatistics = getPublishedData(statisticsSets)
  }
  useAddParamInHtmlLink()
  useEffect(() => {
    if (data.fieldHeroVideoLink) {
      fetch("https://fast.wistia.net/oembed?url=http://home.wistia.com/medias/" + data.fieldHeroVideoLink + "?embedType=async_popover&videoWidth=376").then((res) => res.json()).then((res) => {
        if (res && res.thumbnail_url) {
          setThumbnail(res.thumbnail_url)
        }
      })
    }
  }, [data.fieldHeroVideoLink])
  return (
    <section className="resources-page section !tw-pt-0 content-visibility-auto-mobile">
      <div className="container case-study-page">
        <section className="medium-wrapper tw-mt-[32px] md:tw-mt-[72px] tw-text-[16px] md:tw-text-[18px] tw-py-0 tw-px-[15px] md:tw-px-0 md:tw-py-0">
          <div className="columns md:tw-flex">
            <div className="tw-w-full lg:tw-w-[calc(100%-376px)] lg:tw-max-w-[calc(100%-376px)] [&]:tw-px-0 [&]:md:tw-pr-3">
              {!!authorName && (
                <h3>
                  By {authorName}
                </h3>
              )}
              <div
                className="new-single-resource-content-body"

              >
                {data.fieldTheCompany && (
                  <div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: data.fieldTheCompany.value,
                      }}
                      className="dangerously-html-handle"
                    ></div>
                  </div>
                )}

                {fieldCsStatistics && !!fieldCsStatistics?.length && (
                  <div className="section">
                    <Statistics statistics={fieldCsStatistics} spread />
                  </div>
                )}
                <div className="middle-of-content mb-2">
                {heroVideoUrl ?
                    <PopupVideo
                      videoUrl={heroVideoUrl}
                      thumbnail={heroVideoThumb}
                      thumbnailClass={`thumbnail-box banner-graphic tw-p-0 is-12-mobile  thumbnail-hero`}
                      isHero
                    />
                    :
                    (heroImage) ?
                      <div className={`banner-graphictw-p-0 tw-min-h-[376px]`}>
                        <Picture
                          image={heroImage}
                          className={'resources-hero-image tw-h-full tw-w-full tw-min-h-[376px] md:tw-min-h-0 tw-object-cover'}
                        />
                      </div> : null
                  }
                </div>

                {companyQuoteDataEntity?.status && (
                  <QuoteBlock
                    data={companyQuoteData}
                    isCompany
                    isNarrow
                    hasTextBig
                    hasAuthorLeft
                    hasBackgroundTransparent
                  />
                )}

                {data.fieldTheChallenge && (
                  <div>
                    <div className="single-resource-content-body">
                      <div>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data.fieldTheChallenge.value,
                          }}
                          className="dangerously-html-handle"
                        ></div>
                      </div>
                    </div>
                  </div>
                )}

                {challengeQuoteDataEntity?.status && (
                  <QuoteBlock
                    data={challengeQuoteData}
                    isCompany
                    isNarrow
                    hasTextBig
                    hasAuthorLeft
                    hasBackgroundTransparent
                  />
                )}

                {data.fieldTheSolution && (
                  <div>
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.fieldTheSolution.value,
                        }}
                        className="dangerously-html-handle"
                      />
                    </div>
                  </div>
                )}

                {solutionQuoteDataEntity?.status && (
                  <QuoteBlock
                    data={solutionQuoteData}
                    isCompany
                    isNarrow
                    hasTextBig
                    hasAuthorLeft
                    hasBackgroundTransparent
                  />
                )}

                {data.fieldTheResult && (
                  <div>
                    <div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.fieldTheResult.value,
                        }}
                        className="dangerously-html-handle"
                      />
                    </div>
                  </div>
                )}

                {resultQuoteDataEntity?.status && (
                  <QuoteBlock
                    data={resultQuoteData}
                    isCompany
                    isNarrow
                    hasTextBig
                    hasAuthorLeft
                    hasBackgroundTransparent
                  />
                )}
              </div>
            </div>
            <div className="tw-w-full lg:tw-max-w-[376px] lg:tw-p-[12px]">
              <div className="right-content sidebar-content tw-bg-accruent_white tw-w-full md:tw-w-[376px] tw-relative">
                <StickyBox
                  scrollContainerClass={"sidebar-scroll"}
                >
                  <div className="right-box">
                    <SideBar recommendedResources={data.rectifiedRecommended} />
                  </div>
                </StickyBox>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  )
}

// export default SingleCaseStudyPost;
