import React, { useEffect } from "react";
import { AccruentImg, Video } from "src/components/commonComponents";
import { CommonFormHeader } from "src/components/templateComponents";
import { get } from "src/utils";
const getImageObject = require("src/../helpers/getImageObject")

const VideoNotPresent = () => {
  useEffect(() => {
    document.getElementById("watch-demo-header").style.height = "0px"
  },[])
  return(null)
}
export const WatchDemoForm = ({form, pageContext, fieldFormHeader,isFormLoaded}) => {
  // let imageUrl = "placeholders/accruent_default-hero-placeholder.jpg";
  const {fieldSubtitle, title} = pageContext
  const fieldVideoRef = pageContext.relationships.fieldVideoRef
  let fieldMediaImage = get(pageContext, "relationships.fieldMediaImage");;
  fieldMediaImage = getImageObject(fieldMediaImage,{}, "fieldMediaImage")
  let fieldImage = getImageObject(pageContext?.relationships?.fieldImage,pageContext?.fieldImage)

  return (
    <section className=" tw-text-accruent_base_color tw-font-Roboto tw-block ">
      <div className="form-wrapper">
        <div className="watch-demo-wrapper">
          <div className="container">
            <div className="tw-bg-cover tw-h-full" id="watch-demo-header">
            {(fieldVideoRef?.fieldMediaVideoEmbedField) ?
            <Video videoUrl={fieldVideoRef.fieldMediaVideoEmbedField}
            />: (fieldMediaImage?.gatsbyImage) ? <AccruentImg file={fieldMediaImage}/> : <VideoNotPresent/>}
            </div>
            <div className="md:tw-pb-[76px] md:tw-pt-[61px] tw-py-9 tw-px-0">
              <div className="md:tw-flex tw-block -tw-m-3">
                <div className="tw-block tw-basis-0 tw-shrink tw-grow tw-p-3">
                  <div className="tw-pr-0 md:tw-pr-[26px]">
                  {title && (<h1 className="md:tw-w-[65%] tw-w-full tw-mb-2.5 md:tw-text-headingFont tw-text-xxxlFont tw-font-Poppins tw-text-accruent_heading_color">{title}</h1>)}
                    {fieldSubtitle &&
                    <p className="md:tw-w-[65%] tw-w-full md:tw-text-[24px] md:tw-leading-[29px] tw-text-[22px] tw-leading-[26px] tw-font-normal md:tw-mb-4 tw-mb-2">
                      {fieldSubtitle}
                    </p>}
                    <div className="tw-bg-full tw-h-[293px] md:tw-block tw-hidden">
                      {(fieldImage?.gatsbyImage) && <AccruentImg file={fieldImage}/>

                    }
                    </div>
                  </div>
                </div>
                <div className="tw-block tw-basis-0 tw-shrink tw-grow tw-p-3">
                  <div className="tw-relative md:tw-pl-[26px] tw-pl-0 form-watch-demo-box watch-demo-redesign-form">
                    <div className=" tw-bg-accruent_purple_form tw-h-1 tw-w-full tw-opacity-100"></div>
                      <CommonFormHeader
                        formTag={form}
                        fieldFormHeader={fieldFormHeader}
                        isFormLoaded={isFormLoaded}
                      />
                    <div className="tw-bg-accruent_purple_form tw-h-1 tw-w-full tw-opacity-100"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// export default WatchDemo;
