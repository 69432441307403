import React from "react";
import findSiteType from "src/components/helpers/findSiteType";

export const QuoteBlock=({
  data,
  isCompany = false,
  isNarrow = false,
  hasTextBig = false,
  hasAuthorLeft = false,
  hasBackgroundTransparent = false,
})=>{
  const isEms = findSiteType('ems');
  const isMc = findSiteType('mc');
  return (
    <div className="content">
      <div className="tw-border-0 tw-bg-white tw-rounded tw-text-heading-color tw-max-w-full tw-relative">
        <div className="tw-p-0 tw-bg-transparent">
          <div className={`quote-image tw-h-[38px] tw-mb-[18px] tw-w-[42.75px] md:tw-h-12 md:tw-mb-6 md:tw-w-[54px] ${isCompany ? 'is-company !tw-mt-0 tw-text-accruent-base-color !tw-leading-[20px] ' : ''} ${isEms ? 'is-ems' : ''} ${isMc ? 'is-mc' : ''}`}></div>
          <div className="empty-space"></div>
          <div className="columns is-vcentered is-centered is-mobile">
            <div
              className={`quote-block ${isNarrow ? "" : "is-edge-to-edge"} ${
                hasBackgroundTransparent ? "tw-bg-transparent" : "tw-bg-white"
              }`}
            >
              <div
                className={`column text-kingfisher-daisy tw-pl-[10px] ${
                  hasTextBig ? "is-size-4" : "is-size-5"
                }`}
              >
                <div className="">
                  <div className={isCompany ? 'tw-text-accruent-heading-color tw-font-black tw-text-mobileXlFont' : ''}>{data.quote}</div>
                  <div
                    className={`has-text-weight-bold ${
                      hasAuthorLeft ? "tw-p-0 !tw-pl-0 md:tw-text-[16px] tw-font-bold tw-text-heading_color tw-capitalize tw-text-sm" : "has-text-right"
                    } ${isCompany ? 'tw-font-bold tw-pt-4 tw-pb-0 tw-capitalize quote-author-title tw-text-accruent-base-color tw-mt-0 tw-leading-5' : ''}`}
                  >
                    {isCompany ? '— ' : ''}
                    {data.quoteAuthor}
                  </div>
                  {(isCompany && (data.quoteAuthorDesignation || data.quoteAuthorCompany)) && (
                    <div
                      className={`${
                        hasAuthorLeft ? "tw-p-0 tw-capitalize md:tw-text-[16px] tw-text-[14px] tw-mt-[10px] md:tw-mt-0 tw-font-light" : "has-text-right"
                      } ${isCompany ? 'tw-text-accruent-base-color tw-mt-0 tw-leading-5' : ''}`}
                    >
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {data.quoteAuthorDesignation}
                      {data.quoteAuthorCompany ? `, ${data.quoteAuthorCompany}` : ''}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}