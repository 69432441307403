import React, { useState, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { useTranslation } from "react-i18next";

import extractMarketoValues from "src/components/helpers/extractMarketoIdAndSerial";
import useMarketoForm from "src/components/helpers/useMarketoForm";
import { Loader } from "src/components/commonComponents";
import useInViewport from "src/hooks/useInViewPort"

import makeDynamicFormId from "../../helpers/makeDynamicFormId";
export const GetInTouch = ({ data: title, fieldMarketoFormNode }) => {
  let checkCurrentDomainForUk = location?.pathname?.includes("/uk") ? true: false
  const data = useStaticQuery(graphql`
    {
      nodeMarketoForms: allNodeMarketoForms(filter: {drupal_internal__nid: {in: [4742, 16719]}}) {
        nodes {
          nid: drupal_internal__nid
          uuid: drupal_id
          title
          fieldFormText: field_form_text {
            value: processed
          }
          fieldFormHeader: field_form_header
          fieldEmbedCode: field_embed_code
        }
      }    
    }
  `);

  const successRef = useRef();

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const { t } = useTranslation();
  let formData = fieldMarketoFormNode ? fieldMarketoFormNode : checkCurrentDomainForUk ? data.nodeMarketoForms.nodes[1] : data.nodeMarketoForms.nodes[0];
  const { id, serial, gaLabel } = extractMarketoValues(formData?.fieldEmbedCode);
  const [isFormLoaded, setFormLoaded] = useState(false)

  const [nodeRef, isVisible] = useInViewport();

  const { formTag } = useMarketoForm(
    serial,
    id,
    (form) => {
      form.onSuccess(() => {
        setHasSubmitted(true);
        if (successRef.current)
          successRef.current.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        return false;
      });
      setFormLoaded(true)
    },
    "",
    gaLabel,
    isVisible
  );
  let idForDiv = makeDynamicFormId(formData?.title)
  // This was used to make the contact form customized towards which resource page the user was on.
  // As of 6/17 Content team is using a generic title and body text across all resource type pages.
  // let type = title;
  // if (title === "Blog Entries") type = "Blog Posts";
  // if (title === "Press Releases / News") type = "Press Releases";

  return (
    <div className="container" ref={nodeRef}>
      <div className="tw-flex tw-justify-center tw-p-[0px_12px] lg:tw-p-[1rem_0] tw-mt-[48px] tw-flex-col lg:tw-flex-row get-in-touch-form" id={idForDiv}>
        {hasSubmitted ? (
          <div
            className="column is-6 has-text-centered"
            ref={successRef}
            id="get-in-touch-sucess-message"
          >
            <div className="section">
              <h2 className="title title-3">
                Thank you for joining.
              </h2>
              <p>Make sure you check your inbox for more information!</p>
            </div>
          </div>
        ) : (
          <>
            <div className="md:tw-w-1/3 md:tw-p-[12px]">
              <h2 className="tw-text-mobileXxlFont md:tw-text-xxlFont tw-mb-[16px]">{formData?.fieldFormHeader}</h2>
              <div
                dangerouslySetInnerHTML={{
                  __html: formData?.fieldFormText?.value,
                }}
                className="dangerously-html-handle"
              />
            </div>
            <div className="md:tw-w-5/12 md:tw-p-[12px]">
              {!isFormLoaded &&
                <div>
                  <Loader />
                </div>
              }
              {formTag && formTag}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

// export default GetInTouch;
