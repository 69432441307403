import React, { useEffect, useState } from "react";
import { LocalizedLink,Picture, AccruentImg } from "../commonComponents";
const getImageObject = require("src/../helpers/getImageObject")
const NavbarSubIndustry = (props) => {

  const [activeMenu, setActiveMenu] = useState(props?.items?.[0]?.name)
  const [activeSubMenu, setActiveSubMenu] = useState(props?.items?.[0]?.children?.[0]?.name)
  const [subMenu, setSubMenu] = useState(props?.items?.[0])
  const [subMenuDetail, setSubMenuDetail] = useState(!!props?.items?.[0]?.children?.length ? props?.items?.[0]?.children?.[0] : props?.items?.[0])
  const { setActiveClass, setActiveClassDropdown } = props
  const onCrossClick = () => {
    var element = document.getElementById("navbar-dropdown-v2" + props?.parent);
    element.classList.toggle("menu-is-inactive");
  }

  const checkForResource = props?.parent === "Resources" ? true : false
  const checkForCompany = props?.parent === "Company" ? true : false
  const indexToDivide = checkForResource || checkForCompany ? Math.ceil(props?.items?.length / 2) : props?.items?.length;

  const resetState = () => {
    setActiveMenu(props?.items?.[0]?.name)
    setActiveSubMenu(props?.items?.[0]?.children?.[0]?.name)
    setSubMenu(props?.items?.[0])
    setSubMenuDetail(!!props?.items?.[0]?.children?.length ? props?.items?.[0]?.children?.[0] : props?.items?.[0])
  }
  const handleSetActiveClass = (name) => {
    setActiveClass({ [name]: '' })
    setActiveClassDropdown({ [name]: 'menu-is-inactive' })
  }

  const setParentActiveMethod = (item) => {

    setActiveMenu(item?.name);
    setActiveSubMenu(item?.children?.[0]?.name)
    setSubMenu(item)
    setSubMenuDetail(!!item?.children?.length ? item?.children?.[0] : item)
  }
  const setChildActiveMethod = (item) => {
    if(!(item?.fieldTermHighlight)){
      setActiveSubMenu(item?.name)
      setSubMenuDetail(item)
    }  
  }

  const {fieldTermSecondCta, fieldFeaturedCapabilities, fieldFeaturedProducts, spotlightItem, name: subMenuDetailName, description: subMenuDetailDesc, link: subMenuDetailLink} = subMenuDetail || ""
  const {title: spotLightTitle, image: spotLightImage, description: spotLightDesc, ctaUrl: spotLightCtaUrl, ctaText: spotLightCtaText } = spotlightItem || ""
  const {fieldCardCta: featuredCapabilitiesCard} = fieldFeaturedCapabilities || ""
  const {url: fieldTermSecondaryCta, title: fieldTermSecondaryTitle} = fieldTermSecondCta || ""
  return (
    <div className="new-navbar"
      onMouseLeave={() => resetState()}
    >
      <ul className="dropdown-menu-two">
        <div className="row submenu">
          <div className="col-md-3 maega-menu-colum first-ul">
            <ul >
              <li>
                <div
                  dangerouslySetInnerHTML={{
                    __html: props?.description?.value,
                  }}
                  className="mega-title"
                />
              </li>
              <div className="multiple-ul overflow-ul">
                <ul>
                  {props?.items?.map((item, index) => {
                      return (
                        <li className={(activeMenu === item?.name) ? "active" : ""} onMouseEnter={() => setParentActiveMethod(item)}>
                          <LocalizedLink className="dropdown-item" 
                           handleClick={() => { handleSetActiveClass(props.parent) }}
                          to={item?.link}
                          >
                            <span>{item?.name}</span>
                            {/* <svg class="svg-inline--fa fa-angle-right arrow-icon" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg> */}
                          </LocalizedLink>
                        </li>
                      )
                  })}
                </ul>

              </div>

            </ul>
          </div>
          {!!subMenu?.children?.length && <div className="col-md-4 maega-menu-colum second-ul">
            <ul className="nav-tabs">
              <li>
                <p>
                  <LocalizedLink
                    // to={"/test"}
                    className="mega-title"
                    handleClick={() => { handleSetActiveClass(props.parent) }}
                  >
                    <div
                      dangerouslySetInnerHTML={{
                        __html: subMenu?.description?.value
                      }}
                    />
                  </LocalizedLink>
                </p>

              </li>
              {subMenu?.children?.map((menuItem, index) => {
                return (
                  <li className={(activeSubMenu === menuItem?.name) ? "active" : ""} onMouseEnter={() => setChildActiveMethod(menuItem)}>
                    {(!menuItem?.fieldTermHighlight) ? <LocalizedLink
                      className="dropdown-item pl-0"
                      to={menuItem?.link}
                      handleClick={() => { handleSetActiveClass(props.parent) }}
                    >
                      {menuItem?.name}
                      {/* <svg class="svg-inline--fa fa-angle-right arrow-icon" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg> */}
                    </LocalizedLink> :
                    <div>
                      <hr className="submenu-hr"/>
                      <p className="mega-title">{menuItem?.name}</p>
                    </div>
                    

                    }
                  </li>
                )
              })}

            </ul>
          </div>}
          <div className="col-md-5 maega-menu-colum gray-bg">
            <div className="mediya-warapper">
            <AccruentImg height={232} loading="lazy" className={`navbar-header-img ${!subMenu?.children?.length?"set-fixed-width":""}`} file={subMenuDetail?.navbarHeaderImg} />
              <div className="media">

                <div className="media-body">

                  <div className="is-flex">

                    <div>
                      <h4 className="mb-1">
                        {subMenuDetailName}
                      </h4>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: subMenuDetailDesc?.value
                        }}
                      />
                    </div>
                  </div>
                  <div className="navbar-end text-left nav-division-cta">
                    <div className="navbar-item">
                      <LocalizedLink
                        className="navbar-link is-arrowless has-text-weight-semibold  button outline-button"
                        to={subMenuDetailLink}
                        handleClick={() => { handleSetActiveClass(props.parent) }}
                      >
                        Learn More
                      </LocalizedLink>
                    </div>
                   {fieldTermSecondCta && <div className="navbar-secondary-cta">
                      <LocalizedLink to={fieldTermSecondaryCta}>
                          {fieldTermSecondaryTitle}
                      </LocalizedLink>
                    </div>}
                  </div>
                </div>
                <div onClick={onCrossClick} className="close-icon">
                {/* <svg class="svg-inline--fa fa-xmark-large" data-prefix="fas" data-icon="xmark-large" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M393.4 41.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3L269.3 256 438.6 425.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 301.3 54.6 470.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 9.4 86.6C-3.1 74.1-3.1 53.9 9.4 41.4s32.8-12.5 45.3 0L224 210.7 393.4 41.4z"></path></svg> */}
                </div>
              </div>
              {featuredCapabilitiesCard && 
                <div className="featured-capabilities">
                  <p className="featured-title">FEATURED CAPABILITIES</p>
                  <ul className="featured-list">
                    {featuredCapabilitiesCard?.map((list, index) => {
                      return(
                        <LocalizedLink to={list?.url}>
                          <li><i className="fa-regular fa-arrow-right"></i>{list?.title}</li>
                        </LocalizedLink>
                      )
                    })}
                  </ul>
                </div>}
              {!!fieldFeaturedProducts?.length && 
                <div>
                  <p className="featured-products-title">FEATURED PRODUCTS</p>
                    {fieldFeaturedProducts?.map((product, index) => {
                    const image = getImageObject(product?.relationships?.fieldFeaturedImage?.relationships?.field_image, product?.relationships?.fieldFeaturedImage?.field_image)
                    return(
                      <div className="card-media mt-2">
                        <div className="columns">
                          <div className="column is-3-desktop image-container">
                            <Picture image={image} />
                          </div>
                          <div className="column is-9-desktop">
                            <p className="subtitle-2">{product?.fieldTitle}</p>
                            <p className="sub-text">{product?.fieldDescription}</p>
                            {product?.fieldCardCta?.[0] && <LocalizedLink className="card-link" to={product?.fieldCardCta?.[0]?.url}  handleClick={() => { handleSetActiveClass(props.parent) }}>{product?.fieldCardCta?.[0]?.title}</LocalizedLink>}
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              }
              {spotLightTitle && <div className="card-media">
                <div className="columns">
                  <div className="column is-3-desktop image-container">
                    <Picture image={spotLightImage} />
                  </div>
                  <div className="column is-9-desktop">
                    <p className="subtitle-2">{spotLightTitle}</p>
                    <p className="sub-text">{spotLightDesc}</p>
                    {spotLightCtaUrl && <LocalizedLink className="card-link" to={spotLightCtaUrl}  handleClick={() => { handleSetActiveClass(props.parent) }}>{spotLightCtaText}</LocalizedLink>}
                  </div>
                </div>
              
              </div>}
            </div>
          </div>
        </div>
      </ul>
    </div>
  )
}
export default NavbarSubIndustry;