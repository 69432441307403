import React from "react";
import NavbarSubIndustry from "./NavbarSubIndustry";
import NavBarEmsIndustry from "./NavBarEmsIndustry";
import { LocalizedLink } from "../commonComponents";
import NavbarWithGraphics from "./NavbarWithGraphics";
import NavWithList from "./NavWithList";
import angleDownBlack from "../../../static/angle-down-black.svg"
import angleUpWhite from "../../../static/angle-up-white.svg"
export const NavbarStartMenu = ({
  menus,
  activeClass,
  activeClassDropdown,
  setActiveClass,
  addActiveClass,
  removeActiveClass,
  addHoverableClass,
  setActiveClassDropdown,
  translation,
  location,
  innerCleanFilterWhenClickingResources,
  isMc,
  isEms,
  setRefresh,
  refresh,
  locale
}) => {
  const handleSetActiveClass = (link,name) => {
    if(link === "/resources" || link === "/uk/resources"){
      innerCleanFilterWhenClickingResources(link,name)
    }
    /*setTimeout(() => {
      setRefresh(Math.random());
    },500)*/
  }
  return (
    <div className={`navbar-start ${isMc||isEms ? "": "accruent-graphics"}`}>
      {!isEms && !isMc && locale !=='en-gb' && <div
            aria-hidden="true"
            className="navbar-item has-dropdown is-hoverable accruent-with-graphics"
        >
        <p className="navbar-link is-arrowless">
          <LocalizedLink className="navbar-is-accruent nav-acc-links" id="demolink" to={'/demo'}>
            Demos
          </LocalizedLink>
        </p>
      </div>}
      {refresh && menus?.map(
        ({ name, link, children, uuid, spotlightItem, description, fieldMenuWithGraphics }, index) => (
          <div
            aria-hidden="true"
            //By setting the key equal to the url path this causes all navbar items in this loop
            //to rerender effectively closing all mega menus except for the one being hovered over.
            //That is why we have added the conditional to add the add-hoverable-here and is-hoverable class.
            //We are then using the onMouseLeave and onMouseEnter event to add the is-hoverable class to the
            //navbar-item without the is-hoverable class.
            //Read more here: https://github.com/jgthms/bulma/issues/2514#issuecomment-830808840
            key={`${location.pathname}${uuid}`}
            className={`navbar-item has-dropdown ${
              location.pathname === link ? "add-hoverable-here" : `is-hoverable ${isMc || isEms ? "" : "accruent-with-graphics"}`
            }`}
          >
            <p
              className="navbar-link is-arrowless"
              style={isMc || isEms ? {
                padding: "0 8px",
              }: {padding: ""}}
              onMouseLeave={() => addHoverableClass(link, location.pathname)}
              onMouseEnter={() => addHoverableClass(link, location.pathname)}
            >
              {(isEms || isMc) &&
              (name === "Customers" || name === "Pricing") ? (
                <LocalizedLink
                  to={link}
                  className="navbar-link is-arrowless is-ems"
                  onMouseLeave={() =>
                    addHoverableClass(link, location.pathname)
                  }
                  onMouseEnter={() =>
                    addHoverableClass(link, location.pathname)
                  }
                >
                  <span className={activeClass[name]}>
                    {translation(`${name}`)}
                  </span>
                </LocalizedLink>
              ) : !isEms && !isMc ? (
                <LocalizedLink
                  to={link}
                  className="navbar-is-accruent nav-acc-links"
                  handleClick={() => { handleSetActiveClass(link,name) }}
                >
                  <span 
                  className={activeClass[name]}
                  >
                    {translation(`${name}`)}
                    <img src={angleDownBlack} className="bi bi-chevron-down" decoding="async"/>
                    <img src={angleUpWhite} className="bi bi-chevron-up" decoding="async"/>
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-down" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z" stroke="black" stroke-width="1"/> </svg> */}
                    {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-up" viewBox="0 0 16 16"> <path fill-rule="evenodd" d="M7.646 4.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1-.708.708L8 5.707l-5.646 5.647a.5.5 0 0 1-.708-.708l6-6z" fill="white" stroke="white" stroke-width="1"></path> </svg>  */}

                  </span>
                </LocalizedLink>
              ) : (
                <span className={activeClass[name]}>
                  {translation(`${name}`)}
                </span>
              )}
            </p>
            {!!children?.length && (
              <div
                id={"navbar-dropdown-v2" + name}
                className={`navbar-dropdown dropdown-with-megamenu ${
                  activeClassDropdown[name] + " index-"+index
                } ${isEms || isMc ? " ems" : !fieldMenuWithGraphics ? `accruent-with-graphics-nav with-list ${children?.length > 27 ? "scale-down": ""}` :"accruent-with-graphics-nav"}`}
                onMouseLeave={() => removeActiveClass(name)}
                onMouseEnter={() => addActiveClass(name)}
              >
                <div className="navbar-sub-wrapper">
                  <div
                    className={`columns ${
                      !(isEms || isMc) ? "is-accruent" : ""
                    }`}
                  >
                    {isMc ? (
                      <NavBarEmsIndustry
                        parent={name}
                        parentLink={link}
                        items={children}
                        spotlightItemAvailable={
                          Object.keys(spotlightItem).length > 0
                        }
                        setActiveClass={setActiveClass}
                        setActiveClassDropdown={setActiveClassDropdown}
                        isMc={isMc}
                        innerCleanFilterWhenClickingResources={innerCleanFilterWhenClickingResources}
                      />
                    ) : isEms ? (
                      <NavBarEmsIndustry
                        parent={name}
                        parentLink={link}
                        items={children}
                        spotlightItemAvailable={
                          Object.keys(spotlightItem).length > 0
                        }
                        setActiveClass={setActiveClass}
                        setActiveClassDropdown={setActiveClassDropdown}
                        innerCleanFilterWhenClickingResources={innerCleanFilterWhenClickingResources}
                      />
                    ) : (
                      (fieldMenuWithGraphics ? <NavbarWithGraphics 
                        parent={name}
                        parentLink={link}
                        items={children}
                        spotlightItemAvailable={
                          Object.keys(spotlightItem).length > 0
                        }
                        setActiveClass={setActiveClass}
                        setActiveClassDropdown={setActiveClassDropdown}
                        description={description}
                      />
                      :
                      <NavWithList 
                        parent={name}
                        parentLink={link}
                        items={children}
                        spotlightItemAvailable={
                          Object.keys(spotlightItem).length > 0
                        }
                        setActiveClass={setActiveClass}
                        setActiveClassDropdown={setActiveClassDropdown}
                        description={description}
                        innerCleanFilterWhenClickingResources={innerCleanFilterWhenClickingResources}
                      />
                      )
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        )
      )}
    </div>
  );
};
