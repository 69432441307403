import React from "react";
import { LocalizedLink } from "../commonComponents";

function HeadquartersCard({ headquarter }) {
  const address1 = headquarter.fieldPartnersAddress.addressLine1 || "";
  const address2 = headquarter.fieldPartnersAddress.addressLine2 || "";
  const locality = headquarter.fieldPartnersAddress.locality || "";
  const administrativeArea =
    headquarter.fieldPartnersAddress.administrativeArea || "";
  const postalCode = headquarter.fieldPartnersAddress.postalCode || "";

  const addressUrl = `https://maps.google.com/maps?q=${address1} ${address2} ${locality} ${administrativeArea} ${postalCode}`;
  const encodedUrl = encodeURI(addressUrl);

  return (
    <div className="tw-block tw-p-3">
      <div className="tw-font-black tw-text-[1.25rem] tw-leading-6">{headquarter.title}</div>
      <div className="tw-font-black tw-text-[1.25rem] tw-leading-6 tw-mb-6">
        {headquarter.fieldPartnersAddress.locality},{" "}
        {headquarter.fieldPartnersAddress.administrativeArea ||
          headquarter.fieldPartnersAddress.countryCode}
      </div>
      <div className="tw-headquarter-contact-info tw-min-h-[50%]">
        <div className="block tw-mb-[1.5rem]">
        <LocalizedLink to={encodedUrl}>
          <div>
            {address1} {address2}
          </div>
          <div>
            {locality}, {administrativeArea} {postalCode}
          </div>
        </LocalizedLink>
        </div>
        <div>
          {headquarter.fieldPartnersPhoneNumber ? (
            <span className="tw-font-bold tw-leading-6">
              Phone:{" "}
              {
                <a
                  href={`tel:${headquarter.fieldPartnersPhoneNumber}`}
                >
                  {headquarter.fieldPartnersPhoneNumber}
                </a>
              }
            </span>
          ) : null}
        </div>
        <div>
          {headquarter.fieldPartnersFax ? (
            <span className="tw-font-bold">
              Fax:{" "}
              {
                <a
                  href={`tel:${headquarter.fieldPartnersFax}`}
                >
                  {headquarter.fieldPartnersFax}
                </a>
              }
            </span>
          ) : null}
        </div>
        <div className="tw-mb-6">
          {headquarter.fieldPartnersCustomerSupport ? (
            <span className="tw-font-bold">
              Email:{" "}
              {
                <a
                  href={`mailto:${headquarter.fieldPartnersCustomerSupport}`}
                >
                  {headquarter.fieldPartnersCustomerSupport}
                </a>
              }
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default HeadquartersCard;
