import React from "react";
import createRelatedResources from "src/components/helpers/createRelatedResources";
import { sampleSize } from "src/utils";
import Resources from "src/components/solutions/Resources";
import useResourceData from "src/hooks/useResourceData";
import useCompanyResourceData from "src/hooks/useCompanyResourceData";

/**

* @param {string} individualCardClassName - set custom card class.
* @param {Int} cardCount - set custom card count.
 * 
 
 */

export const RecentResources = ({ location, locale = "en", heading = "Recent Resources", resourceData = null, isYouMayAlsoLike = false, drupalResourceTypeId, titleClass = null, individualCardClassName = null, cardCount = 3, tailwindClassName = "", forClass="" }) => {
    const data = isYouMayAlsoLike ? null : drupalResourceTypeId && drupalResourceTypeId === 'company_news' ? useCompanyResourceData()?.data : useResourceData()?.data
    const [posts, setPosts] = React.useState(null);
    React.useEffect(() => {
        if (isYouMayAlsoLike) {
            if (Array.isArray(resourceData))
                setPosts(resourceData)
            return;
        }
        let mostRecentPost = [];

        // Because of the `group` it's necessary to remap the data to fit `createRelatedResources`
        data.allSitePage.group.forEach((group) => {
            group.nodes.forEach((node) => {
                if (node?.context?.locale === locale) {
                    mostRecentPost.push(node)
                }
            });
        });

        if (location) {
            mostRecentPost = mostRecentPost.filter(
                (item) => item.path !== location.pathname
            );
        }

        const mostRecentPostsResources = createRelatedResources(mostRecentPost, {
            hasDescription: true,
        });

        const samplePosts = sampleSize(mostRecentPostsResources, cardCount).sort((a, b) => {
            return new Date(b.date) - new Date(a.date);
        });
        setPosts(samplePosts);
    }, []);

    if (!posts || posts.length < 1) {
        return <> </>
    }

    return (
        <div className={`container recent-resources ${forClass}`}>
            <h3
                className={`tw-text-xxxlFont !tw-text-left lg:!tw-mb-12 !tw-mb-4 md:tw-text-headingFont ${titleClass}`}
                id="you-may-enjoy-these-articles"
            >
                {heading}
            </h3>
            <Resources
                isCarousel={false}
                title=""
                data={posts}
                sliderId="you-may-enjoy-these-articles"
                individualCardClassName={individualCardClassName}
                tailwindClassName={tailwindClassName}
            />
        </div>
    );
};
