import React from "react";
import VerticalStackItem from "src/components/blocks/VerticalStackItem";

const VerticalStack = ({ data }) => {

  return (<div className="container  vertical-stack-items tw-flex tw-flex-row md:tw-flex-col tw-mr-[-54px] tw-ml-[-16px] md:tw-m-auto tw-p-[82px_0_12px_16px] md:tw-p-0 md:tw-pt-[76px] tw-overflow-scroll md:tw-overflow-hidden">
    {data.map((entity) => {
      return <VerticalStackItem entity={entity} key={entity?.nid} />
    })}
  </div>)
  
};


export default VerticalStack;
