const site_type = process.env.GATSBY_BUILD_MODE
const findSiteType = (siteType) => {
    return ((siteType == site_type) ? true : false)
}

export const getSiteSpecificClass = (baseClass) => {
    if (site_type) {
        return baseClass + "-" + site_type
    }
    return ''
}

export default findSiteType