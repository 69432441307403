import React, { Fragment } from "react";
import NavbarSearch from "src/components/Search/NavbarSearch";
import { LocalizedLink } from "../commonComponents";
import navbarSearch from "../../../static/navbar-search.svg";
import navbarSearchWhite from "../../../static/navbar-search-white.svg";
export const NavbarEndMenu = ({
  menus,
  setShowSearch,
  mounted,
  showSearch,
  setOpenDemoModal,
  openDemoModal,
  locale,
  isMc,
  isEms,
}) => {
  return (
    <div
      className={`navbar-end ${!isMc && !isEms ? "is-accruent-nav": ""}`}
      style={{
        justifyContent: "flex-end",
        marginLeft: "auto",
      }}
    >
      {menus.map(({ name, link }, index) =>
        !isMc && !isEms && index === 0 ? (
          <Fragment key={`${name}_${index}`}>
            <div
              className={`navbar-item ${showSearch ? "show-search" : ""}`}
              style={{ paddingRight: "4px" }}
            >
              <button
                onClick={() => setShowSearch(!showSearch)}
                className= {`button is-text navbar-search-button tw-bg-contain tw-bg-no-repeat tw-bg-center md:tw-w-[17px] xl:tw-w-[40px] xl:!tw-h-[50px] xl:tw-bg-[length:22px_21px] !tw-bg-transparent hover:!tw-bg-accruent_lightgray ${showSearch ? "active" : "hover:md:tw-w-[14px] hover:xl:tw-bg-[length:18px_17px]"}`}
                aria-label="Search"
                aria-expanded={showSearch}
              >
              </button>
            </div>
            {showSearch && (
              <div className="is-hidden-touch desktop-search-component is-accruent">
                <NavbarSearch locale={locale} />
              </div>
            )}
          </Fragment>
        ) : (isEms || isMc) && index === 1 ? (
          <div
            className="navbar-item"
            key={name}
            style={{ padding: "0.5rem 0.75rem" }}
          >
            <button className={`button is-carmine has-carmine-border`}>
              <LocalizedLink to={link}>
                <span
                  className={`navbar-link is-arrowless has-text-weight-semibold testing has-text-white`}
                >
                  {isMc ? "Free Trial" : "Watch Demo"}
                </span>
              </LocalizedLink>
            </button>
          </div>
        ) 
        : index == 2 ? (
          <div
            className="navbar-item"
            key={name}
            style={{ padding: "0.5rem 0.75rem" }}
          >
            {/* <button
              onClick={() => {
                setOpenDemoModal(!openDemoModal);
              }}
              className={`button is-carmine has-carmine-border`}
            >
              <span
                className={`navbar-link is-arrowless has-text-weight-semibold testing has-text-white`}
              >
                {"Watch Demo"}
              </span>
            </button> */}
          </div>
        ) 
        : (
          <Fragment key={`${name}_${index}`}>
            {(isEms || isMc) && index === 0 && (
              <Fragment>
                <div
                  className={`navbar-item ${showSearch ? "show-search" : ""}`}
                  style={{ paddingRight: "4px" }}
                >
                  <button
                    onClick={() => setShowSearch(!showSearch)}
                    className= {`button is-text navbar-search-button tw-bg-contain tw-bg-no-repeat tw-bg-center md:tw-w-[17px] xl:tw-w-[40px] xl:!tw-h-[50px] xl:tw-bg-[length:22px_21px] !tw-bg-transparent hover:!tw-bg-accruent_lightgray ${showSearch ? "active" : "hover:md:tw-w-[14px] hover:xl:tw-bg-[length:18px_17px]"}`}
                    aria-label="Search"
                    aria-expanded={showSearch}
                  >
                  </button>
                </div>
                {showSearch && (
                  <div
                    className={`is-hidden-touch desktop-search-component ${
                      isEms ? "is-ems" : "is-mc"
                    }`}
                  >
                    <NavbarSearch locale={locale} />
                  </div>
                )}
              </Fragment>
            )}
            <div
              className="navbar-item"
              key={name}
              style={{ padding: "0.5rem 0.75rem" }}
            >
              {(isMc || isEms) ? <button
                className={`button wht-btn is-outlined navbar-contact-button`}
              >
                <LocalizedLink
                  to={link}
                  className={`navbar-link is-arrowless has-text-weight-semibold testing navbar-contact-button`}
                >
                  {name}
                </LocalizedLink>
              </button>
              :
              <LocalizedLink
                to={link}
                className={` is-arrowless tw-font-bold testing navbar-contact-button tw-bg-blue_tag tw-rounded-[10px] tw-p-[11px_23px]  tw-tracking-[1.42px] hover:tw-bg-accruent_sapphire`}
              >
                {name}
              </LocalizedLink>
            }
              
            </div>
          </Fragment>
        )
      )}
    </div>
  );
};
