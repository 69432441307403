import React from 'react'
import { LocalizedLink } from 'src/components/commonComponents'
import { isEmpty } from "src/utils";
import dateFormatter from '../../helpers/dateFormatter';

const sliceLength = (fieldBody) => {
  if (fieldBody.length > 100) {
    return fieldBody.slice(0, 100) + '...'
  } else {
    return fieldBody
  }
}
let dateFormatted = null
export const LegalHubCards = ({ data, path, mainPage = false, version = 'V1' }) => {

  if (version == 'V2') {
    return (
      <div className="legal-hub-card-v2-container tw-legal-hub-card-v2-container tw-overflow-auto lg:tw-flex lg:tw-flex-row md:tw-flex-wrap md:tw-flex-no-wrap tw-mt-8">
        {data?.map((card, index) => {
          return (
            <LocalizedLink className="lg:tw-mb-7 tw-mb-4" to={card?.fieldCtaUrl?.url}>
              <div
                key={index}
                className="legal-hub-card-v2 lg:tw-h-full lg:tw-flex lg:tw-flex-col lg:tw-justify-between tw-legal-hub-card-v2 tw-bg-subtitle_color md:tw-mb-7 lg:tw-mr-4 lg:tw-w-[278px]  tw-rounded-[20px] tw-mb-4 tw-py-[24px] tw-px-[16px]"
              >
                <div className="tw-card-contents">
                  <h6 className="title-6 lg:tw-flex tw-items-center tw-flex tw-text-lgFont">
                    {" "}
                    <i
                      className={`${card?.fieldIcon?.style} fa-${card?.fieldIcon?.iconName} tw-text-accruent-heading-color tw-text-[43px] tw-leading-14 tw-mr-3`}
                    ></i>{" "}
                    {card?.fieldCardTitle}{" "}
                  </h6>
                  <div className="body-2 tw-mt-6">{card?.fieldDescription}</div>
                </div>
                {card?.fieldCtaUrl?.title && (
                  <div className="card-link tw-mt-4">
                    <span
                      to={card?.fieldCtaUrl?.url}
                      className="learn-more tw-text-accruent_link_color2 tw-text-base tw-leading-5 tw-tracking-tight tw-font-header-font-family tw-font-bold"
                    >
                      {card?.fieldCtaUrl?.title}
                    </span>
                  </div>
                )}
              </div>
            </LocalizedLink>
          );
        })}
      </div>
    );
  }
  return (
    <div className="tw-columns lg:tw-p-8 tw-flex tw-flex-row tw-flex-wrap tw-overflow-auto tw-columns is-multiline tw-is-multiline">
      {data
        ?.filter((x) => x?.context?.entityUrl?.path !== path)
        .map((cards, index) => {
          const context = cards?.context;
          if (!isEmpty(context?.fieldLastUpdate)) {
            try {
              dateFormatted = dateFormatter(context?.fieldLastUpdate);
            } catch (error) {}
          }
          return (
            <div
              className={`tw-column tw-w-full ${
                !mainPage
                  ? "tw-legal-page-card tw-w-full lg:tw-flex-none lg:tw-bg-accruent-lightgray lg:tw-rounded-2xl lg:tw-mr-3 lg:tw-w-1/3 lg:tw-mt-32 lg:tw-shadow-md lg:tw-max-w-[250px]"
                  : "lg:tw-flex-none lg:tw-w-1/2 main-page-card horizontal-width lg:tw-relative tw-w-[54%] -xl:tw-w-[26%] lg:tw-mr-4 lg:tw-pb-6 lg:tw-rounded-xl lg:tw-mt-6 lg:tw-p-[36px] lg:tw-bg-accruent_subtitle_color lg:tw-pt-[25px]"
              }`}
            >
              {!mainPage ? (
                <div className="">
                  <div className="tw-columns lg:tw-p-8 tw-flex tw-p-24 lg:tw-pt-24 lg:tw-pb-24">
                    <div className="tw-column left-content lg:tw-w-1/3 tw-flex-none tw-w-30">
                      <div className="icon lg:tw-w-full">
                        <i
                          className={`${context?.fieldIcon?.style} fa-${context?.fieldIcon?.iconName} lg:tw-text-[43px] w-text-2xl lg:tw-mr-4`}
                        ></i>
                      </div>
                      <p className="tw-text-center lg:tw-font-bold lg:tw-text-xl lg:tw-leading-6 lg:tw-text-gray-700 lg:tw-m-0 lg:tw-font-poppins">
                        {context?.fieldTitle}
                      </p>
                    </div>
                    <div className="tw-column is-9 right-content tw-right-content tw-flex-none tw-w-70">
                      <div>
                        <p className="tw-font-semibold pre-heading lg:tw-text-accurent_pre_heading_color lg:tw-text-left lg:tw-text-base lg:tw-leading-6 lg:tw-mb-2">
                          Last update: {dateFormatted}
                        </p>
                        {context?.fieldBody && (
                          <p className="content lg:tw-text-accruent_base_color lg:tw-text-base lg:tw-font-roboto  lg:tw-text-left lg:tw-mb-4">
                            {sliceLength(context?.fieldBody)}
                          </p>
                        )}
                        <div className="leader-ship-learn-more">
                          <LocalizedLink
                            to={context?.entityUrl?.path}
                            className="learn-more"
                          >
                            Learn more {">"}
                          </LocalizedLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <span>
                    <LocalizedLink
                      className="main-card-link lg:tw-absolute lg:tw-w-full lg:tw-h-full lg:tw-top-0 lg:tw-left-0"
                      to={context?.entityUrl?.path}
                    ></LocalizedLink>
                  </span>
                  <div className="is-flex lg:tw-gap-4 tw-gap-[5px]">
                    <div className="icon lg:tw-w-full">
                      <i
                        className={`${context?.fieldIcon?.style} fa-${context?.fieldIcon?.iconName}`}
                      ></i>
                    </div>
                    <p className="main-card-content lg:tw-text-$accruent_heading_color lg:tw-text-[18px] lg:tw-font-bold lg:tw-font-poppins lg:tw-mb-0">
                      {context?.fieldTitle}
                    </p>
                  </div>
                </div>
              )}
            </div>
          );
        })}
    </div>
  );
}
// export default LegalHubCards;