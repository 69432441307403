import React, { useState, useEffect } from 'react'
import "src/scss/components/_legalHubTab.scss";
import loadable from '@loadable/component'
import { LocalizedLink } from 'src/components/commonComponents'
import { useLocation } from "@reach/router";
import getTitleId from '../../helpers/makeDynamicFormId';
const LegalHubCards = loadable(() => import("src/components/templateComponents"), {
    resolveComponent: (components) => components.LegalHubCards
  });

export const LegalHubTab = ({ data }) => {
    const [active, setActive] = useState(0);
    const location = useLocation()

    useEffect(() => {
        const index = data?.findIndex((item, i) => {
            return ('#' + getTitleId(item?.fieldTitle)) === location.hash
        })
        if (index > -1) {
            setActive(index)
        } else {
            setActive(0)
        }

    }, [location, data])
    
    const currentTabData = data?.[active]
    return (
      <div className="legal-hub-tab tw-legal-hub-tab lg:tw-flex">
        <div className="legal-tabs tw-legal-tabs tw-border-b-2 lg:tw-border-r-[3px] tw-border-solid tw-border-[#f8f8f8] tw-flex tw-overflow-x-auto lg:tw-flex lg:tw-flex-col lg:tw-overflow-hidden lg:tw-flex-none lg:tw-border-b-0">
          {data?.map((tab, i) => {
            return (
              <div
                key={i}
                className={`tab tw-py-4 tw-px-3 lg:!tw-p-2.5 lg:!tw-pl-0 lg:!tw-pr-[55px] tw-text-base tw-leading-5 tw-tracking-[0.14px] tw-whitespace-nowrap tw-font-semibold tw-cursor-pointer tw-text-tab_color2 ${
                  active === i
                    ? "active lg:!tw-border-b-0 !tw-text-accruent_sapphire !tw-border-b-[3px] lg:tw-border-r-[6px] tw-border-solid tw-border-accruent_sapphire"
                    : ""
                }`}
                onClick={() => setActive(i)}
              >
                <i
                  className={`${tab?.fieldIcon?.style} fa-${tab?.fieldIcon?.iconName}`}
                ></i>{" "}
                {tab.fieldTitle}
              </div>
            );
          })}
        </div>
        <div className="legal-tab-content tw-legal-tab-content lg:tw-mt-0 lg:tw-ml-8 tw-mt-8 tw-ml-4 tw-mr-4">
          <h3 className="tw-text-[26px] lg:tw-text-[34px] tw-tracking-[.23px] lg:tw-tracking-[.31px] tw-leading-[30.16px] lg:tw-leading-[39.44px]">
            {currentTabData?.fieldTitle}
          </h3>
          <div className="subtitle-2 tw-subtitle-2 tw-font-medium tw-mt-2 lg:tw-w-full tw-text-[22px] tw-leading-6 tw-tracking-[0.15px]">
            {currentTabData?.fieldDescription}
          </div>
          {currentTabData?.relationships?.fieldSection?.map((sec, i) => {
            const cards = sec?.relationships?.fieldComponentParagraph?.filter(
              (cp) => cp.__typename === "paragraph__link_cards"
            );
            const links = sec?.relationships?.fieldComponentParagraph?.filter(
              (cp) => cp.__typename === "paragraph__link_list"
            );
            return (
              <div className="info tw-info !tw-mt-8" key={i}>
                <h4 className="title-5 tw-title-5 tw-text-[22px] lg:tw-text-[24px] tw-leading-[27.84px] tw-tracking-[0.22px]">
                  {sec?.fieldTitle}
                </h4>
                <div className="tw-body-1 lg:tw-w-full tw-mt-2 lg:tw-text-[18px]">
                  {sec?.fieldDescription}
                </div>
                {!!cards?.length && <LegalHubCards version="V2" data={cards} />}
                {!!links?.length && (
                  <ul className="links-container tw-links-container tw-text-accurent_link_color3 tw-leading-[26px] tw-font-bold  tw-columns-1 lg:tw-columns-2 tw-mt-6 lg:tw-mt-4 tw-text-lg">
                    {links?.map((link, i) => {
                      return (
                        <>
                          {link?.fieldCardCta?.map((cta, j) => {
                            return (
                              <li
                                className=" tw-mb-3 tw-pb-3 tw-border-b tw-border-solid tw-border-accruent_lilac"
                                key={j}
                              >
                                <LocalizedLink
                                  to={cta?.url}
                                  className="learn-more"
                                >
                                  {cta?.title}
                                </LocalizedLink>
                              </li>
                            );
                          })}
                        </>
                      );
                    })}
                  </ul>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
}

// export default LegalHubTab