import React, { useEffect, useRef, useState } from "react";
import isURL from "src/components/helpers/isURL";
import loadable from '@loadable/component'
import { useLazyLoadOnScroll } from "src/hooks/useLazyLoadOnScroll";
import { isElementInViewport, removeScriptTagsWithFastWistiaSrc } from "src/utils";
const AddWistiaScript = loadable(() => import('src/components/helpers/AddWisitaScript'))

export const Video = ({ videoUrl, videoStandardEmbedCode = null, loadAlways = false }) => {
  if (!videoUrl) return <React.Fragment />;

  let isYoutube = false;
  isYoutube = videoUrl.includes("youtu");
  let id;
  const idFromVideo = () => {
    if (videoUrl && !isURL(videoUrl)) {
      id = videoUrl;
    } else if (!isYoutube && videoUrl && isURL(videoUrl)) {
      id = videoUrl
        ?.split("medias")?.[1]
        ?.replace("/", "")
        ?.trim();
    } else if (isYoutube && videoUrl && isURL(videoUrl)) {
      id = videoUrl.split("be/")[1];
    }
  };
  idFromVideo();

  const shouldLoad = useLazyLoadOnScroll()
  const [isVisible, setIsVisible] = useState(false);
  const [videoSrc, setVideoSrc] = useState(null);
  const videoRef = useRef(null)
  useEffect(()=>{
    if(!loadAlways && !isYoutube && !videoStandardEmbedCode){
    // Find the first element with the specific class name
    setIsVisible(isElementInViewport(videoRef?.current))
    }
  },[])

  useEffect(()=>{
    if(!loadAlways && (!isYoutube && !videoStandardEmbedCode) && (isVisible || shouldLoad))
    setVideoSrc(`//fast.wistia.net/embed/iframe/${id}`)
  },[isVisible,shouldLoad])
  
 const videoStandardEmbedCodeWithoutWista = removeScriptTagsWithFastWistiaSrc(videoStandardEmbedCode)
  return (
    <React.Fragment>
      {!isYoutube && !videoStandardEmbedCode ? (
        <figure className="image is-16by9">
          <iframe
           ref={videoRef}
            src={!loadAlways?videoSrc:`//fast.wistia.net/embed/iframe/${id}`}
            allowtransparency="true"
            frameBorder="0"
            scrolling="no"
            className="wistia_embed has-ratio"
            name="wistia_embed"
            allowFullScreen
            title="wistia"
          ></iframe>
        </figure>
      )
      :
      videoStandardEmbedCode ? (
        <div dangerouslySetInnerHTML={{ __html: videoStandardEmbedCodeWithoutWista }} className="dangerously-html-handle"/> 
      ) 
      : (
        <figure className="tw-pt-[56.25%] tw-block tw-relative tw-m-0 tw-p-0">
          <iframe
            src={`https://www.youtube.com/embed/${id}`}
            allowtransparency="true"
            frameBorder="0"
            scrolling="no"
            className="has-ratio"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="youtube"
          ></iframe>
        </figure>
      )}
      {videoStandardEmbedCode && <AddWistiaScript videoStandardEmbedCode={videoStandardEmbedCode}/>}
    </React.Fragment>
  );
}

