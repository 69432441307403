import React from "react";
import { AccruentImg, LocalizedLink } from "src/components/commonComponents";
import { isEmpty } from "src/utils";
import timeFormatter from "../../../../helpers/timeFormatter";
import { getTimeZoneAbbreviation } from "src/utils";
import { StaticImage } from "gatsby-plugin-image";
const getImageObject = require("src/../helpers/getImageObject");
const defaultImage = "../../../../static/placeholders/articles/ArticleImg1.jpg";
const prefixLangcode = require('src/../helpers/prefixLangcode');

export const EventCard = ({ data, locale, checkForOverviewCards=false }) => {
  let timeZone = 'CST'
  let ImgObj = getImageObject(data?.relationships?.fieldMediaImage, {}, "fieldMediaImage")
  ImgObj = ImgObj?.gatsbyImage?ImgObj:data?.heroImage
  const EventLogo = getImageObject(data?.relationships?.fieldEventLogo, data?.fieldEventLogo)
  const EventLogoAttrs = EventLogo?.gatsbyImage?.images?.fallback;
  const imageToDisplay =
    ImgObj?.gatsbyImage ? (
      <AccruentImg
        file={ImgObj}
        objectFit="cover"
      />
    ) : (
      <StaticImage
        src={defaultImage}
        objectFit="cover"
        alt={`Accruent - Event - ${data.title}`}
        aspectRatio={2.0}
        placeholder="blurred"
        layout="constrained"
        decoding = "async"
      />
    );
  const isVirtualEvent = data.fieldVirtualEvent;

  let address = "", startDateFormated = "", startTime = "", endTime = "", title = "", path = "", endDateFormated = "", fieldResourceDescription = "";
  if (!isEmpty(data.address?.locality)) {
    address = address.concat(data.address.locality, ", ");
  }
  if (!isEmpty(data.address?.administrativeArea)) {
    address = address.concat(data.address.administrativeArea);
  } else if (
    isEmpty(data.address?.administrativeArea) &&
    !isEmpty(data.address?.countryCode)
  ) {
    address = address.concat(data.address?.countryCode);
  }

  if (isVirtualEvent) {
    address = "Virtual";
  }
  if (!isEmpty(data.startEndTimes?.startDate)) {
    let options = data.startEndTimes?.endDate ? data.startEndTimes?.endDate !== data.startEndTimes?.startDate ? {
      month: "long",
      day: "numeric",
    } : {
      month: "long",
      day: "numeric", year: "numeric"
    } : {
      month: "long",
      day: "numeric", year: "numeric"
    }
    startDateFormated = new Date(
      data.startEndTimes.startDate.replace(/-/g, "/")
    ).toLocaleString("en-US", options);
  }



  if (data.fieldStartEndTimes) {
    let startEnd = timeFormatter(data.fieldStartEndTimes)
    startTime = startEnd.startTime
    endTime = startEnd.endTime
  }

  if (!isEmpty(data.startEndTimes?.endDate)) {
    if (data.startEndTimes?.endDate !== data.startEndTimes?.startDate) {
      endDateFormated = new Date(
        data.startEndTimes.endDate.replace(/-/g, "/")
      ).toLocaleString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
      });
      endTime = ""
    }
  }
  if (!isEmpty(data.fieldStartEndTimes?.value) && !isEmpty(data?.startEndTimes?.value) && !isEmpty(data?.fieldTimeZone)) {
    let value = data?.startEndTimes?.value
    timeZone = getTimeZoneAbbreviation(value, data.fieldTimeZone);

  }
  if (!isEmpty(data.fieldOverviewPageButtonText)) {
    title = data.fieldOverviewPageButtonText + '  ›'
  }
  if(data?.fieldCtaUrl?.title){
    title = data?.fieldCtaUrl?.title + '  ›'
  }
  if (!isEmpty(data.fieldResourceDescription)) {
    fieldResourceDescription = data.fieldResourceDescription
  }
  // link-path and link-title
  let linkPath = prefixLangcode(data?.fieldExternalLinkForEvents?.url || data.fieldExternalLink?.url ? data?.fieldExternalLinkForEvents?.url || data.fieldExternalLink?.url : data.entityUrl?.path, locale)
  title = data.fieldExternalLink?.title ? (data.fieldExternalLink?.title + '  ›') : title

  if(data?.fieldCtaUrl?.url){
    linkPath = data?.fieldCtaUrl?.url
  }
  return (
    <div className=" tw-w-full md:tw-w-[32%] md:tw-flex-none">
    <LocalizedLink
      to={linkPath}
      className="card-title has-text-weight-semibold has-text-black"
    >
      <div className={`box-event !tw-border-0 tw-shadow-[0_2px_12px_0_#7d7d7d29] tw-rounded-[48px_48px_48px_0px] tw-bg-accruent_white tw-ml-0 tw-mr-0 tw-mb-[22px] md:tw-m-[0px_3.5px] tw-p-0 tw-pb-[20px] tw-relative md:tw-mb-[24px] tw-flex tw-h-[100%] tw-justify-start tw-flex-col tw-overflow-hidden event-detail-template-cards ${(checkForOverviewCards) ? "remove-padding" : ""}`}>
        <div className="box-club">
          {imageToDisplay && <div className="box-image tw-h-[166px] tw-overflow-hidden">{imageToDisplay}</div>}
          {EventLogo?.gatsbyImage && <div className="white-box-logo tw-bg-accruent_white tw-absolute tw-top-[50px]">
            <img
              {...EventLogoAttrs}
              alt={EventLogo?.alt}
              loading="eager"
              fetchpriority="high"
              className={'tw-w-[115px] tw-max-h-[70px]'}
              decoding = "async"
            />
          </div>}
        </div>
        <div className="box-body tw-p-[0_12px] md:tw-pb-[75px]">
          <h6 className="title-5 tw-mt-[22px] tw-mb-[16px]">{data.title || data?.entityLabel}</h6>
          <div className="box-content-box tw-font-normal">
            <p className="tw-text-[16px] tw-leading-[22px] tw-mb-[24px]">
              {fieldResourceDescription}
            </p>
          </div>
          <div className={`event-footer tw-relative tw-bottom-0 md:tw-absolute md:tw-bottom-[28px] tw-left-0 tw-pl-[17px] tw-w-full ${checkForOverviewCards ? "": ""}`}>
            <div className="time-box tw-flex tw-justify-start tw-text-accruent_base_color">
              {startDateFormated && <div className="icon !tw-w-[20px] !tw-text-[20px]">
                {" "}
                <i className="fal fa-calendar-day"></i>
              </div>}
              <div className="time-date-box tw-w-[calc(100%-30px)] tw-pl-[12px]">
                <p className="date tw-text-[20px] tw-font-medium tw-leading-[24px] tw-mb-[4px]">{startDateFormated} {endDateFormated && <span> - {endDateFormated}</span>}</p>
                {startTime && (<p className="time tw-text-tab_color2 tw-text-[18px] tw-mb-0 tw-font-medium">
                  {startTime} {endTime && <span> - {endTime} </span>} <span className="time-zone tw-text-[12px] tw-pl-[8px]">{timeZone}</span>
                </p>)}
              </div>
            </div>
            {(data?.fieldVenue || data?.fieldBoothNumber) &&
              <div>
                <div className="border-line tw-border-solid tw-border-[1px] tw-text-accruent_gray tw-mt-[13px] tw-mb-[14px] tw-w-[94%]"></div>
                <div className="card-venue time-box tw-flex tw-justify-start tw-text-accruent_base_color">

                  <div className="venue-icon">
                    {" "}
                    <i className="fal fa-location-dot"></i>
                  </div>
                  <div className="time-date-box tw-w-[calc(100%-30px)] tw-pl-[12px]">
                    <p className="date tw-text-[20px] tw-font-medium tw-leading-[24px] tw-mb-[4px]">{data?.fieldVenue}</p>
                    {data?.fieldBoothNumber && (<p className="time tw-text-tab_color2 tw-text-[18px] tw-mb-0 tw-font-medium">
                      <span>{data?.fieldBoothNumber}</span>
                    </p>)}
                  </div>
                </div>
              </div>
            }
            <LocalizedLink to={linkPath} className="learn-more tw-text-[16px] tw-leading-[16px] tw-font-bold tw-text-accruent_sapphire tw-inline-block tw-mt-[24px] hover:tw-text-accruent_sapphire hover:tw-underline">
              {title}
            </LocalizedLink>
          </div>
        </div>

      </div>
    </LocalizedLink>
  </div>
  );
};

// export default EventCard;
