import React from "react";
import { get } from "src/utils";
import cn from "classnames";
// import LocalizedLink from "src/components/LocalizedLink";
// import Hero from "src/components/hero/ResourcesHero";
import { ResourcesHero as Hero} from "src/components/templateComponents";
import getResourceTagsList from "../../../../helpers/getResourceTagsList";
import { Eyebrow, LocalizedLink } from "src/components/commonComponents";
import loadable from '@loadable/component';
import useAddParamInHtmlLink from "src/components/helpers/useAddParamInHtmlLink";
// const SideBar = loadable(() => import('../../components/resources/ResourceSideBar'));
const SideBar = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.SideBar
});
const StickyBox = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.StickyBox
});
// const Video = loadable(() => import('src/components/Video'))
const Video = loadable(() => import("src/components/commonComponents"), {
  resolveComponent: (components) => components.Video
});

export const SingleVideoPost = ({ data }) => {
  const { locale } = data;
  const resourceTags = getResourceTagsList([data.industryIds, data.solutionIds, data.productIds])
  const heroVideoUrl = get(data, "fieldVideoRef.fieldMediaVideoEmbedField") || get(data, "relationships.fieldHeroVideo.fieldMediaVideoEmbedField");
  useAddParamInHtmlLink()
  return (
    <section className="resources-page video-page-wrapper !tw-pt-0 !tw-pb-0 content-visibility-auto-mobile">
      <div className="container">
        <section className="medium-wrapper tw-mt-[0] tw-p-[0_15px] lg:tw-p-0 md:tw-mt-[36px] tw-text-[16px] md:tw-text-[18px] tw-py-0 tw-px-[15px] md:tw-px-0 md:tw-pl-[12px] md:tw-py-0">
          <div className="md:tw-flex">
            <div className="tw-block tw-w-full tw-max-w-full lg:tw-w-[calc(100%-376px)]is-12-mobile tw-px-3 tw-pt-9 tw-pb-[52px] md:tw-px-3 md:tw-pt-3 md:tw-pb-3  ">
              <div className='tw-pb-4'>
                {!!heroVideoUrl && <Video loadAlways={true} videoUrl={heroVideoUrl} />}
                {data.fieldResourceDescription && <div className="subtitle-2 mt-4">{data.fieldResourceDescription}</div>}
              </div>

              {data.body && (
                <div
                  className="single-resource-content-body dangerously-html-handle"
                  dangerouslySetInnerHTML={{ __html: data.body.value }}
                ></div>
              )}
              {data?.fieldGenericBody && (
                <div
                  className="single-resource-content-body dangerously-html-handle"
                  dangerouslySetInnerHTML={{ __html: data?.fieldGenericBody?.value }}
                ></div>
              )}
              {data.fieldProductCallToAction && (
                <LocalizedLink
                  to={data.fieldProductCallToAction.url}
                  rel="noopener noreferrer"
                  target="_blank"
                  className="line-btn tw-mb-3"
                >
                  {data.fieldProductCallToAction.title}
                </LocalizedLink>
              )}
            </div>
            <div className="tw-w-full lg:tw-max-w-[376px] lg:tw-p-[12px]">
              <div className="right-content sidebar-content tw-bg-accruent_white tw-w-full md:tw-w-[376px] tw-relative">
                <StickyBox
                  scrollContainerClass={"sidebar-scroll"}
                >
                  <div className="right-box">
                    <SideBar recommendedResources={data.rectifiedRecommended} />
                  </div>
                </StickyBox>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  )
}

// export default SingleVideoPost;
