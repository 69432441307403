const site_var = require("../src/get_site_data");
const target_site_tid = site_var.drupal_site_id;
const getUrlPathBySiteId = (siteId,path) => {
   if(siteId===target_site_tid){
     return path
   }else if(siteId===451){
     return "https://www.accruent.com"+path
   }else if(siteId===452){
    return "https://www.emssoftware.com"+path
   }else if(siteId===453){
    return "https://website.maintenanceconnection.com"+path
  }else if(siteId===478){
    return "https://www.accruent.uk"+path 
  }
    
    return path;
  }
  
  export default getUrlPathBySiteId;
  