export default [
  {
    account: "Linkedin",
    href: "https://www.linkedin.com/company/accruent",
    icon: "fab fa-linkedin-in",
  },
  {
    account: "Twitter",
    href: "https://twitter.com/accruentllc",
    icon: "fab fa-twitter",
  },
  {
    account: "Facebook",
    href: "https://www.facebook.com/Accruent",
    icon: "fab fa-facebook-f",
  },
  {
    account: "Instagram",
    href: "https://www.instagram.com/accruentllc/",
    icon: "fab fa-instagram",
  },
];
