import React from 'react';
// import { CommonFormHeader } from 'src/components/templateComponents';
import { Statistics } from 'src/components/commonComponents';
import { BrandLogoWithForm, CommonFormHeader } from "src/components/templateComponents";
import makeDynamicFormId from 'src/components/helpers/makeDynamicFormId';
import getPublishedData from 'src/../helpers/getPublishedData'
export const LeftAlignContent = ({ contents, formProps, locale }) => {

  let id = makeDynamicFormId(formProps?.title)

  return (
    <div className='statistics-form trusted-form'>
      <div className='form-page-wrapper'>
        <div id={id} className="scroll-for-id left-form"></div>
        <div className='main-box tw-block md:tw-flex tw-w-full justify-start tw-items-start'>
          <div className="right-content-box tw-w-full md:tw-w-[486px] tw-pt-9 md:tw-pt-0">
            <div className="border-box"></div>
            <div className="form-box contact-us-form contact">
              <CommonFormHeader
                formTag={formProps?.form}
                fieldFormHeader={formProps?.fieldFormHeader}
                isFormLoaded={formProps?.isFormLoaded}
              />
            </div>
            <div className="border-box"></div>
          </div>
        </div>
      </div>
      <div>
        {contents?.map((content, i) => {

          switch (content?.type?.id) {
            case 'brand_logos_section':
              return (<BrandLogoWithForm entity={content} />)
            case 'statistics':
              let fieldStatistic = getPublishedData(content?.relationships?.fieldStatistic);
              return (
                <div className={`landing-statistics ${i !== 0 ? 'unset' : ''}`}>
                  <Statistics
                    statistics={fieldStatistic}
                    entity={content}
                  />
                </div>)
          }
        })}
      </div>
    </div>
  )
}
// export default LeftAlignContent;