import { navigate, useLocation } from "@reach/router";
import React, { useContext, useEffect, useRef, useState } from "react";
import getPathWithQueryParam from "../../../helpers/getPathWithQueryParam";
import ResourcesContext from "src/components/helpers/ResourcesMainPageContext";
import destyleMktoForm from "src/components/helpers/destyleMktoForm";
import styleMarketoForm from "src/components/helpers/styleMarketoForm";
import getCookie from '../../../helpers/getCookieParser';
import createId from "src/components/helpers/createId.js";
const calendlyFormId = 3869

/**
 *
 * @param {string} serial - XXX-XXX-XXX serial key unique to each form
 * @param {number} id - number also unique to each form
 * @param {function} callback - function to be called after all the basic styles are set for further styling
 * @param {string} className - className to be added into the form element
 * @param {string} followUpUrl - it contains redirect path for after success of form submission
 * or extra functionality
 * @callBack receives these parameters in order
 *    @param form the `form` object from Marketo API, which returns from whenReady() function.
 *    @param allInputElements -> same as above
 *    @param formElement -> same as above
 *
 * @returns {Object}
 *    @param formElement -> the whole form taken with the function getFormElem() from Marketo API, used to select each input individually
 *    @param allInputElements -> an array with all the input elements inside the form to add common styles for all of them at once
 *    @param formTag -> the <form> HTML tag with the correct ID to be rendered in the DOM
 */
const useMarketoForm = (
  serial,
  id,
  callback,
  className = "",
  gaLabel = "Content",
  isVisible = true,
  followUpUrl = "",
  setPopup = null
) => {
  const location = useLocation()
  let formElement;
  let allInputElements = [];
  const formTag = <form id={`mktoForm_${id}`} ga-label={gaLabel} className={className}></form>;
  const pageUrl = (typeof window !== 'undefined') ? window.location.href : '';
  // Use a regular expression to find the gclid parameter
  const gclidMatch = pageUrl.match(/[?&]gclid=([^&#]*)/);
  const formHiddenFieldValues = { "formSubmissionUniqueID": createId(), "mkto2__Original_Referrer__c":  pageUrl, "originalReferrer1GCLID": gclidMatch ? gclidMatch[1] : ''}

  const successRef = useRef();
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const callLoadFormOnlyOneTime = useRef(0);
  const callDataLayerOnlyOneTime = useRef(0);

  const { utmCookiesCreated } = useContext(ResourcesContext);

  // Function to extract the value of a specific cookie from the cookie object
  function extractCookieValue(cookieObject, cookieName) {
    for (var i = 0; i < cookieObject.length; i++) {
      var cookie = cookieObject[i];
      if (Object.keys(cookie)[0] === cookieName) {
        return cookie[cookieName];
      }
    }
    return null; // Return null if the cookie is not found
  }

  const addHiddenMktoFields = () => {
    if (typeof window.MktoForms2 != "object") {
      document.addEventListener("load", addHiddenMktoFields, true);
    } else if (!addHiddenMktoFields.done) {
      document.removeEventListener("load", addHiddenMktoFields, true);
      addHiddenMktoFields.done = true;
      window.MktoForms2.whenReady(form => {
        const fetchedCookies = getCookie()
        const validFetchedCookies = (typeof fetchedCookies !== 'undefined') && fetchedCookies.filter(obj => {
          const keys = Object.keys(obj);
          return keys.length > 0 && keys[0] !== "" && (keys[0].includes("utm") || keys[0].includes("gclid") || keys[0].includes("msclkid"));
        });
        const utmParams = validFetchedCookies && validFetchedCookies.reduce((acc, obj) => {
          const key = Object.keys(obj)[0];
          const value = obj[key];
          acc[key.replace(/(^|_)./g, s => s.slice(-1))] = `${value}`
          return acc;
        }, {});
        if (Object.keys(utmParams).length !== 0) {
          // Adding new hidden field to add  unique ID to each submission
          form.addHiddenFields(utmParams)
        }
      })
    }
  }

  useEffect(() => {
    loadForm()
  }, [])

  useEffect(() => {
    if (utmCookiesCreated) {
      addHiddenMktoFields()
    }
  }, [utmCookiesCreated])

  const loadForm = () => {
    if (typeof window.MktoForms2 != "object") {
      document.addEventListener("load", loadForm, true);
    } else if (!loadForm.done) {
      document.removeEventListener("load", loadForm, true);
      loadForm.done = true;
      // Render multiple Marketo forms on a single page
      if (
        serial &&
        id
      ) {
        if (callLoadFormOnlyOneTime.current === 0) {
          // Setting this ref to 1 makes sure the function `loadform()` runs only once,
          // so there is no duplicate form on the page
          callLoadFormOnlyOneTime.current = 1;

          const cookies = getCookie();

          window.MktoForms2.loadForm(
            "//info.accruent.com",
            `${serial}`,
            `${id}`,
            (form) => {
              if (!form) {
                console.info("window.MktoForms2.loadForm was passed an empty form object")
                return false;
              }

              formElement = form.getFormElem()[0];
              // set id to checkbox parent 
              formElement?.querySelectorAll('input[type="checkbox"]')?.forEach((element, i) => {
                element?.parentNode.setAttribute("id", "mktoCheckbox_" + i);
              });
              // takes all styles off the form
              destyleMktoForm(form);
              if (
                window &&
                window.dataLayer &&
                callDataLayerOnlyOneTime.current === 0
              ) {
                callDataLayerOnlyOneTime.current = 1;
                window.dataLayer.push({
                  event: "gaTriggerEvent",
                  gaCategory: "CTA Engagement",
                  gaAction: "Form Load",
                  gaLabel: gaLabel,
                });
                form.onSuccess(function (values, marketoFollowUpUrl) {
                  const fetchedCookies = getCookie()
                  const utmCookieParams = (typeof fetchedCookies !== 'undefined') && fetchedCookies.filter(obj => {
                    const keys = Object.keys(obj);
                    return keys.length > 0 && keys[0] !== "" && (keys[0].includes("utm"));
                  });
                  // OptanonConsent cookie value
                  const optanonConsentValue = extractCookieValue(fetchedCookies, 'OptanonConsent')
                  // OptanonAlertBoxClosed cookie value
                  const optanonAlertBoxClosedValue = extractCookieValue(fetchedCookies, 'OptanonAlertBoxClosed')
                  window.dataLayer.push({
                    event: "gaTriggerEvent",
                    gaCategory: "CTA Engagement",
                    gaAction: "Form Submission",
                    gaLabel: gaLabel,
                    consentInteractionTimestamp: optanonAlertBoxClosedValue,
                    consentValues: optanonConsentValue,
                    formSubmissionTimestamp: new Date().getTime(),
                    formSubmissionUniqueID: formHiddenFieldValues.formSubmissionUniqueID,
                    formUtmParams: JSON.stringify(utmCookieParams)
                  });
                  if (!(id == calendlyFormId)) {
                    setHasSubmitted(true);
                    if (setPopup) {
                      setPopup(false)
                    }
                    else if (followUpUrl) {
                      navigate(getPathWithQueryParam(location, followUpUrl, cookies))
                    }
                    else if (marketoFollowUpUrl) {
                      window.location.href = marketoFollowUpUrl;
                    } else if (successRef.current) {
                      successRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "center",
                        inline: "nearest",
                      });
                    }
                  }

                  return false;
                });
              }

              styleMarketoForm(formElement, allInputElements);
              // This prevents the form to render more than one time. Taking off the id, the MktoForms2 cannot find the element to put extra elements inside it.

              // CALL BACK
              if (callback) {
                callback(form, allInputElements, formElement, formTag);
              }
            }
          );
          window.MktoForms2.whenReady(form => {
            // Adding new hidden field to add  unique ID to each submission
            form.addHiddenFields(formHiddenFieldValues)
            addHiddenMktoFields()
          })
        }
      }
    }
  };
  if (serial && id) {
    if (hasSubmitted) {
      return { formElement, allInputElements, formTag };
    } else return { formElement, allInputElements, formTag };
  } else return { formElement: null, allInputElements: null, formTag: null };
};

export default useMarketoForm;
