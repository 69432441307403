import React ,{useContext, useEffect, useState} from "react";
import { Link } from "gatsby";
import getRelAtrribute from "src/components/helpers/getRelAtrribute";
import { useLocation }  from '@reach/router';
import ResourcesContext from "src/components/helpers/ResourcesMainPageContext";
import getPathWithQueryParam from "src/../helpers/getPathWithQueryParam";

// Use the globally available context to choose the right path
export const LocalizedLink = ({
  to,
  handleClick=()=>{},
  isButton,
  shouldHaveNoFollow = true,
  ...props
}) => {
  const { pageTypeContext } = useContext(ResourcesContext);
  const {fetchedCookies} = useContext(ResourcesContext);
  const [currentPath, setCurrentPath] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const checkForLandingPage = pageTypeContext && pageTypeContext === "landing_page";

  const location = useLocation();
  let checkCurrentDomainForUk = location?.pathname?.includes("/uk") ? true: false, checkToUrlForUk = to?.includes("/uk") ? true: false
  const stripTrailingSlash = (str) => {
    return str?.endsWith("/") ? str?.slice(0, -1) : str;
  };

  const customHandleClick = () => {
    setIsLoading(true);
    handleClick();
    setTimeout(()=>{setIsLoading(false)}, 100)
  }

  const lowerCaseWhereNeeded = (str) => {
    const split = str.split("/(?=/)/"); // Separates the string into an array at "/"

    const lowercased = split.map((section, i) => {
      // At the last section
      if (i === split.length - 1) {
        // If includes a dot return normal string
        if (section.includes(".")) return section;
        // if not return lowercased
        else return section.toLowerCase();
      }

      // default to lowercase return
      return section.toLowerCase();
    });

    return lowercased.join("");
  };

  const isIndex = to === `/`;

  // Test if the link provided is relative (starts with '/') or absolute (any other)
  const isInternalLink = /^\/(?!\/)/.test(to);
  const isHomepageLink = (props.href === '/' || props.href === '/uk');

  // If it's the default language, don't do anything
  // If it's another language, add the "path"
  // However, if the homepage/index page is linked don't add the "to"
  // Because otherwise this would add a trailing slash
  let path = to;
  if (isInternalLink) {
    path = to
  }
// use for updating UTM params
  useEffect(() => {
    setCurrentPath(getPathWithQueryParam(location, path, fetchedCookies))
    if (checkCurrentDomainForUk && checkToUrlForUk && !isInternalLink) {
      let trimToInternal = path.slice(path.indexOf("/uk/"))
      setCurrentPath(getPathWithQueryParam(location, trimToInternal, fetchedCookies))
    }
  }, [to, fetchedCookies])

  // To make sure that there are no trailing slashes on the path
  if (path && !isIndex) {
    path = stripTrailingSlash(path);
    // path = path.replace(" ", "").trim();
    path = lowerCaseWhereNeeded(path);
  }
  if (isButton) {
    props.className += " button ";
  }

  if (isInternalLink || isHomepageLink) {
    if (((checkCurrentDomainForUk && !checkToUrlForUk) || (!checkCurrentDomainForUk && checkToUrlForUk) || checkForLandingPage) && !isHomepageLink) {
      return  (<a
      href={currentPath?currentPath:path}
      onClick={handleClick}
      target={"_blank"}
      {...props}
      >
        {props.children}
      </a>)
    }
    else{
      return (
      <>
        <Link {...props} to={currentPath?currentPath:path} onClick={customHandleClick}/>
        {isLoading? <span style={{color: "black", fontSize: "4px", position: "fixed", top: 0, right: 0}}>Loading...</span>: null}
       </>
      );
    }
  }

  if (path && path.startsWith("#")) {
    return (
      <a href={path} onClick={handleClick} {...props}>
        {props.children}
      </a>
    );
  }
  // Added new condition for Absolute path, only for UK - Will open in same window.
  if (checkCurrentDomainForUk && checkToUrlForUk) {
    let trimToInternal = path.slice(path.indexOf("/uk/"))
    if (checkForLandingPage) {
      return <a
        href={currentPath?currentPath:trimToInternal}
        onClick={handleClick}
        target="_blank"
        {...props}
      />
    }
    return (
      <>
        <Link {...props} to={currentPath?currentPath:trimToInternal} onClick={customHandleClick} />
        {isLoading? <span style={{color: "black", fontSize: "4px", position: "fixed", top: 0, right: 0}}>Loading...</span>: null}
     </>
    )
  }
  return (
    <a
      href={currentPath?currentPath:path}   
      onClick={handleClick}
      rel={`noopener noreferrer ${shouldHaveNoFollow ? "nofollow" : ""}`}
      target={path && path.startsWith("#") ? "" : "_blank"}
      {...getRelAtrribute(path)}
      {...props}
    >
      {props.children}
    </a>
  );
};
