import React from "react";
import { ContentMarketoForm } from "./forms";
export const PricingStaticForm = () => {
  let contentEntity = {
    fieldEmbedCode : "<form id=\"mktoForm_2469\"></form>\r\n<script>MktoForms2.loadForm(\"//info.accruent.com\", \"167-BOY-362\", 2469, function(form) {\r\n    dataLayer.push({\r\n      'event': 'gaTriggerEvent',\r\n      'gaCategory': 'CTA Engagement',\r\n      'gaAction': 'Form Load',\r\n      'gaLabel': 'Pricing Request',\r\n    });\r\n    form.onSuccess(function(values, followUpUrl) {\r\n      dataLayer.push({\r\n        'event': 'gaTriggerEvent',\r\n        'gaCategory': 'CTA Engagement',\r\n        'gaAction': 'Form Submission',\r\n        'gaLabel': 'Pricing Request',\r\n      });\r\n    });\r\n  });\r\n</script>",
    fieldFormHeader: "We will help you build a customized business case",
    staticFormText: "Pricing displayed is an indicative estimate. Pricing will vary based on final configuration. Contact for personalized quote or for more details.",
    title: "Pricing Form",

  }
  return (
    <div className="container">
      <ContentMarketoForm
        entity={contentEntity}
        locale={'en'}
      />
    </div>
  )
}
