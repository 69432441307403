import React, {useState} from "react";
import { Loader } from "../loader";
import extractMarketoValues from "src/components/helpers/extractMarketoIdAndSerial";
import makeDynamicFormId from "src/components/helpers/makeDynamicFormId";
import useMarketoForm from "src/components/helpers/useMarketoForm";
export const GetYourQuoteForm = ({fieldFormHeader, fieldMarketoFormText, fieldMarketoFormTitleId, staticFormText, fieldEmbedCode, formFullWidth}) => {
  const [isFormLoaded, setFormLoaded] = useState(false)
  const formCallback = () => {
    setFormLoaded(true)
  }

  const { id, serial, gaLabel } = extractMarketoValues(
    fieldEmbedCode
  );
  const { formTag } = useMarketoForm(serial, id, formCallback, "", gaLabel);
  
  let idForScroll = makeDynamicFormId(fieldMarketoFormTitleId)

  

  return(
      <div className={`columns get-your-quote-wrapper form-page-wrapper tw-relative tw-px-5 ${formFullWidth ? "!tw-flex-col !tw-p-0" : ""}`}>
         <div id={idForScroll} className="scroll-for-id">
        </div>
        <div className={`column is-12-mobile is-6-tablet is-6-desktop ${formFullWidth ? "!tw-w-full" : ""}`}>
          {fieldFormHeader && (
            <h3 className="title title-4 tw-mb-4">
              {fieldFormHeader}
            </h3>
          )}
          {fieldMarketoFormText?.value && (
            <div className="body dangerously-html-handle"
            dangerouslySetInnerHTML={{ __html: fieldMarketoFormText?.value }}
            />
          )}
          {staticFormText && <p className="subtitle-2">{staticFormText}</p>}
        </div>
        <div className={`column is-12-mobile is-6-tablet is-6-desktop  ${formFullWidth ? "!tw-w-full" : ""}`}>
          <div className="border-box"></div>
            <div className="form-box tw-py-7 tw-px-6 md:tw-py-[38px] md:tw-px-[19px]">
            {!isFormLoaded && 
              <div>
              <Loader/>
              </div>
            }
              {formTag && formTag}
            </div>
            
          <div className="border-box"></div>
        </div>
      </div>
    )
}
