import React, { useState, useEffect } from "react";
import { AccruentImg, CtaLinks, PopupVideo } from "src/components/commonComponents";
import ExpandableBlockItem from "src/components/blocks/ExpandableBlockItem";
import { get } from "src/utils";
import { StaticImage } from "gatsby-plugin-image";
import useInViewport from "src/hooks/useInViewPort";
import { EXPANDABLE_BLOCK_ANIMATION_INTERVAL } from "src/constants"
import { isMobileDevice } from "src/utils"
const getImageObject = require("src/../helpers/getImageObject");

const defaultImage = "../../../static/placeholders/accruent_default-hero-placeholder.jpg";
let imgClassName = "";
const ExpandableBlock = (props) => {
    const { data: blockData, fieldSectionCta } = props;
    const [expandedBlock, setExpandedBlock] = useState({ id: blockData[0]?.nid, imageSrc: getImageObject(blockData[0]?.relationships?.fieldMediaImage,{},"fieldMediaImage"), fieldVideoRef: blockData[0]?.relationships?.fieldVideoRef })
    const [stopAnimation, setStopAnimation] = useState(false);
    const [nodeRef, isVisible] = useInViewport();
    const [intervalId, setIntervalId] = useState(null);
    const [dataIndex, setDataIndex] = useState(1);
    const handleExpandedBlock = (currentBlock) => {
        setStopAnimation(true)
        setExpandedBlock(currentBlock)
    }

    // settings for animation
    const handleAnimation = (index = 1) => {
      let currentIndex = index;
        const loopArrayWithInterval = () => {
          currentIndex = currentIndex % blockData.length;
          setExpandedBlock({
            id: blockData[currentIndex]?.nid,
            imageSrc: getImageObject(
              blockData[currentIndex]?.relationships?.fieldMediaImage,
              {},
              "fieldMediaImage"
            ),
            fieldVideoRef: blockData[currentIndex]?.relationships?.fieldVideoRef,
          });
          setDataIndex(currentIndex++);
        };
   
        return setInterval(loopArrayWithInterval, EXPANDABLE_BLOCK_ANIMATION_INTERVAL);
      };

      useEffect(() => {
        if (!isMobileDevice()) {
          if (isVisible && !stopAnimation) {
            clearInterval(intervalId);
            setIntervalId(handleAnimation())
          }
          if(stopAnimation){
            clearInterval(intervalId)
          }
        }
        return () => {
          clearInterval(intervalId);
        };
      }, [blockData, isVisible, stopAnimation]);

      const handelePopupEvent = (event) => {
        if (!isMobileDevice()) {
          if (event == "open") {
            clearInterval(intervalId);
          } else {
            if (isVisible && !stopAnimation) {
              clearInterval(intervalId);
              setIntervalId(handleAnimation(dataIndex))
            }
          }
        }
      };

      //  ****************************************************************

    const videoUrl = get(expandedBlock, "fieldVideoRef.fieldMediaVideoEmbedField");
    const videoThumbnail = get(expandedBlock, "fieldVideoRef.relationships.thumbnail");
    const videoThumbnailImg = getImageObject(videoThumbnail, {alt: expandedBlock?.fieldVideoRef?.fieldThumbnailAltText});

    let contentImage = expandedBlock.imageSrc ? (
        <AccruentImg
            file={expandedBlock.imageSrc}
            className="content-block-closeup-image has-height-100-percent"
            width={740}
        />
    ) : (
        <StaticImage
            src={defaultImage}
            alt={'Default Image'}
            className="has-height-100-percent"
            aspectRatio={2.0}
            placeholder="blurred"
            layout="constrained"
            width={740}
        />
    );

    const contentMedia = !videoUrl?contentImage:(
        <PopupVideo listenEvent={ handelePopupEvent } videoUrl={videoUrl} thumbnail={videoThumbnailImg} thumbnailClass={imgClassName+" is-relative"} isForegroundThumbnail={true} />
    )
    let ctaProps = [
        {
            url: fieldSectionCta[0]?.url,
            title: fieldSectionCta[0]?.title,
   
        },
        {
            url: fieldSectionCta[1]?.url,
            title: fieldSectionCta[1]?.title,
            background: "white"
        },
    ]
    return (
        <div className="content-block-item product-page-expand-box">
            <div className="tw-m-[-12px] tw-flex tw-gap-[24px]">
                <div className=" tw-w-1/2 tw-hidden md:tw-block">
                    <div className="content-block-item__image">
                        {contentMedia}
                    </div>
                    {fieldSectionCta[0]?.url && <CtaLinks desktop={true} ctaProps={ctaProps}/>}
                </div>
                <div className=" tw-w-full md:tw-w-1/2" ref={nodeRef}>
                    {fieldSectionCta[0]?.url && <CtaLinks ctaProps={ctaProps}/>}
                    {blockData.map((entity, index) => {
                        return <ExpandableBlockItem entity={entity} expandedBlock={expandedBlock} handleExpandedBlock={handleExpandedBlock} key={entity.nid} />
                    })}
                </div>
            </div>
        </div>
    );
};

export default ExpandableBlock;
