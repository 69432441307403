import React from "react";
import TeaserCarousel from "../blocks/TeaserCarousel";
export const TeaserCarouselBlock = ({ contentCarouselSection, fieldResourceCarouselItem, locale, isHome = false, homepageBackground = false, className = null, myAccruentModified = false, marketoDivId = null }) => {
    if (!contentCarouselSection) return <></>
    const { body, title, hideTitle, fieldShowTitleFullWidth } = contentCarouselSection
    return (
        <div
            className="case-studies-section"
            component="CaseStudiesBlock"
            id={marketoDivId}
        >
            <div className={`header center-header tw-w-full ${(!hideTitle && !!title)?'tw-mb-9':''}`}>
                {!hideTitle && (
                    <>
                        {!fieldShowTitleFullWidth ? 
                        <h2 className="tw-font-Helvetica md:tw-font-Poppins tw-break-normal tw-text-center tw-text-mobileXxxlFont md:tw-text-xxxlFont tw-text-accruent-heading-color tw-mb-[33px] tw-w-full ">{title}</h2>:
                        <h3 className="tw-font-Helvetica md:tw-font-Poppins tw-break-normal tw-text-mobileXxxlFont md:tw-text-xxxlFont tw-text-accruent-heading-color tw-mb-[33px] !tw-w-full ">{title}</h3>
                    }
                    </>
                )}
                {!!body?.value && (
                    <div className="mb-5">
                        <div dangerouslySetInnerHTML={{ __html: body?.value }} className="dangerously-html-handle" />
                    </div>
                )}
            </div>
            {
                fieldResourceCarouselItem?.length > 0 && <TeaserCarousel carouselItems={fieldResourceCarouselItem} locale={locale} isHome={isHome} homepageBackground={homepageBackground} className={className} myAccruentModified={myAccruentModified}/>
            }
        </div>
    );
}

