import React, {memo, useEffect, useRef, useState} from "react";
import { AccruentSlider, LocalizedLink, AccruentImg, PopupVideo, Video, Modal } from "src/components/commonComponents";
import parseAndTruncate from "../../../helpers/parseAndTruncate";
import useInViewport from "src/hooks/useInViewPort";
import { isMobileDevice } from "src/utils"
const getImageObject = require("src/../helpers/getImageObject");
const DotsComponent = ({data, sliderRef, currentSlider, isDesktop}) => {
  return (
    <>
      <div className={`dotted tw-flex tw-items-center tw-gap-[32px] tw-justify-center tw-pt-[40px]`}>

        <div className="icon-div tw-bg-[#dddddda1] tw-rounded-[38px]" onClick={() => sliderRef.current.slickGoTo(currentSlider - 1)}><i className={`fa-solid fa-angle-left tw-cursor-pointer tw-p-[8px_12px] tw-text-accruent_dark_imp_blue arrow-icon`}></i></div>
        <ul className="tw-flex tw-m-0 tw-p-0">
          {data?.map((dots, index) => {
            return (
              <li className={currentSlider === index ? "tw-cursor-pointer !tw-pb-0 tw-list-none tw-w-[10px] tw-h-[10px] tw-bg-accruent_sapphire tw-rounded-[50%] tw-inline-block tw-mr-[18px] tw-opacity-[1] active" : " tw-cursor-pointer !tw-pb-0 tw-list-none tw-w-[10px] tw-h-[10px] tw-bg-[#ddd] tw-rounded-[50%] tw-inline-block tw-mr-[18px] tw-opacity-[0.5] hover:tw-opacity-[1] hover:tw-bg-accruent_sapphire"} onClick={() => sliderRef.current.slickGoTo(index)}></li>
            )
          })}
        </ul>
        <div className="icon-div tw-bg-[#dddddda1] tw-rounded-[38px]" onClick={() => sliderRef.current.slickGoTo(currentSlider + 1)}><i className={`fa-solid fa-angle-right tw-cursor-pointer tw-p-[8px_12px] tw-text-accruent_dark_imp_blue arrow-icon`}></i></div>

      </div>
    </>
  )
}

const ContentBlockCarousel = ({carouselData}) => {
  const [currentSlider, setCurrSlider] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [currentVideoUrl, setCurrentVideoUrl] = useState('');
  const [nodeRef, isVisible] = useInViewport();
  const [interaction, setInteraction] = useState(false);
  const sliderRef = useRef();
  const settings = {
    afterChange: function (index) {
      setCurrSlider(index);
    },
    sliderRef: sliderRef,
    nextArrow: null,
    prevArrow: null,
    lazyLoad: false
  }
  const openModal = (videoUrl) => {
    setCurrentVideoUrl(videoUrl)
    setIsOpen(true);
  };
  const closeModal = () => {
    setInteraction(false)
    setIsOpen(false);
  };
  useEffect(() => {
      if(!isMobileDevice()){
        if(isVisible && !interaction){
          let id = setInterval(() => {
            sliderRef.current.slickGoTo(currentSlider+1)
          },5000)
          return () => clearInterval(id);
        }
      }
  },[currentSlider, isVisible, interaction])

  
  return(
    <div className="content-block-carousel" ref={nodeRef} onMouseUp={() => setInteraction(true)}>
      {isOpen && (
        <Modal isOpen={isOpen} onClose={closeModal}>
          <div className="new-video-box">
            <Video videoUrl={currentVideoUrl} />
          </div>
        </Modal>
      )}
      <AccruentSlider className="slider-content-block-carousel tw-bg-inherit md:tw-bg-accruent_slightgray md:tw-rounded-[38px]" sliderId="content-block-carousel" settings={settings}>
        {carouselData && carouselData.map((carousel,index) => {
          const mediaImage = getImageObject(carousel?.relationships?.fieldMediaImage,{},"fieldMediaImage");
          const videoUrl = carousel?.relationships?.fieldVideoRef?.fieldMediaVideoEmbedField;
          const videoThumbnail = carousel?.relationships?.fieldVideoRef?.relationships?.thumbnail;

          const contentMedia = !videoUrl ? (
            <AccruentImg file={mediaImage} className="image-contained" />
          ) : (
            <PopupVideo
              thumbnail={videoThumbnail}
              thumbnailClass={"p-0 is-full-tablet image-contained"}
              handleClick={()=>openModal(videoUrl)}
            />
          );

          return(
            <>
            <div className="carousel-container tw-relative tw-ml-[15px] md:tw-ml-0" >
              <div className="tw-h-full !tw-absolute tw-min-h-[320px] !tw-ml-0 !tw-mr-0 tw-w-full tw-rounded-[38px] tw-z-[-1] !tw-bg-accruent_slightgray md:tw-hidden"></div>
              <div className=" tw-pt-[24px] tw-pl-[16px] tw-pr-[16px] tw-w-[313px] tw-min-h-[600px] md:pt-[32px] md:pl-[32px] md:tw-pr-0 md:tw-w-full md:tw-min-h-full">
                <div>
                  <h2 className="tw-text-[22px] tw-leading-[25.2px] tw-tracking-[0.2px] md:tw-text-[30px] tw-pb-[16px] tw-font-Poppins tw-font-bold">{carousel?.title}</h2>
                </div>
                <div className="tw-m-[-12px] md:tw-items-center md:tw-flex">
                  <div className="tw-p-3 md:tw-w-1/2">
                    {contentMedia}
                  </div>
                  <div className="tw-p-3 md:tw-w-1/2">
                    {carousel?.body?.value && <div
                     className="desc md:tw-block tw-text-[16px] tw-text-accruent_base_color tw-leading-[22px] tw-tracking-[0.29px] tw-font-Roboto tw-overflow-hidden"
                      dangerouslySetInnerHTML={{ __html: carousel?.body?.value }}
                    />}
                    {carousel?.fieldCtaUrl?.url && <LocalizedLink to={carousel?.fieldCtaUrl?.url} className="tw-text-accruent_dark_imp_blue tw-font-bold tw-text-[16px] tw-tracking-[0.03px] tw-font-Poppins tw-mb-[30px] tw-block tw-mt-[15px] hover:tw-text-accruent_dark_imp_blue ">{carousel?.fieldCtaUrl?.title}</LocalizedLink>}
                  </div>
                </div>
              </div>
            </div>
            </>
          )
        })}
      </AccruentSlider>
      <DotsComponent data={carouselData} sliderRef={sliderRef} currentSlider={currentSlider}/>
    </div>
  )
}
export default memo(ContentBlockCarousel);