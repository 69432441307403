import React from 'react'
import { AccruentImg } from './accruentImg'
import { LocalizedLink } from './localizedLink'

export const EventStickyDiv = ({ fieldEventLogo, fieldHeroCtaUrl, fieldHeroCtaTitle, fieldHeroCtaDescription }) => {
  let logo = fieldEventLogo

  return (
    <div className="event-registraion-wrapper">
      {!!logo &&
        <div className="company-logo">
          <AccruentImg file={logo} />
        </div>
      }
      {!!fieldHeroCtaTitle &&
        <div className="company-content">
          {fieldHeroCtaTitle}
        </div>
      }
      {!!fieldHeroCtaUrl && (
        <LocalizedLink
          to={fieldHeroCtaUrl.url}
          className="mktoButton button is-primary is-medium is-fullwidth block"
          isButton
        >
          {fieldHeroCtaUrl.title}
        </LocalizedLink>
      )}
    </div>
  )
}
