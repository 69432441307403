import React from "react";
import getImageObject from "../../helpers/getImageObject";
import { splitComponent } from "src/utils";
import { LocalizedLink, Picture } from "./commonComponents";

const linkStyle = `tw-text-[16px] md:tw-text-smFont 1xl:tw-text-[16px] tw-font-bold tw-text-link-color tw-font-Poppins`
const CardComponent = ({ product }) => {
  const cardImage = getImageObject(product?.relationships?.fieldImage, product?.fieldImage)
  return (
    <div className="cards tw-px-4 tw-py-6">
      <div className="logo-container tw-rounded-[4px] tw-mb-2 tw-bg-white">
        {cardImage && <Picture image={cardImage} parentClassName="logo-images-container" className="logo-images tw-max-h-[35px] tw-w-auto !tw-min-h-0 !tw-inline-block tw-ml-[19px] !tw-my-[11px] md:!tw-my-[18px]" loading="lazy" />}
      </div>
      <p className="card-text tw-text-smFont tw-text-accruent-base-color tw-mb-10 md:tw-text-[16px] tw-font-Roboto">
        <span className="bold tw-font-bold">{product?.fieldCardTitle}</span><span>: {product?.fieldPreHeading}</span>
      </p>
      {Array.isArray(product?.fieldCardCta) && <div className="card-links tw-flex tw-justify-between tw-gap-0.5 card-component-links tw-absolute tw-bottom-[18px] tw-inset-x-4">
        {product?.fieldCardCta[0]?.url && <LocalizedLink className={linkStyle} to={product?.fieldCardCta[0]?.url}>{product?.fieldCardCta[0]?.title}</LocalizedLink>}
        {product?.fieldCardCta[1]?.url && <LocalizedLink  className={linkStyle} to={product?.fieldCardCta[1]?.url}>{product?.fieldCardCta[1]?.title}</LocalizedLink>}
      </div>}
    </div>
  )
}
const OtherProducts = ({ field_other_products }) => {
  return (
    <div className="other-product-container tw-border tw-px-4 tw-rounded-[16px] tw-border-solid tw-border-secondary_nav_bg_color_dropdown tw-bg-accruent_white">
      {
        Array.isArray(field_other_products) && field_other_products.map((otherProd, index) => {
          const checkForLast = index !== field_other_products?.length - 1;
          return (
            <div key={index} className={!checkForLast ? "mb-3" : ""}>
              <h5 className="mb-1 mt-3">{otherProd?.fieldCardTitle}</h5>
              <p className="others-text tw-text-smFont tw-mb-4">{otherProd?.fieldPreHeading}</p>
              {Array.isArray(otherProd?.fieldCardCta) && <div className="card-links tw-flex tw-justify-between tw-gap-0.5">
                {otherProd?.fieldCardCta[0]?.url && <LocalizedLink className={linkStyle} to={otherProd?.fieldCardCta[0]?.url}>{otherProd?.fieldCardCta[0]?.title}</LocalizedLink>}
                {otherProd?.fieldCardCta[1]?.url && <LocalizedLink className={linkStyle} to={otherProd?.fieldCardCta[1]?.url}>{otherProd?.fieldCardCta[1]?.title}</LocalizedLink>}
              </div>}
              {checkForLast && <hr />}
            </div>
          )
        })
      }
    </div>
  )
}
const SupportProductComponent = ({ entity }) => {
  const { title, body, fieldHideTitle } = entity || ""
  const { fieldCurrentProducts, field_other_products } = entity?.relationships || ""
  const { firstContent, secondContent } = splitComponent(fieldCurrentProducts) || ""
  const checkOtherProducts = !!field_other_products?.length
  const cardBgClass = `tw-min-w-[calc(100%_-_70px)] tw-relative tw-rounded-lg md:tw-min-w-[inherit] md:tw-w-[48%] tw-bg-[#f8f8f8]  tw-w-full md:tw-mr-2.5 lg:tw-mr-[inherit]`
  return (
    <div className={"support-container"} >
      <div className="anchor-div tw-absolute tw-top-[-75px]" id={title.toLowerCase().replaceAll(' ', '')}></div>
      <div className="support-page">
        <h2 className=" tw-hidden lg:tw-block">{title}</h2>
        <div
          className="text tw-text-subtitleLgFont tw-text-accruent-base-color tw-tracking-[0.15px] tw-leading-6 tw-font-medium tw-w-[70%] tw-mt-4 tw-font-Roboto tw-hidden lg:tw-block"
          dangerouslySetInnerHTML={{ __html: body?.value }}
        />
        <div className="tw-columns !tw-mx-0">
          <div className={`tw-column  !tw-p-0 !tw-mt-[52px] ${(checkOtherProducts) ? ' lg:tw-w-[66.66%] lg:tw-flex-none': ' tw-w-full'}  tw-w-full`}>
            {(!!fieldCurrentProducts?.length) && (!fieldHideTitle) && <p className="heading-prod tw-text-xsFont tw-tracking-[1.8px] tw-leading-4 tw-text-accruent-base-color tw-font-medium tw-font-Roboto">CURRENT PRODUCTS</p>}
            <div className="product-cards tw-overflow-x-auto tw-w-full md:tw-overflow-hidden;">
              <div className=" md:tw-hidden">
                <div className="first-content tw-flex tw-flex-row tw-gap-4  tw-mb-4">
                  {Array.isArray(firstContent) && firstContent?.map((content, index) => {
                    return (
                      <>
                        {content?.fieldCardTitle && <div className={cardBgClass}>
                          <CardComponent product={content} />
                        </div>}
                      </>
                    )
                  })}
                </div>
                <div className="second-content tw-flex tw-flex-row tw-gap-4">
                  {Array.isArray(secondContent) && secondContent?.map((content, index) => {
                    return (
                      <>
                        {content?.fieldCardTitle && <div className={cardBgClass}>
                          <CardComponent product={content} />
                        </div>}
                      </>
                    )
                  })}
                </div>
              </div>
              <div className=" tw-hidden card-desktop lg:tw-flex tw-flex-wrap tw-gap-4">
                {!!fieldCurrentProducts?.length && fieldCurrentProducts?.map((product, index) => {
                  return (
                    <>
                      {product?.fieldCardTitle && <div className={`card-bg ${cardBgClass} ${checkOtherProducts ? "": "no-other-products md:tw-w-[32%]"}`}>
                        <CardComponent product={product} />
                      </div>}
                    </>
                  )
                })}
              </div>

            </div>
          </div>
          {checkOtherProducts && <div className="tw-column !tw-p-0 !tw-mt-[52px] tw-w-full lg:tw-w-[33.33%]">
            {(!fieldHideTitle) && <p className="heading-prod tw-text-xsFont tw-tracking-[1.8px] tw-leading-4 tw-text-accruent-base-color tw-font-medium tw-font-Roboto">OTHER PRODUCTS</p>}
            <div className="other-products">
              <OtherProducts field_other_products={field_other_products} />
            </div>
          </div>}
        </div>
      </div>
    </div>
  )
}
export default SupportProductComponent;