/**
 * @param {String} wholeForm - MUST be the at least of the form:
 * <script src="//app-abc.marketo.com/js/forms2/js/forms2.min.js"></script>
 * <form id="mktoForm_1517"></form>
 * <script>MktoForms2.loadForm("//app-abc.marketo.com", "167-BOY-362", 1517);</script>
 *
 * But can also be whole content like such: https://gist.githubusercontent.com/jordotech/64817cb2bd0374d215f828cac7c007d2/raw/d549db80609d4550e6a47a4fd214e4f1c0908103/marketo.txt
 *
 * @returns {Object} {serial, id} -
 *    @serial String XXX-XXX-XXX string from the form above
 *    @id Number XXXX number from above form
 */
const extractMarketoId = (string) => {
  if (string) {
    const separated = string.split("<script>")[1].split(",");
    const serial = separated[1].trim().replace(/"/g, "");
    const id = Number(separated[2].split(")")[0].trim());
    const gaLabel = string
      .split("'gaLabel':")[1]
      ?.split(",")[0]
      ?.replace(/'/g, "")
      ?.trim();
    return {
      serial,
      id,
      gaLabel,
    };
  } else
    return {
      serial: null,
      id: null,
      gaLabel: null,
    };
};

export default extractMarketoId;
