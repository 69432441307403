import React, { useEffect, useState, memo } from "react";
import { Picture,LocalizedLink, AccruentImg } from "../commonComponents";
import crossIcon from "../../../static/cross-icon.svg";
import angleRightPurple from "../../../static/angle-right-purple.svg";
const NavBarEmsIndustry = (props) => {

  const [activeMenu, setActiveMenu] = useState(props?.items?.[0]?.name)
  const [activeSubMenu, setActiveSubMenu] = useState(props?.items?.[0]?.children?.[0]?.name)
  const [subMenu, setSubMenu] = useState(props?.items?.[0])
  const [subMenuDetail, setSubMenuDetail] = useState(!!props?.items?.[0]?.children?.length ? props?.items?.[0]?.children?.[0] : props?.items?.[0])
  const { setActiveClass, setActiveClassDropdown } = props
  const onCrossClick = () => {
    var element = document.getElementById("navbar-dropdown-v2" + props?.parent);
    element.classList.toggle("menu-is-inactive");
  }

  const checkForResource = props?.parent === "Resources" ? true : false
  const checkForSolution = props?.parent === "Solutions" ? true : false
  const checkForProduct = props?.parent === "Product" ? true : false
  const checkForIndustry = props?.parent === "Industries" ? true : false
  const checkForCmms = props?.parent === "CMMS Software" ? true : false
  const checkForFeatures = props?.parent === "Features" ? true : false
  const indexToDivide = checkForResource || checkForSolution  || checkForFeatures ? Math.ceil(props?.items?.[0]?.children?.length / 2) : 20;
  const indexProdToDivide = checkForProduct ? 2 : 1

  const resetState = () => {
    setActiveMenu(props?.items?.[0]?.name)
    setActiveSubMenu(props?.items?.[0]?.children?.[0]?.name)
    setSubMenu(props?.items?.[0])
    setSubMenuDetail(!!props?.items?.[0]?.children?.length ? props?.items?.[0]?.children?.[0] : props?.items?.[0])
  }
  const handleSetActiveClass = (name,link) => {
    setActiveClass({ [name]: '' })
    setActiveClassDropdown({ [name]: 'menu-is-inactive' })
    props.innerCleanFilterWhenClickingResources(link,name)
  }

  const setChildActiveMethod = (item) => {
    setActiveSubMenu(item?.name)
    setSubMenuDetail(item)
  }

  const ChildItemListing = ({ childItem, activeSubMenu, setChildActiveMethod }) => {
    return (
      <li className={(activeSubMenu === childItem?.name) ? "active" : ""}
        onMouseEnter={() => setChildActiveMethod(childItem)}
      >
        <LocalizedLink className="dropdown-item" 
        handleClick={() => { handleSetActiveClass(props.parent,childItem?.link) }}
        to={childItem?.link}
        >
          <span>{childItem?.name}</span>
          <img src={angleRightPurple} decoding="async" className="svg-inline--fa fa-angle-right arrow-icon !tw-w-[10px] !tw-h-[16px] !tw-m-auto"/>
          {/* <svg class="svg-inline--fa fa-angle-right arrow-icon" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path></svg> */}
        </LocalizedLink>
      </li>
    )
  }
  const {fieldTermSecondCta} = subMenuDetail || ""
  const {url: fieldTermSecondaryCta, title: fieldTermSecondaryTitle} = fieldTermSecondCta || ""
  return (
    <div className="new-navbar"
      onMouseLeave={() => resetState()}
    >
      <ul className="dropdown-menu-two">
        <div className="row submenu">
          <div className="col-md-3 maega-menu-colum first-ul" style={{ display: 'flex' }}>
          <ul className="overflow-ul">
          {props.items.map((item, index) => {
            return (
              <div>
                <li>
                  <p className="mega-title">{item?.name}</p>
                </li>
                <div className="multiple-ul ems-mc ">
                  <ul>
                    {item?.children?.map((childItem, index) => {
                        return (
                          <ChildItemListing childItem={childItem} activeSubMenu={activeSubMenu} setChildActiveMethod={setChildActiveMethod} />
                        )
                    })}
                  </ul>
                
                </div>
              </div>
            )
          })}
      </ul>
          </div>
          <div className="col-md-5 maega-menu-colum gray-bg">
            <div className="mediya-warapper">
            <AccruentImg height={232} loading="lazy" className="navbar-header-img set-fixed-width" file={subMenuDetail?.navbarHeaderImg} />
              <div className="media">
                <div className="media-body">
                  <div className="is-flex">
                    <div>
                      <div className="title-6 mb-1">
                        {subMenuDetail?.name}
                      </div>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: subMenuDetail?.description?.value
                        }}
                      />
                    </div>
                  </div>
                  <div className="navbar-end text-left nav-division-cta">
                    <div className="navbar-item ">

                      <LocalizedLink
                        className="navbar-link is-arrowless has-text-weight-semibold  button outline-button"
                        to={subMenuDetail?.link}
                        handleClick={() => { handleSetActiveClass(props.parent, subMenuDetail?.link) }}
                      >
                        Learn More
                      </LocalizedLink>

                    </div>
                    {fieldTermSecondCta && <div className="navbar-secondary-cta">
                      <LocalizedLink to={fieldTermSecondaryCta}>
                          {fieldTermSecondaryTitle}
                      </LocalizedLink>
                  </div>}
                  </div>
                  
                </div>
                <div onClick={onCrossClick} className="close-icon">
                  <img src={crossIcon} decoding="async" className="svg-inline--fa fa-xmark-large tw-min-w-[21px]"/>
                  {/* <svg class="svg-inline--fa fa-xmark-large" data-prefix="fas" data-icon="xmark-large" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M393.4 41.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3L269.3 256 438.6 425.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 301.3 54.6 470.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L178.7 256 9.4 86.6C-3.1 74.1-3.1 53.9 9.4 41.4s32.8-12.5 45.3 0L224 210.7 393.4 41.4z"></path></svg> */}
                </div>
              </div>
              {subMenuDetail?.spotlightItem?.title && <div className="card-media">
                <div className="columns">
                  <div className="column is-3-desktop image-container">
                    <Picture image={subMenuDetail?.spotlightItem?.image} />
                  </div>
                  <div className="column is-9-desktop">
                    <p className="subtitle-2">{subMenuDetail?.spotlightItem?.title}</p>
                    <p className="sub-text">{subMenuDetail?.spotlightItem?.description}</p>
                    {subMenuDetail?.spotlightItem?.ctaUrl && <LocalizedLink className="card-link"  handleClick={() => { handleSetActiveClass(props.parent,subMenuDetail?.link) }} to={subMenuDetail?.spotlightItem?.ctaUrl}>{subMenuDetail?.spotlightItem?.ctaText}</LocalizedLink>}
                  </div>
                </div>
              </div>}
            </div>
          </div>
        </div>
      </ul>
    </div>
  )
}
export default NavBarEmsIndustry;