import React, { useEffect, useState } from "react";
import { get } from "src/utils";
import getAuthorName from "../../../../helpers/getAuthorName";
import getResourceTagsList from "../../../../helpers/getResourceTagsList";
import dateFormatter from "../../helpers/dateFormatter";
import { LocalizedLink } from "src/components/commonComponents";
import splitBodyContent from "../../helpers/splitBodyContent";
import loadable from '@loadable/component';
import useAddParamInHtmlLink from "src/components/helpers/useAddParamInHtmlLink";
// const SideBar = loadable(() => import('../../components/resources/ResourceSideBar'));
const SideBar = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.SideBar
});
const StickyBox = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.StickyBox
});
// const Picture = loadable(() => import('src/components/Picture'))
const Picture = loadable(() => import("src/components/commonComponents"), {
  resolveComponent: (components) => components.Picture
});
import { ShareFollowBox } from "src/components/commonComponents";
export const SingleCompanyNews = ({ data }) => {
  const { locale } = data;
  const authorName = getAuthorName(data?.relationships);
  const resourceTags = getResourceTagsList([data.industryIds, data.solutionIds, data.productIds])
  let heroImage = get(data, "heroImage");

  let dateFormatted = ""
  if (data?.fieldPublishDate) {
    dateFormatted = dateFormatter(data?.fieldPublishDate)
  }
  let splitFunc = splitBodyContent(data?.body?.value);
  let firstContentToString = splitFunc?.firstContentToString;
  let secondContentToString = splitFunc?.secondContentToString;
  useAddParamInHtmlLink()
  return (
    <section className="resources-page section !tw-pt-0 content-visibility-auto-mobile">
      <div className="container company-news-page">
        <div className="tw-block md:tw-hidden">
          <ShareFollowBox />
        </div>
        <section className="medium-wrapper tw-text-[16px] md:tw-text-[18px] tw-py-0 tw-px-[15px] md:tw-px-0 md:tw-pl-[12px] md:tw-py-0 tw-mt-[32px] md:tw-mt-[35px] left-company-news">
          <div className="columns md:tw-flex">
            <div className="tw-w-full lg:tw-w-[calc(100%-376px)] lg:tw-max-w-[calc(100%-376px)] [&]:tw-px-0 [&]:md:tw-pr-3">
              <div className="company-new-image">
                <Picture
                  image={heroImage}
                  className={'resources-hero-image'}
                />
              </div>
              <section className="tw-text-[16px] md:tw-text-[18px] tw-py-0 tw-px-[15px] md:tw-px-0 md:tw-py-0 full-article-btn">
                {data.fieldLinkOriginalArticle?.title && (
                  <div className="original-link tw-mb-[14px] lg:tw-mb-0">
                    <LocalizedLink
                      className="line-link company tw-bg-white tw-border-2 tw-border-accruent_dark_imp_blue tw-text-accruent_dark_imp_blue tw-rounded-xl tw-font-bold tw-py-[14px] tw-px-6 tw-mt-8 tw-inline-block tw-mt-4 hover:tw-bg-accruent_dark_imp_blue hover:tw-text-white tw-text-base tw-mb-[30px]"
                      to={data.fieldLinkOriginalArticle.url}
                    >
                      {data.fieldLinkOriginalArticle.title} <i class="fa-solid fa-arrow-up-right-from-square"></i>
                    </LocalizedLink>
                  </div>
                )}
                {data?.fieldArticleFrom && <p><span>Article from: </span>{data?.fieldArticleFrom}</p>}
              </section>
              <hr className="hr-news" />
              {!!authorName && (
                <h3>
                  By {authorName}
                </h3>
              )}
              {data?.fieldResourceDescription && <p className="subtitle-2 resource-desc">{data?.fieldResourceDescription}</p>}
              {data?.body && <div
                className="dangerously-html-handle"
                dangerouslySetInnerHTML={{ __html: firstContentToString }}
              ></div>
              }
              <div className="middle-of-content tw-mb-4">
               {(heroImage) ?
                    <div className={`company-highlight-card no-background tw-p-0`}>
                      <Picture
                        image={heroImage}
                        className={'resources-hero-image tw-h-full tw-w-full  md:tw-min-h-0 tw-object-cover'}
                      />
                    </div> : null
                }
              </div>
              {secondContentToString && <div
                className="dangerously-html-handle"
                dangerouslySetInnerHTML={{ __html: secondContentToString }}
              ></div>
              }

            </div>
            <div className="tw-w-full lg:tw-max-w-[376px] lg:tw-p-[12px]">
              <div className="right-content sidebar-content tw-bg-accruent_white tw-w-full md:tw-w-[376px] tw-relative">
                <StickyBox
                  scrollContainerClass={"sidebar-scroll"}
                >
                  <div className="right-box">
                    <SideBar recommendedResources={data.rectifiedRecommended} />
                  </div>
                </StickyBox>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  )
}

// export default SingleCompanyNews;
