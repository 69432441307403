import React from "react";
//Components
//Hooks
import useQuestionsCTAForm from "src/hooks/useQuestionsCTAForm";
import { LocalizedLink } from "../localizedLink";
export const QuestionsCTA = ({ locale = 'en' }) => {
  const { questionsCTA: allQuestionsCTA } = useQuestionsCTAForm();

  return (
    <div className="columns is-centered tw-w-full lg:tw-w-[95%] tw-my-0 tw-mx-auto">
      {allQuestionsCTA.map((entity) => (
        <div className="column is-4-desktop tw-w-[99.5%] md:w-full tw-mb-[22px] lg:tw-mb-6 md:tw-mb-0 tw-mx-0 is-12-mobile lg:tw-mx-[15px] tw-relative tw-pb-[75px] tw-rounded-t-[48px] tw-rounded-br-[48px] tw-shadow-[3px_3px_20px] tw-shadow-[#7d7d7d29] tw-no-underline" key={entity.uuid}>
          <div className="questions-card !tw-shadow-none !tw-rounded-none tw-p-0 is-justify-content-center is-align-items-center">
            <LocalizedLink
              to={
                locale === 'en' || !entity.fieldCtaUrl?.url?.startsWith('/contact-us')
                  ? entity.fieldCtaUrl?.url
                  : `/uk${entity.fieldCtaUrl?.url}`}
            >
              <div className="columns content-box">
                <div className="column is-one-fifth">
                  <span className="icon is-large block has-text-accruent-sapphire">
                    <i
                      className={`fa-light fa-${entity.fieldIcon?.iconName} fa-4x`}
                      aria-hidden="true"
                    ></i>
                  </span>
                </div>
                <div className="column">
                  <h3 className="title-6 mb-2">{entity.title}</h3>
                  <p className="has-text-weight-light">
                    {locale === "en-gb" ? entity.fieldCardContent?.replace("inquiries", "enquiries") : entity.fieldCardContent}
                  </p>
                  <span
                    className="is-primary button"
                  >
                    {entity.fieldCtaUrl?.title}
                  </span>
                </div>
              </div>
            </LocalizedLink>
          </div>
        </div>
      ))}
    </div>
  );
};

