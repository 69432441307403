import React, { useState } from "react";
import { splitComponentIntoChunk } from "src/utils";
import { LocalizedLink } from "../commonComponents/localizedLink";
import angleRight from "../../../static/angle-right.svg"
const makeFrForGridTemplate = (length) => {
  let fr = ''
  for(let i=0; i<length; i++){
    fr = fr + ' ' +'auto'
  }
  return fr;
}

const NavWithList = ({items, parent, parentLink, setActiveClass, setActiveClassDropdown, innerCleanFilterWhenClickingResources }) => {
  const [active, setActive] = useState(items?.[0]?.name);
  let splitChunks = splitComponentIntoChunk(items)
  let frForGrid = makeFrForGridTemplate(splitChunks?.length)
  const resetState = () => {
    setActive(items?.[0]?.name);
  }
  const handleSetActiveClass = (name,link) => {
    setActiveClass({ [name]: '' })
    setActiveClassDropdown({ [name]: 'menu-is-inactive' })
    innerCleanFilterWhenClickingResources(link,name)
  }
  const TextParagraph = ({name, link, fieldShowPopup}) => {
    return(
        <div className={`text-paragraph tw-flex tw-relative -tw-ml-2.5 -tw-mr-5 -tw-mt-2.5 tw-mb-3.5 tw-pl-2.5 tw-pr-5 tw-py-2.5 tw-transition-[text-shadow] tw-duration-[0.3s] ${active === name ? "active tw-cursor-pointer tw-bg-accruent_lightgray tw-rounded-[8px]": ""}`} onMouseEnter={() => setActive(name)} >
          <LocalizedLink className="tw-w-full -tw-ml-2.5 -tw-my-2.5 tw-pl-2.5 tw-py-2.5" to={link} handleClick={() => { handleSetActiveClass(parent, link) }}>
            <p className="items-text tw-text-[12px] xl:tw-text-[14px]  tw-text-[#222223] tw-tracking-[0px] tw-opacity-[70%] tw-whitespace-nowrap tw-font-medium tw-flex tw-mb-0 tw-font-Roboto [.active_&]:tw-text-accruent_sapphire [.active_&]:tw-text-shadow-navItemText [.active_&]:tw-opacity-[100%] [.active_&]:tw-cursor-pointer ">{name}{fieldShowPopup && <div className="inner-circle -tw-mt-[2px]"><span className="new-tag tw-ml-[10px] tw-text-accruent_white tw-bg-accruent_purple tw-px-2.5 tw-py-0.5 tw-text-[10px] tw-rounded-[8px] tw-font-Roboto !hover:tw-text-accruent_white">NEW</span></div>}</p>
            {active === name &&  
              <img src={angleRight} decoding="async" className="svg-inline--fa fa-angle-right arrow-icon tw-absolute tw-text-[14px] tw-text-[#001C71] tw-w-[8px] tw-right-[5px] tw-top-[10px] xl:tw-top-[11px]"/>
            // <svg class="svg-inline--fa fa-angle-right arrow-icon tw-absolute tw-text-[14px] tw-text-[#001C71] tw-w-2.5 tw-right-[5px] tw-top-[10px] xl:tw-top-[11px]" data-prefix="fas" data-icon="angle-right" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M278.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-160 160c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L210.7 256 73.4 118.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l160 160z"></path>
            // </svg>
            }
          </LocalizedLink>
        </div>
    )
  }
  const NavList = () => {
    return(
      <div className={`list-container tw-grid tw-grid-cols-[1fr_1fr] tw-gap-[35px] tw-pt-3`} style={{gridTemplateColumns: frForGrid}}>
        {splitChunks.map((chunk, index) => {
          return (
            <div key={index}>
              {chunk.map((item, innerIndex) => {
                return (
                  <TextParagraph name={item?.name} link={item?.link} fieldShowPopup={item?.fieldShowPopup} key={innerIndex}/>
                )
              })}
            </div>
          )
        })}

      </div>
    )
  }
  return(
    <div className="nav-list-container tw-pt-[12px] tw-pl-[32px] tw-pr-[32px] tw-w-full" onMouseLeave={() => resetState()}>
      <div className="eyebrow title-text tw-pb-[11px] tw-text-[#BBBBBB]">{parent}</div>
      <NavList />
      <hr className=" tw-mb-[20px] tw-mt-0 tw-bg-[#CCCCCC]" />
      <LocalizedLink to={parentLink} className="view-all tw-text-center tw-block tw-pb-[20px] tw-text-accruent_sapphire tw-font-bold tw-text-[14px] tw-font-Poppins tw-leading-[20px] tw-tracking-[0.03px]"  handleClick={() => { handleSetActiveClass(parent, parentLink) }} >
        {`View All ${parent} > `}
      </LocalizedLink>
    </div>
  )
}
export default NavWithList;