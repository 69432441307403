const getImageObject = require("./getImageObject")

const getTeaserCarouselData = (translatedEntity, translatedResourceItem) => {
  const overRideCheck = translatedEntity?.fieldOverrideItem
  const entityTeaserImage = getImageObject(translatedEntity?.relationships?.fieldTeaserImage,translatedEntity?.fieldTeaserImage)
  const itemTeaserImage = getImageObject(translatedResourceItem?.relationships?.fieldTeaserImage,translatedResourceItem?.fieldTeaserImage)
  const itemMediaImage = getImageObject(translatedResourceItem?.relationships?.fieldMediaImage,{},"fieldMediaImage");
  const itemHeroMediaImage = getImageObject(translatedResourceItem?.relationships?.fieldHeroMedia,{},"fieldHeroMedia")
  const entityTeaserLogo = getImageObject(translatedEntity?.relationships?.fieldTeaserLogo,translatedEntity?.fieldTeaserLogo)
  const itemTeaserLogo = getImageObject(translatedResourceItem?.relationships?.fieldTeaserLogo,translatedResourceItem?.fieldTeaserLogo)
  return{
    fieldBrief: overRideCheck ? translatedEntity?.fieldBrief : translatedResourceItem?.fieldBrief,
    fieldCustomerDesignation: overRideCheck ? translatedEntity?.fieldCustomerDesignation : translatedResourceItem?.fieldCustomerDesignation,
    fieldCustomerFeedback: overRideCheck ? translatedEntity?.fieldCustomerFeedback : translatedResourceItem?.fieldCustomerFeedback,
    fieldCustomerName: overRideCheck ? translatedEntity?.fieldCustomerName : translatedResourceItem?.fieldCustomerName,
    fieldOverrideItem: overRideCheck ? translatedEntity?.fieldOverrideItem : translatedResourceItem?.fieldOverrideItem,
    fieldTeaserImage: overRideCheck ? entityTeaserImage : (itemTeaserImage?.gatsbyImage?itemTeaserImage:'' || itemMediaImage?.gatsbyImage?itemMediaImage:'' || itemHeroMediaImage?.gatsbyImage?itemHeroMediaImage:''),
    fieldTeaserLogo: overRideCheck ? entityTeaserLogo : itemTeaserLogo,
    fieldTestimonialType: overRideCheck ? translatedEntity?.fieldTestimonialType : translatedResourceItem?.fieldTestimonialType
  }
}
export default getTeaserCarouselData