import React, {
  useMemo,
  useState,
  useRef,
  useEffect,
} from "react";
import cn from "classnames";
import { AccruentSlider, CustomPrevArrow, CustomNextArrow } from "src/components/commonComponents";
import createId from "src/components/helpers/createId.js";
import isURL from "src/components/helpers/isURL.js";
import useWindowSize from "src/components/helpers/useWindowSize";
import layout_vars from "src/layoutVariables.js";
import TextTruncate from "react-text-truncate";
import { isEmpty } from "src/utils";
import { StaticImage } from "gatsby-plugin-image";
import trimString from "../helpers/trimString";
import dateFormatter from "../helpers/dateFormatter";
import { AccruentImg, LocalizedLink } from "../commonComponents";
import "src/scss/components/_resourceCard.scss";
export const IndividualResourceCard = ({
  className = "",
  data,
  isCarousel,
  alt,
  aspectRatio,
  shouldNotResetFilterWhenClickingLink = false,
  type = null,
  tailwindClassName="",
  hoverCard=true
}) => {
  let retifiedIndustry = [];
  // const { resetFilter } = useContext(ResourcesContext);
  let dateFormatted;
  // If the industryNames exist and come as an array
  if (data.industry && typeof data.industry !== "string") {
    retifiedIndustry = [...data.industry];
  } else if (data.industry && typeof data.industry === "string") {
    // If it's an string, it will come as CSV
    let holder = data.industry.split(",");
    holder = holder.map((value) => value.trim().replace("&amp;", "&"));
    retifiedIndustry = [...holder];
  }

  // link-path and link-title
  let linkPath = data.fieldExternalLink?.url?.path ? data.fieldExternalLink?.url?.path : data.path
  let linkTitle = (data.fieldExternalLink?.title ? data.fieldExternalLink?.title : data?.overRideLinkText ? data?.overRideLinkText : 'Learn More') + ' ›'


  const [id] = useState(createId());
  const videoCardRef = useRef();
  useEffect(() => {
    const playIcon = document.querySelector(`.play-button-icon${id}`);
    if (!isEmpty(videoCardRef.current) && isEmpty(playIcon)) {
      const playButton = document.createElement("span");
      playButton.class = `play-button-icon${id}`;
      playButton.innerHTML = `<i className="fad fa-play-circle fa-3x has-text-grey-lighter" aria-hidden="true"></i>`;
      playButton.style.position = "absolute";
      playButton.style.top = "50%";
      playButton.style.left = "50%";
      playButton.style.transform = "translate(-50%,-50%)";
      playButton.style.color = "white";
      videoCardRef.current.append(playButton);
    }
  }, [id]);

  const gatsbyImageSizes =
    "(max-width: 320px) calc(100vw - 64px), (max-width: 375px) calc(100vw - 64px), (max-width: 425px) calc(100vw - 64px), (max-width: 568px) calc(100vw - 64px), (max-width: 929px) 415px, (max-width: 1215px) 285px, (max-width: 1407px) 350px, 415px";
  const resourceCardImageStyles = "tw-h-[164px] rounded-[48px_48px_0px_0px] tw-border-solid tw-border-b-[1px] tw-border-accruent_resource_image_border"
  const selectedImage = useMemo(() => {
    if (
      (data.content_type === "video" && data?.image?.gatsbyImage) ||
      (data.content_type === "Videos" && data?.image?.gatsbyImage)
    ) {
      // If card is a Video card and has sharp image
      return (
        <figure className="image has-position-relative" ref={videoCardRef}>
          <AccruentImg
            file={data.image}
            className={`video-resource-image${id} ${resourceCardImageStyles}`}
            width={738}
          />
        </figure>
      );
    } else if (data?.image?.gatsbyImage) {
      // if image is sharp
      return (
        <figure className="image">
          <AccruentImg file={data.image} className={resourceCardImageStyles} width={738} />
        </figure>
      );
    } else if (isURL(data.image)) {
      // if image is a url from drupal or any other url
      return (
        <figure className="image is-4by3">
          <img src={data.image} alt={alt} className={resourceCardImageStyles} loading="lazy" decoding = "async" />
        </figure>
      );
    } else if (data?.isStaticImage) {
      return (
        <>
          {data?.image}
        </>
      )

    } else {
      // no image, use default
      return (
        <figure className="image">
          <StaticImage
            src="../../../static/teaser_default.jpg"
            alt="Placeholder"
            aspectRatio={2.0}
            placeholder="blurred"
            layout="constrained"
            className={`${resourceCardImageStyles}`}
            width={738}
            decoding="async"
          />
        </figure>)
    }
  }, [data, id, alt, aspectRatio]);

  let label;
  if (data.label) {
    label = data.label;
    if (data.label === "Blog Entries") label = "Blog Posts";
    if (data.label === "PressReleases/News") label = "PressReleases";
  }
  // let contentTypeToDisplay = data.label
  //   ? `${label}`
  //   : data.content_type
  //   ? `${data.content_type}`
  //   : null;

  // const contentTypeLink =
  //   data.contentTypeLink ??
  //   (data.label ? rewordResourceLink(data.label) : "/resources");

  /**
   * This useEffect is used to remount the TextTruncate component for the description after the first mount.
   */
  const [descriptionShouldMount, setDescriptionShouldMount] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setDescriptionShouldMount(true);
    }, 0);
  }, []);
  if (type == 'no-card-wrapper') {
    return (
      <div className="tw-mb-8">
        {data.label && <div className="tw-eyebrow">{data.label}</div>}
        {data.title && (
          <h6 className="tw-text-mobileLgFont md:tw-text-lgFont">
            {data.title}
          </h6>
        )}
        {data.description && descriptionShouldMount && (
          <TextTruncate
            line={4}
            element="p"
            truncateText="…"
            text={data.description}
            containerClassName="tw-overflow-hidden tw-text-mdFont tw-mb-2 tw-text-accruent-base-color !tw-mb-2"
          />
        )}
        <LocalizedLink to={linkPath} alt="" className="tw-base_transition tw-block tw-text-mdFont tw-font-extrabold tw-leading-4 tw-text-accruent_sapphire hover:tw-underline hover:tw-text-accruent_sapphire">
          {linkTitle}
        </LocalizedLink>

      </div>
    )
  }

  const getDate = (fieldPublishDate) => {
    if (fieldPublishDate) {
      dateFormatted = dateFormatter(fieldPublishDate)
    }
    return dateFormatted
  }
  let showDateContent = ['press_release', 'company_news'];
  let checkForDate = false
  if (showDateContent?.includes(data?.content_type)) {
    checkForDate = true
  }

  const finalLabel = data?.content_type == "blog_entry" ? "Blog" : data?.content_type?.split('_')?.join( "  ");
  const tagOnImageSyles = " tw-absolute tw-top-[151px] tw-right-[16px] tw-bg-accruent_background_color tw-rounded-[6px] tw-tracking-[1.8px] tw-text-accruent_base_color tw-text-[12px] tw-font-bold tw-p-[4px_12px] tw-uppercase"
  return (
    <div
      className={`xl:tw-w-[32%] md:tw-w-[30%] ${tailwindClassName} tw-w-full`}
    >
      <LocalizedLink to={linkPath} className={"resource-card tw-bg-accruent_white tw-shadow-[3px_3px_20px_#7D7D7D29] tw-flex tw-flex-col tw-justify-between tw-h-full tw-relative tw-rounded-[48px_48px_48px_0px] tag-with-date " + `${hoverCard ? "tw-individual-resource-card-link" : ""}`} >
        <div className="card-image card-main-image">{selectedImage}</div>
        {/* {data?.fieldTags && checkForDate && (
          <p className="tag-on-image">{data?.fieldTags}</p>
        )} */}
        <div className="card-content !tw-p-[0_16px_27px_16px] tw-flex tw-flex-col tw-flex-1 tw-justify-between">
          <div>
            {data?.fieldPublishDate && checkForDate && (
              <p className="card-date tw-m-0 tw-text-accruent-base-color tw-text-[12px] tw-tracking-[1.8px] tw-pt-[16px] tw-mb-[-20px]">{getDate(data?.fieldPublishDate)}</p>
            )}
            
        {data.label &&  <p className={tagOnImageSyles}>{data?.label == "Blog Entry" ? "Blog" : data?.label }</p>}
        {!data.label && !!finalLabel &&  <p className={tagOnImageSyles}>{finalLabel}</p>}
             
            {data.logo ? (
              <>
                <div className="section resource-card-logo">{data.logo}</div>
                {data.title && (
                  <h6 className="has-text-centered !tw-m-0 tw-p-0 tw-pt-[28px] tw-text-mobileLgFont md:tw-text-lgFont">
                    {data.title}
                  </h6>
                )}
              </>
            ) : (
              data.title && (
                <h6 className="!tw-m-0 tw-p-0 tw-pt-[28px] tw-text-mobileLgFont md:tw-text-lgFont">
                  
                  {data.title.length < 80
                    ? data.title
                    : data.title.slice(0, 80) + "..."}
                </h6>
              )
            )}

            {data.description && descriptionShouldMount && (
              <div className="card-description tw-flex tw-flex-col tw-justify-between tw-pb-0 tw-mt-[16px]">
                <TextTruncate
                  line={4}
                  element="p"
                  truncateText="…"
                  text={data.description}
                  containerClassName="content block tw-text-[#707070] tw-text-[16px] tw-tracking-normal tw-mb-0 tw-font-Roboto"
                />
              </div>
            )}
          </div>
          <div className="card-link align-btn tw-text-accruent_sapphire tw-font-bold tw-mt-[23px] tw-text-[16px]">{linkTitle}</div>
        </div>
      </LocalizedLink>
    </div>
  );
};

const Resources = ({
  isCarousel = true,
  title,
  className = "",
  individualCardClassName = "",
  style = {},
  data,
  shouldNotResetFilterWhenClickingLink = false,
  sliderId,
  fieldPreHeading,
  fieldBody,
  fieldRelatedFormat,
  fieldCtaUrl,
  overViewPageData = null,
  tailwindClassName = ""
}) => {
  const { width } = useWindowSize();
  const { mobile, tablet, desktop } = layout_vars;
  const [slidersToShow, setSlidersToShow] = useState(
    width >= desktop ? 3 : width >= tablet ? 2 : 1
  );

  let seeAllButton = null
  let header = (
    <div className="header tw-w-[80%] tw-mb-[36px]">{title && <h2 className="tw-mt-[1.5rem] tw-text-left title-3">{title}</h2>}</div>
  )
  if (overViewPageData) {
    seeAllButton = (
      <button
        onClick={() =>
          overViewPageData.handleSetFilter({ ...overViewPageData.filters, content_type: overViewPageData.content_type })
        }
        className="button is-primary"
      >
        See All
        <span className="is-sr-only">{title}</span>
      </button>
    )
    header = (
      <div className="header mt-3 columns">
        {!!title && (
          <div className="title column">
            <h2 className="title-4 is-capitalized">{`${title}`}</h2>
          </div>
        )}
        {seeAllButton && (
          <div className="column">
            {seeAllButton}
          </div>
        )}
      </div>
    )
  }

  useEffect(() => {
    setSlidersToShow(width >= desktop ? 3 : width >= tablet ? 2 : 1);
  }, [width, mobile, tablet, desktop]);

  if (data.length === 0) return null


  if (fieldRelatedFormat === "align_left_resource_listing_right") {
    return (
      <div className={`resources-section ${fieldRelatedFormat}`}>
        <div className='tw-columns tw-items-center'>
          <div className='tw-column tw-w-full lg:tw-w-[41%]'>
            <div className='left-content'>
              <div className='eyebrow tw-mb-[7px]'>{fieldPreHeading}</div>
              <h3 className='title-3 tw-mb-[16px]'>{title}</h3>
              <p className="subtitle-2 tw-mb-[32px]">{fieldBody}</p>
              {fieldCtaUrl && <LocalizedLink className='landing-btn-class' to={fieldCtaUrl?.url}>
                <button className=" tw-p-[14px_24px_14px_24px] tw-bg-accruent_dark_imp_blue tw-rounded-[12px] tw-text-accruent_white tw-text-[16px] tw-font-bold tw-cursor-pointer">{fieldCtaUrl?.title}</button>
              </LocalizedLink>}
            </div>
          </div>
          <div className='tw-column tw-w-full lg:tw-w-[49%]'>
            {data.map((resourceData, index) => {
              return (
                <div className='right-content tw-mb-[24px] tw-flex tw-gap-[12px]' key={index}>
                  <div className='card-image tw-w-[35%]'>
                    <AccruentImg file={resourceData?.image} />
                  </div>
                  <div className='text-content tw-w-[65%] tw-relative'>
                    <h6 className='subtitle-2 tw-mb-[8px] !tw-text-[18px] !tw-font-bold !tw-font-Poppins'>{resourceData?.title}</h6>
                    <p className=" tw-mb-[14px]">{resourceData?.description && trimString(resourceData?.description, 200)}</p>
                    {resourceData?.path && <LocalizedLink to={resourceData?.path} className='learn-more tw-bg-accruent_dark_imp_blue tw-text-[16px] tw-font-bold tw-absolute tw-bottom-0'>Learn More </LocalizedLink>}
                  </div>
                </div>
              )
            })}

          </div>
        </div>
      </div>
    )
  }

  if (isCarousel && data.length > slidersToShow) {
    return (
      <div className="solutions-resources-wrapper resource-overview-cards">
        {!!title && (
          <h2
            className="title title-3 tw-text-center"
            id={sliderId}
          >
            {`${title}`}
          </h2>
        )}
        <AccruentSlider
          settings={{
            nextArrow: <CustomNextArrow userStyle={{ right: "-15px" }} />,
            prevArrow: <CustomPrevArrow userStyle={{ left: "-15px" }} />,
            slidesToShow: slidersToShow,
            slidesToScroll: slidersToShow,
          }}
          sliderId={sliderId}
        >
          {!isEmpty(data) &&
            data.map((item, i) => {
              if (item) {
                return (
                  <IndividualResourceCard
                    data={item}
                    key={`${i}-${item.title}`}
                    isCarousel
                    className={individualCardClassName}
                    tailwindClassName={tailwindClassName}
                  />
                );
              } else return null;
            })}
        </AccruentSlider>
      </div>
    );
  } else {
    return (
      <div className={`solutions-resources-wrapper resource-overview-cards ${className}`} style={style}>
        {header}
        {fieldBody && <p className="subtitle-2">{fieldBody}</p>}
        <div 
        className="tw-flex tw-flex-wrap tw-gap-5"
        >
          {!isEmpty(data) && Array.isArray(data) &&
            data.map((item, i) => {
              if (item) {
                return (
                  <IndividualResourceCard
                    data={item}
                    key={`${i}-${item.title}`}
                    shouldNotResetFilterWhenClickingLink={
                      shouldNotResetFilterWhenClickingLink
                    }
                    className={individualCardClassName}
                    tailwindClassName={tailwindClassName}
                  />
                );
              } else return null;
            })}
        </div>
      </div>
    );
  }
};

export default Resources;