import i18n from "i18next";

export default () => {
    i18n.init({
        debug: process.env.I18N_DEBUG,
        fallbackLng: "en",
        keySeparator: false, // we do not use keys in form messages.welcome
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {useSuspense: false},
        resources: {
            en: {
                translation: {}
            },
            es: {
                translation: {
                    "Home":"Inicio",
                    "Events": "Eventos",
                    "About Us": "Sobre Nosotros",
                    "Leadership": "Liderazgo",
                    "Press": "Prensa",
                    "Blog": "Blog",
                    "Partners": "Socios",
                    "Customer Success": "Éxito del cliente",
                    "Features": "Caracteristicas",
                    "Contact Us": "Contáctenos",
                    "Service & Support": "Servicio y Soporte",
                    "Company": "Empresa",
                    "Resources": "Recursos",
                }
            },
            de: {
                translation: {
                    "Home": "Starten",
                    "Events": "Ereignisse",
                }
            }
        },
    });

    return i18n;
};
