const splitBodyContent = (data, splitFromText) => {
  if(!data) {
    return 
  }
  else{
    let arrayConversionFromHtml = data.split('\n').filter(x => x!=="")
    let indexOfH2Tag = arrayConversionFromHtml.findIndex(x => x.includes("<h2"))
    let indexToSpilt = indexOfH2Tag > 0 ? indexOfH2Tag : 2
    if(!!arrayConversionFromHtml && arrayConversionFromHtml[2]?.includes("<li>") && indexOfH2Tag < 0 && (!arrayConversionFromHtml[2]?.includes("<ul>"))){
      if(arrayConversionFromHtml[0]?.includes("<p>")){
        indexToSpilt = 1
      }else{
        indexToSpilt = 0
      }
    }
    if(splitFromText){
      indexToSpilt = 1
    }
    let firstContentToString = arrayConversionFromHtml.slice(0, indexToSpilt).join('');
    let secondContentToString = arrayConversionFromHtml.slice(indexToSpilt).join('');
    return {firstContentToString, secondContentToString}
  }
}
export default splitBodyContent;