import React from "react";
import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import { AccruentImg } from "./accruentImg";
import { Video } from "./video";
import { LCPImage } from "./lcpImage";
import { LocalizedLink } from "./localizedLink";

const HeroImage = ({ image, className }) => {
  let imageUrl = "../../../static/placeholders/accruent_default-hero-placeholder.jpg";
  return !!image?.gatsbyImage ? (
    <AccruentImg file={image} className={className} />
  ) : (
    <StaticImage
      src={imageUrl}
      alt="Default Hero Image"
      className={className}
      aspectRatio={2.0}
      placeholder="blurred"
      layout="constrained"
      decoding = "async"
    />
  );
};

const HeroContent = (props) => {
  const {
    content,
    isHalfWidth,
    hasGradientBg,
    preHeading,
    preHeadingClass,
    heading,
    headingClass,
    subHeading,
    subHeadingClass,
    body,
    cta,
    secondaryCta,
  } = props;
  return (
    <div className="columns tw-columns hero-body tw-hero-body  is-gapless w-full h-full absolute p-0">
      <div
        className={classNames("column tw-h-full", {
          "hero-body-mask tw-hero-body-mask": hasGradientBg,
          "is-full": !isHalfWidth,
          "is-half-desktop": isHalfWidth,
        })}
      >
        {!!content ? (
          content
        ) : (
          <div
            className={classNames(
              "container section hero-body-content tw-hero-body-content has-text-centered",
              {
                "hero-body-half-content tw-hero-body-half-content  has-text-left-desktop": isHalfWidth,
              }
            )}
          >
            {!!preHeading && (
              <h2 className={classNames('subtitle has-text-white is-size-4-desktop', preHeadingClass)}>{preHeading}</h2>
            )}
            {!!heading && (
              <h1
                className={classNames(
                  "title has-text-white title-2",
                  headingClass
                )}
              >
                {heading}
              </h1>
            )}
            {!!subHeading && (
              <h3
                className={classNames(subHeadingClass, {
                  "subtitle has-text-white is-size-4-desktop": !subHeadingClass,
                })}
              >
                {subHeading}
              </h3>
            )}
            {!!body && (
              <div
                className="content is-size-5-desktop is-size-6-touch has-text-white dangerously-html-handle"
                dangerouslySetInnerHTML={{
                  __html: body.value,
                }}
              />
            )}
            <div className="is-flex-tablet">
              {!!cta && (
                <LocalizedLink
                  to={cta.url}
                  className="button is-white is-outlined is-medium mx-2 my-1"
                  isButton
                >
                  {cta.title}
                </LocalizedLink>
              )}
              {!!secondaryCta && (
                <LocalizedLink
                  to={secondaryCta.url}
                  className="button is-white is-outlined is-medium mx-2 my-1"
                  isButton
                >
                  {secondaryCta.title}
                </LocalizedLink>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const Hero = (props) => {
  const { image, videoUrl, heroVideo, hasSeparator, heading, isSmall, fieldHideHeroImage } = props;
  return (
    <section className="hero tw-hero tw-relative">
      <LCPImage/>
      {!!videoUrl ? (
        <Video videoUrl={videoUrl} />
      ) : (
        <React.Fragment>
            {!fieldHideHeroImage && <HeroImage
              image={image}
              className={classNames({
                "hero-image-fullheight tw-h-[calc(_100vh_-_128px_)]": !!heading,
                "hero-small tw-hero-small": isSmall,
                "service-hero-height tw-service-hero-height": !!heading
              })}
            />
            }
          {!videoUrl && <HeroContent {...props} />}
        </React.Fragment>
      )}
      {!!hasSeparator && <span className="hero-separator tw-hero-separator" />}
    </section>
  );
};

