import React, { useEffect, useState } from 'react';
import { Script } from "gatsby";
import loadable from '@loadable/component'
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import withI18next from "../../i18n/withI18next";
import pushDataLayerTags from "../../../helpers/pushDataLayerTags";
// Contexts
import ResourcesMainPageContext from "src/components/helpers/ResourcesMainPageContext";
import useResourceFilterPageContext from "../helpers/useResourceFilterPageContext";
import useMainPageContext from '../helpers/useMainPageContext';
import useAddParamInHtmlLink from "src/components/helpers/useAddParamInHtmlLink";
// import InternationalUsersNoticeModal from 'src/components/modals/InternationalUsersNoticeModal';
import { SEO , InternationalUsersNoticeModal } from '../commonComponents';
import { getCountryNameFromTimezone } from '../../../helpers/timezoneUtilFunctions'
import { getPathname } from '../../../helpers/locationUtilFunctions'
import findSiteType from "src/components/helpers/findSiteType";
import useGetAllUkPages from 'src/hooks/useGetAllUkPages';
import getCookie from '../../../helpers/getCookieParser';
import { LANDING_PAGE_ID } from "src/constants";
import { useLazyLoadOnScroll } from 'src/hooks/useLazyLoadOnScroll';
import { LocalizedLink } from '../commonComponents';
const AirtableComponent = loadable(() => import("src/components/commonComponents"), {resolveComponent: (components) => components.AirtableComponent});
const Footer = loadable(() => import('src/components/Layout/footer/Footer'))
import NavbarVersion2 from 'src/components/navigation/NavbarVersion2';
import NavWithCta from 'src/components/navigation/NavWithCta';
import "../../styles/_resources.css";
const FooterWithCopywrite = loadable(() => import('src/components/Layout/footer/FooterWithCopywrite'))
// const Optimizely = loadable(() => import("src/components/externalScripts/Optimizely"))
import "../../styles/appGlobal.css";

const isMc = findSiteType('mc');
const isEms = findSiteType('ems');
const siteClass = isMc ? "main-site-content-mc" : isEms ? "main-site-content-ems" : "main-site-content-accruent"
import accruentInsights from "../../../static/staticPages/accruent-insights.png";
import crossImg from "src/images/new-version-homepage/circle-xmark-sharp-solid.png"
const Layout = ({ children, location, pageContext }) => {
  const { i18n } = useTranslation();
  const { locale, entityId } = pageContext;
  const [checkForLandingPage, setLandingPage] = useState((pageContext?.type?.id === LANDING_PAGE_ID));
  const [intPopupData, setIntPopupData] = useState({})
  const [cookies, setCookies] = useState();
  const [utmCookiesCreated, setUtmCookiesCreated] = useState(false);
  const [banner, setBanner] = useState(true);
  const [insightsBanner, setInsightsBanner] = useState(true);
  const currentPathname = getPathname();
  const {siteUKPaths} =  useGetAllUkPages();
  const isMc = findSiteType('mc')
  const isEms = findSiteType('ems')
  const shouldLoad = useLazyLoadOnScroll();
  // const contactUsPage = location.pathname == '/contactus'
  useAddParamInHtmlLink()
  const utmArray = ["utm_source","utm_medium","utm_campaign","utm_term","utm_content","utm_id","gclid","msclkid"]
  useEffect(() => {
    pushDataLayerTags(pageContext);
    if(pageContext?.type?.id && pageContext?.type?.id === LANDING_PAGE_ID){
      setLandingPage(true);
    } else {
      setLandingPage(false);
    }
    // if (location?.pathname !== '/' && location.pathname !== "/events/accruent-insights" && location.pathname !== "/new-homepage") {
    //   setInsightsBanner(true)
    // } else {
    //   setInsightsBanner(false)
    // }
    setCookies(getCookie())
    setBanner(true);
  }, [location, pageContext]);

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, [location, i18n, locale]);

  useEffect(() => {
    // Check if One Trust Cookie consent is validated by user
    window.addEventListener('otBannerDismissed', () => {
      if (!utmCookiesCreated) {
        setUtmCookiesCreated(true)
      }
    });
    //Added error handling in case cookieStore is not defined.
    try {
      cookieStore.addEventListener('change', ({ changed }) => {
        for (const { name, value } of changed) {
          if (utmArray?.includes(name)) {
            setCookies(getCookie())
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  }, [])
  useEffect(() => {
    let isUserUsa = '',ukPathExist = '', ukPath = '';
    if (!currentPathname.startsWith("/uk") && Intl && (!(window.localStorage.getItem("International-popup")) && !isEms && !isMc && window?.innerWidth > 800)) {
      isUserUsa = getCountryNameFromTimezone(Intl.DateTimeFormat().resolvedOptions().timeZone)
      if(!isUserUsa){
        if (currentPathname == "/" || currentPathname == "/homepage" || currentPathname == "/home-industry") {
          ukPathExist = true
          setIntPopupData({
            ukPathExist: ukPathExist,
            isUserUsa: isUserUsa
          })
        } else {
          ukPath = siteUKPaths?.allSitePage?.nodes?.find(node => node.path == `/uk${currentPathname}`)
          ukPathExist = ukPath ? true : false
          setIntPopupData({
            ukPath: ukPath,
            ukPathExist: ukPathExist,
            isUserUsa: isUserUsa
          })
        }
      }
      window.localStorage.setItem("International-popup", true);
    }
  },[])
  let initialResourceMainPageState, filterObj;
  initialResourceMainPageState = useMainPageContext(locale)
  filterObj = useResourceFilterPageContext({ locale })
  const isSSR = {isSSR: typeof window === "undefined"}
  const pageTypeContext ={ pageTypeContext: pageContext?.type?.id}
  const languageCode = {languageCode: locale}
  const fetchedCookies = cookies? {fetchedCookies: cookies}: "";

  initialResourceMainPageState={...initialResourceMainPageState, ...filterObj, ...isSSR, ...pageTypeContext, ...languageCode, ...fetchedCookies, utmCookiesCreated}
  const footerProps = {
    location: location,
    pageType: pageContext?.type?.id,
    locale: locale
  }
  const navbarProps = {
    pageType: pageContext?.type?.id,
    landingHeaderCta: pageContext?.fieldCtaUrl
  }

  const bodyContent = (footerProps) => {
    return (
      <>
        <main
          id="main-site-content"
          className={`main-site-content tw-relative tw-min-h-screen ${siteClass}`}
          role="main"
        >
          {children}
        </main>
        {checkForLandingPage ? (
          <FooterWithCopywrite
            footerProps={footerProps}
            key={checkForLandingPage}
          />
        ) : (
          <Footer
            footerProps={footerProps}
            key={checkForLandingPage}
          />
        )}
      </>
    );
  }

  const Banner = () => {
    return (
      <>
        {banner && <div className="tw-bg-[#485FC7] tw-flex-col md:tw-flex-row tw-flex md:tw-justify-center tw-items-start md:tw-items-center tw-gap-[16px] tw-p-[20px] md:tw-p-0">
          <p className="tw-mt-[18px] tw-text-[18px] tw-text-[#FFFFFF] tw-leading-[26px] tw-tracking-[0px] tw-font-Roboto">Got a minute? Check out our new website homepage.</p>
          <div className='tw-flex  md:tw-flex-row tw-items-center'>
            <LocalizedLink to="/new-homepage" className=" tw-flex tw-border-solid tw-border-[2px] tw-border-[#FFFFFF] tw-rounded-[40px]"><span className=" tw-p-[6px_24px] tw-text-[#FFFFFF] tw-text-[16px] tw-leading-[16px] tw-tracking-[0.03px]">Provide Feedback</span></LocalizedLink>
          </div>
        </div>}
      </>
    )
  }

  const InsightsBanner = () => {
    return(
      <>
        {insightsBanner && <div style={{ background: 'transparent linear-gradient(270deg, #F32735 0%, #4F2B83 100%) 0% 0% no-repeat padding-box' }} >
          <div className=" md:tw-px-[50px] tw-flex-col md:tw-flex-row tw-flex md:tw-justify-between tw-items-start md:tw-items-center tw-gap-[16px] tw-p-[20px] md:tw-p-0">
            <img src={accruentInsights} className='tw-w-[120px]' />
            <div className='md:tw-flex tw-gap-[10px]'>
              <p className="tw-mt-[18px] tw-text-[18px] tw-text-[#FFFFFF] tw-leading-[26px] tw-tracking-[0px] tw-font-Roboto">Join us at Insights 2024 -Where visionary ideas meet actionable strategies</p>
              <div className='tw-flex  md:tw-flex-row tw-items-center tw-w-full md:tw-w-auto tw-justify-between'>
                <LocalizedLink to="https://www.accruent-insights.com/event/cae93bef-65b7-40af-abcd-32f35d8e4df9/summary" className=" tw-flex tw-border-solid tw-border-[2px] tw-border-[#FFFFFF] tw-rounded-[40px]"><span className=" tw-p-[6px_24px] tw-text-[#FFFFFF] tw-font-Poppins tw-font-bold tw-text-[16px] tw-leading-[16px] tw-tracking-[0.03px]">Register Now</span></LocalizedLink>
                <img src={crossImg} className=' tw-block md:tw-hidden tw-w-[25px] tw-cursor-pointer' onClick={() => setInsightsBanner(false)}></img>
              </div>
            </div>
            <img src={crossImg} className=' tw-hidden md:tw-block tw-w-[25px] tw-cursor-pointer' onClick={() => setInsightsBanner(false)}></img>
          </div>
        </div>}
      </>
    )
  }

  const pageWithoutForm = ["/","/uk", "/about-us/careers"]
  const marketowithoutLazy = ["white_paper", "webinar", "ebook"]
  const marketoLazy = ["resourcePage", "productPage", "solutionPage", "industryPage"]
  const marketoOnSroll = marketoLazy?.includes(pageContext?.page_type) && !marketowithoutLazy?.includes(pageContext?.content_type);
  const isThereForm = !(pageContext?.content_type == "knowledge_hub_")?true:pageContext?.relationships?.fieldContentSections?.find(cs => (cs?.relationships?.fieldProxy?.type.id == 'marketo_forms'))
  let conditionToRenderMarketo =  !(pageWithoutForm.includes(pageContext?.entityUrl?.path || location?.pathname)) && isThereForm && !marketoOnSroll
  const pageWithoutFontAwsome = ["/","/uk"]
  const staticProductSolutionPageUrls = ["/products/ems", "/products/maintenance-connection", "/solutions/desk-room-booking-software", "/solutions/space-planning-software", "/solutions/engineering-document-management-software", "/solutions/cmms-software", "/products/lucernex", "/products/meridian"]
  const pageUrl = (typeof window !== 'undefined') ? window.location.pathname : '';
  const isStaticProductSolutionPage = staticProductSolutionPageUrls.includes(pageUrl)
  console.log("isStaticProductSolutionPage", isStaticProductSolutionPage)
  let conditionToRenderFontAwsome =  !(pageWithoutFontAwsome.includes(pageContext?.entityUrl?.path || location?.pathname))
  let renderVitals = process.env.GATSBY_WEB_VITALS_TRACKER
  return (
    <React.Fragment>
      {/* <Script id="first-unique-id" dangerouslySetInnerHTML={{
        __html: `(function(c,h,a,f,i,l,e){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
c[a].a=i;c[a].l=l;c[a].e=e;var g=h.createElement("script");g.async=true;g.type="text/javascript";
g.src=f+'?aid='+i;var b=h.getElementsByTagName("script")[0];b.parentNode.insertBefore(g,b);
})(window,document,"rtp","//sjrtp6-cdn.marketo.com/rtp-api/v1/rtp.js","accruent");
rtp('send','view');
rtp('get', 'campaign',true);` }} /> */}
      {conditionToRenderMarketo && <Script id={Math.random()} src={`//info.accruent.com/js/forms2/js/forms2.min.js`} />}
      {marketoOnSroll && shouldLoad && <Script id={Math.random()} src={`//info.accruent.com/js/forms2/js/forms2.min.js`} />}
      {conditionToRenderFontAwsome && <Script defer src={`https://kit.fontawesome.com/${process.env.GATSBY_FONTAWESOME_KIT_ID}.js`} crossOrigin="anonymous"/> }
      {isStaticProductSolutionPage && <Script src={`https://fast.wistia.com/assets/external/E-v1.js`} />}
      {/* <Optimizely key={pageContext?.entityUrl?.path || location?.pathname} page={pageContext?.entityUrl?.path || location?.pathname}/> */}
      <Helmet>
        {!isNaN(entityId) && <meta name="entityId" content={entityId} />}
      </Helmet>
      <SEO lang={locale} />
      {renderVitals && <AirtableComponent page={pageContext?.entityUrl?.path || location?.pathname} isEms={isEms} isMc={isMc}/>}
      {(!(intPopupData?.isUserUsa) && intPopupData?.ukPathExist) && <InternationalUsersNoticeModal ukPagePath={intPopupData?.ukPath}/>}
      <ResourcesMainPageContext.Provider value={initialResourceMainPageState}>
        {checkForLandingPage ?
          <NavWithCta locale={locale} navbarProps={navbarProps} key={checkForLandingPage} />
          :
          <div>
            {<div className='navbar-version-2' id='navbar-version-2'>
              {/* {location?.pathname === '/' && !isEms && !isMc && <Banner />} */}
              {!isEms && !isMc && <InsightsBanner />}
              <NavbarVersion2 locale={locale} navbarProps={navbarProps} key={checkForLandingPage}/>
            </div>} 
          </div>
        }
        {bodyContent(footerProps)}
      </ResourcesMainPageContext.Provider>
    </React.Fragment>
  );
};

export default withI18next()(Layout);
