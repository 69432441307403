import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "@reach/router";
import ResourcesContext from "src/components/helpers/ResourcesMainPageContext";
// import Alertbar from "../Layout/Alertbar";
import NavbarMobile from "src/components/navigation/NavbarMobile";
import useToggleNavbarActiveState from "src/components/helpers/useToggleNavbarActiveState";
import NavbarSearch from "src/components/Search/NavbarSearch";
import findSiteType from "src/components/helpers/findSiteType";
import useMountingForIcon from "src/hooks/useMountingForIcon";
import { WatchDemoModal } from "../commonComponents";
import * as containerStyles from "./_navbar.module.scss";
import { TopNavbar } from "./topNavbar";
import { NavbarStartMenu } from "./navbarStartMenu";
import { NavbarEndMenu } from "./navbarEndMenu";
import { NavbarBrand } from "./navbarBrand";
import { LocalizedLink } from "../commonComponents/localizedLink";
const NavbarVersion2 = ({ locale = "en", navbarProps }) => {
  const {
    navData,
    eyebrowNavData,
    setFilter,
    filters,
  } = useContext(ResourcesContext);
  const location = useLocation();
  const { t } = useTranslation();
  const [isActive, setIsActive] = useState(false);
  const [currentNavItem, setCurrentNavItem] = useState(null);
  const [navigationLeft, setNavigationLeft] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [activeClass, setActiveClass] = useState({ menu: "" });
  const [activeClassDropdown, setActiveClassDropdown] = useState({ menu: "" });
  const [openDemoModal, setOpenDemoModal] = useState(false);
  const [refresh, setRefresh] = useState(1);
  let scrollListener = null;
  const [scrollState, setScrollState] = useState("top");
  const changeScroll = "scroll";
  const isMc = findSiteType("mc");
  const isEms = findSiteType("ems");
  const mounted = useMountingForIcon();

  useEffect(() => {
    if (window.scrollY !== 0) {
      setScrollState("scrolling");
      document
        .getElementsByClassName("wht-btn")?.[0]
        ?.classList.add(changeScroll);
    }
  }, []);

  useEffect(() => {
    scrollListener = document.addEventListener("scroll", (e) => {
      var scrolled = document.scrollingElement.scrollTop;
      if (scrolled >= 90) {
        if (scrollState !== "scrolling") {
          setScrollState("scrolling");
          document
            .getElementsByClassName("wht-btn")?.[0]
            ?.classList?.add(changeScroll);
        }
      } else {
        if (scrollState !== "top") {
          setScrollState("top");
          document
            .getElementsByClassName("wht-btn")?.[0]
            ?.classList?.remove(changeScroll);
        }
      }
    });
    return () => {
      document.removeEventListener("scroll", scrollListener);
      setActiveClass({ Products: "" });
    };
  }, [scrollState]);

  useEffect(() => {
    setShowSearch(false);
    setIsActive(false);
  }, [location]);

  if (typeof window !== "undefined") {
    window.addEventListener("scroll", function (e) {
      setShowSearch(false);
    });
  }

  const innerCleanFilterWhenClickingResources = (link,name) => {
    if (link === "Resources" || ((link === "/resources" || link === "/uk/resources") && filters)) {
      const filterKeys = Object.keys(filters);
      const resettedFilters = {};
      filterKeys.forEach((key) => {
        resettedFilters[key] = "";
      });
      setFilter({ ...resettedFilters });
    }
  };

  //Below we are creating the arrays that will hold the left navigation (navbarStart) and the right navigation (navbarEnd)
  //to match Bulma styling. Following the mockup, the logic below is to grab the first five values and add them to the navbarStart
  //and grab the last three values and add them to navbarEnd.
  let navLength = navData?.length;
  let navbarStart =
    isEms || isMc
      ? navData?.slice(0, navLength - 2)
      : navData?.slice(0, navLength - 3);
  let navbarEnd = isEms || isMc ? navData?.slice(-2) : navData?.slice(-3);
  //Because the is-hoverable class produces the undesirable display of the mega menu when clicking to navigate to a top level navbar-item,
  //we are removing the is-hoverable class if the current pagelocation is equal to the navbar-item in which the mouse is hovering over. Additionally,
  //we are adding a class 'add-hoverable-here' to indicate which navbar-item div then needs the is-hoverable class added to once the onMouseLeave event
  //happens. The function below checks for the correct page and the proper navbar-item div then adds the class is-hoverable.
  //TODO: optional/nice to have - remove the add-hoverable-here class after it serves it purpose.
  const addHoverableClass = (link, pageLocation) => {
    const divMissingHoverableClass = document.getElementsByClassName(
      "add-hoverable-here"
    )[0];
    if (pageLocation === link && divMissingHoverableClass) {
      divMissingHoverableClass.classList.add("is-hoverable");
    }
    setActiveClass({ Products: "" });
    setActiveClassDropdown({ Products: "" });
  };

  const addActiveClass = (menuName) => {
    setActiveClass({ [menuName]: "menu-is-active" });
    setActiveClassDropdown({ [menuName]: "" });
  };

  const removeActiveClass = (menuName) => {
    setActiveClass({ [menuName]: "" });
    setActiveClassDropdown({ [menuName]: "menu-is-inactive" });
  };

  const navbarId = "main-mobile-nav-dropdown-version-2";
  const burgerId = "main-navbar-burger-version-2";
  //useToggleNavbarActiveState(burgerId, true, document.getElementById(navbarId));
  const handleWatchDemoToggle = () => {
    setOpenDemoModal(!openDemoModal);
  };

  const toggleIsActive = () => {
    setIsActive(!isActive);
    document.documentElement.classList.toggle("has-mobile-nav-open");
  }

  return (
    <div className={containerStyles.navbarContainer}>
    <header className={`navigation navigation-version-2 ${isEms?"ems-nav":isMc?"mc-nav":""}`}>
      {openDemoModal && (
        <WatchDemoModal handleToggle={handleWatchDemoToggle} locale={locale} />
      )}
      <nav
        className={`navbar is-hidden-touch upper-nav
        ${scrollState === "top" ? "" : "scroll"}
        `}
        role="navigation"
        aria-label="secondary navigation"
      >
        <TopNavbar
          eyebrowNavData={eyebrowNavData}
          translation={t}
          isAccruent={!(isMc || isEms)}
          locale={locale}
        />
      </nav>
      <nav
        className={`navbar lower-nav ${
          scrollState === "top" ? "top" : "scroll"
        }`}
        role="navigation"
        aria-label="main navigation"
      >
        <div className="container is-fullhd has-position-initial navbar-container">
          <NavbarBrand
            isNavbarEnd={!!navbarEnd}
            mounted={mounted}
            showSearch={showSearch}
            setShowSearch={setShowSearch}
            locale={locale}
            isMc={isMc}
            isEms={isEms}
            scrollState={scrollState}
            burgerId={burgerId}
            navbarId={navbarId}
            {...{toggleIsActive}}
            {...{isActive}}
          />
          {showSearch && (
            <div className="navbar-mobile-search is-hidden-desktop">
              <NavbarSearch locale={locale} />
            </div>
          )}

          <div className="navbar-menu">
            <NavbarStartMenu
              menus={navbarStart}
              activeClass={activeClass}
              addActiveClass={addActiveClass}
              removeActiveClass={removeActiveClass}
              addHoverableClass={addHoverableClass}
              setActiveClassDropdown={setActiveClassDropdown}
              activeClassDropdown={activeClassDropdown}
              setActiveClass={setActiveClass}
              translation={t}
              location={location}
              innerCleanFilterWhenClickingResources={
                innerCleanFilterWhenClickingResources
              }
              isMc={isMc}
              isEms={isEms}
              key={refresh}
              refresh={refresh}
              setRefresh={setRefresh}
              locale={locale}
            />
            {!!navbarEnd && (
              //There are three items in the navbar end section of the navbar; search, contact us, watch a demo.
              //Drupal is sending us these nav items in the correct order therefore we are using the index of the items
              //in the ternary conditional to style them individually. To make adjustment please be aware that the order
              //of the buttons [search,contact us, watch demo]
              <NavbarEndMenu
                menus={navbarEnd}
                setShowSearch={setShowSearch}
                showSearch={showSearch}
                mounted={mounted}
                setOpenDemoModal={setOpenDemoModal}
                openDemoModal={openDemoModal}
                locale={locale}
                isMc={isMc}
                isEms={isEms}
              />
            )}
          </div>
          <div id={navbarId} className={`navbar-menu is-hidden-desktop ${isActive? 'is-active': ''}`}>
            <ResourcesContext.Consumer>
              {({ navData }) => (
                <NavbarMobile
                  currentNavItem={
                    currentNavItem ||
                    navData?.filter(
                      ({ name }) =>
                        name !== "Search" &&
                        name !== "Request Info" &&
                        name !== "Request Demo" &&
                        name !== "Get Started" &&
                        name !== "Watch Demo" &&
                        name !== "Free Trial" &&
                        name !== "Contact Us"
                    )
                  }
                  navCtaButtons={navData?.filter(
                    ({ name }) =>
                      name === "Request Info" ||
                      name === "Request Demo" ||
                      name === "Watch Demo" ||
                      name === "Free Trial" ||
                      name === "Get Started" ||
                      name === "Contact Us"
                  )}
                  setCurrentNavItem={setCurrentNavItem}
                  navigationLeft={navigationLeft}
                  setNavigationLeft={setNavigationLeft}
                  handleWatchDemoToggle={handleWatchDemoToggle}
                  locale={locale}
                  innerCleanFilterWhenClickingResources={
                    innerCleanFilterWhenClickingResources
                  }
                  navbarId={navbarId}
                />
              )}
            </ResourcesContext.Consumer>
          </div>
          {/* For static contactus page */}
            {/* <LocalizedLink to={"/"} className="static-contactus-btn tw-hidden tw-inline-block tw-text-[16px] tw-leading-4 tw-text-[#001C71] tw-cursor-pointer tw-font-Poppins tw-font-bold"><span className=" lg:tw-hidden tw-mr-[39px]">{"< Back"}</span></LocalizedLink> */}
        </div>
      </nav>
    </header>
    </div>
  );
};

export default NavbarVersion2;
