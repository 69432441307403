import React from 'react';
import "src/scss/components/_bannerTiles.scss";

/**
 * @param {Array of Objects} data - list of Banner Tiles: [{title: String,fieldBannerTilesItem: [Object] ...}]
 */

export const BannerTiles = ({ data }) => {
    const { title, fieldHideTitle } = data
    const fieldBannerTilesItem = data?.relationships?.fieldBannerTilesItem
    return (
        <div className='full-width-bg-cover banner-tiles-container tw-banner-tiles-container'>
            {!fieldHideTitle && <h2 class="title title-3">{title}</h2>}
            <div className='is-flex banner-title-item-Container tw-w-full tw-min-h-[136px] tw-mt-[12px] tw-cursor-default tw-flex-col md:tw-flex-row'>
                {fieldBannerTilesItem?.map((entity) => {
                    const bgImg = entity?.relationships?.fieldBackgroundImage
                    const txtColor = entity?.fieldTextColor ? entity?.fieldTextColor : '#ffffff'

                    return (
                        <div key={entity?.nid} className="is-relative  banner-title-item tw-flex-1" >
                            {bgImg && <div className='banner-card-image tw-absolute tw-w-full tw-h-full'>
                                <img
                                    alt={bgImg?.fieldImage?.alt}
                                    src={bgImg?.relationships?.fieldImage?.url}
                                    decoding = "async"
                                    className=' tw-w-full tw-h-full tw-object-cover'
                                />
                            </div>}
                            <div style={{ color: txtColor }} className='is-flex is-flex-direction-column is-justify-content-center is-align-content-center has-text-centered banner-content tw-p-[16px] md:tw-p-[30px_5%] tw-h-full '>
                                <i className={`${entity?.fieldIcon?.style} fa-${entity?.fieldIcon?.iconName} banner-title-icon tw-text-[40px] tw-leading-[46px] tw-tracking-[0.36px] tw-z-[5]`} ></i>
                                <div className='banner-title tw-text-[18px] tw-leading-[20.88px] tw-tracking-[0.16px] md:tw-text-[20px] md:tw-leading-[23.2px] tw-mt-[8px] tw-z-[5] tw-font-Poppins tw-font-bold '>{entity?.title}</div>
                            </div>
                        </div>

                    )
                })}
            </div>
        </div>
    )
}

// export default BannerTiles;
