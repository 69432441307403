import React from "react"
import { Loader } from "src/components/commonComponents"

export const CommonFormHeader = ({formTag,fieldFormHeader, isFormLoaded}) => {

  return (
    <div className="form-header">
    {fieldFormHeader && isFormLoaded && (
      <h5 className="tw-pt-9 tw-pl-[9px] md:tw-pl-0 tw-pb-[5px] tw-text-lg md:tw-text-lgFont tw-text-accruent_heading_color tw-font-Poppins tw-font-bold"> {fieldFormHeader}</h5>
      )}
        {!isFormLoaded && 
          <div>
           <Loader/>
          </div>
        }
        <div className={`${!isFormLoaded ? "unset-display":"set-display"}`}>
          {formTag}
        </div>
    </div>
  )
}

// export default CommonFormHeader