import React, {useContext} from "react";
import { LocalizedLink } from "src/components/commonComponents";
import ResourcesContext from "src/components/helpers/ResourcesMainPageContext";

export const ResourceTagsList = ({ ResourceTags, noUrl=false }) => {
  const { languageCode } = useContext(ResourcesContext);
  return (
    <div className="tw-min-h-[65px] md:tw-min-h-[inherit] tw-hidden">
      {ResourceTags.map((obj, index) => {
        if(obj.label)
        return (
          <span className="tw-h-[22px] tw-text-blue_tag tw-bg-accruent_gray tw-text-tab-hover-color tw-inline-block tw-text-xsFont tw-leading-3 tw-mb-3 tw-mr-3 tw-px-2 tw-py-1 tw-font-Helvetica md:tw-font-Roboto lg:tw-font-Roboto" key={index}>
            <LocalizedLink className="tw-base_transition tw-inline-block tw-h-4 tw-text-blue_tag tw-font-Helvetica md:tw-font-Roboto lg:tw-font-Roboto"
             to={noUrl ? "#": languageCode && languageCode === "en-gb" ? `/uk/resources?${obj.type}=${obj.id}`:`/resources?${obj.type}=${obj.id}`} >
              {`${obj.label}`}
            </LocalizedLink>
          </span>
        );
      })}
    </div>
  );
}

// export default ResourceTagsList;
