import React from "react";
import { Picture } from "../picture";
import { PopupVideo } from "../popupVideo";
export const HeroImageContainer = ({ heroVideo, backgroundNewImage, foreGroundImage, heroGif, heroImageAttrs, heroImage, heroGifImage, tailwindStandClassNames="" }) => {
  const isGifImage = !!heroGifImage?.url // coming from Gif field of drupal
   const isImageWithVideo = (heroVideo && !heroImageAttrs?.fallback?.src )? false : true
   const onlyHeroImageStyle = "tw-min-h-[199px] tw-inline-block md:tw-min-h-full md:tw-block"
   const heroVideoStyle = "tw-bottom-0 tw-top-0 tw-absolute tw-right-0 tw-left-0 tw-p-[3px_16px] md:tw-p-[3px_25px] tw-block tw-m-auto tw-max-h-[288px] tw-max-w-[513px]"
   const foregroundImageStyle = 'tw-w-full tw-m-[23px_0px_23px_0px] md:tw-m-[12px_0px_12px_0px] tw-object-fill tw-rounded-[24px] tw-h-auto';
  return (
    <div className={`${isImageWithVideo?'video-height tw-min-h-[205px] md:tw-min-h-full':''}`}>
      {/* Background Image in case of no video */}
      {!heroVideo && backgroundNewImage?.gatsbyImage && <div className="background-with-foreground tw-min-h-[199px] md:tw-min-h-full tw-relative">
        <Picture image={{ ...backgroundNewImage }} className="hero-background-image tw-w-full" />
        {(foreGroundImage || heroGif) && <div>{foreGroundImage?.gatsbyImage? 
          <Picture
            image={{ ...foreGroundImage }}
            parentClassName={'foreground-img-container'}
            className={`foreground-img ${foregroundImageStyle} tw-absolute tw-top-[10px] tw-margin-[0px]`}
            alt={backgroundNewImage?.alt}
          />
          :
          <img src={heroGif} width={"auto"} height={"332"} alt={heroImage?.alt} className={`${foregroundImageStyle} foreground-img`} />
        }</div>}
      </div>}

      {/* Image in case of Video */}
      {heroVideo && !!heroImageAttrs?.fallback?.src &&
        <picture>
          {heroImageAttrs?.sources?.map(src => <source {...src} />)}
          <img
            {...heroImageAttrs?.fallback}
            alt={heroImage?.alt}
            loading="eager"
            fetchpriority="high"
            className={'hero-image img-with-video tw-w-full tw-relative tw-invisible md:tw-visible'}
            height={332}
          />
        </picture>
      }

      {/* Gif image in case of No video and No background Image */}
      {((heroGif && !heroImageAttrs?.fallback?.src) || isGifImage) && !heroVideo &&
        <img src={isGifImage ? heroGifImage?.url : heroGif}  width={"auto"} height={"auto"}alt={isGifImage ? heroGifImage?.alt : heroImage?.alt} className={`${foregroundImageStyle} foreground-img`} />
      }


     {/* In case of only Hero Image present */}
     {!isGifImage && !heroVideo && !backgroundNewImage?.gatsbyImage && heroImageAttrs?.fallback?.src &&
        <picture
        className={`only-hero-image-picture
         ${onlyHeroImageStyle}`}
        >
          {heroImageAttrs?.sources?.map(src => <source {...src} />)}
          <img
            {...heroImageAttrs?.fallback}
            alt={heroImage?.alt}
            loading="eager"
            fetchpriority="high"
            className={'hero-image tw-relative tw-w-full'}
            height={332}
          />
        </picture>
      }

      {(!backgroundNewImage?.gatsbyImage && heroVideo && !heroImageAttrs?.fallback?.src) && <div className="mobile-no-image-video"></div>}
      {heroVideo && <div className={!heroImageAttrs?.fallback?.src ? "no-image-video md:tw-min-h-[288px]" : ""}>

        <PopupVideo videoUrl={heroVideo?.fieldMediaVideoEmbedField} thumbnail={heroVideo?.relationships?.thumbnail.url} thumbnailClass={`!tw-p-0 is-full-tablet hero-video ${heroVideoStyle} ${tailwindStandClassNames}`} 
        thumbnailImage = {heroVideo?.relationships?.thumbnail} alt={heroVideo?.thumbnail?.alt} isCatergoryHero={true}
        />

      </div>}
    </div>
  )
}