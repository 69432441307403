import React, { useEffect,  useState, useRef } from "react";
import getImageObject from "src/../helpers/getImageObject";
import '../../scss/_new_demo.scss'
import { useLocation }  from '@reach/router';
import { StoryLane } from "./storyLane";
import { LocalizedLink } from "./localizedLink";
import { Picture } from "./picture";
import { isElementInViewport } from 'src/utils';
import { useLazyLoadOnScroll } from "src/hooks/useLazyLoadOnScroll";
import extractMarketoValues from "src/components/helpers/extractMarketoIdAndSerial";
import { fieldEmbedCode } from "src/rawData/demo";
import loadable from '@loadable/component';
const DemoForm = loadable(() => import('src/components/solutions/DemoForm'))
export const BenefitsWithTabs = ({benefit}) => {
  const [benfitsItem, setBenefitItem] = useState(benefit?.relationships?.fieldBenefitItem?.[0])
  const [tabs, setTabs] = useState("")
  const [activeTab, setActiveTab] = useState(benefit?.relationships?.fieldBenefitItem?.[0]?.entity?.title?.toLowerCase())
  const [storyLaneVisible, setStoryLaneVisible] = useState(false);
  const location = useLocation();
  let storyLaneRef = useRef(null);
  const shouldLoad = useLazyLoadOnScroll()
  const { id, serial, gaLabel } = extractMarketoValues(
     fieldEmbedCode
  );
  useEffect(() => {
    if(location?.hash){
      let setTab = location.hash.slice(1).replaceAll("-"," ")?.toLowerCase()
      let findTab = benefit?.relationships?.fieldBenefitItem?.find(x => x?.entity?.title?.toLowerCase() === setTab)
      if(findTab){
        setActiveTab(setTab)
        setBenefitItem(findTab)  
      }
    }
  },[location.hash])
  useEffect(() => {
    let tabsArr = []
    benefit?.relationships?.fieldBenefitItem?.forEach((item, index) => {
      tabsArr.push(item?.entity?.title)
    })
    setTabs(tabsArr)
  },[])
  const {body: footerBody, fieldCtaUrl: footerCta} = benefit || ""
  const {title: footerCtaTitle, url: footerCtaUrl} = footerCta || ""
  const {body, fieldShortDesc, fieldLinkToOriginalArticle, fieldIframeUrl, fieldTitle} = benfitsItem?.entity || ""
  const storyLane = {
    storyLaneUrl: fieldIframeUrl
  }
  const graphicImage = getImageObject(benfitsItem?.entity?.fieldGraphic, benfitsItem?.entity?.relationships?.fieldGraphic)
  useEffect(() => {
    setStoryLaneVisible(isElementInViewport(storyLaneRef.current))
    if(document && location?.hash){
      let scrollPos = document.getElementById("demo-tabs-section")
      setTimeout(() => {
        scrollPos.scrollIntoView({
          behavior: "smooth"
        })
      },100)
    }
  },[])

  return (
    <div className="intrective-container tw-mt-0 md:tw-mt-[-42px]">
      <div id="demo-tabs-section" className="demo-tabs-section"></div>
      <div class="intrective-tabs tabs is-large">
        <ul className=" tw-items-center tw-flex tw-flex-grow-[1] tw-flex-shrink-0 tw-justify-start !tw-border-b-0">
          {!!tabs?.length && tabs?.map((tab, index) => {
            return(
              <li key={index} className={activeTab === tab?.toLowerCase() ? "is-active !tw-border-b-accruent_sapphire tw-border-b-4 tw-z-[1] tw-inline-block": " tw-border-b-4 tw-border-b-accruent_gray tw-z-[1] tw-inline-block "}> 
                <LocalizedLink className=" !tw-p-[22px_32px] tw-font-Roboto tw-font-bold tw-text-[16px] tw-tracking-[0px] tw-text-accruent_black_input tw-border-b-0" to={`#${tab?.replaceAll(" ","-").toLowerCase()}`}>{tab}</LocalizedLink>
              </li>
            )
          })}
        </ul>
      </div>
      <div className="tab-body tr-mt-[25px] tw-mb-[66px]">
        {body?.value && <div
          className="para tw-text-left tw-font-Roboto tw-text-[18px] tw-tracking-[0px] tw-text-accruent_base_color tw-mb-[26px] md:tw-w-[572px]"
          dangerouslySetInnerHTML={{ __html: body?.value }}
        />}
        <div className="tab-buttons tw-flex tw-flex-row tw-gap-[60px] tw-items-center">
          {!!fieldLinkToOriginalArticle?.length && fieldLinkToOriginalArticle?.map((link, index) => {
            return(
              <LocalizedLink className="common_button tw-border-none tw-outline-none tw-text-left tw-text-[16px] tw-font-Roboto tw-font-bold tw-tracking-[0px] tw-text-accruent_sapphire tw-bg-transparent tw-cursor-pointer tw-pl-[24px] common_first" key={index} to={link?.url}>{link?.title}</LocalizedLink>
            )
          })}
        </div>
      </div>
      <div className="tab-body-sec">
        <div class=" tw-flex tw-m-[-12px] tw-flex-col-reverse md:tw-flex-row">
          <div class=" md:tw-w-3/4 content_img tw-box-shadow-demo tw-rounded-[20px] tw-bg-white tw-z-[10] !tw-p-0 ">
            <div class="sl-embed" ref={storyLaneRef}>
              {(storyLaneVisible || shouldLoad) && <StoryLane storyLane={storyLane}/>}
            </div>
          </div>
          <div class=" md:tw-w-1/4  content_sub tw-flex tw-flex-col tw-items-center tw-p-[12px]">
            <Picture className="logo_img  " image={graphicImage}/>
            <div className="booking-para">
              {fieldTitle && <h2 className=" tw-text-left tw-font-Roboto tw-text-[18px] md:tw-text-[28px] xl:tw-text-[32px] tw-font-bold tw-tracking-[0.31px] tw-text-accruent-heading-color tw-mb-[8px] ">{fieldTitle}</h2>}
              {fieldShortDesc && <p className="tw-text-left tw-text-[14px] md:tw-text-[18px] xl:tw-text-[20px] tw-font-medium tw-font-Roboto tw-tracking-[0.15px] tw-text-accruent-base-color ">{fieldShortDesc}</p>}
            </div>
          </div>
        </div>
      </div>
      <div className="tab-footer tw-mb-[-100px]">
        <div className="full-width-bg-cover tw-bg-accruent_sapphire tw-mt-[-140px] ">
          <div className="footer_content tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-pt-[190px] tw-pb-[95px]">
            {footerBody?.value && <div
              className="para-footer tw-text-center tw-font-medium tw-text-[24px] tw-font-Roboto tw-tracking-[0.22px] tw-text-accruent_white tw-mb-[16px]"
              dangerouslySetInnerHTML={{ __html: footerBody?.value }}
            />}
            {footerCtaUrl && 
                  <section>
                  <DemoForm
                   serial={serial}
                   formId={id}
                   gaLabel={gaLabel}
                   shouldLoad={(shouldLoad)}
                 />
               </section>
            // <LocalizedLink className="request_btn" to={footerCtaUrl}>{footerCtaTitle}</LocalizedLink>
          }
          </div>
        </div>
        
      </div>
    </div>
  )
}
