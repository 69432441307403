import React from "react";
import {AccruentSlider} from "src/components/commonComponents";
import TeaserCarouselItem from "./TeaserCarouselItem";
import getPublishedData from "src/../helpers/getPublishedData";

const TeaserCarousel = (props) => {
    const { carouselItems, locale, isHome, homepageBackground, className, myAccruentModified = false } = props
    const items = getPublishedData(carouselItems);
    return (
        <div
            className={`teaser-carousel tw-mt-8 md:tw-mt-0 tw-mb-0 tw-mx-auto tw-w-full md:tw-w-4/5 ${className}`}
            component="TeaserCarousel"
        >
            <AccruentSlider sliderId="teaser-carousel">
                {items.map((entity, index) => {
                    return entity ? <TeaserCarouselItem entity={entity} key={index} locale={locale} isHome={isHome} homepageBackground={homepageBackground} myAccruentModified={myAccruentModified} /> : false
                })}
            </AccruentSlider>
        </div>
    );
}

export default TeaserCarousel
