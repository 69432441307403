import React from 'react';
import TextTruncate from "react-text-truncate";
import { StaticImage } from "gatsby-plugin-image";
import findSiteType from "src/components/helpers/findSiteType";
import { AccruentImg, LocalizedLink } from '../commonComponents';
const imageBasePath = "../../../static/";
const prefixLangcode = require('src/../helpers/prefixLangcode');

const EventSection = ({ data, title, whiteBg, seeAllTitle, seeAllLink, news, locale }) => (
  <section>
    <div className={`container event-wrapper`}>
      <div className="columns">
        <div className="column is-6-desktop event-header">
          <h3 className="title title-4">{data?.[0]?.["newsTitle"] ? data?.[0]?.["newsTitle"] : title}</h3>
          <div className="see-all">
            <LocalizedLink
              to={seeAllLink}
            >
              {seeAllTitle}
            </LocalizedLink>
          </div>
          <div className="event-header-border-top"></div>
        </div>
      </div>

      <div className="columns is-mobile is-multiline event-cards is-flex-direction-row-reverse">
        {data.map((event, index) => {
        const link = prefixLangcode(event?.link, locale)
         return (
          <div
            className="column is-6-desktop is-6-tablet is-12-mobile event-card"
            key={event.id || index}
          >
            <div className={`homepage-events-card ${whiteBg ? 'white-bg' : ''}`}>
              <div>
                {event.logo && (
                  <div className="homepage-events-card-image">
                     {!event.logo?.gatsbyImage ?
                       (
                         findSiteType('mc') ?
                           <StaticImage
                             className="has-height-100-percent"
                             src={imageBasePath + "mc-social-share.png"}
                             alt={"default Image"}
                             aspectRatio={2.0}
                             placeholder="blurred"
                             width={720}
                             style={{height: '100%'}}
                             decoding = "async"
                           /> :
                           findSiteType('ems') ?
                             <StaticImage
                               className="has-height-100-percent"
                               src={imageBasePath + "ems-social-share.png"}
                               alt={"default Image"}
                               aspectRatio={2.0}
                               placeholder="blurred"
                               width={720}
                               style={{height: '100%'}}
                               decoding = "async"
                             /> :
                             <StaticImage
                               className="has-height-100-percent"
                               src={imageBasePath + "accruent-social-share.png"}
                               alt={"default Image"}
                               aspectRatio={2.0}
                               placeholder="blurred"
                               width={720}
                               style={{height: '100%'}}
                               decoding = "async"
                             />
                       ) :
                       <AccruentImg
                         file={event.logo}
                         className="has-height-100-percent"
                         objectFit="cover"
                         width={720}
                         style={{height: '100%'}}
                       />
                     }
                    {(event?.fieldTag && news) && <div className="card-image-tag">{event?.fieldTag}</div>}
                  </div>
                )}
                <div className="homepage-events-card-title-wrapper">
                  {(event?.fieldPublishDate && news) && <div className="homepage-events-card-publish-date">{event?.fieldPublishDate}</div>}
                  <div className="homepage-events-card-title">
                    <span className={`title-5 tw-text-mobileXlFont md:tw-text-pxSize5 ${!news ? 'not-news' : ''}`}>
                      {event?.title?.length > 60 ? `${event.title.slice(0, 60)}...` : event.title}
                    </span>
                  </div>
                </div>
                <div className="homepage-events-card-description-wrapper">
                  <div className={`homepage-events-card-description ${news ? 'news-card' : ''}`}>
                    <TextTruncate
                      line={2}
                      element="span"
                      truncateText="…"
                      text={event.description}
                      className={`subtitle-2 ${news ? 'news-card-description' : ''}`}
                    />
                  </div>
                </div>
              </div>

              <div className="homepage-events-card-date-wrapper">
                <div className="homepage-events-card-date">
                  {event.startDateFormated && <div className="time-box">
                    <div className="icon">
                      {" "}
                      <i className="fal fa-calendar-day"></i>
                    </div>
                    <div className="time-date-box">
                      <p className="date">{event.startDateFormated} {event.endDateFormated && <span> - {event.endDateFormated}</span>}</p>
                      {event.startTime && (<p className="time">
                        {event.startTime} {event.endTime && <span> - {event.endTime} </span>} <span className="time-zone">{event.timeZone}</span>
                      </p>)}
                    </div>
                  </div>}
                  <LocalizedLink to={link} className="learn-more">
                    {event.linkTitle}
                  </LocalizedLink>
                </div>
              </div>
              <LocalizedLink className='main-card-link' to={link}>

              </LocalizedLink>
            </div>
          </div>
        )})}
      </div>
    </div>
  </section>
);

export default EventSection;
