import React, { useEffect, useRef } from 'react'

const getBrowserHeight=()=>{
    let h=1024
    if(window.innerWidth !== undefined && window.innerHeight !== undefined) {
         h = window.innerHeight;
      } else {
         h = document.documentElement.clientHeight;
      }
      return h;
}

export const StickyBox = ({children,scrollContainerClass,secondaryContentClass='resource-secondary-content', leftContentClass='left-wrapper'})=> {
    const ref = useRef(null)

    const handleScroll = (e) => {
        let leftContent = document.getElementsByClassName(leftContentClass)
        let headerNav = document.getElementsByClassName('lower-nav')
        let container = document.getElementsByClassName('relative-pos')
        let el = document.getElementsByClassName('sticky-box')
        if(!el?.[0]?.classList.contains('sticky-box-stuck') && (leftContent?.[0]?.getBoundingClientRect().height) < el?.[0]?.getBoundingClientRect().height + 6000)
         {
             return;
         }
        if ( container?.[0]?.getBoundingClientRect()?.y < headerNav?.[0]?.getBoundingClientRect().height) {
            el?.[0]?.classList.add('sticky-box-stuck')
            // el?.[0]?.classList.remove('sticky-box-fadeout')
        } else {
            el?.[0]?.classList.remove('sticky-box-stuck')
        }

        // handle box on footer
        let resourceSecondarySection = document.getElementsByClassName(secondaryContentClass)
        let rect1 = el?.[0]?.getBoundingClientRect()
        let rect2 = resourceSecondarySection?.[0]?.getBoundingClientRect()
        let overlap = !(rect1?.right < rect2?.left ||
            rect1?.left > rect2?.right ||
            rect1?.bottom < rect2?.top)
        if (overlap) {
            el?.[0]?.classList.remove('sticky-box-stuck')
            // el?.[0]?.classList.add('sticky-box-fadeout')

        }
    }

    useEffect(() => {
    if(920>getBrowserHeight()){
            let scrollContainer = document.getElementsByClassName(scrollContainerClass)
            scrollContainer?.[0]?.classList.add('overflow-scroll')
        }

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        }
    }, [])

    return (
        <div className="relative-pos">
            <div className="sticky-box" ref={ref}>
                 {children}
            </div>
        </div>
    )
}

