import React, { useState, useMemo, useRef } from "react";
import { useStaticQuery, graphql } from "gatsby";

import { useLocation } from "@reach/router";
import { Modal } from "./modals";
import { LocalizedLink } from "./localizedLink";
//https://bulma.io/documentation/elements/icon/
export const ShareFollowBox = ({ newswireLink }) => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `);

  /**
   * This location is only available on run time and not build time
   * because it uses hook from reach-router https://reach.tech/router/api/useLocation
   * The functionality related to this value is only important on the browser.
   */
  const location = useLocation();

  const [hasCopied, setHasCopied] = useState(false);
  const currentPageUrl = useMemo(() => {
    return data.site.siteMetadata.siteUrl + location.pathname;
  }, [location, data]);

  const modalInputRef = useRef();
  const handleCopyUrl = () => {
    const input = modalInputRef.current;
    input.select();
    document.execCommand("copy");
    setHasCopied(true);
  };

  const [isOpen, setIsOpen] = useState(false);
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
    setHasCopied(false);
  };
  const iconsStyle = "tw-social-icon"
  return (
    <div className="tw-text-smFont tw-uppercase tw-text-accruent-base-color tw-h-[26px] tw-mb-4 md:tw-h-auto md:tw-mb-0">
      {isOpen && (
        <Modal isOpen={isOpen} onClose={closeModal}>
          <div className="box share-box has-text-left">
            <h4 className="title title-5 has-text-transform-none">
              Copy and paste this URL to view or share current page
            </h4>
            <div className="columns is-centered is-vcentered">
              <div className="column is-10">
                <input
                  ref={modalInputRef}
                  value={currentPageUrl}
                  className="input"
                />
              </div>
              <div className="column">
                <button className="button is-primary" onClick={handleCopyUrl}>
                  {hasCopied && (
                    <span className="icon">
                      <i className="fal fa-check"></i>
                    </span>
                  )}
                  <span>Copy</span>
                </button>
              </div>
            </div>
          </div>
        </Modal>
      )}
      <span className="tw-mr-2 tw-inline-block tw-leading-4 tw-tracking-[2.1px] tw-font-Roboto tw-text-accruent_base_color tw-h-[18px] md:tw-h-auto">Share</span>
      <a
        href=""
        onClick={(e) => {
          e.preventDefault();
          openModal();
        }}
        aria-label="Share Accruent"
      >
        <span className="tw-social-icon">
          <i className="fal fa-share-alt"></i>
        </span>
      </a>

      <span className="set-height tw-mr-[8px] tw-inline-block tw-leading-4 tw-tracking-[2.1px] tw-font-Roboto tw-text-accruent_base_color tw-h-[18px] md:tw-h-auto">Follow Us</span>
      <a
        href="https://www.facebook.com/Accruent"
        target="_blank"
        aria-label="Accruent on Facebook"
      >
        <span className={iconsStyle}>
          <i className="fab fa-facebook-f"></i>
        </span>
      </a>

      <a
        href="https://www.linkedin.com/company/accruent"
        target="_blank"
        aria-label="Accruent on Linkedin"
      >
        <span className={iconsStyle}>
          <i className="fab fa-linkedin-in"></i>
        </span>
      </a>
      <a
        href="https://twitter.com/accruentllc"
        target="_blank"
        aria-label="Accruent on Twitter"
      >
        <span className={iconsStyle}>
          <i className="fab fa-x-twitter"></i>
        </span>
      </a>
      <a
        href="https://www.instagram.com/accruentllc/"
        target="_blank"
        aria-label="Accruent on Instagram"
      >
        <span className={iconsStyle}>
          <i className="fab fa-instagram"></i>
        </span>
      </a>
      <a
        href="https://www.youtube.com/@AccruentOfficial?app=desktop"
        target="_blank"
        aria-label="Accruent on Youtube"
      >
        <span className={iconsStyle}>
          <i className="fab fa-youtube"></i>
        </span>
      </a>
      {newswireLink && (
        <span className="newswire-link">
          <LocalizedLink
            to={newswireLink.url}
          >
            {newswireLink.title}
          </LocalizedLink>
        </span>
      )}
    </div>
  );
};

