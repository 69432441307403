import React from "react";
import { AccruentImg } from "./accruentImg";
const getImageObject = require("src/../helpers/getImageObject");

export const FullWidthImage = ({ data }) => {
  const fieldMediaImage = getImageObject(data?.relationships?.fieldMediaImage,{},"fieldMediaImage")
  return (
    <div>
      {((!data?.fieldHideTitle && data?.title) || data?.body?.value) && <div className="full-width-image-container tw-columns">
        <div className="tw-column tw-w-full lg:tw-w-[75%]">
          {!(data?.fieldHideTitle) && <h2 className="title-3 tw-mb-[1.5rem]">{data?.title}</h2>}
         {!!data?.body?.value && <p className="tw-text-[1.25rem]">
            <div
              dangerouslySetInnerHTML={{
                __html: data?.body?.value,
              }}
            />
          </p>}
        </div>
      </div>}
      <AccruentImg
        file={fieldMediaImage}
        className="full-width-image tw-w-full"
      />
    </div>
  );
};

