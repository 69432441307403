import React, {useState} from "react";
import cn from "classnames";
import { IndividualResourceCard } from "src/components/solutions/Resources"
import loadable from '@loadable/component'
const SidebarSummary = loadable(() => import('src/components/sidebar/SidebarSummary'))
const ShareFollowBox = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.ShareFollowBox
 });

export const SideBar = ({ recommendedResources, inThisArticle, hideShareBox }) => {
  const [collapse,setCollapse] = useState(false)

  return (
    <aside className="new-side-bar-wrapper">
      {!hideShareBox && <ShareFollowBox />}
      <SidebarSummary inThisArticle={inThisArticle} />
      <div className="tw-bg-accruent_kingfisher_daisy tw-h-[4px] tw-w-full tw-mt-[34px] tw-mb-10 border-box"></div>
      {recommendedResources.length > 0 &&
      <>
      <div className="righ-min-scroll">
        <div className="collapse-wrapper">
          <div id="accordion-right">
            <div className="tw-bg-transparent">
              <div onClick={()=>setCollapse(!collapse)} className="tw-pb-8 tw-border-b-0 tw-pl-0 tw-bg-transparent tw-b-0 tw-pr-2 tw-pt-4 tw-text-lgFont tw-font-Poppins" id="heading_2">
                <a
                  className="tw-base_transition tw-flex tw-justify-between tw-text-lgFont tw-cursor-pointer tw-w-full tw-text-accruent-heading-color tw-uppercase"
                  data-toggle="collapse"
                  data-target="#left_2"
                  aria-expanded="false"
                  aria-controls="left_2"
                >
                  <span>YOU MAY ALSO LIKE</span>
                  <span className="arrow">
                    <span className="down tw-inline-block">
                      <i className="fas fa-angle-down"></i>
                    </span>
                    {/* <span className="up">
                      <i className="fas fa-angle-up"></i>
                    </span> */}
                  </span>
                </a>
              </div>
              <div
                id="left_2"
                className={cn("",{"tw-h-0 tw-overflow-hidden":collapse})}
                aria-labelledby="heading_2"
                data-parent="#accordion-right"
              >
              <div>
                {recommendedResources.length > 0 &&
                  recommendedResources.map((card) => (
                    <IndividualResourceCard
                      data={card}
                      className="column is-12"
                      key={card.title}
                      type={'no-card-wrapper'}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="tw-bg-accruent_kingfisher_daisy tw-h-[4px] tw-w-full tw-mt-[34px] tw-mb-10 border-box"></div>
      </>  }

    </aside>
  );
};

// export default SideBar;
