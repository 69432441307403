const getImageObject = require("./getImageObject")
const getNavLevel = (entity) => {
  if (!entity?.parent) return 1;
  if (!entity?.parent?.[0]) return 1;
  return getNavLevel(entity?.parent?.[0]) + 1;
};

const getNavbarData = (entities, language) => {

  const formattedEntities = [];
  let maxNavLevel = 1;
  if (entities?.navItems === null || !entities?.navItems) return []
  entities.navItems?.forEach((entity) => {
    const isLocaleEntity = entity?.locale === language;
    if (!isLocaleEntity) return
    const { entityId, description, name, uuid, fieldTermHighlight, fieldTermSecondCta, fieldMenuWithGraphics, fieldShowPopup } = entity
    const parent = entity?.relationships?.parent
    let { link } = entity;
    const navLevel = getNavLevel(entity?.relationships);
    let parentEntityId;
    if (maxNavLevel < navLevel) maxNavLevel = navLevel;

    if (typeof link === 'object' && link !== null && link.url) {
      link = link.url;
    }

    if (Array.isArray(parent) && parent[0]?.entityId) {
      parentEntityId = parent[0].entityId;
    }
    if (navLevel === 1 || navLevel === 2) {
      var spotlightItem = {}
      if (entity.fieldSpotlightCardTitle != null && (typeof entity.fieldSpotlightCardLink.url !== 'undefined')) {
        spotlightItem = {
          'title': entity?.fieldSpotlightCardTitle,
          'description': entity?.fieldSpotlightCardDescription,
          'image': getImageObject(entity?.relationships?.fieldSpotlightCardImage, entity?.fieldSpotlightCardImage),
          'ctaUrl': entity?.fieldSpotlightCardLink?.url,
          'ctaText': entity?.fieldSpotlightCardLink?.title,
        }
      }
      const {fieldFeaturedCapabilities, fieldFeaturedProducts, fieldHeroImage, fieldProductLogo, fieldQuickCta} = entity?.relationships || ""
      const navbarHeaderImg = getImageObject(fieldHeroImage, entity.fieldHeroImage)
      const navbarGreyImg = getImageObject(fieldProductLogo, entity.fieldProductLogo)
      formattedEntities.push({
        entityId,
        name,
        navLevel,
        parentEntityId,
        link,
        description,
        uuid,
        spotlightItem,
        fieldTermHighlight,
        fieldTermSecondCta,
        fieldFeaturedCapabilities,
        fieldFeaturedProducts,
        navbarHeaderImg,
        fieldMenuWithGraphics,
        fieldQuickCta,
        navbarGreyImg,
        fieldShowPopup
      })
    } else {
      formattedEntities.push({
        entityId,
        name,
        navLevel,
        parentEntityId,
        link,
        description,
        uuid,
      })
    }
  });
  for (let i = 0; i < formattedEntities.length; i++) {
    const childNodes = formattedEntities.filter(
      (node) => node.parentEntityId === formattedEntities[i].entityId
    );
    formattedEntities[i].children = childNodes;
    formattedEntities[i].children.forEach((node) => {
      node.parent = formattedEntities[i];
    });
  }
  for (const entity of formattedEntities) {
    delete entity.entityId;
    delete entity.parentEntityId;
  }
  return formattedEntities.filter(({ navLevel }) => navLevel === 1);
};

export default getNavbarData;
