import React from "react";
import { AccruentImg } from "src/components/commonComponents";

export const BrandLogoWithForm = ({ entity }) => {
  return (
    <div className='brand-logo-section'>
      <div className='eyebrow'>{entity?.title}</div>
      <div className='columns is-multiline'>
        {entity?.relationships?.fieldLogoImages.map((item, index) => {
          
          return (
            <div className='column is-3-desktop is-6-mobile' key={index}>
              <div className='logo-card tw-h-[56px] tw-w-[152px] tw-rounded-[15px] tw-bg-[#F5F5F5]'>
                <AccruentImg
                  file={item?.gatsbyImage}
                />
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}
// export default BrandLogoWithForm;