/**
 * Render dynamic cta horizontally based on ctaProps.
 * @param {Array} ctaProps -(required) It should be an array of objects with required values for url and title.
 * @param {Boolean} desktop - To change css styles for desktop/mobile versions using bulma classname.
 * 
 */

import React, {memo} from "react";
import { LocalizedLink } from "./localizedLink";
import '../../scss/components/_ctaLinks.scss'
export const CtaLinks = memo(({ctaProps, desktop}) => {
  return(
    <div className={`tw-cta-links-component tw-flex tw-gap-4 tw-pt-[22px] ${desktop ? "is-hidden-mobile": "is-hidden-desktop tw-pt-0 tw-pb-[53px]"}`}>
      {ctaProps?.map((cta, index) => {
        return(
          <>
            {cta?.url && <div className={`tw-cta-link tw-border-accruent_dark_imp_blue tw-rounded-xl tw-border-2 tw-border-solid tw-bg-accruent_dark_imp_blue ${cta?.background}`} key={index}>
              <LocalizedLink to={cta?.url}>{cta?.title}</LocalizedLink>
            </div>}
          </>
        )
      })}
    </div>
  )
});

