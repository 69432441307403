import React from 'react'
import "src/scss/components/_pageLoader.scss";
import "src/scss/components/_formsLoader.scss";

export  const Loader=() =>{

  return (
    <div
      key={`loader`}
      id="___page_loader"
      className="loader-container tw-items-center tw-bg-white tw-flex tw-justify-center tw-absolute tw-w-full tw-h-full tw-left-0 tw-top-0 tw-right-0 tw-bottom-0 tw-z-[90]"
    >
      <div className="grey-bg-overlay tw-bg-white tw-absolute tw-h-full tw-w-full tw-top-[40px] tw-bottom-0 tw-left-0 tw-right-0"></div>
      <div className="page-loader tw-relative tw-w-10 tw-h-10">
        <div className="spinner tw-relative tw-w-full tw-h-full tw-inline-block">
          <div className="bar1 tw-w-[8%] tw-h-[17%] tw-bg-accruent_sapphire tw-absolute tw-left-[44.5%] tw-top-[37%] tw-opacity-0 tw-rounded-[50px]"></div>
          <div className="bar2 tw-w-[8%] tw-h-[17%] tw-bg-accruent_sapphire tw-absolute tw-left-[44.5%] tw-top-[37%] tw-opacity-0 tw-rounded-[50px]"></div>
          <div className="bar3 tw-w-[8%] tw-h-[17%] tw-bg-accruent_sapphire tw-absolute tw-left-[44.5%] tw-top-[37%] tw-opacity-0 tw-rounded-[50px]"></div>
          <div className="bar4 tw-w-[8%] tw-h-[17%] tw-bg-accruent_sapphire tw-absolute tw-left-[44.5%] tw-top-[37%] tw-opacity-0 tw-rounded-[50px]"></div>
          <div className="bar5 tw-w-[8%] tw-h-[17%] tw-bg-accruent_sapphire tw-absolute tw-left-[44.5%] tw-top-[37%] tw-opacity-0 tw-rounded-[50px]"></div>
          <div className="bar6 tw-w-[8%] tw-h-[17%] tw-bg-accruent_sapphire tw-absolute tw-left-[44.5%] tw-top-[37%] tw-opacity-0 tw-rounded-[50px]"></div>
          <div className="bar7 tw-w-[8%] tw-h-[17%] tw-bg-accruent_sapphire tw-absolute tw-left-[44.5%] tw-top-[37%] tw-opacity-0 tw-rounded-[50px]"></div>
          <div className="bar8 tw-w-[8%] tw-h-[17%] tw-bg-accruent_sapphire tw-absolute tw-left-[44.5%] tw-top-[37%] tw-opacity-0 tw-rounded-[50px]"></div>
        </div>
      </div>
    </div>
  );
}

