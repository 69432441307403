import getUrlPathBySiteId from 'src/../helpers/getUrlPathBySiteId';
const getTeaserCarouselDataFromResource = (contentCarouselSection) => {

    if (contentCarouselSection) {
        let obj = {
            title: contentCarouselSection.title,
            hideTitle: contentCarouselSection.fieldHideTitle,
            fieldShowTitleFullWidth: contentCarouselSection?.fieldShowTitleFullWidth,
            body: contentCarouselSection.body,
            carouselItems: contentCarouselSection.relationships?.fieldResourceCarouselItem?.map((entity) => {
                const isCaseStudy = entity?.type?.id === "case_study"

                if (!entity.fieldOverrideItem && entity.relationships?.fieldResourceCarouselItems) {
                    let item = entity.relationships?.fieldResourceCarouselItems
                    return {
                        teaserImage: isCaseStudy ? item?.fieldMediaImage : item?.fieldTeaserImage,
                        teaserLogo: item?.fieldTeaserLogo,
                        description: item?.fieldCustomerFeedback,
                        name: item?.fieldCustomerName,
                        designation: item?.fieldCustomerDesignation,
                        url: { path: getUrlPathBySiteId(item?.fieldTargetSite?.targetId, item?.path?.alias) },
                        status: item?.status,
                    }
                }
                return {
                    teaserImage: isCaseStudy ? entity?.fieldMediaImage : entity?.fieldTeaserImage,
                    teaserLogo: entity?.fieldTeaserLogo,
                    description: entity?.fieldCustomerFeedback,
                    name: entity?.fieldCustomerName,
                    designation: entity?.fieldCustomerDesignation,
                    url: { path: getUrlPathBySiteId(entity?.fieldTargetSite?.targetId, entity?.path?.alias) },
                    status: entity?.status,
                }
            })
        }
        return obj

    }

    return contentCarouselSection
}

export default getTeaserCarouselDataFromResource;
