import React, { useEffect } from "react";
import { Video } from "src/components/commonComponents";
import { CommonFormHeader } from "src/components/templateComponents";
import PopupWithForm from "src/components/commonComponents/forms/PopupWithForm";
import "../../../styles/_mutipleVideosForm.css";
export const FormPageWithMultipleVideos = ({form, pageContext, fieldFormHeader, isFormLoaded, popup, isFormWithPopupLoaded, formTagPopup, fieldFormPopHeader, fieldShowFormInPopup = false}) => {

  const {fieldSubtitle, title, fieldHeroTitle, fieldHeroSubtitle} = pageContext
  const fieldVideos = pageContext?.relationships?.fieldVideos

  return (
    <section className="tw-font-Roboto tw-text-accruent_base_color tw-block ">
      <div className="">
        <div className="tw-px-4 md:tw-px-0 md:tw-pb-0 tw-pb-[52px] watch-demo-wrapper four-box-watch-demo-wrapper tw-relative">
          <div className="container">
            <div className="tw-pt-[37px]">
              {fieldHeroTitle && (<h1 className="!tw-mb-4 md:tw-text-headingFont tw-text-xxxlFont tw-font-Poppins tw-text-accruent_heading_color">{fieldHeroTitle}</h1>)}
              {fieldHeroSubtitle &&
                <h2 className="tw-mb-4 md:tw-w-3/4 tw-w-full md:tw-text-xlFont lg:tw-leading-[27.84px] tw-text-[22px] tw-leading-[25.2px] tw-tracking-[.2px] tw-font-Poppins tw-text-accruent_heading_color">
                  {fieldHeroSubtitle}
                </h2>}
              <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-4 lg:tw-grid-cols-2 tw-flex-wrap tw-mt-0 -tw-mx-3">
                {fieldVideos?.length !== 0 &&
                  fieldVideos?.map((video, index) => {
                    return (
                      <div key={index} className="tw-mb-[52px] tw-py-0 tw-px-2 lg:tw-flex-none tw-w-full tw-block md:tw-basis-0 md:tw-shrink md:tw-grow">
                        {(video?.fieldMediaVideoEmbedField) &&
                          <div>
                            <Video videoUrl={video.fieldMediaVideoEmbedField} videoStandardEmbedCode={video?.fieldVideoTranscript?.value} />
                            <div className="tw-text-accruent_heading_color tw-font-Poppins tw-font-bold tw-text-[20px] tw-leading-[23px] tw-break-normal tw-mt-[16.5px]">{video?.fieldTitle || video?.name}</div>
                          </div>
                        }
                      </div>
                    )
                  })
                }
              </div>
            </div>
            {fieldShowFormInPopup && formTagPopup && <PopupWithForm
              form={formTagPopup}
              fieldFormHeader={fieldFormPopHeader}
              isFormLoaded={isFormWithPopupLoaded}
              popup={popup}
            />}
            <div className="tw-pt-[43px] md:tw-pb-[76px] tw-pb-9">
              <div className="md:tw-flex tw-flex-none -tw-my-3 -tw-mx-3">
                <div className="tw-block md:tw-basis-0 md:tw-grow md:tw-shrink tw-px-3 tw-pb-3">
                  <div className="md:tw-pr-[26px] tw-pr-0">
                    {title && (<h2 className="tw-mb-4 md:tw-w-3/4 tw-w-full md:tw-text-headingFont tw-text-xxxlFont tw-text-accruent_heading_color tw-font-Poppins">{title}</h2>)}
                    <p className="md:tw-text-2xl tw-text-[22px] md:tw-leading-[29px] tw-leading-[26px] md:tw-mb-4 tw-mb-2 md:tw-w-[65%] tw-w-full">
                      {fieldSubtitle}
                    </p>
                    <div className="tw-bg-full tw-h-[293px] md:tw-block tw-hidden"></div>
                  </div>
                </div>
                <div className="tw-block md:tw-basis-0 md:tw-grow md:tw-shrink tw-px-3 tw-pb-3">
                  <div className="md:tw-pl-[26px] tw-pl-0 tw-min-h-[400px] tw-relative form-watch-demo-box watch-demo-redesign-form">
                    <div className=" tw-bg-accruent_purple_form tw-h-1 tw-w-full"></div>
                    <CommonFormHeader
                      formTag={form}
                      fieldFormHeader={fieldFormHeader}
                      isFormLoaded={isFormLoaded}
                    />
                    <div className="tw-bg-accruent_purple_form tw-h-1 tw-w-full tw-mt-5 lg:tw-mb-5"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

// export default FormPageWithMultipleVideos;