/**
 * Function to get published data for an entities
 * @param {Array} entities Entity array
 */
const getPublishedData = (entities) => {
    if (Array.isArray(entities)) {
        return entities?.filter(entity => entity?.status)
    }
    return entities;
}

export default getPublishedData