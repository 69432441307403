import React from "react";
import { get } from "src/utils";
// import LocalizedLink from "src/components/LocalizedLink";
import getAuthorName from "../../../../helpers/getAuthorName";
import getResourceTagsList from "../../../../helpers/getResourceTagsList";
// import Hero from "src/components/hero/ResourcesHero";
import { ResourcesHero as Hero} from "src/components/templateComponents";
import { Eyebrow, LocalizedLink } from "src/components/commonComponents";
import splitBodyContent from "../../helpers/splitBodyContent";
import loadable from '@loadable/component';
import useAddParamInHtmlLink from "src/components/helpers/useAddParamInHtmlLink";
// const SideBar = loadable(() => import('../../components/resources/ResourceSideBar'));
const SideBar = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.SideBar
});
const StickyBox = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.StickyBox
});
// const Picture = loadable(() => import('src/components/Picture'))
const Picture = loadable(() => import("src/components/commonComponents"), {
  resolveComponent: (components) => components.Picture
});
// const PopupVideo = loadable(() => import('src/components/popupVideo/PopupVideo'))
const PopupVideo = loadable(() => import("src/components/commonComponents"), {
  resolveComponent: (components) => components.PopupVideo
});

export const SingleBrochurePost = ({ data }) => {
  const { locale } = data;
  const authorName = getAuthorName(data);
  const resourceTags = getResourceTagsList([data.industryIds, data.solutionIds, data.productIds])
  let heroImage = get(data, "heroImage");
  const heroVideoUrl = get(data, "relationships.fieldHeroVideo.fieldMediaVideoEmbedField");
  const heroVideoThumb = get(data, "relationships.fieldHeroVideo.relationships.thumbnail.url");

  let splitFunc = splitBodyContent(data?.body?.value);
  let firstContentToString = splitFunc?.firstContentToString;
  let secondContentToString = splitFunc?.secondContentToString;
  useAddParamInHtmlLink()

  return (
    <section className="resources-page section !tw-pt-0 !tw-pb-0 content-visibility-auto-mobile">
      <div className="container brochure-page">
       
      <section className="medium-wrapper tw-text-[16px] md:tw-text-[18px] tw-py-0 tw-px-[15px] md:tw-px-0 md:tw-pl-[12px] md:tw-py-0 tw-mt-[32px] md:tw-mt-[72px]">
          <div className="columns md:tw-flex">
            <div className="tw-w-full lg:tw-w-[calc(100%-376px)] lg:tw-max-w-[calc(100%-376px)] [&]:tw-px-0 [&]:md:tw-pr-3">
              {!!authorName && (
                <div className="subtitle is-size-5 is-capitalized">
                  By {authorName}
                </div>
              )}
              {data.body && <div
                className="new-single-resource-content-body dangerously-html-handle"
                dangerouslySetInnerHTML={{ __html: firstContentToString }}
              ></div>
              }
              <div className="middle-of-content mb-2">
               {heroVideoUrl ?
                  <PopupVideo
                    videoUrl={heroVideoUrl}
                    thumbnail={heroVideoThumb}
                    thumbnailClass={`thumbnail-box tw-min-h-[376px] [&]:tw-p-0 is-12-mobile  thumbnail-hero`}
                    isHero
                  />
                  :
                  (heroImage) ?
                    <div className={`tw-min-h-[376px] [&]:tw-p-0`}>
                      <Picture
                        image={heroImage}
                        className={'tw-h-full tw-w-full tw-min-h-[376px] md:tw-min-h-0 tw-object-cover'}
                      />
                    </div> : null
                }
              </div>
              {secondContentToString && <div
                className="new-single-resource-content-body dangerously-html-handle"
                dangerouslySetInnerHTML={{ __html: secondContentToString }}
              ></div>
              }
            </div>

            <div className=" right-wrapper tw-w-full tw-max-w-[376px] lg:tw-p-[12px]">
              <div className="tw-bg-accruent_white tw-w-full md:tw-w-[376px] tw-relative">
              <StickyBox
                  scrollContainerClass={"sidebar-scroll"}
                  >
                  <div className="right-box">
                    <SideBar recommendedResources={data.rectifiedRecommended} />
                  </div>
                  </StickyBox>
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  )
}

// export default SingleBrochurePost;
